const Switch = ({value, setValue}) => {
    
    const toggle = () => {
        if(typeof setValue === 'function') setValue(!value)
    }

    return (
        <div  className={`w-9 h-4 rounded-full cursor-pointer transition-all duration-300 relative ${value ? 'bg-blue-400' : 'bg-bluegray-400 dark:bg-bluegray-500'}`}  onClick={toggle}>
            <div className={`w-3 h-[12px] mt-0.5 mx-0.5 inline-block bg-white rounded-full transition-all duration-300 absolute ${value ? 'left-[54%]' : 'left-[1px]'}`}></div>
        </div>
    )
}

export default Switch