import { GiCheckMark } from 'react-icons/gi'
import { FaExclamationTriangle } from 'react-icons/fa'
import { HiSelector } from 'react-icons/hi'
import { AnimatePresence, motion } from 'framer-motion'
import { TextAnimateX } from '../../../commons/anims'
import { useEffect, useMemo, useRef, useState } from 'react'
import FlowText from '../FlowText'
import { createPortal } from "react-dom"
import { AiFillCloseCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../../store/theme/actions'
import { useTranslation } from 'react-i18next'
const SelectOptionAnim = {
    hidden: {
        height: 0,
        opacity: 0
    },
    visible: {
        height: 'auto',
        opacity: 1,
        zIndex: 10000,
    }
}
let multiSelectTime;
const AssessmentOptionChip = ({ fieldProps, fieldMeta, label, edit, options, fieldHelper, position = 'bottom', loading = false, dataLoaded = false, onChange, type, value, setAnswer, externalValue,ariaLabel }) => {

    const input = useRef(null)
    const wrapRef = useRef(null)
    const [showError, setShowError] = useState(false)
    const [filter, setFilter] = useState('')
    const [focussed, setFocussed] = useState(false)
    const [pos, setPos] = useState({ width: 0, top: 0, left: 0 })
    const [option, setOption] = useState([])
    const [enterPress, setEnterPress] = useState()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [opt,setOpt] = useState(options)
    const makeFocus = () => {
        if (input.current !== null)
            input.current.focus()
    }
    const toggleFocus = () => {
        if (focussed) {
            setFilter('')
            if (input.current !== null) input.current.blur()
        }
        setFocussed(!focussed)
        document.body.removeEventListener('click', toggleFocus)
    }
    const handleBlur = () => {
        setTimeout(() => {
            if (focussed) toggleFocus()
        }, 200)
    }
    useEffect(() => {
        if (focussed) {
            document.body.addEventListener('click', toggleFocus)
            let e = wrapRef.current.getBoundingClientRect()
            let o = { width: `${e.width}px`, left: `${e.x}px` }
            if ((e.top + e.height + 270) > window.innerHeight) {
                o.bottom = `${window.innerHeight - e.top}px`
            } else {
                o.top = `${(e.top + e.height)}px`
            }
            setPos(o)
        }

        return () => document.body.removeEventListener('click', toggleFocus)
    }, [focussed]) // eslint-disable-line

    const toggleError = (val) => setShowError(val)
    const setValue = (ev,value) => {
        fieldHelper.setValue(value)
        if(!option.includes){
            setOpt([...opt,{text:value,value}])
            option.push(value)
        setOption(option) 
        }  
        chgValue(value)
        ev?.stopPropagation()
        clearTimeout(multiSelectTime)
        if(fieldProps.value.includes(value)) fieldHelper.setValue(fieldProps.value.filter(c => c !== value))
        else fieldHelper.setValue([...fieldProps.value, value])
        if(typeof onChange === 'function') onChange(value)
    }

    const removeItem = (ev, val) => {
        removeValue(val)    
        ev?.stopPropagation()
        fieldHelper.setValue(fieldProps.value.filter(c => c !== val))
    }
    const filteredOptions = useMemo(() => {
        // unique.forEach(a =>{
        //     return ({text:a,value:a})
        // })
        let newArr = opt.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim())))
        let unique = [...new Set(newArr.map(a=>({text:a.text,value:a.value})))];
        return unique.map(c=>({text:c.text,value:c.value}))
    // }, [filter, opt, enterPress]) // eslint-disable-line
        },  [filter]) // eslint-disable-line
    const displayText = useMemo(() => {
        if(fieldProps.value === undefined) return false
        let a = fieldProps.value.map(c => {
            let o = opt.find(e => e.value.toString() === c.toString())
            return o ? o : null
        }).filter(c => c !== null)
        // a = [...a,...option]
        if(a.length > 0) {
            return (
                <div className={`flex items-center flex-wrap ${focussed ? 'justify-end' : ''}`}>
                    {
                        !focussed ?
                            a.map(c => (
                                <span key={c.value} className={`flex items-center gap-1 ${focussed ? 'text-[6px]' : 'text-[10px]'} transition-all duration-200 bg-bluegray-400 text-white dark:bg-gray-500 px-2 py-0.5 rounded-xl mx-0.5 my-0.5`}>
                                    {c.text}
                                    <AiFillCloseCircle onClick={(ev) => removeItem(ev, c.value)} />
                                </span>
                            ))
                        :
                            <span className={`${focussed ? 'text-[10px]' : 'text-xs'} transition-all duration-200 bg-bluegray-400 text-white dark:bg-gray-500 px-2 py-0.5 rounded-xl mx-0.5 my-0.5 whitespace-nowrap`}>{a.length} {t('Selected')}</span>
                    }
                </div>
            )
        } else return false
    }, [fieldProps.value, options, focussed])// eslint-disable-line


    const setEntervalue = (value) => {
        let numberCheck = parseInt(value)
        var regex = /^[^\p{L}\p{N}]+$/u;
        if(isNaN(numberCheck) && !regex.test(value)){
            let remove = false;
            fieldProps.value.map(c=>{
                if(c === value){
                    dispatch(setAlert({title:t('Warning'),subtitle:t('Disease already selected'),active:true,type:'warning'}))
                    document.body.removeEventListener('click', toggleFocus)
                    remove = true
                } return null;
            })
            if(!remove){
                if (value) {
                    fieldProps.value.push(value)
                    // fieldHelper.setValue(value)
                    setOpt([...opt,{text:value,value}])
                    option.push(value)
                    setOption(option)
                    toggleFocus()
                    chgValue(value)
                }else{
                    dispatch(setAlert({title:t('Warning'),subtitle:t('Please enter the text to search the disease or to enter the disease'),type:'warning',active:true}))
                }
            }
        }else{
            dispatch(setAlert({title:t('Warning'),subtitle:t('Please enter the valid disease'),active:true,type:'warning'}))
        }
    }

    const chgValue = (e) => {
        if (typeof setAnswer === 'function' && edit) {
            if (type === 'option') {
                // setAnswer(value === e ? '' : e+','+'add')
                setAnswer(value === e ? '' : 'add,'+ e)
            }
        }
    }

    const removeValue = (e) => {
        if(option.length > 0){
            option.map((c, i) => {
                if (c === e) {
                    fieldProps.value.splice(i,1)
                    option.splice(i, 1)
                    fieldHelper.setValue(option)
                    setOption(option)
                    if (typeof setAnswer === 'function' && edit) {
                        if (type === 'option') {
                            console.log("Type",type)
                            setAnswer(value === e ? '' : e +',remove')
                        }
                    }
                } return null;
            })
        }else {
            if (typeof setAnswer === 'function' && edit) {
                if (type === 'option') {
                    setAnswer(value === e ? '' : e +',remove')
                }
            }
        }
    }
    const checkValue=(e)=>{
        let remove = false
        fieldProps.value.map(c=>{
            if(c === e){
                dispatch(setAlert({title:t('Warning'),subtitle:t('Selected Disease Removed'),active:true,type:'warning'}))
                remove = true
            } return null;
        })
        if(!remove){
            setValue(e)
        }
    }

    return (
        <>
            {/* <div className={`relative px-2 py-1 rounded flex flex-wrap gap-4 items-center ${loading ? 'animate-pulse' : ''} mb-5`}>
                {
                    [...option].map(c => (
                        <div key={c} className={`flex items-center text-sm py-1 px-3 rounded-lg ring-1 ring-gray-400 dark:ring-gray-600 ${loading ? 'hover:cursor-wait' : 'hover:cursor-pointer'} ${c ? 'bg-tcolor text-white' : ''}`}>
                            <AnimatePresence>
                                {c &&
                                    <motion.span className="overflow-hidden" initial="hidden" animate="visible" exit="hidden">
                                        <motion.span className={'flex'}><span>{c}</span><button onClick={() => removeValue(c)}><AiFillCloseCircle size="20px" className="pr-1 ml-1 -mb-1" /></button></motion.span>
                                    </motion.span>
                                }
                            </AnimatePresence>
                        </div>
                    ))
                }
            </div> */}
            <div className="relative">
            <div ref={wrapRef} className={`group relative flex flex-col ${edit ? 'ring-1 ring-gray-300 dark:ring-gray-600' : ''} px-2 pt-1 rounded-lg ${focussed ? ' focus-within:ring-tcolor dark:focus-within:ring-tcolor' : ''} transition-all duration-100 cursor-pointer ${loading ? 'animate-pulse' : ''}`} onClick={makeFocus}>
                <span className={`${edit ? 'ml-1' : ''} text-xs text-gray-500 dark:text-gray-400`}><FlowText text={label} direction="x" /></span>
                <div className="relative">
                    {/* <input ref={input} type="text" className="h-0 w-0 absolute" {...fieldProps} onFocus={toggleFocus} /> */}
                    {!edit && dataLoaded &&
                        <div className={`pb-2 h-8 ${fieldProps.value.length > 0 ? '' : 'text-gray-500 line-through'}`}>{displayText || t('No Data')}</div>
                    }
                    {
                        !dataLoaded &&
                        <div className="w-3/4 h-2 my-2 rounded-lg animate-pulse bg-gray-300"></div>
                    }
                    {
                        edit && dataLoaded &&
                        <>
                            <div className={`pb-1.5 pl-3 min-h-8 w-[96%]  ${fieldProps.value?.length > 0 ? '' : 'text-zinc-700 dark:text-white '} ${focussed ? 'absolute -top-3 right-10 w-full' : ''}`}>{displayText || (focussed ? '' : `${label}`)}</div>
                            <input ref={input} className={ `pb-1.5 pl-3 bg-transparent pr-10 w-full outline-none ${!focussed ? 'h-0 w-0 absolute': ''}` } type="text" value={filter} autoComplete="off" onFocus={toggleFocus} onBlur={handleBlur} onChange={ev => setFilter(ev.target.value)} onKeyPress={ev => { if (ev.code === "Enter") { setEntervalue(ev.target.value) } else { setEnterPress(0) } }} aria-label={ariaLabel}/>
                        </>
                    }
                    {edit &&
                        <span className="absolute right-2 -top-1 text-xl flex">
                            {fieldMeta.error && fieldMeta.touched && <FaExclamationTriangle className="text-red-500 cursor-pointer" onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} />}
                            <HiSelector className="text-gray-400 ml-2" />
                        </span>
                    }
                    <AnimatePresence>
                        {fieldMeta.error && fieldMeta.touched && showError &&
                            <motion.span variants={TextAnimateX} initial="hidden" animate="visible" exit="hidden" className="absolute -top-1 right-16 inline-block bg-gray-300 dark:bg-ldark py-0.5 px-2 rounded text-sm text-red-500">
                                {fieldMeta.error}
                            </motion.span>
                        }
                    </AnimatePresence>
                </div>
            </div>
            { createPortal(
                <AnimatePresence>
                    {
                        focussed &&
                        <div className="fixed left-0 right-0 bottom-0 top-0">
                            <motion.div variants={SelectOptionAnim} initial="hidden" animate="visible" exit="hidden" className={`fixed overflow-y-auto max-h-[270px] bg-bluegray-200 dark:bg-mdark ring-1 ring-bluegray-300 dark:ring-ldark p-2 rounded`} style={pos}>
                            <span className="inline-block mb-2 text-xs text-tcolor dark:text-gray-400">{label}</span>
                            {
                                filteredOptions.map((c, i) => (
                                    <div key={i} onClick={(ev) => fieldProps.value?.includes(c.value) ? removeItem(ev, c.value) : setValue(ev, c.value)}>
                                        <div onClick={() => checkValue(c.value)} className="py-2 px-3 hover:bg-tcolor hover:text-white rounded-lg text-sm flex justify-between items-center text-bluegray-600 dark:text-gray-300 dark:hover:text-white cursor-pointer">
                                        {c.text}
                                        { fieldProps.value?.includes(c.value) && <GiCheckMark className="text-green-500 w-4 flex-shrink-0" /> }
                                        </div>
                                    </div>
                                ))
                            }
                            { filteredOptions.length === 0 && <span className="block px-5 pb-2 text-gray-500">{t("No Data")}</span>}
                        </motion.div>
                        </div>
                        
                    }
                </AnimatePresence>, document.body)
            }
        </div>
        </>
    )
}

export default AssessmentOptionChip