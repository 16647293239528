import { useEffect, useState, useRef } from "react"
import { MdAssessment } from "react-icons/md"
import { RiInboxArchiveFill, RiDirectionFill,RiRefreshFill,RiAccountBoxFill } from 'react-icons/ri'
import { FaStickyNote ,FaUserPlus,FaRegHandPointRight} from 'react-icons/fa'
import { AiFillPrinter } from 'react-icons/ai'
import QuickAction from '../components/snippets/QuickAction'
import { useDispatch } from "react-redux"
import { setAlert } from '../store/theme/actions'
import API from "../axios/API"
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import Dialog from "../components/snippets/Dialog"
import ClosedCaseDialog from './Dialogs/ClosedCaseDialog'
import TransferCaseDialog from './Dialogs/TransferCaseDialog';
import NotesDialog from './Dialogs/NotesDialog'
import FlowText from '../components/snippets/FlowText'
import ChildOrgTransferDailog from './Dialogs/ChildOrgTransferDailog'
import TransferOrg from './Dialogs/TransferOrg'
import { useTranslation } from "react-i18next"
import {setAssignCaseRender} from '../store/auth/actions'
import { setReopencasecount } from '../store/auth/actions'
import axios from "axios"
import ReferrerCaseDialog from "./Dialogs/ReferrerCaseDialog"
import RemindersDialog from "./Dialogs/RemindersDialog"
import Reassessments from "./Dialogs/Reassessment"
import ReassessDialog from "../components/snippets/ReassesDialog"
import 'moment-timezone';
import moment from 'moment';
import CareplanDialog from "../../src/pages/Dialogs/CareplanDialog"
import { setCloseCaseRender,refreshTrigger} from  '../../src/store/auth/actions';
import DAVDialog from "../components/snippets/DAVDialog"
import DavServiceDialog from "./Dialogs/DavServiceDialog"
import EnhancedClosedDialog from "./Dialogs/EnhancedClosedDialog"


const QuickActionMultiRole = ({ role, screen, caseId, cpId, assessId, status, careReceiverId, caregiverId, careManagerId,asstype ,rescr,refName,reassess,cgPrefLang,relation,davEnable }) => {
    // const [result, setResult] = useState('')
    const caseTable = useRef(null)
    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.auth.userDetails);
    const [activeQuickAction, setActiveQuickAction] = useState({ position: 'bottom', active: null })
    const history = useHistory()
    // const [caseid, setCaseid] = useState()
    const assignCaseRender = useSelector(state => state.auth.assignCaseRender)
    const reopencaseCount = useSelector(state => state.auth.reopencaseCount)
    // const [assignCaseRender, setAssignCaseRender] = useState(0)
    // const [reopencount, setReopencasecount] = useState(0)
    const [downloadAssessmentPopup, setDownloadAssessmentPopup] = useState(false)
    const [assessmentId, setAssessmentId] = useState('')
    const [downloadSummaryPopup, setDownloadSummaryPopup] = useState(false)
    const [careplanId, setCareplanId] = useState('')
    const [downloadCareplanPopup, setDownloadCareplanPopup] = useState(false)
    // const [currentCaseId, setCurrentCaseId] = useState('')
    const [closeCaseModal, setCloseCaseModal] = useState(false)
    const [transferCaseModal, setTransferCaseModal] = useState(false)
    const [reassignreferrerCaseModal,setReassignReferrerCaseModal] = useState(false)
    const [notesDialogModal, setNotesDialogModal] = useState(false)
    const [createRemindersModal,setCreateReminderModal] = useState(false)
    const [childOrgTransferCaseModal, setChildOrgTransferCaseModal] = useState(false)
    const [transferOrg, setTransferOrg] = useState(false)
    const [reassessDialogModal, setReassessDialogModal] = useState(false)
    const [reassessDialogModal1, setReassessDialogModal1] = useState(false)
    const [assessmentTypeId,setAssesmentTypeId] = useState()
    const organization = useSelector(state => state.auth.organization)
    const render = useSelector(state =>state.auth.closeCaseRender)
    let orgId = localStorage.getItem('orgid')
    let reason = localStorage.getItem('reassess')
    // let Create = localStorage.getItem('createScreen')
    let Rescreens = localStorage.getItem('Rescreen')
    let QARescreen = localStorage.getItem('QARescreen')
    let noCaregiver = localStorage.getItem('noCaregiver')
    const {t} = useTranslation()
    let davService = localStorage.getItem('military')
    const [davServiceEnable,setDavServiceEnable] = useState(false)
    let enhanceClose = localStorage.getItem('enhanceClose')
    const [enhancecloseCaseModal, setEnhanceCloseCaseModal] = useState(false)
    useEffect(() => {
        getValue()
        
    }, [])// eslint-disable-line  


    const getValue = () => {
        let tempOptions = []
        switch (screen) {
            case 'allCases':
                role.map(c => {
                    switch (c) {
                        case 'screener':
                            // PendingAssessment=3,Assessment in Progress=4,Screener in Progress=1
                            if ((status === 3 || status === 1) && (userDetails.caremanagerId === careManagerId) && asstype === 0) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' :  (status === 1) ? 'Continue Screener' :(status === 19) ? 'Score in progress' : '',
                                        link: '', onClick: () => (status === 3) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                )
                                break;
                            }else if ((status === 3) && (userDetails.caremanagerId === careManagerId) && asstype >= 1 && QARescreen === "true") {
                                tempOptions.push(
                                    {icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)},
                                    {icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()}
                                )
                                break;
                            }else if ((status === 3) && (userDetails.caremanagerId === careManagerId) && asstype >= 1 && QARescreen === "false") {
                                tempOptions.push(
                                    {icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()}
                                )
                                break;
                            }else if ((status === 1) && (userDetails.caremanagerId === careManagerId) && asstype >= 1) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' :  (status === 1) ? 'Continue Screener' :(status === 19) ? 'Score in progress' : '',
                                        link: '', onClick: () => (status === 3) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                )
                                break;
                            }else if ((QARescreen === 'true') && (status !== 3 && status !== 1 && status !== 11 && status !== 35 ) && (userDetails.role.includes('screener') && (userDetails.caremanagerId !== careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            }else if ((QARescreen === 'false') && (status !== 3 && status !== 1 && status !== 11 && status !== 35 ) && (userDetails.role.includes('screener') && (userDetails.caremanagerId !== careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            }else if ((QARescreen === 'true') && (status !== 3 && status !== 1 && status !== 11 && status !== 35 ) && (userDetails.role.includes('screener') && (userDetails.caremanagerId === careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            }else if ((QARescreen === 'false') && (status !== 3 && status !== 1 && status !== 11 && status !== 35 ) && (userDetails.role.includes('screener') && (userDetails.caremanagerId === careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            }
                            else if ((QARescreen === 'true') &&(status === 3 && asstype >= 1) && (userDetails.role.includes('screener') && (userDetails.caremanagerId !== careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            } else if ((QARescreen === 'false') &&(status === 3 && asstype >= 1) && (userDetails.role.includes('screener') && (userDetails.caremanagerId !== careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            }else if (((status === 3 && asstype === 0) || status === 1)&& (userDetails.caremanagerId !== careManagerId)) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 1) ? 'Continue Screener' : (status === 1) ? 'Continue Screener' :(status === 19) ? 'Score in progress' : '',
                                        link: '', onClick: () => (status === 3) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                                //Pending Referrer=35
                            }
                             else if(status === 35) {
                                tempOptions.push(
                                   
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => openCase(caseId, careReceiverId, caregiverId, careManagerId)
                                })
                                break;
                            }
                            //closed=11
                            else if((QARescreen === 'true') && status === 11 && asstype > 1) {
                                tempOptions.push(
                                    { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)},
                                    { icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()}
                                   )
                                break;
                        }else if((QARescreen === 'false') && status === 11 && asstype >= 1) {
                            tempOptions.push(
                                { icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()}
                               )
                            break;
                        }else if((QARescreen === 'true') && status === 11 && caregiverId !== 0 && careReceiverId !== 0  && assessId !== 0) {
                            tempOptions.push(
                                { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)},
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                }
                               )
                            break;
                        }else if((QARescreen === 'false') && status === 11 && asstype === 0 && assessId !== 0) {
                            tempOptions.push(
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                }
                               )
                            break;
                        }else if(status === 11 && asstype === 0 && assessId === 0 && caregiverId !== 0 && careReceiverId !== 0 && careReceiverId !==0 && careManagerId !== 0) {
                            tempOptions.push(
                                {
                                    icon: MdAssessment, title: (asstype === 0 && (caregiverId !== 0 || careReceiverId !== 0)) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 1) ? 'Continue Screener' :(status === 19 && asstype !== 0) ? 'Score in progress' : '',
                                    link: '', onClick: () => (asstype === 0) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                },
                                // { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                }
                                // { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                            )
                            break;
                        }else if(status === 11  && caregiverId !== 0 && careReceiverId !== 0) {
                            tempOptions.push(
                                {
                                    icon: MdAssessment, title: (asstype === 0 && (caregiverId !== 0 || careReceiverId !== 0)) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 11) ? 'Continue Screener' :(status === 19 && asstype !== 0) ? 'Score in progress' : '',
                                    link: '', onClick: () => (asstype === 0) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                },
                                // { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                }
                                // { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                            )
                            break;
                        }else if(status === 11 && asstype === 0 && assessId === 0 && (caregiverId === 0 || careReceiverId === 0 || relation === null)) {
                            tempOptions.push(
                                // { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                }
                                // { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                            )
                            break;
                        }else{
                                tempOptions.push(
                                   
                                // { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                    )
                                break;
                            }
                            case 'referrer':
                                // PendingAssessment=3,Assessment in Progress=4,Screener in Progress=1
                               
                                 if(caregiverId===0 && status===35 && noCaregiver === 'true'){

                                    tempOptions.push(
                                       
                                        {
                                         icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) 
                                        },
                                        {
                                            icon: RiAccountBoxFill, title: 'No Caregiver', link: '', onClick: () => updateNoCaregiver(caseId) 
                                        }
                                        
                                        )
                                        break;
                                }
                                else if(status === 35) {
                                    tempOptions.push(
                                       
                                    {
                                     icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) 
                                    })
                                    break;
                                }
                                //closed=11
                                else if(status !== 35 && userDetails.role[0] === 'referrer'){
                                    tempOptions.push(
                                        {
                                            title: 'No Records',
                                           }
                                    // { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                        )
                                    break;
                                }else{
                                    tempOptions.push(
                                       
                                    // { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                        )
                                    break;
                                }
                        case 'caremanager':
                            //Pending Referrer=35status === 35
                            if (status === 35 && (userDetails.caremanagerId === careManagerId)) {
                                tempOptions.push({
                                    icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 21) ? 'Careplan in progress' : (status === 19 && asstype !== 0) ? 'Score in progress' : (status === 19 && asstype === 0) ? 'Summary in progress' : (status === 7) ? 'Initiate Follow-Up Assessment' : (status === 5) ? 'Pending Careplan' : (status === 9) ? 'Followup Assessment in progress' : (status === 31) ? 'Followup summary in progress' : (status === 1) ? 'Continue Screener' : (status === 35) ? 'Start Case' : (status === 28) ? 'Followup Pending Careplan': (status === 29) ? 'Followup Careplan in progress':'',
                                    link: '', onClick: () => (status === 3) ? createNewAssessment(caseId) : (status === 4) ? assessmentInProgress(caseId, assessId) : (status === 21) ? careplanInProgress(caseId, cpId, assessId) : (status === 19 && asstype !== 0) ? scoreInProgress(caseId, assessId) : (status === 19 && asstype === 0) ? summaryInProgress(caseId, assessId) : (status === 7) ? createNewFollowupAssessment(caseId) : (status === 5) ? createCareplan(caseId, assessId) : (status === 9) ? assessmentInProgress(caseId, assessId) : (status === 31) ? summaryInProgress(caseId, assessId) : (status === 1) ? ContinueScreener(caseId, assessId,asstype ) : (status === 28) ? createCareplan(caseId, assessId): (status === 29) ? careplanInProgress(caseId, cpId, assessId): ''
                                },
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                )
                                break;
                            } else if(status === 35 && (userDetails.caremanagerId !== careManagerId)) {
                                tempOptions.push(
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                )
                                break;
                            }
                                else if(status === 11) {
                                    tempOptions.push(
                                        { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                            } else if(userDetails.caremanagerId === careManagerId){
                                tempOptions.push({
                                    icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 21) ? 'Careplan in progress' : (status === 19 && asstype !== 0) ? 'Score in progress': (status === 19 && asstype === 0) ? 'Summary in progress' : (status === 7) ? 'Initiate Follow-Up Assessment' : (status === 5) ? 'Pending Careplan' : (status === 9) ? 'Followup Assessment in progress' : (status === 31) ? 'Followup summary in progress' : (status === 1) ? 'Continue Screener' : (status === 35) ? 'Start Case' : (status === 28) ? 'Followup Pending Careplan': (status === 29) ? 'Followup Careplan in progress': '',
                                    link: '', onClick: () => (status === 3) ? createNewAssessment(caseId) : (status === 4) ? assessmentInProgress(caseId, assessId) : (status === 21) ? careplanInProgress(caseId, cpId, assessId) : (status === 19 && asstype !== 0) ? scoreInProgress(caseId, assessId) : (status === 19 && asstype === 0) ? summaryInProgress(caseId, assessId) : (status === 7) ? createNewFollowupAssessment(caseId) : (status === 5) ? createCareplan(caseId, assessId) : (status === 9) ? assessmentInProgress(caseId, assessId) : (status === 31) ? summaryInProgress(caseId, assessId) : (status === 1) ? ContinueScreener(caseId, assessId,asstype ) :(status === 28) ? createCareplan(caseId, assessId):(status === 29) ? careplanInProgress(caseId, cpId, assessId): ''
                                },
                                    { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                    { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                    { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) })
                                    break;
                            } 
                            else{
                                tempOptions.push({ icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: 'Kindly complete the assessment before print', type: 'warning', active: true })) },
                                { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) })
                                break;
                            }
                        case 'dynamic_cm':
                                //Pending Referrer=35
                                if (status === 35 && (userDetails.caremanagerId === careManagerId && userDetails.orgId !== parseInt(organization))) {
                                    tempOptions.push({
                                        icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 21) ? 'Careplan in progress' : (status === 19 && asstype !== 0) ? 'Score in progress' : (status === 19 && asstype === 0) ? 'Summary in progress' : (status === 7) ? 'Initiate Follow-Up Assessment' : (status === 5) ? 'Pending Careplan' : (status === 9) ? 'Followup Assessment in progress' : (status === 31) ? 'Followup summary in progress' : (status === 1) ? 'Continue Screener' : (status === 35) ? 'Start Case' : (status === 28) ? 'Followup Pending Careplan': (status === 29) ? 'Followup Careplan in progress':'',
                                        link: '', onClick: () => (status === 3) ? createNewAssessment(caseId) : (status === 4) ? assessmentInProgress(caseId, assessId) : (status === 21) ? careplanInProgress(caseId, cpId, assessId) : (status === 19 && asstype !== 0) ? scoreInProgress(caseId, assessId) : (status === 19 && asstype === 0) ? summaryInProgress(caseId, assessId) : (status === 7) ? createNewFollowupAssessment(caseId) : (status === 5) ? createCareplan(caseId, assessId) : (status === 9) ? assessmentInProgress(caseId, assessId) : (status === 31) ? summaryInProgress(caseId, assessId) : (status === 1) ? ContinueScreener(caseId, assessId,asstype) : (status === 28) ? createCareplan(caseId, assessId): (status === 29) ? careplanInProgress(caseId, cpId, assessId): ''
                                    },
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                } else if(status === 35 && (userDetails.caremanagerId !== careManagerId && userDetails.orgId !== parseInt(organization))) {
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }
                                else if(status === 11) {
                                        tempOptions.push(
                                            { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                            { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                        break;
                                } else if(userDetails.caremanagerId === careManagerId && userDetails.orgId !== parseInt(organization)){
                                    tempOptions.push({
                                        icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 21) ? 'Careplan in progress' : (status === 19 && asstype !== 0) ? 'Score in progress': (status === 19 && asstype === 0) ? 'Summary in progress' : (status === 7) ? 'Initiate Follow-Up Assessment' : (status === 5) ? 'Pending Careplan' : (status === 9) ? 'Followup Assessment in progress' : (status === 31) ? 'Followup summary in progress' : (status === 1) ? 'Continue Screener' : (status === 35) ? 'Start Case' : (status === 28) ? 'Followup Pending Careplan': (status === 29) ? 'Followup Careplan in progress': '',
                                        link: '', onClick: () => (status === 3) ? createNewAssessment(caseId) : (status === 4) ? assessmentInProgress(caseId, assessId) : (status === 21) ? careplanInProgress(caseId, cpId, assessId) : (status === 19 && asstype !== 0) ? scoreInProgress(caseId, assessId) : (status === 19 && asstype === 0) ? summaryInProgress(caseId, assessId) : (status === 7) ? createNewFollowupAssessment(caseId) : (status === 5) ? createCareplan(caseId, assessId) : (status === 9) ? assessmentInProgress(caseId, assessId) : (status === 31) ? summaryInProgress(caseId, assessId) : (status === 1) ? ContinueScreener(caseId, assessId,asstype) :(status === 28) ? createCareplan(caseId, assessId):(status === 29) ? careplanInProgress(caseId, cpId, assessId): ''
                                    },
                                        { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) })
                                        break;
                                } 
                                else if(userDetails.orgId !== parseInt(organization)){
                                    tempOptions.push({ icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: 'Kindly complete the assessment before print', type: 'warning', active: true })) },
                                    { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                    { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) })
                                    break;
                                }
                        break;  
                        case 'admin':                        
                            if(status === 35 && refName !== null){
                                tempOptions.push(
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) }    
                                )
                                break;
                            }else if(status === 35 && refName === null){
                                tempOptions.push(
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                )
                                break;
                            }
                            else if(status === 11 &&  userDetails.orgId === parseInt(organization) && refName !== null) {
                                tempOptions.push(
                                    { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                    { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },
                                    { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                    // { icon: RiDirectionFill, title: 'Transfer Org', link: '', onClick: () => openTransferOrg(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                )
                                break;
                            }
                            else if(status === 11 &&  userDetails.orgId === parseInt(organization) && refName === null) {
                                tempOptions.push(
                                    { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                    { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                    // { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                )
                                break;
                            }
                            else if((organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && refName !== null)){
                                tempOptions.push(
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                    // { icon: RiDirectionFill, title: 'Transfer Org', link: '', onClick: () => openTransferOrg(caseId) },
                                    { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },
                                    { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: ('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                    { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                    { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                )
                                break;
                            }  else if((organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && refName === null)){
                                tempOptions.push(
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                    // { icon: RiDirectionFill, title: 'Transfer Org', link: '', onClick: () => openTransferOrg(caseId) },
                                    { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: ('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                    { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                    { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                )
                                break;
                            }
                            break;
                            case 'dynamic_scr':
                            // PendingAssessment=3,Assessment in Progress=4,Screener in Progress=1
                            if ((status === 3 || status === 1) && (userDetails.caremanagerId === careManagerId) && asstype === 0) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' :  (status === 1) ? 'Continue Screener' :(status === 19) ? 'Score in progress' : '',
                                        link: '', onClick: () => (status === 3) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                )
                                break;
                            }else if ((status === 3) && (userDetails.caremanagerId === careManagerId) && asstype >= 1 && QARescreen === "true") {
                                tempOptions.push(
                                    {icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)},
                                    {icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()}
                                )
                                break;
                            }else if ((status === 3) && (userDetails.caremanagerId === careManagerId) && asstype >= 1 && QARescreen === "false") {
                                tempOptions.push(
                                    {icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()}
                                )
                                break;
                            }else if ((status === 1) && (userDetails.caremanagerId === careManagerId) && asstype >= 1) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' :  (status === 1) ? 'Continue Screener' :(status === 19) ? 'Score in progress' : '',
                                        link: '', onClick: () => (status === 3) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                )
                                break;
                            }else if ((QARescreen === 'true') && (status !== 3 && status !== 1 && status !== 11 && status !== 35 ) && (userDetails.role.includes('dynamic_scr') && (userDetails.caremanagerId !== careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            }else if ((QARescreen === 'false') && (status !== 3 && status !== 1 && status !== 11 && status !== 35 ) && (userDetails.role.includes('dynamic_scr') && (userDetails.caremanagerId !== careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            }else if ((QARescreen === 'true') && (status !== 3 && status !== 1 && status !== 11 && status !== 35 ) && (userDetails.role.includes('dynamic_scr') && (userDetails.caremanagerId === careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            }else if ((QARescreen === 'false') && (status !== 3 && status !== 1 && status !== 11 && status !== 35 ) && (userDetails.role.includes('dynamic_scr') && (userDetails.caremanagerId === careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            }
                            else if ((QARescreen === 'true') &&(status === 3 && asstype >= 1) && (userDetails.role.includes('dynamic_scr') && (userDetails.caremanagerId !== careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            } else if ((QARescreen === 'false') &&(status === 3 && asstype >= 1) && (userDetails.role.includes('dynamic_scr') && (userDetails.caremanagerId !== careManagerId))) {
                                tempOptions.push(
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                            }else if (((status === 3 && asstype === 0) || status === 1)&& (userDetails.caremanagerId !== careManagerId)) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 1) ? 'Continue Screener' : (status === 1) ? 'Continue Screener' :(status === 19) ? 'Score in progress' : '',
                                        link: '', onClick: () => (status === 3) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                    },
                                    {
                                        icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                    }
                                   
                                )
                                break;
                                //Pending Referrer=35
                            }
                             else if(status === 35) {
                                tempOptions.push(
                                   
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => openCase(caseId, careReceiverId, caregiverId, careManagerId)
                                })
                                break;
                            }
                            //closed=11
                            else if((QARescreen === 'true') && status === 11 && asstype > 1) {
                                tempOptions.push(
                                    { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)},
                                    { icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()}
                                   )
                                break;
                        }else if((QARescreen === 'false') && status === 11 && asstype >= 1) {
                            tempOptions.push(
                                { icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()}
                               )
                            break;
                        }else if((QARescreen === 'true') && status === 11 && caregiverId !== 0 && careReceiverId !== 0  && assessId !== 0) {
                            tempOptions.push(
                                { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)},
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                }
                               )
                            break;
                        }else if((QARescreen === 'false') && status === 11 && asstype === 0 && assessId !== 0) {
                            tempOptions.push(
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                }
                               )
                            break;
                        }else if(status === 11 && asstype === 0 && assessId === 0 && caregiverId !== 0 && careReceiverId !== 0 && careReceiverId !==0 && careManagerId !== 0) {
                            tempOptions.push(
                                {
                                    icon: MdAssessment, title: (asstype === 0 && (caregiverId !== 0 || careReceiverId !== 0)) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 1) ? 'Continue Screener' :(status === 19 && asstype !== 0) ? 'Score in progress' : '',
                                    link: '', onClick: () => (asstype === 0) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                },
                                // { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                }
                                // { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                            )
                            break;
                        }else if(status === 11  && caregiverId !== 0 && careReceiverId !== 0) {
                            tempOptions.push(
                                {
                                    icon: MdAssessment, title: (asstype === 0 && (caregiverId !== 0 || careReceiverId !== 0)) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 11) ? 'Continue Screener' :(status === 19 && asstype !== 0) ? 'Score in progress' : '',
                                    link: '', onClick: () => (asstype === 0) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                },
                                // { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                }
                                // { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                            )
                            break;
                        }else if(status === 11 && asstype === 0 && assessId === 0 && (caregiverId === 0 || careReceiverId === 0 || relation === null)) {
                            tempOptions.push(
                                // { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                {
                                    icon: FaUserPlus, title: 'Add Caregiver',link: '', onClick: () => AddCase()
                                }
                                // { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                            )
                            break;
                        }else{
                                tempOptions.push(
                                   
                                // { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                    )
                                break;
                            }
                            case 'dynamic_admin':
                                if(status === 35 && userDetails.orgId !== parseInt(organization) && refName !== null){
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },    
                                    )
                                    break;
                                }else   if(status === 35 && userDetails.orgId !== parseInt(organization) && refName === null){
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    )
                                    break;
                                }
                                else if(status === 11 &&  userDetails.orgId !== parseInt(organization) && refName !== null) {
                                    tempOptions.push(
                                        { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                        { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                } else if(status === 11 &&  userDetails.orgId !== parseInt(organization) && refName === null) {
                                    tempOptions.push(
                                        { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                        { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }
                                else if((organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && refName !== null)){
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                        { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },
                                        // { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) },
                                        { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: ('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                    )
                                    break;
                                }else if((organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && refName === null)){
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                        // { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) },
                                        { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: ('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                    )
                                    break;
                                
                                }
                                break;
                                default:
                                    return null
                                
                    }
                    return null;
                    
                })
                break;
                case 'todo':
                    role.map(c => {
                        switch (c) {
                            case 'screener':
                                // PendingAssessment=3,Assessment in Progress=4,Screener in Progress=1
                                if ((status === 3 || status === 1) && (userDetails.caremanagerId === careManagerId) && asstype === 0) {
                                    tempOptions.push(
                                        {
                                            icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' :  (status === 1) ? 'Continue Screener' :(status === 19) ? 'Score in progress' : '',
                                            link: '', onClick: () => (status === 3) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                        },
                                       
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                    )
                                    break;
                                }else if ((status !== 3 && status !== 1 ) && (userDetails.role[0] ==='screener')) {
                                    tempOptions.push(
                                        {
                                            icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                        },
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => console.log(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                    )
                                    break;
                                }else if ((status === 3 && asstype >= 1) && (userDetails.role[0] ==='screener')) {
                                    tempOptions.push(
                                        {
                                            icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                        },
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => console.log(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                    )
                                    break;
                                }else if (((status === 3 && asstype === 0) || status === 1)&& (userDetails.caremanagerId !== careManagerId)) {
                                    tempOptions.push(
                                        {
                                            icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 1) ? 'Continue Screener' : (status === 1) ? 'Continue Screener' :(status === 19) ? 'Score in progress' : '',
                                            link: '', onClick: () => (status === 3) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                        },
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => console.log(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                    )
                                    break;
                                    //Pending Referrer=35
                                }
                                 else if(status === 35) {
                                    tempOptions.push({ icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => console.log(caseId) })
                                    break;
                                }
                                //closed=11
                                else if(status === 11 && asstype >= 1) {
                                    tempOptions.push(
                                        {
                                            icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                        },
                                        { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                            }else if(status === 11 && asstype === 0 && assessId !== 0) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                    },
                                    { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                    // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                )
                                break;
                            }else if(status === 11 && asstype === 0 && assessId === 0) {
                                tempOptions.push(
                                    {
                                        icon: MdAssessment, title: (asstype === 0) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 1) ? 'Continue Screener' :(status === 19 && asstype !== 0) ? 'Score in progress' : '',
                                        link: '', onClick: () => (asstype === 0) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                    },
                                    { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                )
                                break;
                            }else{
                                    tempOptions.push(
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => console.log(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                        )
                                    break;
                                }
                            case 'caremanager':
                                //Pending Referrer=35
                                if (status === 35 && (userDetails.caremanagerId === careManagerId)) {
                                    tempOptions.push({
                                        icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 21) ? 'Careplan in progress' : (status === 19 && asstype !== 0) ? 'Score in progress' : (status === 19 && asstype === 0) ? 'Summary in progress' : (status === 7) ? 'Initiate Follow-Up Assessment' : (status === 5) ? 'Pending Careplan' : (status === 9) ? 'Followup Assessment in progress' : (status === 31) ? 'Followup summary in progress' : (status === 1) ? 'Continue Screener' : (status === 35) ? 'Start Case' : (status === 28) ? 'Followup Pending Careplan': (status === 29) ? 'Followup Careplan in progress':'',
                                        link: '', onClick: () => (status === 3) ? createNewAssessment(caseId) : (status === 4) ? assessmentInProgress(caseId, assessId) : (status === 21) ? careplanInProgress(caseId, cpId, assessId) : (status === 19 && asstype !== 0) ? scoreInProgress(caseId, assessId) : (status === 19 && asstype === 0) ? summaryInProgress(caseId, assessId) : (status === 7) ? createNewFollowupAssessment(caseId) : (status === 5) ? createCareplan(caseId, assessId) : (status === 9) ? assessmentInProgress(caseId, assessId) : (status === 31) ? summaryInProgress(caseId, assessId) : (status === 1) ? ContinueScreener(caseId, assessId,asstype ) : (status === 28) ? createCareplan(caseId, assessId): (status === 29) ? careplanInProgress(caseId, cpId, assessId): ''
                                    },
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                } else if(status === 35 && (userDetails.caremanagerId !== careManagerId)) {
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }
                                    else if(status === 11) {
                                        tempOptions.push(
                                            { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                            { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                        break;
                                } else if(userDetails.caremanagerId === careManagerId){
                                    tempOptions.push({
                                        icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 21) ? 'Careplan in progress' : (status === 19 && asstype !== 0) ? 'Score in progress': (status === 19 && asstype === 0) ? 'Summary in progress' : (status === 7) ? 'Initiate Follow-Up Assessment' : (status === 5) ? 'Pending Careplan' : (status === 9) ? 'Followup Assessment in progress' : (status === 31) ? 'Followup summary in progress' : (status === 1) ? 'Continue Screener' : (status === 35) ? 'Start Case' : (status === 28) ? 'Followup Pending Careplan': (status === 29) ? 'Followup Careplan in progress': '',
                                        link: '', onClick: () => (status === 3) ? createNewAssessment(caseId) : (status === 4) ? assessmentInProgress(caseId, assessId) : (status === 21) ? careplanInProgress(caseId, cpId, assessId) : (status === 19 && asstype !== 0) ? scoreInProgress(caseId, assessId) : (status === 19 && asstype === 0) ? summaryInProgress(caseId, assessId) : (status === 7) ? createNewFollowupAssessment(caseId) : (status === 5) ? createCareplan(caseId, assessId) : (status === 9) ? assessmentInProgress(caseId, assessId) : (status === 31) ? summaryInProgress(caseId, assessId) : (status === 1) ? assessmentInProgress(caseId, assessId) :(status === 28) ? createCareplan(caseId, assessId):(status === 29) ? careplanInProgress(caseId, cpId, assessId): ''
                                    },
                                        { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) })
                                        break;
                                } 
                                else{
                                    tempOptions.push({ icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: 'Kindly complete the assessment before print', type: 'warning', active: true })) },
                                    { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                    { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                    // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) })
                                    break;
                                }
                            case 'dynamic_cm':
                                    //Pending Referrer=35
                                    if (status === 35 && (userDetails.caremanagerId === careManagerId && userDetails.orgId !== parseInt(organization))) {
                                        tempOptions.push({
                                            icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 21) ? 'Careplan in progress' : (status === 19 && asstype !== 0) ? 'Score in progress' : (status === 19 && asstype === 0) ? 'Summary in progress' : (status === 7) ? 'Initiate Follow-Up Assessment' : (status === 5) ? 'Pending Careplan' : (status === 9) ? 'Followup Assessment in progress' : (status === 31) ? 'Followup summary in progress' : (status === 1) ? 'Continue Screener' : (status === 35) ? 'Start Case' : (status === 28) ? 'Followup Pending Careplan': (status === 29) ? 'Followup Careplan in progress':'',
                                            link: '', onClick: () => (status === 3) ? createNewAssessment(caseId) : (status === 4) ? assessmentInProgress(caseId, assessId) : (status === 21) ? careplanInProgress(caseId, cpId, assessId) : (status === 19 && asstype !== 0) ? scoreInProgress(caseId, assessId) : (status === 19 && asstype === 0) ? summaryInProgress(caseId, assessId) : (status === 7) ? createNewFollowupAssessment(caseId) : (status === 5) ? createCareplan(caseId, assessId) : (status === 9) ? assessmentInProgress(caseId, assessId) : (status === 31) ? summaryInProgress(caseId, assessId) : (status === 1) ? ContinueScreener(caseId, assessId,asstype ) : (status === 28) ? createCareplan(caseId, assessId): (status === 29) ? careplanInProgress(caseId, cpId, assessId): ''
                                        },
                                            { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        )
                                        break;
                                    } else if(status === 35 && (userDetails.caremanagerId !== careManagerId && userDetails.orgId !== parseInt(organization))) {
                                        tempOptions.push(
                                            { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        )
                                        break;
                                    }
                                    else if(status === 11) {
                                            tempOptions.push(
                                                { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                                { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                                { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                            )
                                            break;
                                    } else if(userDetails.caremanagerId === careManagerId && userDetails.orgId !== parseInt(organization)){
                                        tempOptions.push({
                                            icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 21) ? 'Careplan in progress' : (status === 19 && asstype !== 0) ? 'Score in progress': (status === 19 && asstype === 0) ? 'Summary in progress' : (status === 7) ? 'Initiate Follow-Up Assessment' : (status === 5) ? 'Pending Careplan' : (status === 9) ? 'Followup Assessment in progress' : (status === 31) ? 'Followup summary in progress' : (status === 1) ? 'Continue Screener' : (status === 35) ? 'Start Case' : (status === 28) ? 'Followup Pending Careplan': (status === 29) ? 'Followup Careplan in progress': '',
                                            link: '', onClick: () => (status === 3) ? createNewAssessment(caseId) : (status === 4) ? assessmentInProgress(caseId, assessId) : (status === 21) ? careplanInProgress(caseId, cpId, assessId) : (status === 19 && asstype !== 0) ? scoreInProgress(caseId, assessId) : (status === 19 && asstype === 0) ? summaryInProgress(caseId, assessId) : (status === 7) ? createNewFollowupAssessment(caseId) : (status === 5) ? createCareplan(caseId, assessId) : (status === 9) ? assessmentInProgress(caseId, assessId) : (status === 31) ? summaryInProgress(caseId, assessId) : (status === 1) ? ContinueScreener(caseId, assessId,asstype ) :(status === 28) ? createCareplan(caseId, assessId):(status === 29) ? careplanInProgress(caseId, cpId, assessId): ''
                                        },
                                            { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                            { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                            { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                            { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) })
                                            break;
                                    } 
                                    else if(userDetails.orgId !== parseInt(organization)){
                                        tempOptions.push({ icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: 'Kindly complete the assessment before print', type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) })
                                        break;
                                    }
                            break;  
                            case 'admin':
                                if(status === 35 && refName !== null){
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) }    
                                    )
                                    break;
                                }else if(status === 35 && refName === null){
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    )
                                    break;
                                }
                                else if(status === 11 &&  userDetails.orgId === parseInt(organization) && refName !== null) {
                                    tempOptions.push(
                                        { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                        { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        // { icon: RiDirectionFill, title: 'Transfer Org', link: '', onClick: () => openTransferOrg(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }
                                else if(status === 11 &&  userDetails.orgId === parseInt(organization) && refName === null) {
                                    tempOptions.push(
                                        { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                        { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }
                                else if((organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && refName !== null)){
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        // { icon: RiDirectionFill, title: 'Transfer Org', link: '', onClick: () => openTransferOrg(caseId) },
                                        { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) },
                                        { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: ('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                    )
                                    break;
                                }  else if((organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && refName === null)){
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        // { icon: RiDirectionFill, title: 'Transfer Org', link: '', onClick: () => openTransferOrg(caseId) },
                                        { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) },
                                        { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: ('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                        { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                    )
                                    break;
                                }
                                break;
                                case 'dynamic_scr':
                                    // PendingAssessment=3,Assessment in Progress=4,Screener in Progress=1
                                    if ((status === 3 || status === 1) && (userDetails.caremanagerId === careManagerId) && asstype === 0) {
                                        tempOptions.push(
                                            {
                                                icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' :  (status === 1) ? 'Continue Screener' :(status === 19) ? 'Score in progress' : '',
                                                link: '', onClick: () => (status === 3) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                            },
                                           
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                            // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                        )
                                        break;
                                    }else if ((status !== 3 && status !== 1 ) && (userDetails.role[0] ==='screener')) {
                                        tempOptions.push(
                                            {
                                                icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                            },
                                            // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => console.log(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                            // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                        )
                                        break;
                                    }else if ((status === 3 && asstype >= 1) && (userDetails.role[0] ==='screener')) {
                                        tempOptions.push(
                                            {
                                                icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                            },
                                            // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => console.log(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                            // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                        )
                                        break;
                                    }else if (((status === 3 && asstype === 0) || status === 1)&& (userDetails.caremanagerId !== careManagerId)) {
                                        tempOptions.push(
                                            {
                                                icon: MdAssessment, title: (status === 3) ? 'Create New Assessment' : (status === 1) ? 'Continue Screener' : (status === 1) ? 'Continue Screener' :(status === 19) ? 'Score in progress' : '',
                                                link: '', onClick: () => (status === 3) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                            },
                                            // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => console.log(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                            // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                        )
                                        break;
                                        //Pending Referrer=35
                                    }
                                     else if(status === 35) {
                                        tempOptions.push({ icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => console.log(caseId) })
                                        break;
                                    }
                                    //closed=11
                                    else if(status === 11 && asstype >= 1) {
                                        tempOptions.push(
                                            {
                                                icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                            },
                                            { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                            // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                        break;
                                }else if(status === 11 && asstype === 0 && assessId !== 0) {
                                    tempOptions.push(
                                        {
                                            icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId)
                                        },
                                        { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }else if(status === 11 && asstype === 0 && assessId === 0) {
                                    tempOptions.push(
                                        {
                                            icon: MdAssessment, title: (asstype === 0) ? 'Create New Assessment' : (status === 4) ? 'Assessment in progress' : (status === 1) ? 'Continue Screener' :(status === 19 && asstype !== 0) ? 'Score in progress' : '',
                                            link: '', onClick: () => (asstype === 0) ? console.log(caseId) : (status === 4) ? console.log(caseId, assessId) : ''
                                        },
                                        { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }else{
                                        tempOptions.push(
                                            // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => console.log(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                            // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => console.log(caseId) }
                                            )
                                        break;
                                    }
                                case 'dynamic_admin':
                                    if(status === 35 && userDetails.orgId !== parseInt(organization) && refName !== null){
                                        tempOptions.push(
                                            { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                            { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },    
                                        )
                                        break;
                                    }else   if(status === 35 && userDetails.orgId !== parseInt(organization) && refName === null){
                                        tempOptions.push(
                                            { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        )
                                        break;
                                    }
                                    else if(status === 11 &&  userDetails.orgId !== parseInt(organization) && refName !== null) {
                                        tempOptions.push(
                                            { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                            { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },
                                            { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                            { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                        break;
                                    } else if(status === 11 &&  userDetails.orgId !== parseInt(organization) && refName === null) {
                                        tempOptions.push(
                                            { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                            { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                            { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                        break;
                                    }
                                    else if((organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && refName !== null)){
                                        tempOptions.push(
                                            { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                            { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                            { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },
                                            // { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) },
                                            { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: ('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                            { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                            { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                        )
                                        break;
                                    }else if((organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && refName === null)){
                                        tempOptions.push(
                                            { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                            { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                            // { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) },
                                            { icon: AiFillPrinter, title: 'Print Assessment', link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9)) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: ('Kindly complete the assessment before print'), type: 'warning', active: true })) },
                                            { icon: AiFillPrinter, title: 'Print Summary', link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) },
                                            { icon: AiFillPrinter, title: 'Print Care Plan', link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) },
                                        )
                                        break;
                                    }
                                    break;
                                    default:
                                        return null
                        }
                        return null;
                    })
                    break;
            case 'closeCases':
                role.map((c) => {
                    switch (c) {
                        case 'screener':
                            if(asstype === 0){
                                tempOptions.push(
                                    { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: MdAssessment, title: 'Create New Assessment', link: '', onClick: () => createNewAssessment(caseId) }
                                )
                                break;
                            }else{
                                tempOptions.push(
                                    { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId) }
                                )
                                break;
                            }
                            break;
                        case 'caremanager':
                            tempOptions.push(
                                { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) }
                            )
                            break;
                        case 'admin':
                            tempOptions.push(
                                { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                // { icon: RiDirectionFill, title: 'Transfer Org', link: '', onClick: () => openTransferOrg(caseId) },
                                { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) }
                            )
                            break;
                            case 'dynamic_cm':
                                tempOptions.push(
                                    { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) }
                                )
                                break;
                                case 'dynamic_scr':
                            if(asstype === 0){
                                tempOptions.push(
                                    { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: MdAssessment, title: 'Create New Assessment', link: '', onClick: () => createNewAssessment(caseId) }
                                )
                                break;
                            }else{
                                tempOptions.push(
                                    { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId) }
                                )
                                break;
                            }
                            case 'dynamic_admin':
                                if ((organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin'))){
                                tempOptions.push(
                                    { icon: RiDirectionFill, title: 'Reopen', link: '', onClick: () => reopenCase(caseId) },
                                    { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                    // { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) }
                                )
                                }
                                break;
                            default:
                                return null
                    } 
                    return null;
                })
                break;
            case 'unassignedCase':
                role.map((c) => {
                    switch (c) {
                        case 'caremanager':
                            if(organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('caremanager') && rescr === true && status === 3){
                            tempOptions.push(
                                { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId) },
                                { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                            )
                            break;
                        }else if(organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('caremanager') && rescr === true && (status === 19 || status === 1)){
                            tempOptions.push(
                                { icon: MdAssessment, title: (status === 19) ? 'Score in progress' : 'Continue Screener' ,link: '', onClick: () => (status === 19) ? scoreInProgress(caseId, assessId) : ContinueScreener(caseId, assessId,asstype )},
                                { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                
                            )
                            break;
                            }else if(organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('caremanager') && rescr === false){
                                tempOptions.push(
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                )
                                break;
                                }
                                break;
                                case 'dynamic_cm':
                                     if(userDetails.role.includes('dynamic_cm') && rescr === true && status === 3 && asstype === 0 && userDetails.orgId !== parseInt(organization)){
                                    tempOptions.push(
                                        { icon: MdAssessment, title: 'Create New Assessment', link: '', onClick: () =>  createNewAssessment(caseId) },
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }if(userDetails.role.includes('dynamic_cm') && rescr === true && status === 3 && userDetails.orgId !== parseInt(organization)){
                                    tempOptions.push(
                                        { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId) },
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }else if(userDetails.role.includes('dynamic_cm') && rescr === true && status === 1 && userDetails.orgId !== parseInt(organization)){
                                    tempOptions.push(
                                        { icon: MdAssessment, title: 'Continue Screener' ,link: '', onClick: () => ContinueScreener(caseId, assessId,asstype )},
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                    }else if(userDetails.role.includes('dynamic_cm') && rescr === true && status === 19 && asstype !== 0 && userDetails.orgId !== parseInt(organization)){
                                        tempOptions.push(
                                            { icon: MdAssessment, title:'Score in progress' ,link: '', onClick: () => scoreInProgress(caseId, assessId)},
                                            { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                            { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                            { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                        break;
                                        }else if(userDetails.role.includes('dynamic_cm') && rescr === true && userDetails.orgId !== parseInt(organization)){
                                        tempOptions.push(
                                            { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId) },
                                            { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                            { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                            { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                        break;
                                    }else if(userDetails.role.includes('dynamic_cm') && userDetails.orgId !== parseInt(organization)){
                                        tempOptions.push(
                                            { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                            { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                            { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                        break;
                                        }
                        break;               
                        case 'admin':
                            if((organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin'))){
                            tempOptions.push(
                                { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                // { icon: RiDirectionFill, title: 'Transfer Org', link: '', onClick: () => openTransferOrg(caseId) },
                                { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                            )
                            break;
                        }
                        break;
                        case 'dynamic_admin':    
                            if ((organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin'))){
                                tempOptions.push(
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                    { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                    // { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                )
                                break;
                            }
                            break;
                            case 'screener':
                                if(status === 1){
                                    tempOptions.push(
                                        // { icon: MdAssessment, title: 'screen', link: '', onClick: () => createNewAssessment(caseId) },
                                        {icon: MdAssessment, title:'Continue Screener' ,link: '', onClick: () => ContinueScreener(caseId, assessId,asstype )},
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }else if (userDetails.role.includes('screener') && rescr === true && status === 3){
                                    tempOptions.push(
                                        { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId) },
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }else if (status === 19){
                                    tempOptions.push(
                                        { icon: MdAssessment, title: 'Score in progress', link: '', onClick: () => scoreInProgress(caseId, assessId) },
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }else{
                                    tempOptions.push(
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                }
                                break;
                                case 'dynamic_scr':
                                    if(status === 1){
                                        tempOptions.push(
                                            // { icon: MdAssessment, title: 'screen', link: '', onClick: () => createNewAssessment(caseId) },
                                            {icon: MdAssessment, title:'Continue Screener' ,link: '', onClick: () => ContinueScreener(caseId, assessId,asstype )},
                                            // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                            // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                        break;
                                    }else if (userDetails.role.includes('dynamic_scr') && rescr === true && status === 3){
                                        tempOptions.push(
                                            { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId) },
                                            // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                            // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                        break;
                                    }else if (status === 19){
                                        tempOptions.push(
                                            { icon: MdAssessment, title: 'Score in progress', link: '', onClick: () => scoreInProgress(caseId, assessId) },
                                            // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                            // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                        break;
                                    }else{
                                        tempOptions.push(
                                            // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                            // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                            { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                        )
                                    }
                                    break;
                                default:
                                    return null
                    }
                    return null;
                })
                break;
            case 'watchListCase':
                role.map((c) => {
                    switch (c) {
                        case 'caremanager':
                            if(organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('caremanager') && rescr === false && status === 3){
                                tempOptions.push(
                                    { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId) },
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                )
                                break;
                                }else if(organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('caremanager') && rescr === false && (status === 19 || status === 1)){
                                    tempOptions.push(
                                        { icon: MdAssessment, title: (status === 19) ? 'Score in progress' : 'Continue Screener' ,link: '', onClick: () => (status === 19) ? scoreInProgress(caseId, assessId) : ContinueScreener(caseId, assessId,asstype )},
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                    }
                        break;            
                        case 'dynamic_cm':
                            if(userDetails.role.includes('dynamic_cm') && rescr === false && status === 3 && userDetails.orgId !== parseInt(organization)){
                                tempOptions.push(
                                    { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId) },
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                )
                                break;
                                }else if(userDetails.role.includes('dynamic_cm') && rescr === false && userDetails.orgId !== parseInt(organization)){
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Assign Case', link: '', onClick: () => assignCases(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                    }
                        break;            
                        case 'admin':
                            if((organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && refName !== null)){
                            tempOptions.push(
                                { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                // { icon: RiDirectionFill, title: 'Transfer Org', link: '', onClick: () => openTransferOrg(caseId) },
                                { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },
                                { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                            )
                            break;
                            }else  if((organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && refName === null)){
                                tempOptions.push(
                                    { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                    { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                    // { icon: RiDirectionFill, title: 'Transfer Org', link: '', onClick: () => openTransferOrg(caseId) },
                                    { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                    { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                )
                                break;
                                }
                        break;        
                        case 'dynamic_admin':
                               if ((organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && refName !== null)){
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                        { icon: RiRefreshFill, title: 'Reassign Referrer', link: '', onClick: () => openReassignReferrerModal(caseId) },
                                        // { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;  
                                }else    if ((organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && refName === null)){
                                    tempOptions.push(
                                        { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(caseId) },
                                        { icon: RiDirectionFill, title: 'Associated Child-org Transfer', link: '', onClick: () => openTransferOrg(caseId) },
                                        // { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;  
                                }
                            break;    
                            case 'screener':
                                if(status === 1){
                                    tempOptions.push(
                                        // { icon: MdAssessment, title: 'screen', link: '', onClick: () => createNewAssessment(caseId) },
                                        {icon: MdAssessment, title:'Continue Screener' ,link: '', onClick: () => ContinueScreener(caseId, assessId,asstype )},
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }else if (status === 3){
                                    tempOptions.push(
                                        { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId) },
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }
                                else if (status === 19){
                                    tempOptions.push(
                                        { icon: MdAssessment, title: 'Score in progress', link: '', onClick: () => scoreInProgress(caseId, assessId) },
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }else{
                                    tempOptions.push(
                                        // { icon: MdAssessment, title: 'Score in progress', link: '', onClick: () => scoreInProgress(caseId, assessId) },
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    ) 
                                    break;    
                                }
                                case 'dynamic_scr':
                                if(status === 1){
                                    tempOptions.push(
                                        // { icon: MdAssessment, title: 'screen', link: '', onClick: () => createNewAssessment(caseId) },
                                        {icon: MdAssessment, title:'Continue Screener' ,link: '', onClick: () => ContinueScreener(caseId, assessId,asstype )},
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }else if (status === 3){
                                    tempOptions.push(
                                        { icon: MdAssessment, title: 'Re-Screen', link: '', onClick: () => createRescreen(caseId) },
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }
                                else if (status === 19){
                                    tempOptions.push(
                                        { icon: MdAssessment, title: 'Score in progress', link: '', onClick: () => scoreInProgress(caseId, assessId) },
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    )
                                    break;
                                }else{
                                    tempOptions.push(
                                        // { icon: MdAssessment, title: 'Score in progress', link: '', onClick: () => scoreInProgress(caseId, assessId) },
                                        // { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(caseId) },
                                        // { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(caseId) },
                                        { icon: FaStickyNote, title: 'Create Reminder', link: '', onClick: () =>openCreateRemindersModal(caseId) }
                                    ) 
                                    break;    
                                }
                                
                                default:
                                    return null;
                                
                    }
                    return null;
                })
                break;
                default:
                    return null;
        }
        var options = tempOptions,
            unique = [...new Set(options.map(a => a.title))];
        let action = unique.sort();
        let actions = []
        action.map(c => {
            switch (c) {
                
                case 'Assign Case':
                    return actions.push({ icon: RiDirectionFill, title: t('Assign Case'), link: '', onClick: () => assignCases(caseId) })
                case 'Create New Assessment':
                    return actions.push({ icon: MdAssessment, title: t('Create New Assessment'), link: '', onClick: () => createNewAssessment(caseId) })
                case 'Assessment in progress':
                    return actions.push({ icon: MdAssessment, title: t('Assessment in progress'), link: '', onClick: () => assessmentInProgress(caseId, assessId) })
                case 'Continue Screener':
                     return actions.push({ icon: MdAssessment, title: t('Continue Screener'), link: '', onClick: () => ContinueScreener(caseId, assessId,asstype ) })
                case 'Careplan in progress':
                    return actions.push({ icon: MdAssessment, title: t('Care Plan in Progress'), link: '', onClick: () => careplanInProgress(caseId, cpId, assessId) })
                case 'Score in progress':
                        return actions.push({ icon: MdAssessment, title: t('Summary in progress'), link: '', onClick: () => scoreInProgress(caseId, assessId) })
                case 'Summary in progress':
                    return actions.push({ icon: MdAssessment, title: t('Summary in progress'), link: '', onClick: () => summaryInProgress(caseId, assessId) })
                case 'Initiate Follow-Up Assessment':
                    return actions.push({ icon: MdAssessment, title: t('Initiate Follow-Up Assessment'), link: '', onClick: () => createNewFollowupAssessment(caseId) })
                case 'Pending Careplan':
                    return actions.push({ icon: MdAssessment, title: t('Pending Care Plan'), link: '', onClick: () => createCareplan(caseId, assessId) })
                case 'Followup Assessment in progress':
                    return actions.push({ icon: MdAssessment, title: t('Followup Assessment in progress'), link: '', onClick: () => assessmentInProgress(caseId, assessId) })
                case 'Followup summary in progress':
                    return actions.push({ icon: MdAssessment, title: t('Followup summary in progress'), link: '', onClick: () => summaryInProgress(caseId, assessId) })
                case 'Followup Pending Careplan':
                     return actions.push({ icon: MdAssessment, title: t('Followup Pending Care Plan'), link: '', onClick: () => createCareplan(caseId, assessId) })
                case 'Followup Careplan in progress':
                     return actions.push({ icon: MdAssessment, title: t('Followup Care Plan in Progress'), link: '', onClick: () => careplanInProgress(caseId, cpId, assessId) })
                case 'Start Case':
                    return actions.push({ icon: MdAssessment, title: t('Start Case'), link: '', onClick: () => openCase(caseId, careReceiverId, caregiverId, careManagerId) })
                case 'Re-Screen':
                    return actions.push({ icon: MdAssessment, title: t('Re-Screen'), link: '', onClick: () => createRescreen(caseId) })
                case 'Close Case':
                    return actions.push({ icon: RiInboxArchiveFill, title: t("Close Case"), link: '', onClick: () => openCloseCaseModal(caseId) })
                case 'Transfer Case':
                    return actions.push({ icon: RiDirectionFill, title: t('Transfer Case'), link: '', onClick: () => openTransferCaseModal(caseId) })
                case 'Associated Child-org Transfer':
                    return actions.push({ icon: RiDirectionFill, title: t('Associated Child-org Transfer'), link: '', onClick: () => openTransferOrg(caseId) })
                case 'Reassign Referrer':
                    return actions.push({ icon: RiRefreshFill, title: t('Reassign Referrer'), link: '', onClick: () => openReassignReferrerModal(caseId) })
                case 'Child-org Transfer':
                    return actions.push({ icon: RiDirectionFill, title: t('Child-org Transfer'), link: '', onClick: () => openChildOrgTransferCaseModal(caseId) })
                case 'Create Notes':
                    return actions.push({ icon: FaStickyNote, title: t('Create Notes'), link: '', onClick: () => openCreateNotesModal(caseId) })
                case 'Create Reminder':
                     return actions.push({ icon: FaStickyNote, title: t('Create Reminder'), link: '', onClick: () => openCreateRemindersModal(caseId) })
                case 'Print Assessment':
                    return actions.push({ icon: AiFillPrinter, title: t('Print Assessment'), link: '', onClick: () => ((status === 19) || (status === 21) || (status === 5) || (status === 7) || (status === 9) || (status === 31) || (status === 28) ||(status === 29) ) ? enableDownload('assessment', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the assessment before print'), type: 'warning', active: true })) })
                case 'Print Summary':
                    return actions.push({ icon: AiFillPrinter, title: t('Print Summary'), link: '', onClick: () => ((status === 5) || (status === 21) || (status === 7) || (status === 9) ||(status === 28) || (status === 29)) ? enableDownload('summary', assessId) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the summary before print'), type: 'warning', active: true })) })
                case 'Print Care Plan':
                    return actions.push({ icon: AiFillPrinter, title: t('Print Care Plan'), link: '', onClick: () => ((status === 7) || (status === 9)) ? enableCareplanDownload(assessId, cpId, caseId,cgPrefLang) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Kindly complete the careplan before print'), type: 'warning', active: true })) })
                case 'Reopen':
                    return actions.push({ icon: RiDirectionFill, title: t('Reopen'), link: '', onClick: () => reopenCase(caseId) })
                case 'Add Caregiver':
                    return actions.push({ icon:FaUserPlus, title: t('Add Caregiver'), link:'', onClick: () => ((status === 35) || ((status ===11) && (caregiverId === 0 || careReceiverId === 0 || relation === null))) ? openCase(caseId, careReceiverId, caregiverId, careManagerId) : AddCase()})
                case 'No Records':
                    return actions.push({ icon:FaRegHandPointRight, title: t('No Actions'), link:'', onClick: ''})
                // case 'No Caregiver':
                //     return actions.push({ icon:RiAccountBoxFill, title: t('No Caregiver'), link:'', onClick: () => (((status ===35) && (caregiverId === 0))) ? updateNoCaregiver(caseId) :''})
    
                    default:
                        return null
                    
            } 
        })
        return <QuickAction id={caseId} active={activeQuickAction} setActive={id => setActiveQuickAction(id)} table={caseTable} options={actions} />
    }
    const createNewAssessment = (caseId) => {
        let details ={
            "screener":0
        }
        API.post(`organizations/${orgId}/cases/${caseId}/assessments`,details).then(res => {
            if (res.status === 200) {
                // if(reason === "true" && Create === 'true'){
                //     setAssesmentTypeId(res.data.body.assessmentId)
                //     setReassessDialogModal1(true)
                // }else{
                    history.push(`/case/${res.data.body.caseId}/assessment/${res.data.body.assessmentId}`)
                // }
               
                dispatch(setAlert({ title: t('Success'), subtitle: t('Assessment created successfully'), type: 'success', active: true }))

            }
        }).catch(err => {
            console.log(err)
        })
    }
    const createRescreen = (caseId) => {
        let details ={
            "screener":1
        }
        API.post(`organizations/${orgId}/cases/${caseId}/assessments`,details).then(res => {
            if (res.status === 200) {
                if(reason === "true" && Rescreens === 'true'){
                    setAssesmentTypeId(res.data.body.assessmentId)
                    setReassessDialogModal(true)
                }else{
                    history.push(`/case/${res.data.body.caseId}/assessment/${res.data.body.assessmentId}`)
                }
                // history.push(`/case/${res.data.body.caseId}/assessment/${res.data.body.assessmentId}`)
                dispatch(setAlert({ title: t('Success'), subtitle: t('Assessment created successfully'), type: 'success', active: true }))
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const AddCase =() =>{
        history.push({pathname:`/casedata/${caseId}`,state:{tabIndex:3,cgNew:true}})
    }
    const assessmentInProgress = (caseId, assessId) => {
        // if(reason === "true" && reassess === false && Create === 'true' && status === 9){
        //     setAssesmentTypeId(assessId)
        //     setReassessDialogModal(true)
        // }else{
            history.push(`/case/${caseId}/assessment/${assessId}`)
    }

    const  ContinueScreener = (caseId, assessId ,asstype ) => {
        // if(reason === "true" && reassess === false && Create === 'true'){
        //     setAssesmentTypeId(assessId)
        //     setReassessDialogModal(true)
        // }else 
        if(reason === "true" && reassess === false && asstype > 1 && Rescreens === 'true'){
            setAssesmentTypeId(assessId)
            setReassessDialogModal(true)
        }else{
            history.push(`/case/${caseId}/assessment/${assessId}`)
        }
    }
    
    const careplanInProgress = (caseId, cpId, assessId) => {
        history.push(`/case/${caseId}/assessment/${assessId}/careplan/${cpId}/${1}`)
    }
    const scoreInProgress = (caseId, assessId) => {
        history.push(`/case/${caseId}/score/${assessId}`)
    }
    const summaryInProgress = (caseId, assessId) => {
        history.push(`/case/${caseId}/summary/${assessId}`)
    }
    const createNewFollowupAssessment = (caseId) => {
        if(davService === 'true' && davEnable){
            setDavServiceEnable(true)
        }else{
          history.push(`/case/${caseId}/resourceUtlization`)
        }
    }
    const assignCases = (caseId) => {
        if (caseId !== undefined) {
            // setCaseId([c])
            let details = {
                "orgId": orgId,
                "caseId": [caseId],
                "newCareManagerId": userDetails.caremanagerId
            }
            API.post('organizations/cases:assign', details).then(res => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Case assigned successfully'), active: true, type: 'success' }))
                    dispatch(setAssignCaseRender( assignCaseRender+1))
                }
            }).catch(e => {
                console.log(e)
            })
        } else {
            let details = {
                "orgId": orgId,
                "caseId": caseId,
                "newCareManagerId": userDetails.caremanagerId
            }
            API.post('organizations/cases:assign', details).then(res => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Case assigned successfully'), active: true, type: 'success' }))
                    dispatch(setAssignCaseRender ( assignCaseRender+1))
                }
            }).catch(e => {
                console.log(e)
            })
        }
    }
    const enableDownload = (type, value) => {
        if (type === 'assessment') {
            setDownloadAssessmentPopup(true)
            setAssessmentId(value)
        } else if (type === 'summary') {
            setDownloadSummaryPopup(true)
            setAssessmentId(value)
        }
    }
    const enableCareplanDownload = (assessmentId, cpId, caseId) => {
        setDownloadCareplanPopup(true)
        setAssessmentId(assessmentId)
        setCareplanId(cpId)
        // console.log(cgPrefLang);
        // if(cgPrefLang === "es"){
        //     cgPrefLang = "Spanish"
        // }
        // setCurrentCaseId(caseId)
    }
    const reopenCase = async (caseId) => {
        let pageRefresh=true;
        if (caseId !== undefined) {
            await API.post(`cases:reopen?orgId=${orgId}`, [caseId]).then(res => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Case Reopened Successfully'), type: 'success', active: true }))
                    dispatch(setReopencasecount(reopencaseCount+1))                    
                    let subscriberID = null
                    let subList = history?.location?.state?.state?.list;
                    if(subList){
                        const filterData = subList.filter(e=> e.caseId ===caseId)
                        subscriberID = filterData[0].subscriberId;                   
                     API.get(`organizations/${orgId}/cases:search?subId="${subscriberID}"`).then((res)=>{
                        if(res.status === 200){
                            history.push('/case_list',{state:{list:res.data.body,from:"GeneralSearch",subId:subscriberID}}) 
                            dispatch(setAlert({ title: t('Success'), subtitle: t('Case Reopened Successfully'), type: 'success', active: true }))
                            dispatch(setReopencasecount(!pageRefresh))
                            dispatch(refreshTrigger(pageRefresh))                         
                        }
                    })
                }
            }
            }).catch(e => {
                console.log(e)
            })
        } else {
            // setCaseid([caseId])
        }
        // API.post('cases:reopen', [caseId]).then(res => {
        //     if (res.status === 200) {
        //         dispatch(setAlert({ title: 'Success', subtitle: 'Case Reopened Successfully', type: 'success', active: true }))
        //         dispatch(setReopencasecount(reopencaseCount+1))
        //     }
        // }).catch(e => {
        //     console.log(e)
        // })
    }
    const updateNoCaregiver = (c) => {
        if (c !== undefined)
        API.patch(`/cases/${c}/noCaregiver`).then(res => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('No Caregiver Updated Successfully'), type: 'success', active: true }))
                // history.push('/pendingRefferals')
            }
        }).catch(err => {
            if (err.response.status === 406) {
                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
               
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            }
        })
    }
    const createCareplan = (caseId, assessId) => {
        API.post(`organizations/${orgId}/assessments/${assessId}/care-plans`).then((res) => {
            if (res.status === 200) {
                history.push(`/case/${caseId}/assessment/${assessId}/careplan/${res.data.cpId}/${0}`)
                dispatch(setAlert({ title: t('Success'), subtitle: t('Care Plan Created Successfully'), type: 'success', active: true }))
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const openCloseCaseModal = (c) => {
        if (c !== undefined && enhanceClose === 'true'){
            setEnhanceCloseCaseModal(true)
        }else{
              // setCaseid([c])
            setCloseCaseModal(true)
        }
    }
    const openCreateNotesModal = (caseId) => {
        if (caseId !== undefined)
            // setCaseid(caseId)
        setNotesDialogModal(true)
    }
    const openCreateRemindersModal = (caseId) => {
        if(caseId !== undefined)
        // setCaseid(caseId)
        setCreateReminderModal(true)
    }
    const openCase = (caseId, careReceiverId, caregiverId, careManagerId) => {
        history.push({pathname:`/createCase/${caseId}/carereceiver/${careReceiverId}/caregiver/${caregiverId}/${careManagerId}`,state:{cgNew:true}})
    }
    const openTransferCaseModal = (c) => {
        if (c !== undefined)
            // setCaseid([c])
        setTransferCaseModal(true)
    }

    const openReassignReferrerModal = (c) => {
        if (c !== undefined)
            // setCaseid([c])
        setReassignReferrerCaseModal(true)
    }

    const openChildOrgTransferCaseModal = (c) => {
        if (c !== undefined)
            // setCaseid([c])
        setChildOrgTransferCaseModal(true)
    }
    const openTransferOrg = (c) => {
        if (c !== undefined)
        setTransferOrg(true)
    }
    const printAssessment = () => {
        let timestamp = new Date().getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        API.get(`organizations/${orgId}/assessments/${assessmentId}:print?timezone=${tzabbr}`).then(res => {
            if (res.status === 200) {
                const linkSource = `data:application/pdf;base64,${res.data.body}`;
                const downloadLink = document.createElement("a");
                const fileName = "TCE-Assessment.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                setDownloadAssessmentPopup(false)
            }
        })
    }

    const printSummary = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/assessments/${assessmentId}/summary`,{
            headers: {
                'Authorization': localStorage.getItem('token'), 
                'Accept': ''
              }
        }).then(res => {
            if (res.status === 200) {
                const linkSource = `data:application/pdf;base64,${res.data.body}`;
                const downloadLink = document.createElement("a");
                const fileName = "TCE-Summary.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                setDownloadSummaryPopup(false)
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
                setDownloadSummaryPopup(false)
            }
        }).catch(err => {
            dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
            setDownloadSummaryPopup(false)
        })
        // dispatch(setAlert({ title: 'Warning', subtitle: 'Work in progress', type: 'warning', active: true }))
        // setDownloadSummaryPopup(false)
    }

    const closeDialogs = () =>{
        setReassessDialogModal(false)
        dispatch(setCloseCaseRender(render+1));
    }
    // const printCareplan = () => {
    //     let timestamp = new Date().getTime();
    //     let tz = moment.tz.guess()
    //     let tzabbr = moment.tz.zone(tz).abbr(timestamp)
    //     // let details = {
    //     //     caseId: currentCaseId,
    //     //     cpId: careplanId,
    //     //     assessId: assessmentId,
    //     //     step: 2,
    //     //     lang: 'en',
    //     //     orgId: organization
    //     // }
    //     axios.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/care-plans/${careplanId}/queue/${2}?Timezone=${tzabbr}`,{
    //         headers: {
    //             'Authorization': localStorage.getItem('token'), 
    //             'Accept': ''
    //         }
    //           }).then(res => {
    //         if (res.status === 200) {
    //             const linkSource = `data:application/pdf;base64,${res.data.body}`;
    //             const downloadLink = document.createElement("a");
    //             const fileName = "TCE-Care Plan.pdf";
    //             downloadLink.href = linkSource;
    //             downloadLink.download = fileName;
    //             downloadLink.click();
    //             dispatch(setAlert({title:t('Success'),subtitle:t('Care Plan download successfully'),type:'success',active:true}))
    //             setDownloadCareplanPopup(false)
    //         } else {
    //             dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
    //             setDownloadCareplanPopup(false)
    //         }
    //     }).catch(err => {
    //         dispatch(setAlert({ title:t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
    //         setDownloadCareplanPopup(false)
    //     })
    //     // dispatch(setAlert({ title: 'Warning', subtitle: 'Work in progress', type: 'warning', active: true }))
    //     // setDownloadCareplanPopup(false)
    // }

    return (
        <>
            <h1>{getValue()}</h1>
            <Dialog title={t("Close Case")} showDialog={closeCaseModal} onClose={() => setCloseCaseModal(false)}>
                <ClosedCaseDialog onClose={() => setCloseCaseModal(false)} caseIds={[caseId]} />
            </Dialog>
            <Dialog title={t("Transfer Case")} showDialog={transferCaseModal} onClose={() => setTransferCaseModal(false)}>
                <TransferCaseDialog onClose={() => setTransferCaseModal(false)} caseIds={[caseId]} />
            </Dialog>
            <Dialog title={t("Associated Child-org Transfer")} showDialog={transferOrg} onClose={() => setTransferOrg(false)}>
                <TransferOrg onClose={() => setTransferOrg(false)} caseIds={[caseId]} />
            </Dialog>
            <Dialog title={t("Reassign Referrer")} showDialog={reassignreferrerCaseModal} onClose={() => setReassignReferrerCaseModal(false)}>
                <ReferrerCaseDialog onClose={() => setReassignReferrerCaseModal(false)} caseIds={[caseId]} />
            </Dialog>
            <Dialog title={t("Child-org Transfer")} showDialog={childOrgTransferCaseModal} onClose={() => setChildOrgTransferCaseModal(false)}>
                <ChildOrgTransferDailog onClose={() => setChildOrgTransferCaseModal(false)} caseIds={[caseId]} />
                {/* <h1>Working in progress</h1> */}
            </Dialog>
            <Dialog title={t("Create Notes")} showDialog={notesDialogModal} onClose={() => setNotesDialogModal(false)}>
                <NotesDialog onClose={() => setNotesDialogModal(false)} props={caseId} type={'create'} />
            </Dialog>
            <Dialog title={t('Create Reminder')} showDialog={createRemindersModal} onClose={() => setCreateReminderModal(false)}>
                <RemindersDialog onClose={() => setCreateReminderModal(false)} props={caseId} type={'create'}/>
            </Dialog>
            <ReassessDialog title={t("Assessment Reason")} showDialog={reassessDialogModal1} onClose={() => setReassessDialogModal1(false)}>
                <Reassessments onClose={() => setReassessDialogModal1(false)} props={caseId} assess ={assessmentTypeId} />
            </ReassessDialog>
            <ReassessDialog title={t("Reassessment Reason")} showDialog={reassessDialogModal} onClose={() => closeDialogs()}>
                <Reassessments onClose={() => setReassessDialogModal(false)} props={caseId} assess ={assessmentTypeId} type={'Rescreen'} />
            </ReassessDialog>
            <Dialog title={t("Download File")} showDialog={downloadAssessmentPopup} onClose={() => setDownloadAssessmentPopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Are you sure you want to download this Assessment form?")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setDownloadAssessmentPopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => printAssessment()}>{t("Yes")}</button>
                    </div>
                </div>
            </Dialog>
            <Dialog title={t("Download File")} showDialog={downloadSummaryPopup} onClose={() => setDownloadSummaryPopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Are you sure you want to download this summary form?")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setDownloadSummaryPopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => printSummary()}>{t("Yes")}</button>
                    </div>
                </div>
            </Dialog>
            <Dialog title={t("Download")} showDialog={downloadCareplanPopup} onClose={() => setDownloadCareplanPopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                    <div className="flex items-center justify-center"><span class="text-tcolor dark:text-white font-bold ">{t("CG Preferred Language")}</span> : {cgPrefLang} </div><br/>
                        <CareplanDialog careplanId={careplanId} onClose={() => setDownloadCareplanPopup(false)}/>
                    </div>
                    {/* <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setDownloadCareplanPopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => printCareplan()}>{t("Yes")}</button>
                    </div> */}
                </div>
            </Dialog>
            <DAVDialog title={t("DAV Military Service")} showDialog={davServiceEnable} onClose={() => setDavServiceEnable(false)}>
                <DavServiceDialog onClose={() => setDavServiceEnable(false)} props={caseId} cgId={caregiverId} crId={careReceiverId} resource={true} />
            </DAVDialog>
            <Dialog title={t("Enhanced Close Case Reasons")} showDialog={enhancecloseCaseModal} onClose={() => setEnhanceCloseCaseModal(false)}>
                <EnhancedClosedDialog onClose={() => setEnhanceCloseCaseModal(false)} caseIds={[caseId]} />
            </Dialog>
        </>

    )
}

export default QuickActionMultiRole