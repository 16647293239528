import { useCallback, useMemo, useState, useEffect, useLayoutEffect, useRef } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'

import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom"
import { RiDirectionFill, RiInboxArchiveFill } from 'react-icons/ri'
import InlineTextField from '../../components/snippets/InlineTextField'
import DatatableLoader from "../../components/snippets/DatatableLoader"
// import QuickAction from '../../components/snippets/QuickAction'
import API from '../../axios/API'
import { AnimatePresence, motion } from 'framer-motion'
import { PopInAnim } from '../../commons/anims'
import Dialog from '../../components/snippets/Dialog'
import ClosedCaseDialog from '../Dialogs/ClosedCaseDialog'
import TableSettings from '../../components/snippets/TableSettings'
import TableSelectFilter from '../../components/snippets/TableSelectFilter'
import { FaLayerGroup } from 'react-icons/fa'
import PopoverMenu from '../../components/snippets/PopoverMenu'
import TransferCaseDialog from '../Dialogs/TransferCaseDialog';
import TransferOrg from '../Dialogs/TransferOrg';
import { setAlert } from '../../store/theme/actions'
import NotesDialog from '../Dialogs/NotesDialog' 
import ChildOrgTransferDailog from '../Dialogs/ChildOrgTransferDailog'
import { useTranslation } from 'react-i18next'
import QuickActionMultiRole from '../QuickAction'   
import 'moment-timezone';
import moment from 'moment';
import ReferrerCaseDialog from '../Dialogs/ReferrerCaseDialog'
import VipButton from "../../components/snippets/VipButton";
import Empty from '../../components/Empty'
import axios from 'axios'
import FlowText from '../../components/snippets/FlowText'
import SelectColumn from '../../components/snippets/SelectColumn'
import EnhancedClosedDialog from '../Dialogs/EnhancedClosedDialog'


createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

var caseListSelected = []

const WatchList = ({history}) => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [records,setRecords] = useState(false);
    const [watchCaseDetails, setWatchCaseDetails] = useState([]);
    const [selectRows, setSelectedRows] = useState([])
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(true)
    const [openMultiAction, setOpenMultiAction] = useState(false)
    const organization = useSelector(state => state.auth.organization)
    const userDetails = useSelector(state => state.auth.userDetails)
    const [closeCaseModal, setCloseCaseModal] = useState(false)
    const [activeQuickAction] = useState({ position: 'bottom', active: null })
    const [columnsSelected, setColumnsSelected] = useState([])
    const [statusFilter, setStatusFilter] = useState('All Status')
    const caseTable = useRef(null)
    const [caseId, setCaseId] = useState([])
    const [transferCaseModal, setTransferCaseModal] = useState(false)
    const [transferOrg, setTransferOrg]  = useState(false)
    const [childOrgTransferCaseModal, setChildOrgTransferCaseModal] = useState(false)
    const [reassignreferrerCaseModal,setReassignReferrerCaseModal] = useState(false)
    const closecaseRender = useSelector(state => state.auth.closeCaseRender)
    const transfercaseRender = useSelector(state => state.auth.transferCaseRender)
    const childOrgTransferRender = useSelector(state =>state.auth.childOrgTransferRender)
    const [notesDialogModal, setNotesDialogModal] = useState(false)
    const assignCaseRender = useSelector(state => state.auth.assignCaseRender)
    const [assignCaseRenders, setAssignCaseRender] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [sizePage] = useState([10]);
    const [totalRows, setTotalRows] = useState(0);
    const [column,setColumn] = useState()
    const [sortDirection,setSortDirection] = useState()
    const [triggerAll, setTriggerAll] = useState(false)
    const [statusList, setStatusList] = useState([])
    const { t } = useTranslation()
    const language = useSelector(state=>state.theme.language)
    const dispatch = useDispatch()
    let orgId = localStorage.getItem('orgid')
    let vipEnable = localStorage.getItem('vipEnable')
    const [selectSearch, setSelectSearch] = useState([])    
    const [columnSelect, setColumnSelect] = useState(1)
    const [tempSelected,setTempSelected] = useState([])
    let lang= localStorage.getItem('language')
    let enhanceClose = localStorage.getItem('enhanceClose')
    const [enhancecloseCaseModal, setEnhanceCloseCaseModal] = useState(false)
    
    useEffect(() => {

    setRecords(false)
         //Page navigation handled using sessionstorage
       var pageView = sessionStorage.getItem('pageView');
       var sort = sessionStorage.getItem('sort');
       var direction = sessionStorage.getItem('direction');
       if(pageView){
        setPage(parseInt(pageView));
        sessionStorage.removeItem('pageView');
       }
       if(sort){        
        setColumn(sort);
        sessionStorage.removeItem('sort');
       }
       if(direction){
        setSortDirection(direction);
        sessionStorage.removeItem('direction');
       }

        setLoading(true);
        if (history.location.state !== undefined && history.location.state.state.from === 'GeneralSearch') {
            setFilter('')

        setWatchCaseDetails([])
        history.location.state.state.list.forEach((e, i) => {
            history.location.state.state.list[i].caseLastUpdated = lastdat(history.location.state.state.list[i].caseLastUpdated)
        })
        setWatchCaseDetails(history.location.state.state.list)
        setTotalRows(history.location.state.state.list.length)
        setLoading(false)
    } else {
        // let path = statusFiltepage ? `page=${page}&size=${perPage}` : page && perPage ? `page=${page}&size=${perPage}` : statusFilter && perPage ? `size=${perPage}&status=${statusFilter}` : `page=${page}&size=${perPage}`
        let path = column && statusFilter && statusFilter !== 'All Status' && statusFilter !== undefined && filter ? `page=${page}&size=${perPage}&status=${statusFilter}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}` :
        column && statusFilter === undefined && filter ? `page=${page}&size=${perPage}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}`: column && statusFilter && statusFilter !== 'All Status' && statusFilter !== undefined ? `page=${page}&size=${perPage}&status=${statusFilter}&sort=${column},${sortDirection}` :
        statusFilter === undefined && filter ? `page=${page}&size=${perPage}&search=${filter}&searchField=${columnSelect}` : statusFilter && statusFilter !== 'All Status' && 
        statusFilter !== undefined && filter ? `page=${page}&size=${perPage}&status=${statusFilter}&search=${filter}&searchField=${columnSelect}` : statusFilter && statusFilter !== 'All Status' && statusFilter !== undefined ? `page=${page}&size=${perPage}&status=${statusFilter}`:
        column && filter ? `page=${page}&size=${perPage}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}` : column ? `page=${page}&size=${perPage}&sort=${column},${sortDirection}` : pageView && column !== undefined && sortDirection !== undefined ? `page=${page}&size=${perPage}&sort=${column},${sortDirection}` : filter && filter !== undefined ? `page=${page}&size=${perPage}&search=${filter}&searchField=${columnSelect}` :  `page=${page}&size=${perPage}`
        // history.location.state = undefined
        axios.all([API.get(`status?screenName=watchList&orgId=${orgId}`),API.get(`fields?screenName=watchList`), API.get(`organizations/${organization}/cases?queue=watched&${path}`),API.get(`menus/case-searchFields?screenName=watchList?lang=${lang}`)]).then(axios.spread(
            (statusResp,watchListResp, watchListActive,allColumnSelect) => {
                let details = []
                setWatchCaseDetails([])
                watchListResp.data.body.map(c => {
                    details.push({ id: c.fieldId, name: c.fieldName, active: c.active })
                    return null;
                })
                setColumnsSelected(details)
                setStatusList([{ text: (t('All Status')), value: (('All Status')) }, ...statusResp.data.body.map((c) => ({ text: c.statusName, value: c.statusId }))])
                setSelectSearch([...allColumnSelect.data.body.map((c) => ({ text: c.name, value: c.value }))])
                setTotalPage(watchListActive.data.page.totalPages)
                setTotalRows(watchListActive.data.page.totalElements)
                if((!watchListActive.data._embedded && page > 1) || (!watchListActive.data._embedded && (closecaseRender ||  transfercaseRender || childOrgTransferRender || assignCaseRenders))){
                    setRecords(true)
                    setFilter('')
                }else{
                    setRecords(false)
                }
                watchListActive.data._embedded.immutableCaseList.forEach((e, i) => {
                    watchListActive.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(watchListActive.data._embedded.immutableCaseList[i].caseLastUpdated)
                })
                setWatchCaseDetails(watchListActive.data._embedded.immutableCaseList)
                setLoading(false)

            }
        )).catch(e => {
            console.log(e)
        }).finally(() => setLoading(false))
    }

        // API.get(`fields?screenName=watchList`).then((res)=>{
        //     if(res.data.body.length === 0){

        //     }else{
        //         let details = []
        //     res.data.body.map(c =>{
        //         details.push({id: c.fieldId,name : c.fieldName , active : c.active})
        //         return null;
        //     })
        //    setColumnsSelected(details);
        //     }
        // })
        // API.get(`organizations/${organization}/cases?queue=watched`).then((res) => {
        //     res.data._embedded.immutableCaseList.forEach((e,i)=>{
        //         res.data._embedded.immutableCaseList[i].caseLastUpdated=lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)	
        //     })
        //     setWatchCaseDetails(res.data._embedded.immutableCaseList);
        // }).catch(e => {
        //     // console.log(e)
        // }).finally(() => setLoading(false))
    }, [organization, closecaseRender, transfercaseRender,childOrgTransferRender,language,assignCaseRender,assignCaseRenders,triggerAll, page]) // eslint-disable-line
   
    const lastdat =(value)=>
    {if(value !== null && value){
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss')+ ' ' +tzabbr
        //      let followupdate = new Date(value)
        //  return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
         }else{
             return ''
         }
      }
    // const QuickActionComp = useCallback((row) => {
    //     // let options = (userDetails.role.includes('caremanager') && userDetails.role.length === 1) ? 
    //     // [
    //     //             { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(row.caseId) },
    //     //             { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(row.caseId) },
    //     //             { icon: RiDirectionFill, title: 'Assign Cases', link: '', onClick: () => assignCases(row.caseId) }
    //     // ] : (userDetails.role.includes('admin') && userDetails.role.length === 1) ?
    //     //  [
    //     //                  { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(row.caseId) },
    //     //                  { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(row.caseId) },
    //     //                  { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(row.caseId) },
    //     //                  { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(row.caseId) },
    //     //  ] : (userDetails.role.include('admin') && userDetails.role.includes('caremanager') && userDetails.role.length === 2) ? [
    //     //     { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(row.caseId) },
    //     //     { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(row.caseId) },
    //     //     { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(row.caseId) },
    //     //     { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(row.caseId) },
    //     //     { icon: RiDirectionFill, title: 'Assign Cases', link: '', onClick: () => assignCases(row.caseId) }
    //     //  ] : []
    //     let options = ((userDetails.role[0] === 'caremanager' && userDetails.role.length === 1) || (userDetails.role.includes('caremanager') && userDetails.role.includes('admin')!==true)) ?
    //         [
    //             { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(row.caseId) },
    //             { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(row.caseId) },
    //             { icon: RiDirectionFill, title: 'Assign Cases', link: '', onClick: () => assignCases(row.caseId) }
    //         ]
    //         : (userDetails.role.includes('admin') && userDetails.role.includes('caremanager')) ?
    //             [
    //                 { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(row.caseId) },
    //                 { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(row.caseId) },
    //                 { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(row.caseId) },
    //                 { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(row.caseId) },
    //                 { icon: RiDirectionFill, title: 'Assign Cases', link: '', onClick: () => assignCases(row.caseId) }
    //             ] : (userDetails.role[0]==="admin" && userDetails.role[1]==="screener") ? 
    //             [
    //                 { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(row.caseId) },
    //                 { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(row.caseId) },
    //                 { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(row.caseId) },
    //                 { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(row.caseId) },
    //             ]:
    //             [
    //                 { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(row.caseId) },
    //                 { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(row.caseId) },
    //                 { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(row.caseId) },
    //                 { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(row.caseId) },
    //                 { icon: RiDirectionFill, title: 'Assign Cases', link: '', onClick: () => assignCases(row.caseId) }
    //             ]
    //     // let options = ((userDetails.role[0] === 'caremanager' && userDetails.role.length === 1) || (userDetails.role.includes('caremanager') && userDetails.role.includes('admin')!==true)) ?
    //     //     [
    //     //     ]
    //     //     : (userDetails.role.includes('admin') && userDetails.role.includes('caremanager')) ?
    //     //         [
    //     //         ] : (userDetails.role[0]==="admin" && userDetails.role[1]==="screener") ? 
    //     //         [
    //     //             { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(row.caseId) },
    //     //             { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(row.caseId) },
    //     //             { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(row.caseId) },
    //     //             { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(row.caseId) },
    //     //         ]:
    //     //         [
    //     //             { icon: RiInboxArchiveFill, title: 'Close Case', link: '', onClick: () => openCloseCaseModal(row.caseId) },
    //     //             { icon: RiDirectionFill, title: 'Transfer Case', link: '', onClick: () => openTransferCaseModal(row.caseId) },
    //     //             { icon: RiDirectionFill, title: 'Child-org Transfer', link: '', onClick: () => openChildOrgTransferCaseModal(row.caseId) },
    //     //             { icon: FaStickyNote, title: 'Create Notes', link: '', onClick: () => openCreateNotesModal(row.caseId) },
    //     //             { icon: RiDirectionFill, title: 'Assign Cases', link: '', onClick: () => assignCases(row.caseId) }
    //     //         ]
    //     return (
    //         <QuickAction id={row.caseId} active={activeQuickAction} setActive={id => setActiveQuickAction(id)} table={caseTable} options={options} />
    //     )
    // }, [activeQuickAction]) // eslint-disable-line   
    
    const caseNavigation = (value) => {
        sessionStorage.setItem('pageView', page.toString());
        sessionStorage.setItem('sort', column);
        sessionStorage.setItem('direction', sortDirection);
    }

    const enableColumns = () => {
        let details = []
        columnsSelected.map(c =>{
            details.push(
                {
                 "fieldId": c.id,
                  "fieldName": c.name,
                  "active": c.active
                }
              )
              return null;
        })
        API.post(`fields?screenName=watchList`,details).then((res) =>{
        })
        
    }
    const caseStatusList = useMemo(() => {
        return [(t('All Status')), ...new Set(watchCaseDetails.map(c => c.caseStatus))].map(c => ({ text: c, value: c }))
    }, [watchCaseDetails,  t])

    const colums = useMemo(() => {
        return [
            {
                cell: row => {return (((organization && userDetails && userDetails.orgId === parseInt(organization))&& (userDetails.role.includes('caremanager')|| userDetails.role.includes('admin') || userDetails.role.includes('screener'))) || (userDetails.orgId !== parseInt(organization)) && (userDetails.role.includes('dynamic_scr')))?<QuickActionMultiRole role={userDetails.role} screen={'watchListCase'} status={row.statusId} caseId={row.caseId} assessId={row.latestAssessId} asstype={row.assessType} rescr={row.rescreenActivated} refName={row.referredByName} reassess={row.reason} />:((organization && userDetails && userDetails.orgId !== parseInt(organization))&& (userDetails.role.includes('dynamic_cm')|| userDetails.role.includes('dynamic_admin')))?<QuickActionMultiRole role={userDetails.role} screen={'watchListCase'} status={row.statusId} caseId={row.caseId} assessId={row.latestAssessId} asstype={row.assessType} rescr={row.rescreenActivated} refName={row.referredByName} reassess={row.reason} />:''  },
                allowOverflow: true,
                button: true,
                width: '56px', // custom width for icon button
            },
            { name: t('Case #'), selector: 'caseId', cell: row => <Link to={`/casedata/${row.caseId}`} onClick={() => caseNavigation(row)}>{row.caseId}</Link>,style:{cursor: "pointer","& :hover": {textDecoration: "underline"}}, initialActive: true, sortable: true, sortField: 'SORT_CASE_ID' },
            { name: t('Program Name'), selector: 'programName', sortable: true,sortField: 'SORT_PROGRAM_NAME' },
            // {
            //     name: t('Referred by'),
            //     selector: row =>(row.referredByName !== "null null") ? row.referredByName : '',
            //     sortable: true,wrap: true,sortField: 'SORT_REFERRED_BY'
            // },
            { name: t('Score'), selector: '',
            cell:row=>{
                const data = row.measureHistory;
                
                return(<div style={{wordWrap:'break-word',padding:'2px',whiteSpace:'normal',width:'125%'}}>
                    <div className="flex flex-row justify-start">{ (data.length && data[data.length -1].relationshipHml !== undefined && data[data.length -1].relationshipHml === 1) ? <div title={t("Relationship")} className="score-1"></div> :( data.length && data[data.length -1].relationshipHml !== undefined &&  data[data.length -1].relationshipHml === 2) ? <div title={t("Relationship")} className="score-2"></div>:(data.length && data[data.length -1].relationshipHml !== undefined && data[data.length -1].relationshipHml === 3) ? <div title={t("Relationship")} className="score-3"></div>:''}&nbsp; 
                    { (data.length && data[data.length -1].stressHml !== undefined && data[data.length -1].stressHml === 1) ? <div title={t("Stress")} className="score-1"></div> :( data.length && data[data.length -1].stressHml !== undefined &&  data[data.length -1].stressHml === 2) ? <div title={t("Stress")} className="score-2"></div>:(data.length && data[data.length -1].stressHml !== undefined && data[data.length -1].stressHml === 3) ? <div title={t("Stress")} className="score-3"></div>:''}&nbsp; 
                    { (data.length && data[data.length -1].objectiveHml !== undefined && data[data.length -1].objectiveHml === 1) ? <div title={t("Objective")} className="score-1"></div> :( data.length && data[data.length -1].objectiveHml !== undefined &&  data[data.length -1].objectiveHml === 2) ? <div title={t("Objective")} className="score-2"></div>:(data.length && data[data.length -1].objectiveHml !== undefined && data[data.length -1].objectiveHml === 3) ? <div title={t("Objective")} className="score-3"></div>:''}&nbsp; 
                    { (data.length && data[data.length -1].upliftHml !== undefined && data[data.length -1].upliftHml === 1) ? <div title={t("Uplifts")} className="score-1"></div> :( data.length && data[data.length -1].upliftHml !== undefined &&  data[data.length -1].upliftHml === 2) ? <div title={t("Uplifts")} className="score-2"></div>:(data.length && data[data.length -1].upliftHml !== undefined && data[data.length -1].upliftHml === 3) ? <div title={t("Uplifts")} className="score-3"></div>:''}&nbsp; 
                    { (data.length && data[data.length -1].depressionHml !== undefined && data[data.length -1].depressionHml === 1) ? <div title={t("Depression")} className="score-1"></div> :( data.length && data[data.length -1].depressionHml !== undefined &&  data[data.length -1].depressionHml === 2) ? <div title={t("Depression")} className="score-2"></div>:(data.length && data[data.length -1].depressionHml !== undefined && data[data.length -1].depressionHml === 3) ? <div title={t("Depression")} className="score-3"></div>:''}&nbsp; 
                    { (data.length && data[data.length -1].iddHml !== undefined && data[data.length -1].iddHml === 1) ? <div title={t("IDD")} className="score-1"></div> :( data.length && data[data.length -1].iddHml !== undefined &&  data[data.length -1].iddHml === 2) ? <div title={t("IDD")} className="score-2"></div>:(data.length && data[data.length -1].iddHml !== undefined && data[data.length -1].iddHml === 3) ? <div title={t("IDD")} className="score-3"></div>:''}&nbsp; 
                    { (data.length && data[data.length -1].itpNow !== undefined && data[data.length -1].itpNow === 0) ? <div title={t("ITP")} className="score-1"></div> :(data.length && data[data.length -1].itpNow !== undefined && data[data.length -1].itpNow === 1) ? <div title={t("ITP")} className="score-3"></div>:''} 
                    </div>
                </div>)
            }, 
            initialActive: true },
            { name: t('Caregiver Name'), selector:'caregiverName', cell: row => { return <div data-private="lipsum">{row.caregiverName !== 'null null' ? <div style={{wordWrap:'break-word',padding:'10px',whiteSpace:'normal',width:'auto'}}>{row.caregiverName}</div>  : ''}</div> }, sortable: true, initialActive: true, wrap: true, sortField: 'SORT_CAREGIVER_NAME' },
            // { name: t('Care Receiver Name'), selector: row => { return <div data-private="lipsum">{row.careReceiverName !== 'null null' ? <div style={{wordWrap:'break-word',padding:'10px',whiteSpace:'normal',width:'auto'}}>{row.careReceiverName}</div>  : ''}</div> }, sortable: true },
            { name: t('Care Receiver Name'), selector:'careReceiverName', cell: row => { return <div data-private="lipsum" style={{display:'flex'}}> {vipEnable === 'true' && row.vipStatus === true ? <button aria-label="Vip"><VipButton /></button> :<button aria-label="Empty Space"><Empty/></button>}&nbsp;<span style={{display:'flex'}}>{row.careReceiverName !== 'null null' ? row.careReceiverName  : ''}</span></div> }, sortable: true ,wrap: true, sortField: 'SORT_CARERECIPIENT_NAME' },
            { name: t('Care Receiver County'), selector: 'county', sortable: true,wrap: true, sortField: 'SORT_CARE_RECEIVER_COUNTY' },
            { name: t('Relationship'), selector: 'relationship', sortable: true,wrap: true, sortField: 'SORT_RELATIONSHIP'},
            {
                name: <>{t('Status')}<TableSelectFilter options={statusList} label={t("Status")} defaultValue={t("All Status")} value={statusFilter} setValue={value => changeStatus(value)} injectClass="p-1" /></>,
                selector: 'caseStatus',
                // sortable: true,
                sortField: 'SORT_STATUS',
                wrap: true,
                initialActive: true,
                optionalColumn: false
            },
            { name: t('Location'), selector: 'location', sortable: true, wrap: true, sortField: 'SORT_LOCATION'},
            { name: t('Last Updated'), selector:'caseLastUpdated' ,cell:row =>row.caseLastUpdated, sortable: true,wrap: true, sortField: 'SORT_LAST_UPDATE'},
            // { name: 'Case Notes', selector: 'notesLastModified', sortable: true, cell: row => <button onClick={() => dispatch(setAlert({ title: 'Warning', subtitle: 'Work in progress', type: 'warning', active: true }))}><FaStickyNote size="20px" className="text-gray-600 dark:text-gray-300" /></button> }
        ].map(c => {
            if (c.name !== undefined && columnsSelected.find(e => e.name === c.name)) c.omit = !columnsSelected.find(e => e.name === c.name).active
            return c
        })
    }, [activeQuickAction, columnsSelected,statusFilter,caseStatusList,watchCaseDetails]) // eslint-disable-line

    useLayoutEffect(() => {
        setColumnsSelected(colums.filter(c => c.name !== undefined && c.optionalColumn !== false).map(c => ({ name: c.name, active: c.initialActive || false })))
    }, []) // eslint-disable-line    
    const SubHeader = useMemo(() => (
        <div className="flex items-center relative">
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} onEnter={() => searchRecords()} onClick={() => searchRecords()} onClose={() => closeFilter()} />
            <span aria-label='Search Select'><SelectColumn options={selectSearch} label={t("Select Search")} value={columnSelect} setValue={value => changeSelect(value)} injectClass="p-1" /></span>
            <TableSettings columns={columnsSelected} setColumns={((columns) => {setColumnsSelected(columns);enableColumns()})} />
        </div>
    ), [filter, columnsSelected,columnSelect,selectSearch,records]) // eslint-disable-line
    
    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return watchCaseDetails.filter(c => filter === '' || filter === null || filterSplit)
    }, [watchCaseDetails, filter,statusFilter])// eslint-disable-line
    const ExpandComponent = useCallback(row => {
        return (
            <div className="bg-bluegray-300 dark:bg-ldark py-2 px-3" key={row.data.caseId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>
        )
    }, [])
    const rowsSelected = useCallback(({ selectedRows }) => {
        caseListSelected = tempSelected.map(c => c) 
        let DatacaseIds = watchCaseDetails.map(c => c.caseId)
        let tempcase = tempSelected.filter(c => !DatacaseIds.includes(c.caseId))
        selectedRows.forEach(c =>{
            tempcase.push(c)
        })
        // setCaseId(selectedRows.map(c => c.caseId))
        // setSelectedRows(selectedRows)
        setTempSelected(tempcase)
    }, [tempSelected,watchCaseDetails])
   
    useEffect(() => {
        setCaseId(tempSelected.map(c => c.caseId))
        setSelectedRows(tempSelected)
        // console.log(tempSelected,CaseListlocal)
        // let localArray = CaseListlocal || []
        // let tempStore = JSON.parse(localStorage.getItem('caselist'))
        // console.log(tempStore)
        // tempStore = tempSelected
        // localStorage.setItem('caselist',JSON.stringify(tempStore))
        // dispatch(setCaseList(localArray))
    }, [tempSelected])

    useEffect(()=>{
    setTempSelected([])
    setSelectedRows([])
    setCaseId()
    },[closecaseRender,transfercaseRender,childOrgTransferRender,assignCaseRender])

    const openCloseCaseModal = (c) => {
        if(enhanceClose === 'true'){
            setEnhanceCloseCaseModal(true)
        }else{
            setCaseId([c])
        setCloseCaseModal(true)
        }
    }

    const openTransferCaseModal = (c) => {
        if (c !== undefined)
            setCaseId([c])
        setTransferCaseModal(true)
    }
    const openTransferOrg = (c) => {
        if (c !== undefined)
        setCaseId([c])
        setTransferOrg(true)
    }

    const openReassignReferrerModal = (c) => {
        if (c !== undefined)
        setCaseId([c])
        setReassignReferrerCaseModal(true)
    }


    const openChildOrgTransferCaseModal = (c) => {
        if (c !== undefined)
            setCaseId([c])
        setChildOrgTransferCaseModal(true)
    }
    // const openCreateNotesModal = (c) => {
    //     if (c !== undefined) {
    //         setCaseId(c)
    //         setNotesDialogModal(true)
    //     } else {
    //         setCaseId(selectRows)
    //         setNotesDialogModal(true)
    //     }
    // }
 
       
    //    useEffect(() => {
    //     if(filteredcasedetails.length === 0 && (statusFilter !== 'All Status' || statusFilter === 'Todo el estado')){
    //      setStatusFilter('All Status')
    //     }

    // },[filteredcasedetails,statusFilter])
    const assignCases = (c) => {
        if (c !== undefined) {
            let details = {
                "orgId": orgId,
                "caseId": [c],
                "newCareManagerId": userDetails.caremanagerId
            }
            API.post('organizations/cases:assign', details).then(res => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Case assigned successfully'), active: true, type: 'success' }))
                    setAssignCaseRender(assignCaseRenders + 1)
                }
            }).catch(e => {
                console.log(e)
            })
        } else {
            let details = {
                "orgId": orgId,
                "caseId": caseId,
                "newCareManagerId": userDetails.caremanagerId
            }
            API.post('organizations/cases:assign', details).then(res => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Case assigned successfully'), active: true, type: 'success' }))
                    setAssignCaseRender(assignCaseRenders + 1)
                }
            }).catch(e => {
                console.log(e)
            })
        }
    }
    const closeFilter = () => {
        setPage(1)
        setStatusFilter()
        setColumn()
        setSortDirection()
        setColumnSelect(1)
        // changeStatus('All Status')
        setTriggerAll(!triggerAll)
    }
    const closeFilterStatus = () => {
        setRecords(false)
        setPage(1)
        setStatusFilter()
        setColumn()
        setSortDirection()
        setFilter('')
        changeStatus('All Status')
        setTriggerAll(!triggerAll)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setWatchCaseDetails([])
        setPerPage(sizePage);
        let apiPath = statusFilter ? `size=${newPerPage}&status=${statusFilter}` : `page=${page}&size=${newPerPage}`
        API.get(`organizations/${organization}/cases?queue=watched&${apiPath}`).then((res) => {
            if (res.status === 200) {
                setTotalRows(res.data.page.totalElements)
                setPerPage(res.data.page.size)
                res.data._embedded.immutableCaseList.forEach((e, i) => {
                    res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                })
                setWatchCaseDetails(res.data._embedded.immutableCaseList)
            }
        })
        setLoading(false);
        // sizeHandling(newPerPage)
    };

    if ((!loading && history.location.state === undefined && (filteredcasedetails.length >= 10 || watchCaseDetails.length >= 10)) || (!loading && (filteredcasedetails.length >= 10 || watchCaseDetails.length >= 10))) {
        if (page > 1) {
            // document.getElementById('pagination-next-page')?.removeAttribute('disabled')
            document.getElementById('pagination-first-page')?.removeAttribute('disabled')
            document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
                setPage(1)
            })
        }
        document.getElementById('pagination-next-page')?.addEventListener(('click'), () => {
            setPage(page + 1)
        })
        document.getElementById('pagination-last-page')?.addEventListener(('click'), () => {
            //setPage(totalPage)
        })
        if (page > 1) {
            document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
            document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
                setPage(page - 1)
            })
        }
    }  else if (!loading && page > 1 && filteredcasedetails.length < 10 && watchCaseDetails.length < 10) {
        document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
        document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
            setPage(page - 1)
        })
        document.getElementById('pagination-first-page')?.removeAttribute('disabled')
        document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
            setPage(1)
        })
    } else if (filteredcasedetails.length < 10 && watchCaseDetails.length < 10 && !loading && history.location.state === undefined) {
        document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    }else {
        document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    }

    const changeStatus = (value) => {
        statusList.map((c) => {
            if (c.value === value) {
                setStatusFilter(c.value)
            }
            return c
        })
        if (value !== 'All Status' && filter && filter !== undefined && column) {
            API.get(`organizations/${organization}/cases?queue=watched&size=${perPage}&status=${value}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}`).then((res) => {
                if (res.status === 200&& res.data._embedded) {
                    setPage(1)
                    res.data._embedded.immutableCaseList.forEach((e, i) => {
                        res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                    })
                    setWatchCaseDetails(res.data._embedded.immutableCaseList)
                    setTotalRows(res.data.page.totalElements)
                }else {
                    setRecords(true)
                    setWatchCaseDetails([])
                }
            })
        }else if (value !== 'All Status' && filter) {
            API.get(`organizations/${organization}/cases?queue=watched&size=${perPage}&status=${value}&search=${filter}&searchField=${columnSelect}`).then((res) => {
                if (res.status === 200&& res.data._embedded) {
                    setPage(1)
                    res.data._embedded.immutableCaseList.forEach((e, i) => {
                        res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                    })
                    setWatchCaseDetails(res.data._embedded.immutableCaseList)
                    setTotalRows(res.data.page.totalElements)
                }else {
                    setRecords(true)
                    setWatchCaseDetails([])
                }
            })
        }else if (value !== 'All Status' && column) {
            API.get(`organizations/${organization}/cases?queue=watched&size=${perPage}&status=${value}&sort=${column},${sortDirection}`).then((res) => {
                if (res.status === 200&& res.data._embedded) {
                    setPage(1)
                    res.data._embedded.immutableCaseList.forEach((e, i) => {
                        res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                    })
                    setWatchCaseDetails(res.data._embedded.immutableCaseList)
                    setTotalRows(res.data.page.totalElements)
                }else {
                    setRecords(true)
                    setWatchCaseDetails([])
                }
            })
        }
        else if (value !== 'All Status') {
            API.get(`organizations/${organization}/cases?queue=watched&size=${perPage}&status=${value}`).then((res) => {
                if (res.status === 200 && res.data._embedded) {
                    setPage(1)
                    res.data._embedded.immutableCaseList.forEach((e, i) => {
                        res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                    })
                    setWatchCaseDetails(res.data._embedded.immutableCaseList)
                    setTotalRows(res.data.page.totalElements)
                }else {
                    setRecords(true)
                    setWatchCaseDetails([])
                }
            })
        } else {
            API.get(`organizations/${organization}/cases?queue=watched&page=${page}&size=${perPage}`).then((res) => {
                if (res.status === 200 && res.data._embedded) {
                    res.data._embedded.immutableCaseList.forEach((e, i) => {
                        res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                    })
                    setWatchCaseDetails(res.data._embedded.immutableCaseList)
                    setTotalRows(res.data.page.totalElements)
                }
                else {
                    setRecords(true)
                    setWatchCaseDetails([])
                }
            })
        }
        if (res.data._embedded) {
            setRecords(false)
        }
        else {
            setRecords(true)
            setWatchCaseDetails([])
        }
    }

    const changeSelect = (value) => {
        selectSearch.map((c) => {
            if (c.value === value) {
                setColumnSelect(value)
            }
            return c
        })
    }

    const searchRecords = () => {
        if (filter.length > 0) {
            if (statusFilter !== 'All Status' && statusFilter !== undefined && column && filter) {
                API.get(`organizations/${organization}/cases?queue=watched&page=1&size=${perPage}&status=${statusFilter}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                        setWatchCaseDetails([])
                        res.data._embedded.immutableCaseList.forEach((e, i) => {
                            res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                        })
                        setWatchCaseDetails(res.data._embedded.immutableCaseList)
                        setTotalRows(res.data.page.totalElements)
                    }else {
                        setRecords(true)
                        setWatchCaseDetails([])
                    }
                })
            }
            else if (statusFilter !== 'All Status' && statusFilter !== undefined) {
                API.get(`organizations/${organization}/cases?queue=watched&page=1&size=${perPage}&status=${statusFilter}&search=${filter}&searchField=${columnSelect}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                        setWatchCaseDetails([])
                        res.data._embedded.immutableCaseList.forEach((e, i) => {
                            res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                        })
                        setWatchCaseDetails(res.data._embedded.immutableCaseList)
                        setTotalRows(res.data.page.totalElements)
                    }else {
                        setRecords(true)
                        setWatchCaseDetails([])
                    }
                })
            } else if(filter && filter !== undefined && column && column && sortDirection !== undefined) {
                if(filter){
                    let Apipath = filter ? `page=1&size=${perPage}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}` : `page=${page}&size=${perPage}`
                    API.get(`organizations/${organization}/cases?queue=watched&${Apipath}`).then((res) => {
                        if (res.status === 200 && res.data._embedded) {
                            setPage(1)
                            res.data._embedded.immutableCaseList.forEach((e, i) => {
                                res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                                res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                                res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                                res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                            })
                            setWatchCaseDetails(res.data._embedded.immutableCaseList)
                            setTotalRows(res.data.page.totalElements)
                        }else {
                            setRecords(true)
                            setWatchCaseDetails([])
                        }
                    })
                }
            } else{
                API.get(`organizations/${organization}/cases?queue=watched&page=1&size=${perPage}&search=${filter}&searchField=${columnSelect}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        // setPage(1)
                        setWatchCaseDetails([])
                        res.data._embedded.immutableCaseList.forEach((e, i) => {
                            res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                        })
                        setWatchCaseDetails(res.data._embedded.immutableCaseList)
                        setTotalRows(res.data.page.totalElements)
                    }else {
                        setRecords(true)
                        setWatchCaseDetails([])
                    }
                })
            }
        } 
        else  {
            if(statusFilter && statusFilter !== 'All Status'){
                let Apipath = statusFilter ? `page=1&size=${perPage}&status=${statusFilter}` : `page=${page}&size=${perPage}`
                API.get(`organizations/${organization}/cases?queue=watched&${Apipath}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                        res.data._embedded.immutableCaseList.forEach((e, i) => {
                            res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                        })
                        setWatchCaseDetails(res.data._embedded.immutableCaseList)
                        setTotalRows(res.data.page.totalElements)
                    }else {
                        setRecords(true)
                        setWatchCaseDetails([])
                    }
                })
            }
          
        }
        if (res.data._embedded) {
            setRecords(false)
        }
        else {
            setRecords(true)
            setWatchCaseDetails([])
        }
    }
 
    const handleSort = async (column, sortDirection) => {
        setTempSelected(caseListSelected.map(c => c))
        setColumn(column.sortField)
        setSortDirection(sortDirection.toUpperCase())
        let apiPath = statusFilter && statusFilter !== 'All Status' && statusFilter !== undefined && filter ? `size=${perPage}&status=${statusFilter}&search=${filter}&searchField=${columnSelect}&sort=${column.sortField}` :
        statusFilter !== 'All Status' && statusFilter !== undefined  ? `size=${perPage}&status=${statusFilter}&sort=${column.sortField}`: 
        filter ? `size=${perPage}&search=${filter}&searchField=${columnSelect}&sort=${column.sortField}` :`page=1&size=${perPage}&sort=${column.sortField}`;
        API.get(`organizations/${organization}/cases?queue=watched&${apiPath},${sortDirection.toUpperCase()}`).then((res) => {
            if (res.status === 200) {
                res.data._embedded.immutableCaseList.forEach((e, i) => {
                    res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                })
                setWatchCaseDetails(res.data._embedded.immutableCaseList)
                setTotalRows(res.data.page.totalElements)
            }
        })
        //   setData(remoteData);
    };
    useEffect(() => {
        caseListSelected = []
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    },[])

    const rowSelectCritera = (row) => {
        return tempSelected.map(c => c.caseId).includes(row.caseId)
      }

    return (
        <div className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg relative" ref={caseTable}>
            {
                loading ?
                    <DatatableLoader /> :
                    <DataTable title="casedetails" columns={colums} data={filteredcasedetails} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination paginationServer={true} noHeader highlightOnHover 
                    selectableRows={(organization && userDetails && userDetails.orgId === parseInt(organization)) || (userDetails.role.includes('dynamic_cm') || (userDetails.role.includes('dynamic_admin'))) ? true:false} 
                    selectableRowsComponentProps={{"role":"columnheader"}}
                    selectableRowSelected={rowSelectCritera}
                    selectableRowsHighlight persistTableHead subHeader onSort={handleSort} 
                     sortServer paginationRowsPerPageOptions={sizePage} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} onSelectedRowsChange={rowsSelected} paginationComponentOptions={{ rowsPerPageText: 'Items per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }} noDataComponent={t('There are no records to display')} />
                    // <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>
            }
           {records ? <div className='flex justify-center'> <span onClick={() => closeFilterStatus()}><button aria-label="Back to Watchlist" className='bg-tcolor flex px-6 py-0.5 mr-3 mt-3 items-center text-sm'>{t("Click here to Watchlist")}</button> </span></div> : ''}

            <AnimatePresence>
                {
                    selectRows.length > 0 &&
                    <motion.button variants={PopInAnim} initial="hidden" animate="visible" exit="hidden" className="flex items-center absolute bottom-6 ml-4 rounded text-white text-xs hover:bg-opacity-80" onClick={() => setOpenMultiAction(true)}>
                        <FaLayerGroup size="18px" className="mr-1 text-black dark:text-white" />
                        {selectRows.length > 0 &&
                            <span className="absolute -top-3 left-3/4 bg-tcolor rounded-lg py-0.5 px-1.5 text-[9px]">{selectRows.length}</span>
                        }
                        <PopoverMenu title={`${t('Action')} (${selectRows.length})`} origin="topright" active={openMultiAction} onClose={() => setOpenMultiAction(false)}>
                            <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openCloseCaseModal()}>
                                <RiInboxArchiveFill size="16px" className="mr-2" />
                               {t("Close Case")}
                            </div>
                           { ((userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin')) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin'))) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={()=> openTransferCaseModal()}>
                                <RiDirectionFill size="16px" className="mr-2" />
                                {t("Transfer Case")}
                            </div> :''}
                            {
                                ((userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin'))) && <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openTransferOrg()}>
                                    <RiDirectionFill size="16px" className="mr-2" />
                                    {t("Associated Child-org Transfer")}
                                </div> 
                            }
                            {
                                ((userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && selectRows.every(i => i.referredByName !== null)) ||(userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && selectRows.every(i => i.referredByName !== null))) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openReassignReferrerModal()}>
                                    <RiDirectionFill size="16px" className="mr-2" />
                                    {t("Reassign Referrer")}
                                </div> : ''
                            }
                            {((userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin'))) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openChildOrgTransferCaseModal()}>
                                <RiDirectionFill size="16px" className="mr-2" />
                                {t("Child-org Transfer")}
                            </div> : ''}
                            {((userDetails.orgId !== parseInt(organization) && userDetails.role.includes('caremanager')) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_cm'))) ?<div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => assignCases()} >
                                <RiDirectionFill size="16px" className="mr-2" />
                                {t("Assign Cases")}
                            </div>: ''}
                        </PopoverMenu>
                    </motion.button>
                }
            </AnimatePresence>
            <Dialog title={t("Close Case")} showDialog={closeCaseModal} onClose={() => setCloseCaseModal(false)}>
                <ClosedCaseDialog onClose={() => setCloseCaseModal(false)} caseIds={caseId} />
            </Dialog>
            <Dialog title={t("Transfer Case")} showDialog={transferCaseModal} onClose={() => setTransferCaseModal(false)}>
                <TransferCaseDialog onClose={() => setTransferCaseModal(false)} caseIds={caseId} />
                {/* <h1>Working in progress</h1> */}
            </Dialog>
            <Dialog title={t("Associated Child-org Transfer")} showDialog={transferOrg} onClose={() => setTransferOrg(false)}>
                <TransferOrg onClose={() => setTransferOrg(false)} caseIds={caseId} />
            </Dialog>
            <Dialog title={t("Reassign Referrer")} showDialog={reassignreferrerCaseModal} onClose={() => setReassignReferrerCaseModal(false)}>
                <ReferrerCaseDialog onClose={() => setReassignReferrerCaseModal(false)} caseIds={caseId} />
            </Dialog>
            <Dialog title={t("Child-org Transfer")} showDialog={childOrgTransferCaseModal} onClose={() => setChildOrgTransferCaseModal(false)}>
                <ChildOrgTransferDailog onClose={() => setChildOrgTransferCaseModal(false)} caseIds={caseId} />
            </Dialog>
            <Dialog title={t("Create Notes")} showDialog={notesDialogModal} onClose={() => setNotesDialogModal(false)}>
                <NotesDialog onClose={() => setNotesDialogModal(false)} props={caseId} type={'create'} />
            </Dialog>
            <Dialog title={t("Enhanced Close Case Reasons")} showDialog={enhancecloseCaseModal} onClose={() => setEnhanceCloseCaseModal(false)}>
                <EnhancedClosedDialog onClose={() => setEnhanceCloseCaseModal(false)} caseIds={caseId} />
            </Dialog>
        </div>
    )
}

export default WatchList