import { useCallback, useMemo, useState, useEffect, useLayoutEffect, useRef } from "react"
import { AnimatePresence, motion } from 'framer-motion'
import { setAlert } from "../../store/theme/actions"
import { BiBriefcase } from 'react-icons/bi'
import { useDispatch, useSelector } from "react-redux"
import QuickAction from "../../components/snippets/QuickAction"
import InlineTextField from '../../components/snippets/InlineTextField'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import DataTable, { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import API from "../../axios/API"
import { useTranslation } from "react-i18next"
import FieldLoader from "../../components/snippets/FieldLoader"
import { RiAccountBoxFill, RiInboxArchiveFill, RiRefreshFill } from 'react-icons/ri'
import { RiDirectionFill } from 'react-icons/ri'
import Dialog from '../../components/snippets/Dialog'
import ClosedCaseDialog from '../Dialogs/ClosedCaseDialog'
import 'moment-timezone';
import moment from 'moment';
import ReferrerCaseDialog from "../Dialogs/ReferrerCaseDialog"
import { PopInAnim } from '../../commons/anims'
import { FaLayerGroup, FaUserPlus } from 'react-icons/fa'
import { Link } from "react-router-dom"
import PopoverMenu from '../../components/snippets/PopoverMenu'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import FlowText from '../../components/snippets/FlowText'
import SelectColumn from "../../components/snippets/SelectColumn"


createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

var caseListSelected = []

const PendingCarereceivers = () => {

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [filter, setFilter] = useState('')
    const [subFilter, setSubFilter] = useState('')
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [records,setRecords] = useState(false);
    const [totalPage, setTotalPage] = useState()
    const [columnsSelected, setColumnsSelected] = useState([])
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const caseTable = useRef(null)
    const [openMultiAction, setOpenMultiAction] = useState(false)
    const [activeQuickAction, setActiveQuickAction] = useState({ position: 'bottom', active: null })
    const organization = useSelector(state => state.auth.organization)
    const [pendingCrList, setPendingCrList] = useState([])
    const [fetchingFollowupFor, setFetchingFollowupFor] = useState(null)
    const [followupFetchedList, setFollowupFetchedList] = useState([])
    const language = useSelector(state => state.theme.language)
    const [selectRows, setSelectedRows] = useState([])
    const userDetails = useSelector(state => state.auth.userDetails)
    const [closeCaseModal, setCloseCaseModal] = useState(false)
    const [reassignreferrerCaseModal, setReassignReferrerCaseModal] = useState(false)
    const closecaseRender = useSelector(state => state.auth.closeCaseRender)
    const render = useSelector(state => state.auth.transferCaseRender)
    const [caseId, setCaseId] = useState('')
    // const [statusId,setStatusId] = useState('')
    const [followupList, setFollowupList] = useState([])
    let orgId = localStorage.getItem('orgid')
    const [currentRow,setCurrentRow] = useState(null)
    const [perPage, setPerPage] = useState(10);
    const [sizePage] = useState([10]);
    const [totalRows, setTotalRows] = useState(0);
    const [column,setColumn] = useState()
    const [sortDirection,setSortDirection] = useState()
    const history = useHistory()
    let noCaregiver = localStorage.getItem('noCaregiver')
    const [triggerAll, setTriggerAll] = useState(false)
    const [selectSearch, setSelectSearch] = useState([])    
    const [columnSelect, setColumnSelect] = useState(1)
    const [tempSelected,setTempSelected] = useState([])
    let lang= localStorage.getItem('language')
    const [rowExpand,setRowExpand] = useState(false)
    const [innerPage,setInnerPage] = useState(1)

    useEffect(() => {

       //Page navigation handled using sessionstorage
       var pageView = sessionStorage.getItem('pageView');
       var sort = sessionStorage.getItem('sort');
       var direction = sessionStorage.getItem('direction');
       if(pageView){
        setPage(parseInt(pageView));
        sessionStorage.removeItem('pageView');
       }
       if(sort){        
        setColumn(sort);
        sessionStorage.removeItem('sort');
       }
       if(direction){
        setSortDirection(direction);
        sessionStorage.removeItem('direction');
       }
    //    setCurrentRow(null)
    //    setFollowupList([])
    
        setLoading(true)
        setPendingCrList([])
        if (history.location.state !== undefined && history.location.state.state.from === 'GeneralSearch') {
            setFilter('')
            
            history.location.state.state.list.forEach((e, i) => {
                history.location.state.state.list[i].followupDate = followdate1(history.location.state.state.list[i].followupDate)
                history.location.state.state.list[i].caseCreated = datecon(history.location.state.state.list[i].caseCreated)
            })
            setPendingCrList(history.location.state.state.list)
            if(currentRow !== null && followupList.length>0 && history.location.state !== undefined){
                setPendingCrList(history.location.state.state.list.map(c => {
                    if (c.caseId === currentRow) c.followupList = followupList
                    return c
                }))
            }
            setTotalRows(history.location.state.state.list.length)
            setLoading(false)
        } else {
            let path = column && filter ? `page=${page}&size=${perPage}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}` :
            filter && filter !== undefined ? `page=${page}&size=${perPage}&search=${filter}&searchField=${columnSelect}` :
            column ? `page=${page}&size=${perPage}&sort=${column},${sortDirection}`: pageView && column !== undefined && sortDirection !== undefined ? `page=${page}&size=${perPage}&sort=${column},${sortDirection}` : `page=${page}&size=${perPage}`
            // let apiPath = `page=${page}&size=${perPage}`
            axios.all([API.get(`organizations/${organization}/referrals?${path}`),API.get(`menus/case-searchFields?screenName=pendingReferral?lang=${lang}`)]).then(axios.spread(
                (referralCaseActive,allColumnSelect) => {
                    setSelectSearch([...allColumnSelect.data.body.map((c) => ({ text: c.name, value: c.value }))])
                    setTotalRows(referralCaseActive.data.page.totalElements)
                    if((!referralCaseActive.data._embedded && page > 1) || (!referralCaseActive.data._embedded && (closecaseRender))){
                        setRecords(true)
                        setFilter('')
                    }else{
                        setRecords(false)
                    }
                    referralCaseActive.data._embedded.immutableCaseList.forEach((e, i) => {
                        referralCaseActive.data._embedded.immutableCaseList[i].followupDate = followdate1(referralCaseActive.data._embedded.immutableCaseList[i].followupDate)
                        referralCaseActive.data._embedded.immutableCaseList[i].caseCreated = datecon(referralCaseActive.data._embedded.immutableCaseList[i].caseCreated)
                    })
                    setPendingCrList(referralCaseActive.data._embedded.immutableCaseList.map(c => {
                        c.followupList = []
                        return c
                    }))
                    setPendingCrList(referralCaseActive.data._embedded.immutableCaseList)
                    if (currentRow !== null && followupList.length > 0 && history.location.state === undefined){
                        setPendingCrList(referralCaseActive.data._embedded.immutableCaseList.map(c => {
                            if (c.caseId === currentRow) c.followupList = followupList
                            return c
                        }))
                    }
                    setTotalPage(referralCaseActive.data.page.totalPages)
                    setLoading(false)
    
                }
            )).catch(e => {
                console.log(e)
            }).finally(() => setLoading(false))
        } 
        // let apiPath = `page=${page}&size=${perPage}`
        // API.get(`organizations/${organization}/referrals?${apiPath}`).then(res => {
        //     if (res.status === 200) {                
        //         if(res.data._embedded){
        //             res.data._embedded.immutableCaseList.forEach((e,i)=>{
        //                res.data._embedded.immutableCaseList[i].followupDate = followdate1(res.data._embedded.immutableCaseList[i].followupDate)
        //                res.data._embedded.immutableCaseList[i].caseCreated=datecon(res.data._embedded.immutableCaseList[i].caseCreated)
        //             })
        //             setPendingCrList(res.data._embedded.immutableCaseList.map(c => {
        //                 c.followupList = []    
        //                 return c
        //             }))
        //         }else{
        //             setPendingCrList([])
        //         }
        //         setLoading(false)
        //     }
        // })
    }, [language,closecaseRender,render, organization, page,triggerAll])

    const followdate1 = (value) => {
        if (value !== null && value) {
            let timestamp = new Date(value).getTime();
            let tz = moment.tz.guess()
            let tzabbr = moment.tz.zone(tz).abbr(timestamp)
            return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr
            //       let followupdate = new Date(value)
            //   return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${value.slice(11,19)}`
        } else {
            return ''
        }

    }
    const datecon = (value) => {
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr
        //     let newdate = new Date(value)
        //     // newdate = (newdate.toUTCString().slice(4,10),newdate.toUTCString().slice(11,24))
        //    return `${ newdate.toUTCString().slice(8,11)}${ newdate.toUTCString().slice(4,7)}, ${ newdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
    }

    const QuickAcitonComp = useCallback((row) => {
        let options = (row.careManagerId !== 0 && row.careManagerId === userDetails.caremanagerId && userDetails.role.includes('caremanager') && !userDetails.role.includes('admin')) ?
            [
                { icon: BiBriefcase, title: t('Start Case'), link: `/createCase/${row.caseId}/carereceiver/${row.careReceiverId}/caregiver/${row.caregiverId}/${row.careManagerId}` },
                { icon: RiInboxArchiveFill, title: t('Close Referrals'), link: '', onClick: () => openCloseCaseModal(row.caseId) }
                // {icon : FaUserEdit , title:'Edit Carereceiver',link:'',onClick:()=>dispatch(setAlert({title:'Warning',subtitle:'Work in progress',type:'warning',active:true}))}
            ] : (row.careManagerId === 0 && ((userDetails.orgId === parseInt(organization) && row.referredByName !== null && userDetails.role.includes('caremanager') && !userDetails.role.includes('admin')) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_cm') && !userDetails.role.includes('dynamic_admin')))) ? [
                { icon: BiBriefcase, title: t('Start Case'), link: `/createCase/${row.caseId}/carereceiver/${row.careReceiverId}/caregiver/${row.caregiverId}/${row.careManagerId}` },
                { icon: RiInboxArchiveFill, title: t('Close Referrals'), link: '', onClick: () => openCloseCaseModal(row.caseId) }
                // {icon : FaUserEdit , title:'Edit Carereceiver',link:'',onClick:()=>dispatch(setAlert({title:'Warning',subtitle:'Work in progress',type:'warning',active:true}))}
            ]:(row.careManagerId !== 0 && row.careManagerId === userDetails.caremanagerId && userDetails.role.includes('caremanager') && userDetails.role.includes('admin')) ?
            [
                { icon: BiBriefcase, title: t('Start Case'), link: `/createCase/${row.caseId}/carereceiver/${row.careReceiverId}/caregiver/${row.caregiverId}/${row.careManagerId}` },
                { icon: RiInboxArchiveFill, title: t('Close Referrals'), link: '', onClick: () => openCloseCaseModal(row.caseId) },
                { icon: RiRefreshFill, title: t('Reassign Referrer'), link: '', onClick: () => openReassignReferrerModal(row.caseId) }
                // {icon : FaUserEdit , title:'Edit Carereceiver',link:'',onClick:()=>dispatch(setAlert({title:'Warning',subtitle:'Work in progress',type:'warning',active:true}))}
            ] : (row.careManagerId === 0 && ((userDetails.orgId === parseInt(organization) && row.referredByName !== null &&  userDetails.role.includes('caremanager') && userDetails.role.includes('admin')) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_cm') && userDetails.role.includes('dynamic_admin')))) ? [
                { icon: BiBriefcase, title: t('Start Case'), link: `/createCase/${row.caseId}/carereceiver/${row.careReceiverId}/caregiver/${row.caregiverId}/${row.careManagerId}` },
                { icon: RiInboxArchiveFill, title: t('Close Referrals'), link: '', onClick: () => openCloseCaseModal(row.caseId) },
                { icon: RiRefreshFill, title: t('Reassign Referrer'), link: '', onClick: () => openReassignReferrerModal(row.caseId) }
                // {icon : FaUserEdit , title:'Edit Carereceiver',link:'',onClick:()=>dispatch(setAlert({title:'Warning',subtitle:'Work in progress',type:'warning',active:true}))}
            ]
                : (((userDetails.orgId === parseInt(organization) && row.referredByName !== null && userDetails.role.includes('admin')) || (userDetails.orgId !== parseInt(organization) && row.referredByName !== null && userDetails.role.includes('dynamic_admin')))) ? [{ icon: RiInboxArchiveFill, title: t('Close Referrals'), link: '', onClick: () => openCloseCaseModal(row.caseId) },
                { icon: RiRefreshFill, title: t('Reassign Referrer'), link: '', onClick: () => openReassignReferrerModal(row.caseId) }
                ] :
                    // ((userDetails.role.includes('referrer')) && (row.caregiverId===0) && (noCaregiver === 'true')) ?
                    // [ { icon: FaUserPlus, title: t('Add Caregiver'), link: '',onClick: () => openCase(row.caseId, row.careReceiverId, row.caregiverId, row.careManagerId) },
                    // { icon: RiInboxArchiveFill, title: t('Close Referrals'), link: '', onClick: () => openCloseCaseModal(row.caseId) },
                    // { icon: RiAccountBoxFill, title: t('No Caregiver'), link: '', onClick: () => updateNoCaregiver(row.caseId) }]:
                    ((userDetails.role.includes('referrer')) && (noCaregiver === 'true')) ? [
                        { icon: FaUserPlus, title: t('Add Caregiver'), link: '', onClick: () => openCase(row.caseId, row.careReceiverId, row.caregiverId, row.careManagerId) },
                        { icon: RiInboxArchiveFill, title: t('Close Referrals'), link: '', onClick: () => openCloseCaseModal(row.caseId) }
                    ] :
                        [{ icon: RiInboxArchiveFill, title: t('Close Referrals'), link: '', onClick: () => openCloseCaseModal(row.caseId) }
                        ]

        return (
            <QuickAction id={row.caseId} active={activeQuickAction} setActive={id => setActiveQuickAction(id)} table={caseTable} options={options} />
        )
    }, [activeQuickAction, pendingCrList])// eslint-disable-line


    const caseNavigation = (value) => {
        sessionStorage.setItem('pageView', page.toString());
        sessionStorage.setItem('sort', column);
        sessionStorage.setItem('direction', sortDirection);
    }

    const colums = useMemo(() => {
        return [
            {
                cell: row => { return (((userDetails.orgId === parseInt(organization)) && (userDetails.role.includes('caremanager') || userDetails.role.includes('admin') || userDetails.role.includes('referrer'))) || (userDetails.orgId !== parseInt(organization) && (userDetails.role.includes('dynamic_cm') || userDetails.role.includes('dynamic_admin') || userDetails.role.includes('dynamic_ref')))) ? QuickAcitonComp(row) : '' },
                allowOverflow: true,
                button: true,
                width: '56px',
            },
            // { name: t('Case #'), selector: 'caseId', cell: row => {return (userDetails.role.includes('referrer')) ? <Link to={`/createCase/${row.caseId}/carereceiver/${row.careReceiverId}/caregiver/${row.caregiverId}/${row.careManagerId}`}>{row.caseId}</Link> : <Link>{row.caseId}</Link>},style:{cursor: "pointer","& :hover": {textDecoration: "underline"}}, sortable: true },
            { name: t('Case #'), selector: 'caseId', cell: row => {return (((userDetails.orgId === parseInt(organization)) && (row.careManagerId === 0 || userDetails.caremanagerId === row.careManagerId)) && (userDetails.role.includes('referrer') || userDetails.role.includes('caremanager'))|| ((userDetails.orgId !== parseInt(organization)) && (row.careManagerId === 0 || userDetails.caremanagerId === row.careManagerId)) && (userDetails.role.includes('dynamic_ref') || userDetails.role.includes('dynamic_cm'))) ? <Link to={`/createCase/${row.caseId}/carereceiver/${row.careReceiverId}/caregiver/${row.caregiverId}/${row.careManagerId}`} onClick={() => caseNavigation(row)}>{row.caseId}</Link> :((userDetails.orgId === parseInt(organization) && (userDetails.parentRole.length === 1 && userDetails.parentRole.includes('admin'))) || (userDetails.orgId !== parseInt(organization) && (userDetails.childRole.length === 1 && userDetails.childRole.includes('dynamic_admin'))) ) ? <Link>{row.caseId}</Link>:  <Link  onClick={() => dispatch(setAlert({title:t('Warning'),subtitle:t('Case Already Assigned'), type: 'warning', active: true }))}>{row.caseId}</Link>},style:{cursor: "pointer","& :hover": {textDecoration: "underline"}}, sortable: true, sortField: 'SORT_CASE_ID' },
          {
                name: t('Care Receiver Name'),
                selector:'careReceiverName', cell: row => {return <div  data-private="lipsum">{(row.careReceiverName!=="null null") ? `${row.careReceiverName}` : ''}</div>}, sortable: true, sortField: 'SORT_CARERECIPIENT_NAME'
            },
            {
                name:  t('Care Receiver SubscriberID'), selector: 'subscriberId', sortable: true,wrap: true,sortField:'SORT_SUBSCRIBER_ID'},
              
            { name: t('Care Receiver County'), selector: 'county', sortable: true,wrap: true, sortField: 'SORT_CARE_RECEIVER_COUNTY'},
            {
                name: t('Caregiver Name'),
                selector:'caregiverName', cell: row => {return <div  data-private="lipsum">{(row.caregiverName!=="null null") ? `${row.caregiverName}` : ''}</div>},
                sortable: true, sortField: 'SORT_CAREGIVER_NAME'
            },
            {
                name: t('Caremanager Name'),
                selector:'caremangerName', cell: row =>(row.caremanagerName!=="null null") ? row.caremanagerName: '', sortable: true,wrap: true,sortField: 'SORT_CAREMANAGER_NAME'
            },
            {
                name: t('Referred by'),
                selector:'referredByName', cell: row =>(row.referredByName !== "null null") ? row.referredByName : '',
                sortable: true,wrap: true,sortField: 'SORT_REFERRED_BY'
            },
            { name: t('Follow-Up Date'), selector:'followupDate', cell: row => (row.followupDate !== null ) ? row.followupDate:'' , sortable: true ,wrap: true,sortField: 'SORT_FOLLOW_UP_DATE'},
            {
                name: t('Created At'),
                selector:'caseCreated', cell: row => row.caseCreated, 
                sortable: true,wrap: true,
                sortField: 'SORT_CREATED_AT'
            }
        ].map(c => {
            if (c.name !== undefined && columnsSelected.find(e => e.name === c.name)) c.omit = columnsSelected.find(e => e.name === c.name).active
            return c
        })
    }, [activeQuickAction, columnsSelected, pendingCrList]) // eslint-disable-line
    useLayoutEffect(() => {
        setColumnsSelected(colums.filter(c => c.name !== undefined && c.optionalColumn !== false).map(c => ({ name: c.name, active: c.initialActive || false })))
    }, []) // eslint-disable-line    
    const SubHeader = useMemo(() => (
        <div className="flex items-center relative">
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} onEnter={() => searchRecords()} onClick={() => searchRecords()} onClose={() => closeFilter()}/>
            <span aria-label='Search Select'><SelectColumn options={selectSearch} label={t("Select Search")} value={columnSelect} setValue={value => changeSelect(value)} injectClass="p-1" /></span>        
        </div>
    ), [filter, columnsSelected,columnSelect,selectSearch]) // eslint-disable-line
    
    const filteredpendingCrList = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return pendingCrList.filter(c => filter === '' || filter === null || filterSplit)
    }, [pendingCrList, filter])


    const SubHeaderSub = useMemo(() => (
        <div className="flex items-center relative">
            <InlineTextField label={t("Search")} autoFocus={subFilter.toString().length > 0 ? true : false} value={subFilter} setValue={setSubFilter} />
        </div>
    ), [subFilter, followupList])//eslint-disable-line

    const filteredFollowupList = useMemo(() => {
        let filterSplit = subFilter.toString().toLowerCase().split(' ')
        return followupList.filter(c => subFilter === '' || subFilter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [followupList, subFilter])

    const RowComponent = useCallback(row => {
        return (
            <div className="bg-bluegray-300 dark:bg-ldark py-2 px-3" key={row.data.caseId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>
        )
    }, [])
    const rowsSelected = useCallback(({ selectedRows }) => {
        caseListSelected = tempSelected.map(c => c) 
        let DatacaseIds = pendingCrList.map(c => c.caseId)
        let tempcase = tempSelected.filter(c => !DatacaseIds.includes(c.caseId))
        selectedRows.forEach(c =>{
            tempcase.push(c)
        })
        // setCaseId(selectedRows.map(c => c.caseId))
        // setSelectedRows(selectedRows)
        setTempSelected(tempcase)
    }, [tempSelected,pendingCrList])
   
    useEffect(() => {
        setCaseId(tempSelected.map(c => c.caseId))
        setSelectedRows(tempSelected)
    }, [tempSelected])

    useEffect(()=>{
    setTempSelected([])
    setSelectedRows([])
    setCaseId()
    },[closecaseRender,render])

    // const fetchFollowupFor = (caseId) => {
    //     setFetchingFollowupFor(caseId)
    //     API.get(`cases/${caseId}/follow-ups`)
    //         .then(res => {
    //             if (res.data.status) {
    //                 setPendingCrList(pendingCrList.map(c => {
    //                     if (c.caseId === caseId) c.followupList = res.data.data
    //                     return c
    //                 }))
    //             } else dispatch(setAlert({ title: t('Failed to get Assessment'), subtitle: res.data.errors.join(', '), active: true, type: 'error' }))
    //         })
    //         .catch(err => {
    //             console.log(err)
    //             dispatch(setAlert({ title: t('Failed to get Assessment'), subtitle: '', active: true, type: 'error' }))
    //         }).finally(() => setFetchingFollowupFor(null))
    // }

    const followdate = (value) => {
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr

    }



    // return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,11)}, ${followupdate.toUTCString().slice(11,16)} ${value.slice(11,19)}`

    const updatedate = (value) => {
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr
        // let updatedates = new Date(value)
        // // updatedates = (updatedates.toUTCString().slice(4,21))
        // return `${updatedates.toUTCString().slice(8,11)}${updatedates.toUTCString().slice(4,7)}, ${updatedates.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
    }

    const followupColumns = useMemo(() => {
        return [
            // { name: t('F ollow-Up Id'), center: true, selector: row=>row.rcfId, sortable: true },
            { name: t('Reason'), center: true, selector: row => row.reasonName, sortable: true },
            { name: t('Created by'), center: true, selector: row => row.createdBy, sortable: true },
            { name: t('Notes'), center: true, selector: row => { return (<div style={{ wordWrap: 'break-word', padding: '10px', whiteSpace: 'normal', width: 'auto' }}> {row.notes}</div>) } },
            { name: t('Follow-Up Date'), selector: row => { return (row.followupDate !== null) ? `${followdate(row.followupDate)}` : '' }, sortable: false },
            { name: t('Updated At'), selector: row => `${updatedate(row.createdDate)}`, sortable: false }
        ]
    }, [activeQuickAction, followupFetchedList]) // eslint-disable-line
    const rowExpanded = (data, row) => {
    setRowExpand(!rowExpand)
        setCurrentRow(row.caseId)
        setSubFilter('')
        if (row.followupList?.length === 0 && !followupFetchedList.includes(row.caseId)) {
            // fetchFollowupFor(row.caseId)
            setFollowupFetchedList([row.caseId])
            API.get(`organizations/${orgId}/cases/${row.caseId}/follow-ups`)
                .then(res => {
                    if (res.status === 200) {
                        setPendingCrList(pendingCrList.map(c => {
                            if (c.caseId === row.caseId) c.followupList = res.data
                            return c
                        }))
                    } else dispatch(setAlert({ title: t('Failed to get followup'), subtitle: res.data.errors.join(', '), active: true, type: 'error' }))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setAlert({ title: t('Failed to get followup'), subtitle: '', active: true, type: 'error' }))
                }).finally(() => setFetchingFollowupFor(null))
        }
    }

    const NoFollowup = () => (
        <div className="my-6 text-sm text-gray-500 dark:text-gray-400">
            {t('No Follow-Up to display')}
        </div>
    )
    const ExpandComponent = useCallback(({ data }) => {
        return (
            <div className="p-4 bg-bluegray-300 dark:bg-ldark">
                {fetchingFollowupFor === data.caseId && <FieldLoader />}
                {data.followupList && setFollowupList(data.followupList)}
                <DataTable columns={followupColumns} data={filteredFollowupList} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination noHeader highlightOnHover persistTableHead
                    selectableRowsHighlight subHeader subHeaderComponent={SubHeaderSub} onChangePage={val => setInnerPage(val)} paginationDefaultPage={innerPage} expandableRows={screenSize < 3} expandableRowsComponent={<RowComponent />} noDataComponent={<NoFollowup />} />
            </div>
        )
    }, [activeQuickAction, themeMode, fetchingFollowupFor, filteredFollowupList]) // eslint-disable-line

    const openCloseCaseModal = (c) => {
        if (c !== undefined)
            setCaseId([c])
        setCloseCaseModal(true)
    }
    const openCase = (caseId, careReceiverId, caregiverId, careManagerId) => {
        history.push({ pathname: `/createCase/${caseId}/carereceiver/${careReceiverId}/caregiver/${caregiverId}/${careManagerId}` })
    }
    const openReassignReferrerModal = (c) => {
        if (c !== undefined)
            setCaseId([c])
        setReassignReferrerCaseModal(true)
    }
    const updateNoCaregiver = (c) => {
        if (c !== undefined)
            API.patch(`/cases/${c}/noCaregiver`).then(res => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: t('Success'), subtitle: t('No Caregiver Updated Successfully'), type: 'success', active: true }))
                    // history.push('/pendingRefferals')
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                       } else {
                    dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                }
            })
    }
    const closeFilter = () => {
        setPage(1)
        setColumn()
        setSortDirection()
        setColumnSelect(1)
        setCurrentRow(null)
        setFollowupFetchedList([])
        // changeStatus('All Status')
        setTriggerAll(!triggerAll)
    }
    
    const closeFilterStatus = () => {
        setRecords(false)
        setPage(1)
        setColumn()
        setSortDirection()
        setFilter('')
        setTriggerAll(!triggerAll)
    }
   
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setPendingCrList([])
        setPerPage(sizePage);
        let apiPath = `page=${page}&size=${newPerPage}`
        API.get(`organizations/${organization}/referrals?${apiPath}`).then((res) => {
            if (res.status === 200) {
                setTotalRows(res.data.page.totalElements)
                setPerPage(res.data.page.size)
                res.data._embedded.immutableCaseList.forEach((e,i)=>{
                    res.data._embedded.immutableCaseList[i].followupDate = followdate1(res.data._embedded.immutableCaseList[i].followupDate)
                    res.data._embedded.immutableCaseList[i].caseCreated=datecon(res.data._embedded.immutableCaseList[i].caseCreated)
                })
                setPendingCrList(res.data._embedded.immutableCaseList)
            }
        })
        setLoading(false);
        // sizeHandling(newPerPage)
    };

    // if ((!loading && history.location.state === undefined && (filteredpendingCrList.length >= 10 || pendingCrList.length >= 10)) || (!loading && (filteredpendingCrList.length >= 10 || pendingCrList.length >= 10))) {
    //     if (page > 1) {
    //         // document.getElementById('pagination-next-page')?.removeAttribute('disabled')
    //         document.getElementById('pagination-first-page')?.removeAttribute('disabled')
    //         document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
    //             setPage(1)
    //         })
    //     }
    //     document.getElementById('pagination-next-page')?.addEventListener(('click'), () => {
    //         setPage(page + 1)
    //     })
    //     document.getElementById('pagination-last-page')?.addEventListener(('click'), () => {
    //         setPage(totalPage)
    //     })
    //     if (page > 1) {
    //         document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
    //         document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
    //             setPage(page - 1)
    //         })
    //     }
    // }  else if (!loading && page > 1 && filteredpendingCrList.length < 10 && pendingCrList.length < 10) {
    //     document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
    //     document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
    //         setPage(page - 1)
    //     })
    //     document.getElementById('pagination-first-page')?.removeAttribute('disabled')
    //     document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
    //         setPage(1)
    //     })
    // } else if (filteredpendingCrList.length < 10 && pendingCrList.length < 10 && !loading && history.location.state === undefined) {
    //     document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
    //     document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
    //     document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
    //     document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    // }else {
    //     document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
    //     document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
    //     document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
    //     document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    // }

    const changeSelect = (value) => {
        selectSearch.map((c) => {
            if (c.value === value) {
                setColumnSelect(value)
            }
            return c
        })
    }

    const searchRecords = () => {
        setCurrentRow(null)
        setFollowupFetchedList([])
        if (filter.length > 0) {
            if (column && filter) {
                API.get(`organizations/${organization}/referrals?page=1&size=${perPage}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        // setPage(1)
                        setPendingCrList([])
                        res.data._embedded.immutableCaseList.forEach((e, i) => {
                            res.data._embedded.immutableCaseList[i].followupList = []
                            res.data._embedded.immutableCaseList[i].followupDate = followdate1(res.data._embedded.immutableCaseList[i].followupDate)
                            res.data._embedded.immutableCaseList[i].caseCreated = datecon(res.data._embedded.immutableCaseList[i].caseCreated)
                        })
                        setPendingCrList(res.data._embedded.immutableCaseList)
                        setTotalRows(res.data.page.totalElements)
                    }
                    else{
                        setPendingCrList([])
                        setRecords(true)
                    }
                })
            }else{
                let Apipath = filter ? `page=1&size=${perPage}&search=${filter}&searchField=${columnSelect}` : `page=${page}&size=${perPage}`
                API.get(`organizations/${organization}/referrals?${Apipath}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                        res.data._embedded.immutableCaseList.forEach((e, i) => {
                            res.data._embedded.immutableCaseList[i].followupList = []
                            res.data._embedded.immutableCaseList[i].followupDate = followdate1(res.data._embedded.immutableCaseList[i].followupDate)
                            res.data._embedded.immutableCaseList[i].caseCreated = datecon(res.data._embedded.immutableCaseList[i].caseCreated)
                        })
                        setPendingCrList(res.data._embedded.immutableCaseList)
                        setTotalRows(res.data.page.totalElements)
                    }
                    else{
                        setPendingCrList([])
                        setRecords(true)
                    }
                })  
            }        
        }
        if (res.data._embedded) {
            setRecords(false)
        }
        else {
            setRecords(true)
            setPendingCrList([])
        }
    }
 
    const handleSort = async (column, sortDirection) => {
        setTempSelected(caseListSelected.map(c => c))
        setColumn(column.sortField)
        setSortDirection(sortDirection.toUpperCase())
        let apiPath = `page=1&size=${perPage}&sort=${column.sortField}`;
        API.get(`organizations/${organization}/referrals?${apiPath},${sortDirection.toUpperCase()}`).then((res) => {
            if (res.status === 200) {
                res.data._embedded.immutableCaseList.forEach((e,i)=>{
                    res.data._embedded.immutableCaseList[i].followupDate = followdate1(res.data._embedded.immutableCaseList[i].followupDate)
                    res.data._embedded.immutableCaseList[i].caseCreated=datecon(res.data._embedded.immutableCaseList[i].caseCreated)
                 })
                setPendingCrList(res.data._embedded.immutableCaseList)
                setTotalRows(res.data.page.totalElements)
            }
        })
        //   setData(remoteData);
    };

    useEffect(() => {
        caseListSelected = []
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                // console.log("element e", e)
                // e.setAttribute("role", "gridcell")  
                // const node = document.createAttribute("role");
                // node.value = "gridcell";
                // e.attributes.setNamedItem(node)
                e.setAttribute('role','gridcell')
            })
        }
    },[])

    // useLayoutEffect(()=>{
        
    // },[rowExpand])
    const prevButton = document.querySelector('.rdt_ExpanderRow .rdt_Pagination #pagination-previous-page');
    const nextButton = document.querySelector('.rdt_ExpanderRow .rdt_Pagination #pagination-next-page')
    
    if (prevButton) {
        prevButton.id = 'customPrevButtonId';
    }

    if (nextButton) {
        nextButton.id = 'customNextButtonId';
    } 
    const rowSelectCritera = (row) => {
        return tempSelected.map(c => c.caseId).includes(row.caseId)
      }
    return (
        <div className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg relative" ref={caseTable}>
            {
                loading ?
                    <DatatableLoader /> :
                    <DataTable title="pendingCrList" columns={colums} data={filteredpendingCrList}
                        theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination paginationServer={true} noHeader
                        selectableRows={(organization && userDetails && userDetails.orgId === parseInt(organization) && (userDetails.role.includes('admin') || userDetails.role.includes('caremanager') || userDetails.role.includes('referrer'))) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') || userDetails.role.includes('dynamic_cm') || userDetails.role.includes('dynamic_ref')) ? true : false}
                        selectableRowsComponentProps={{ "role": "columnheader" }}
                        selectableRowSelected={rowSelectCritera}
                        selectableRowsHighlight persistTableHead subHeader onSort={handleSort} 
                        sortServer paginationRowsPerPageOptions={sizePage} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} subHeaderComponent={SubHeader} onChangePage={val => setPage(val)}
                        paginationDefaultPage={page}
                        paginationComponentOptions={{
                            rowsPerPageText: 'Items per page:',
                            rangeSeparatorText: 'of',
                            noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All'
                        }}
                        expandableRows
                        expandableRowExpanded={(row) => (row.caseId === currentRow)}
                        expandableRowsComponent={<ExpandComponent />}
                        onSelectedRowsChange={rowsSelected} onRowExpandToggled={rowExpanded}
                        noDataComponent={t('There are no records to display')} />
                        // <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>
            }
           {records ?<div className='flex justify-center'> <span onClick={() => closeFilterStatus()}><button aria-label="Back to Unassigned Cases" className='bg-tcolor flex px-6 py-0.5 mr-3 mt-3 items-center text-sm'>{t("Click here to Pending Referrals Cases")}</button> </span></div> : ''}

            <AnimatePresence>
                {
                    selectRows.length > 0 &&
                    <motion.button variants={PopInAnim} initial="hidden" animate="visible" exit="hidden" className="flex items-center absolute bottom-6 ml-4 rounded text-white text-xs hover:bg-opacity-80" onClick={() => setOpenMultiAction(true)}>
                        <FaLayerGroup size="18px" className="mr-1 text-black dark:text-white" />
                        {selectRows.length > 0 &&
                            <span className="absolute -top-3 left-3/4 bg-tcolor rounded-lg py-0.5 px-1.5 text-[9px]">{selectRows.length}</span>
                        }
                        <PopoverMenu title={`${t('Action')} (${selectRows.length})`} origin="topright" active={openMultiAction} onClose={() => setOpenMultiAction(false)}>
                            {(selectRows[0].statusId !== 11) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openCloseCaseModal()}>
                                <RiInboxArchiveFill size="16px" className="mr-2" />
                                {t("Close Referrals")}
                            </div> : ''}
                            {/* {selectRows[0].caseStatus !== 'PENDING_REFERRER' ?: ''} */}

                            {
                                ((userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && selectRows.every(i => i.referredByName !== null)) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && selectRows.every(i => i.referredByName !== null))) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openReassignReferrerModal()}>
                                    <RiDirectionFill size="16px" className="mr-2" />
                                    {t("Reassign Referrer")}
                                </div> : ''
                            }

                        </PopoverMenu>
                    </motion.button>
                }
            </AnimatePresence>

            <Dialog title={t("Close Referrals")} showDialog={closeCaseModal} onClose={() => setCloseCaseModal(false)}>
                <ClosedCaseDialog onClose={() => setCloseCaseModal(false)} caseIds={caseId} />
            </Dialog>
            <Dialog title={t("Reassign Referrer")} showDialog={reassignreferrerCaseModal} onClose={() => setReassignReferrerCaseModal(false)}>
                <ReferrerCaseDialog onClose={() => setReassignReferrerCaseModal(false)} caseIds={caseId} />
            </Dialog>
        </div>
    )
}

export default PendingCarereceivers