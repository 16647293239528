import { useTranslation } from "react-i18next"
// import GoogleMapReact from 'google-map-react';
// import { FaMapMarkerAlt } from "react-icons/fa";

const ServiceInfo = ({ info, source }) => {
    const { t } = useTranslation()
    // const handleApiLoaded = (map, maps) => {
        // if(source.virtual) return
        // let marker = new maps.Marker({
        //     position: { lat: parseInt(source[0] || 0), lng: parseInt(source[1] || 0) },
        //     map,
        //     title: 'Hello World!'
        // });
        // return marker
    // }
    // let mapOptions = {
    //     keyboardShortcuts:false
    // }
    // const FromMarker = () => (
    //     <FaMapMarkerAlt size="30px" className="text-green-500"/>
    // )
    // const ToMarker = () => (
    //     <FaMapMarkerAlt size="30px" className="text-red-500"/>
    // )
   

    return (
        <div className="flex gap-4 mt-5 py-1">
            <div className="flex-1 p-3 m-0">
                <div className="text-tcolor dark:text-gray-400">{t('Address')}</div>
                <div>
                    { info.address ? `${info.address}`:'' }
                </div>
                <div>
                    { info.city ? `${info.city}, ${info.state}`:''}{info.zipCode ?` ${info.zipCode}`:'' }
                </div>
                {
                    info.email && info.email === '' &&
                    <div className="flex gap-2 mt-1">
                        <div className="text-tcolor dark:text-gray-400">{t('Email')}</div>
                        <div>
                            <a className="underline" href={`mailto:${info.email}`}>{ info.email !== '-' ? info.email :''}</a>
                        </div>
                    </div>
                }
                {
                    info.phone && info.phone !== '' &&
                    <div className="flex gap-2 mt-1">
                        <div className="text-tcolor dark:text-gray-400">{t('Phone')}</div>
                        <div>
                            <a className="underline" href={`tel:${info.phone}`}>{info.phone !== '-' ? info.phone:'' }</a>
                        </div>
                    </div>
                }
                {
                    info.webLink && info.webLink !== '' &&
                    <div className="flex gap-2 mt-1">
                        <div className="text-tcolor dark:text-gray-400">{t('Website')}</div>
                        <div>
                            <a target="_blank" rel="noreferrer" className="underline" href={(info.webLink.indexOf("http://") === 0 || info.webLink.indexOf("https://") === 0 || info.webLink.indexOf("HTTP://") === 0 || info.webLink.indexOf("HTTPS://") === 0)  ? `${info.webLink}` : `https://${info.webLink}`}>{info.webLink !== '-' ? info.webLink :'' }</a>
                        </div>
                    </div>
                }
                {
                    info.rating !== 0 && info.rating !== '' &&
                    <div className="flex gap-2 mt-1">
                        <div className="text-tcolor dark:text-gray-400">{t('Rating')}</div>
                        <div>
                            { info.rating || 'Unrated' }
                        </div>
                    </div>
                }
                {
                    info.cost !== 0 && info.cost !== '' &&
                    <div className="flex gap-2 mt-1">
                        <div className="text-tcolor dark:text-gray-400">{t('Cost')}</div>
                        <div>
                            { info.cost || 'Unknown' }
                        </div>
                    </div>
                }
                {
                    info.detail && info.detail !== '' &&
                    <div className="flex flex-col gap-1 mt-1">
                        <div className="text-tcolor dark:text-gray-400">{t('Detail')}</div>
                        <div className="pl-2 text-sm">
                            { info.detail !== '-' ? info.detail:'' }
                        </div>
                    </div>
                }
                {
                    info.tags && info.tags !== '' &&
                    <div className="flex flex-col gap-1 mt-1">
                        <div className="text-tcolor dark:text-gray-400">{t('Tags')}</div>
                        <div className="flex flex-wrap gap-1 pl-2 text-sm">
                            {
                                info.tags.split(',').map((c, i) => (
                                    <div key={i} className="text-xs bg-gray-400 dark:bg-gray-500 text-white py-0.5 px-2 rounded-lg whitespace-nowrap">
                                        { `${c}` }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
            <div className="flex-1 p-3 m-0">
            { !info.virtual && info.latitude !== 'null' &&
                <div className="flex-1">
                    <div>
                    <iframe width="500px" title="myFrame" height="300px" src={`https://maps.google.com/maps?q=${info.latitude},${info.longitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`}></iframe>
                        {/* <GoogleMapReact
                            bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_TOKEN }}
                            defaultCenter={source.map(c => parseFloat(c))}
                            defaultZoom={8}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                            options={(mapOptions)}
                            >
                            <FromMarker
                                lat={(source[0])}
                                lng={(source[1])}
                                text="From"
                            />
                            <ToMarker
                                lat={(info.latitude !== 'null' ? info.latitude:'')}
                                lng={(info.longitude !== 'null' ?info.longitude:'')}
                                text="To"
                            />
                        </GoogleMapReact> */}
                    </div>
                </div>
            }
            </div>
           
        </div>
    )
}

export default ServiceInfo