import { GiCheckMark } from 'react-icons/gi'
import { FaExclamationTriangle } from 'react-icons/fa'
import { HiSelector } from 'react-icons/hi'
import { AnimatePresence, motion } from 'framer-motion'
import { TextAnimateX } from '../../commons/anims'
import { useEffect, useMemo, useRef, useState } from 'react'
import FlowText from './FlowText'
import { useTranslation } from 'react-i18next'
import { createPortal } from 'react-dom'
import { AiFillCloseCircle } from 'react-icons/ai'

const SelectOptionAnim = {
    hidden: {
        height: 0,
        opacity: 0
    },
    visible: {
        height: 'auto',
        opacity: 1,
        zIndex: 10000,
    }
}

let multiSelectTime;
const RaceMultiSelect = ({ fieldProps, fieldMeta, label, edit, options, asians,native ,fieldHelper, position='bottom', loading=false, dataLoaded=false, onChange, ariaLabel,setAsian,setNative }) => {

    const input = useRef(null)
    const wrapRef = useRef(null)
    const [showError, setShowError] = useState(false)
    const [filter, setFilter] = useState('')
    const [focussed, setFocussed] = useState(false)
    const [pos, setPos] = useState({width: 0, top: 0, left:0})
    const { t } = useTranslation()

    const [asianCurrent,setAsianCurrent] = useState([])
    const [nativeCurrent,setNativeCurrent] = useState([])
    
    const makeFocus = () => {
        if(input.current !== null)
            input.current.focus()
    }
    const toggleFocus = (ev) => {
        if(focussed) {
            setFilter('')
            if(input.current !== null) input.current.blur()
        }
        setFocussed(!focussed)
        window.removeEventListener('click', toggleFocus)
    }
    const handleBlur = () => {
        multiSelectTime = setTimeout(() => {
            if(focussed) toggleFocus()
        }, 200)
    }

    useEffect(() => {
        // setDropdown([...dropdown])
        if(focussed) {
            setTimeout(() => {
                window.addEventListener('click', toggleFocus)
            }, 100)
            let e = wrapRef.current.getBoundingClientRect()
            let o = {width: `${e.width}px`, left: `${e.x}px`}
            if((e.top + e.height + 270) > window.innerHeight) {
                o.bottom = `${window.innerHeight - e.top}px`
            } else {    
                o.top = `${(e.top + e.height)}px`
            }
            setPos(o)
        }

        return () => window.removeEventListener('click', toggleFocus)
    }, [focussed]) // eslint-disable-line

    const toggleError = (val) => setShowError(val)
    const setValue = (ev, value) => {
        ev.stopPropagation()
        clearTimeout(multiSelectTime)
        let data;
        if(fieldProps.value.includes(value)) {
            data = fieldProps.value.filter(c => c !== value)
            fieldHelper.setValue(fieldProps.value.filter(c => c !== value))
        }else {
            data = [...fieldProps.value, value]
            fieldHelper.setValue([...fieldProps.value, value])
        }
        if(value===9){
            fieldHelper.setValue([value])
        }else if (value!==9 && fieldProps.value.includes(9)){
            fieldHelper.setValue([...fieldProps.value.filter(c=>c!==9),value])
        }
        if(typeof onChange === 'function') onChange(value)
    }
    const setAsianValue = (ev,value) =>{
        ev.stopPropagation()
        clearTimeout(multiSelectTime)
        let data = [...fieldProps.value, value]
              
        if(fieldProps.value.includes(value)) fieldHelper.setValue(fieldProps.value.filter(c => c !== value))
        else  if (value!==9 && fieldProps.value.includes(9)){
            fieldHelper.setValue([...fieldProps.value.filter(c=>c!==9),value])
        }
        else fieldHelper.setValue([...fieldProps.value,value])
        if(asianCurrent.length > 0 && fieldProps.value.includes(asianCurrent)){
            fieldHelper.setValue(data.filter(c => c !== !asianCurrent.includes(value)))
        } 
        if(asianCurrent.includes(value)){
            let values = asianCurrent.filter((x)=>x!==value)
            setAsianCurrent(values)
        }else{
            setAsianCurrent((prevValue)=>[...prevValue,value])
        }
        if(typeof setAsian === 'function') setAsian(value)

    }
    const setNativeValue = (ev,value) =>{
        ev.stopPropagation()
        clearTimeout(multiSelectTime)
        let data = [...fieldProps.value, value]
        
        if(fieldProps.value.includes(value)) fieldHelper.setValue(fieldProps.value.filter(c => c !== value))
        else  if (value!==9 && fieldProps.value.includes(9)){
            fieldHelper.setValue([...fieldProps.value.filter(c=>c!==9),value])
        }
        else fieldHelper.setValue([...fieldProps.value,value])
        if(nativeCurrent.length > 0 && fieldProps.value.includes(nativeCurrent)){
            fieldHelper.setValue(data.filter(c => c !== !nativeCurrent.includes(value)))
        } 
        if(nativeCurrent.includes(value)){
            let values = asianCurrent.filter((x)=>x!==value)
            setNativeCurrent(values)
        }else{
            setNativeCurrent((prevValue)=>[...prevValue,value])
        }
        if(typeof setNative === 'function') setNative(value)
    }
    const removeItem = (ev, val) => {
        ev.stopPropagation()
        fieldHelper.setValue(fieldProps.value.filter(c => c !== val))
        if(typeof setAsian === 'function' && val >= 10 && val <=29) setAsian(val)
        if(typeof setNative === 'function' && val >=30 && val <=35) setNative(val)
    }
    const filteredOptions = useMemo(() => {
        return options.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim())))
    }, [filter,options]) // eslint-disable-line
    const asianOption = useMemo(()=>{
        return asians.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim())))
    }, [filter,asians]) // eslint-disable-line
    const nativeOption = useMemo(()=>{
        return native.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim())))
    }, [filter,native]) // eslint-disable-line

    const displayText = useMemo(() => {
        if(fieldProps.value === undefined) return false
        let a = fieldProps.value.map(c => {
            let o = [...options,...asians,...native].find(e => e.value.toString() === c.toString())
            return o ? o : null
        }).filter(c => c !== null)
        if(a.length > 0) {
            return (
                <div className={`flex items-center flex-wrap ${focussed ? 'justify-end' : ''}`}>
                    {
                        !focussed ?
                        a.map(c => (
                            <span key={c.value} className={`flex items-center gap-1 ${focussed ? 'text-[6px]' : 'text-[10px]'} transition-all duration-200 ${c.value !== 29 && c.value !== 35 &&'bg-bluegray-400'} text-white dark:bg-gray-500 px-2 py-0.5 rounded-xl mx-0.5 my-0.5 font-medium`}>
                                {c.value !== 29 && c.value !== 35 && c.text}
                                { c.value !== 29 && c.value !== 35 && edit && <AiFillCloseCircle title={t("Remove")} className="-mr-1" size="12px" onClick={(ev) => removeItem(ev, c.value)} /> }
                            </span>
                        ))
                        :
                            <span className={`${focussed ? 'text-[10px]' : 'text-xs'} transition-all duration-200 bg-bluegray-400 text-white dark:bg-gray-500 px-2 py-0.5 rounded-xl mx-0.5 my-0.5 whitespace-nowrap`}>{a.length} {t('Selected')}</span>
                    }
                </div>
            )
        } else return false
    }, [fieldProps.value, options,asians,native, focussed])// eslint-disable-line

    return (
        <div className="relative">
            <div ref={wrapRef} className={`group relative flex flex-col ${edit ? 'ring-1 ring-gray-300 dark:ring-gray-600' : ''} px-2 pt-1 rounded-lg ${focussed ? ' focus-within:ring-tcolor dark:focus-within:ring-tcolor' : ''} transition-all duration-100 cursor-pointer ${loading ? 'animate-pulse' : ''}`} onClick={makeFocus}>
                <span className={`${edit ? 'ml-1' : ''} text-xs text-gray-500 dark:text-gray-400`}><FlowText text={label} direction="x" /></span>
                <div className="relative overflow-y-auto h-[50px] hover :snap-x">
                    {/* <input ref={input} type="text" className="h-0 w-0 absolute" {...fieldProps} onFocus={toggleFocus} /> */}
                    {!edit && dataLoaded &&
                        <div className={`pb-2 h-8 ${fieldProps.value.length > 0 ? '' : 'text-gray-500 line-through'}`}>{displayText || ''}</div>
                    }
                    {
                        !dataLoaded &&
                        <div className="w-3/4 h-2 my-2 rounded-lg animate-pulse bg-gray-300"></div>
                    }
                    {
                        edit && dataLoaded &&
                        <>
                            <div className={`pb-1.5 pl-3 min-h-8 w-[96%]  ${fieldProps.value?.length > 0 ? '' : 'text-zinc-700'} ${focussed ? 'absolute -top-3 right-10 w-full' : ''}`}>{displayText || (focussed ? '' : `${label}`)}</div>
                            <input aria-label={ariaLabel} ref={input} className={ `pb-1.5 pl-3 bg-transparent pr-10 w-full outline-none ${!focussed ? 'overflow-y-auto h-[10px] w-0 absolute': ''}` } type="text" value={filter} autoComplete="off" onFocus={toggleFocus} onBlur={handleBlur} onChange={ev => setFilter(ev.target.value)} />
                        </>
                    }
                    {edit &&
                        <span className="absolute right-2 -top-1 text-xl flex">
                            {fieldMeta.error && fieldMeta.touched && <FaExclamationTriangle className="text-red-500 cursor-pointer" onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} />}
                            <HiSelector className="text-gray-400 ml-2" />
                        </span>
                    }
                    <AnimatePresence>
                        {fieldMeta.error && fieldMeta.touched && showError &&
                            <motion.span variants={TextAnimateX} initial="hidden" animate="visible" exit="hidden" className="absolute -top-1 right-16 inline-block bg-gray-300 dark:bg-ldark py-0.5 px-2 rounded text-sm text-red-500">
                                {fieldMeta.error}
                            </motion.span>
                        }
                    </AnimatePresence>
                </div>
            </div>
            { createPortal(
                <AnimatePresence>
                    {
                        focussed &&
                                                    <motion.div variants={SelectOptionAnim} initial="hidden" animate="visible" exit="hidden" className={`fixed overflow-y-auto max-h-[270px] bg-bluegray-200 dark:bg-mdark ring-1 ring-bluegray-300 dark:ring-ldark p-2 rounded`} style={pos}>
                            <span className="inline-block mb-2 text-xs text-tcolor dark:text-gray-400">{label}</span>
                            {
                                filteredOptions.map((c, i) => {
                                    return(
                                    <>
                                       <div key={i} onClick={(ev) => c.value !== 4 && c.value !== 6 && setValue(ev, c.value)} className={`py-2 px-3 hover:bg-tcolor hover:text-white rounded-lg text-sm flex justify-between items-center  dark:text-gray-300 dark:hover:text-white ${c.value !== 4 && c.value !== 6 ? 'cursor-pointer text-bluegray-600' : 'font-semibold'}`}>
                                          {c.text}
                                          { fieldProps.value?.includes(c.value) && <GiCheckMark className="text-green-500 w-4 flex-shrink-0" /> }
                                       </div>
                                       {
                                       c.value === 4 ?
                                       asianOption.map((k,j)=>{
                                        return <div key={j} onClick={(ev) => setAsianValue(ev, k.value)} className="py-2 px-8 hover:bg-tcolor hover:text-white rounded-lg text-sm flex justify-between items-center text-bluegray-600 dark:text-gray-300 dark:hover:text-white cursor-pointer">
                                        {k.text}
                                        { fieldProps.value?.includes(k.value) && <GiCheckMark className="text-green-500 w-4 flex-shrink-0" /> }
                                     </div>
                                     }):c.value === 6 ?
                                       nativeOption.map((n,l)=>{
                                        return <div key={l} onClick={(ev) => setNativeValue(ev, n.value)} className="py-2 px-8 hover:bg-tcolor hover:text-white rounded-lg text-sm flex justify-between items-center text-bluegray-600 dark:text-gray-300 dark:hover:text-white cursor-pointer">
                                        {n.text}
                                        { fieldProps.value?.includes(n.value) && <GiCheckMark className="text-green-500 w-4 flex-shrink-0" /> }
                                     </div>
                                     }):''
                                       }
                                    </>
                                )}
                                )
                            }
                            { filteredOptions.length === 0 && <span className="block px-5 pb-2 text-gray-500">{t("No Data")}</span>}
                        </motion.div>
                    }
                </AnimatePresence>, document.body)
            }
        </div>
    )
}
export default RaceMultiSelect