import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaInfo } from "react-icons/fa"
import { useDispatch } from "react-redux"
import API from "../../axios/API"
import AssessmentOptionField from "../../components/snippets/Assessment/AssessmentOptionField"
import Dialog from "../../components/snippets/Dialog"
import InlineSelect from "../../components/snippets/InlineSelect"
import InlineTextField from "../../components/snippets/InlineTextField"
import { setAlert } from "../../store/theme/actions"
import ServiceInfo from "./ServiceInfo"



const SearchService = ({serviceType,caseId,serviceId, onAdd,categoryId,onAddCategoryId,careplanId,additional=false}) => {

    const [search, setSearch] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [distance, setDistance] = useState(25)
    const [keywords, setKeywords] = useState([])
    const [resourceData, setResourceData] = useState({})
    const [loading, setLoading] = useState(false)
    const [selectedServices, setSelectedServices] = useState([])
    const [viewService, setViewService] = useState({})
    const [showViewService, setShowViewService] = useState(false)
    const [source, setSource] = useState([])
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const miles = [5, 10, 15, 20, 25, 50, 100].map(c => ({value: c, text: `${c} ${t('Miles')}`}))
    useEffect(()=>{
        API.get(`care-plans/${careplanId}/services/${additional ? 0 : serviceType}/keywords`).then(res=>{
            if(res.status === 200){
                setZipcode(res.data.body.zipcode)
                setKeywords(res.data.body.keywords.map(c=>({ answerLabel: c, value: c ,answerId:c,selected:false})))
            }
        }).catch(e=>{
            console.log(e)
        })
    },[careplanId, serviceType])
    useEffect(() => {
       if(zipcode !== null){
        let newZip = ''
        if(zipcode === '') return
        zipcode.toString().split('').forEach(c => {
            if(/^\d*$/.test(c) && newZip.length < 5) newZip = `${newZip}${c}`
        })
        if(newZip !== zipcode.toString()) setZipcode(newZip)
       }
    }, [zipcode])
    useEffect(() => {
        setZipcode(zipcode || '')
    }, [zipcode])
    const enableSearch = useMemo(() => {
        return (search.trim() !== '' || keywords.length !== 0) && zipcode.toString().length === 5 &&/^\d*$/.test(zipcode)
    }, [zipcode, search, keywords])
    const NoResource = useCallback(() => (
        <div className="mt-4 text-sm ml-3 text-gray-600 dark:text-gray-200 mb-2">
            {t('No resource found, Try different search')}
        </div>
    ), [t])
    const LoadingView = useCallback(() => (
        <div className="flex flex-col gap-4">
            <div className="w-3/4 h-1.5 rounded-lg animate-pulse bg-gray-300"></div>
            <div className="w-3/4 h-1.5 rounded-lg animate-pulse bg-gray-300"></div>
            <div className="w-3/4 h-1.5 rounded-lg animate-pulse bg-gray-300"></div>
        </div>
    ), [])
    const searchResource = () => {
        if(!enableSearch) return
        setLoading(true)
        let details
        if(search){
            details={
                keyword: keywords.filter(c=>c.selected).map(c=>c.answerLabel).join(', '),
                distance:distance,
                zipcode : zipcode,
                search:search,
            }
        }else{
            details={
                keyword: keywords.filter(c=>c.selected).map(c=>c.answerLabel).join(', '),
                distance:distance,
                zipcode : zipcode,
            }
        }
        if(details.keyword || details.search){
            API.post(`resources`, details)
        .then(res => {
            if(res.status === 200) {
                setResourceData(res.data.body)
            } else setResourceData({})
        })
        .catch(err => {
           console.log(err)
        }).finally(() => setLoading(false))
        }else{
            setLoading(false)
            dispatch(setAlert({title:t('Error'),subtitle:t('Please select at least one keyword or enter values in the search bar'),active:true,type:'error'}))
        }
    }

    const viewResource = (ev, service) => {
        ev.stopPropagation()
        setViewService(service)
        if(!service.virtual) setSource([resourceData.latitude, resourceData.longitude])
        else setSource([])
        setShowViewService(true)
    }
    const selectService = service => {
        if(selectedServices.find(c => c.rdbId === service.rdbId)) setSelectedServices(selectedServices.filter(c => c.rdbId !== service.rdbId))
        else if(selectedServices.length < 3) setSelectedServices([...selectedServices, service])
    }
    const isSelected = id => {
        return selectedServices.find(c => c.rdbId === id)
    }
    const ListResource = useCallback((type) => (

        resourceData?.[type].map(c => (
            <div  key={c.rdbId} className={`flex justify-between items-center border-b border-gray-500 py-3 px-2 cursor-pointer ${isSelected(c.rdbId) ? 'bg-tcolor text-black' : 'hover:bg-tcolor text-black dark:hover:bg-gray-600 dark:text-white'}`} onClick={() => selectService(c)}>
                <div>
                    <div tabIndex={0} className="text-sm">{ c.serviceName }</div>
                    <div className="flex gap-1 mt-1">
                        { c.distance && c.distance !== '' &&
                            <div className="text-xs bg-gray-400 dark:bg-gray-400 text-white py-0.5 px-2 rounded-lg">
                                { `${c.distance}mi` }
                            </div>
                        }
                        { c.zipCode && c.zipCode !== '' &&
                            <div className="text-xs bg-gray-400 dark:bg-gray-400 text-black py-0.5 px-2 rounded-lg">
                                { `${c.zipCode}` }
                            </div>
                        }
                        { c.state && c.state !== '' &&
                            <div className="text-xs bg-gray-400 dark:bg-gray-400 text-black py-0.5 px-2 rounded-lg">
                                { `${c.state}` }
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <div role="contentinfo" tabIndex={0} aria-label={c.serviceName+' view Info'} className="p-1 bg-tcolor text-white rounded-full transform hover:scale-125" title={t('View Info')} onClick={(ev) => viewResource(ev, c)}>
                        <FaInfo />
                    </div>
                </div>
            </div>
        ))
    ), [resourceData, selectedServices, isSelected]) // eslint-disable-line
    const submit = () => {
        let output = selectedServices.map(c=>{
            let address = `${c.serviceName}`
            if(c.address !== null){
                address =   `${address},${c.address}`
            }
            if(c.city !== null){
                address =   `${address},${c.city}`
            }
            if(c.state !== null){
                address =   `${address},${c.state}`
            }
            if(c.zipCode !== null){
                address =   `${address},${c.zipCode}`
            }
            if(c.phone !== null){
                address =   `${address},${c.phone}`
            }
            let serviceKeywords = []
            keywords.filter(c=>{
                if(c.selected === true){
                    serviceKeywords.push(c.answerLabel)
                }
                return null;
            })
            return {adhocContent:null,adhocTemplate:null,comment:null,deleted:false,info:address,rdbId:parseInt(c.rdbId),serviceId:additional ? 0 : serviceId,selectedServiceId:null,webLink:c.webLink,keyword:serviceKeywords.join(','),serviceName:c.serviceName}
        })
        !additional && onAddCategoryId(categoryId)
        onAdd(output)
    }

    return ( 
        <div style={{maxHeight:'750px',overflowX:'auto'}}>
            <div className="md:flex lg:flex gap-3 mt-3 py-2 px-1">
                <div className="felx-1 flex-shrink-0 flex flex-col gap-4">
                    <InlineTextField label={`${t('Search')}(${t('Optional')})`} value={search} setValue={val => setSearch(val)} ariaLabel={'Optional Search Field'}/>
                    <div className="flex items-center gap-4">
                        <div>
                            <InlineTextField injectClass="w-52" label={t('Zip Code')} value={zipcode} type="number" setValue={val => setZipcode(val)}  ml={true} length={'5'} ariaLabel={'Zipcode'} showSearch={false}/>
                        </div>
                        <div>
                            <InlineSelect label={t('Distance')} items={miles} value={distance} setvalue={val => setDistance(val)} />
                        </div>
                        <div>
                            <div className={`flex gap-1 items-center bg-gradient-to-tr px-3 py-0.5 rounded text-sm  text-black ${enableSearch ? 'cursor-pointer hover:ring-2 hover:ring-offset-2 hover:ring-tcolor hover:ring-offset-gray-200 dark:hover:ring-offset-ldark from-tcolor to-blue-400' : 'cursor-not-allowed from-gray-500 to-gray-600'}`} onClick={searchResource}>
                                <button>{t('Search')}</button>
                            </div>
                        </div>
                    </div>
                    {((search.trim() === '' && keywords.length === 0) || zipcode.toString().length === 5) &&
                        <div className="text-xs text-red-600 px-3">
                            {((search.trim() !== '' || keywords.length !== 0) && zipcode.toString().length === 5 &&/^\d*$/.test(zipcode)) ?
                                t('')
                                :
                                t('ZIP Code Field Only Numbers ...')
                            }
                        </div>
                    }
                </div>
                <div className="flex-1 flex-shrink-0 ring-1 ring-gray-300 dark:ring-gray-600 rounded-lg">
                    <div className="py-1">
                        <div className="pl-2 mb-1 text-xs text-gray-600 dark:text-gray-400">{t('Select Keywords')} ({keywords.length})</div>
                        <div tabIndex={0} className="max-h-[150px] overflow-y-auto">
                            <AssessmentOptionField type="multi_option" options={keywords} value={keywords} setValue={val => setKeywords(val)} edit={true} loading={false} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex gap-2 mt-3 py-1 px-2">
                <div className="flex-1 form-wrap p-3 m-0">
                    <div className="text-gray-600 dark:text-gray-300 text-lg font-bold">{t('Local Resources')}</div>
                    <div className="py-2 max-h-40 overflow-y-auto">
                        {
                            loading ? <LoadingView /> : 
                            (!resourceData.localResource || !Array.isArray(resourceData.localResource) || resourceData.localResource.length < 1) ?
                                <NoResource />
                            :
                            ListResource("localResource")
                        }
                    </div>
                </div>
                <div className="flex-1 form-wrap p-3 m-0">
                    <div className="text-gray-600 dark:text-gray-300 text-lg font-bold">{t('Remote Resources')}</div>
                    <div className="py-2 max-h-40 overflow-y-auto">
                        {
                            loading ? <LoadingView /> :
                            (!resourceData.remoteResource || !Array.isArray(resourceData.remoteResource) || resourceData.remoteResource.length < 1) ?
                                <NoResource />
                            :
                            ListResource("remoteResource")
                        }
                    </div>
                </div>
            </div>
            { selectedServices.length > 0 &&
                <div className="flex justify-end mr-4 mt-4 px-4">
                    <span>{t("Select Maximum 3 resources")}</span>
                    <button disabled={loading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => submit()}>{t('Add to Care Plan')}</button>
                </div>
            }
            <Dialog showDialog={showViewService} title={viewService.serviceName} onClose={() => setShowViewService(false)} zIndex="1000000000" injectClass="w-[70vw]">
                <ServiceInfo info={viewService} source={source} />
            </Dialog>
        </div>
    )
}

export default SearchService