import { useFormik } from "formik"
import { useEffect, useState ,useLayoutEffect} from "react"
import * as Yup from 'yup'
import { useMemo } from "react"
import FavFormButton from "../../components/snippets/FavFormButton"
import FieldInput from "../../components/snippets/FieldInput"
import FieldSelect from "../../components/snippets/FieldSelect"
import FormCard from "../../components/snippets/FormCard"
import Tab from "../../components/snippets/Tabs/Tab"
import TabGroup from "../../components/snippets/Tabs/TabGroup"
import TabButtonGroup from "../../components/snippets/Tabs/TabButtonGroup"
import TabButton from "../../components/snippets/Tabs/TabButton"
import { useHistory } from "react-router-dom"

import API from "../../axios/API"
import Notifications from "./Notifications"
import FieldCheckbox from '../../components/snippets/FieldCheckbox'
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'
import { setAlert } from '../../store/theme/actions'
import Campaign from "./Campaign"
// const select = ['Active', 'InActive'].map(c => ({ text: c, value: c }))
const select = [{text:'Active',value:true}, {text:'InActive',value:false}].map(c => ({ text: c.text, value: c.value }))



const EditOrganization = ({ match }) => {
    let history = useHistory()
    const userDetails = useSelector(state => state.auth.userDetails)
    const orgId = useSelector(state => state.auth.organization)

    useEffect(()=>{
        if (JSON.stringify(match.params) === JSON.stringify({})) {
            match.params.orgId = orgId
        }
    },[orgId]) // eslint-disable-line
    // const { t }  = useTranslation()
    const dispatch = useDispatch()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [editForm, setEditForm] = useState(false)
    const [formLoading, setFormLoading] = useState(false)
    const [initialData, setInitialData] = useState()
    const [states, setStates] = useState([])
    const [tab, setTab] = useState(0)
    const { t } = useTranslation()
    const organization = useSelector(state => state.auth.organization)
    const [orgCode,setOrgCode] = useState(false)
    let campaign = localStorage.getItem('campaign')
    // let prgName = localStorage.getItem('prgname')
    // const [programName1, setProgramName1]=useState(false)
    const [autoReminder,setAutoReminder] = useState(false)
    const [followup,setFollowup]  =  useState(false)
    const [followDays,setFollowDays] = useState()
    const [remind1,setRemind1] = useState()
    const [remind2,setRemind2] = useState()
    let lang =localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))
    const [protocolEnable,setProtocolEnable] = useState(false)
    const [protocolOption,setProtocolOption] = useState([])
    const [protocolDefault,setProtocolDefault] = useState([])
    


    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test('validPhone', t("Not a Valid Phone"), (value) => {
            if (value === undefined || value === '') return true
            return (value.replace(/ /g, '').length === 13 || value.replace(/ /g, '').length === 12)
        })
    })
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test('onlyAlphabet',t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[a-zA-Z ]*$/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'validateEmail', function () {
        return this.test('validEmail', t("Not valid"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'validateName', function () {
        return this.test('onlyAlphabet and numbers are allowed', t('Only Alphabets and numbers allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'onlyNumbers', function () {
        return this.test('onlynubmers', t("Only Numbers Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[0-9]*$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'onlyAlphaNumeric', function () {
        return this.test('only AlphaNumeric', t("only AlphaNumeric Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[A-Za-z0-9_]*$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test('Empty space at begining', t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    useEffect(() => {
        if(menuItems !== undefined && menuItems !== null && menuItems !== ''){
            if(menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined){
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            }else{
                API.get(`menus/states?lang=${lang}`).then((statesResp)=>{
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            API.get(`/menus/protocol`).then((protocolResp)=>{
                setProtocolDefault(protocolResp.data.body.map(c => ({ text: c.name, value: c.value })))
            })
        }else{
        API.get(`/menus/states?lang=${lang}`).then(res => {
            setStates(res.data.body.map(c => ({ text: c.name, value: c.key })))
        }).catch(e => {
            // console.log(e);
        }).finally()
    }
    }, [])// eslint-disable-line

    useEffect(() => {
        setDataLoaded(false)
        API.get(`organizations/${match.params.orgId}`).then(res => {
            setInitialData(res.data)
            setRemind1(res.data.reminder1)
            setRemind2(res.data.reminder2)
            if(res.data.orgCodeDisable === true){
                setOrgCode(true)
            }
            if(res.data.automaticReminder === true){
                setAutoReminder(true)
            }
            if(res.data.tcareProtocolEnabled === true){
                setProtocolEnable(true)
            }
            setProtocolOption(res.data.protocol)

            // if(prgName === 'true'){
            //     setProgramName1(true)
            // }
        }).catch(e => {
            console.log(e)
        }).finally(() => setDataLoaded(true))
    }, [orgId])// eslint-disable-line

    const formData = useMemo(() => {
        return { ...initialData }
    }, [initialData])// eslint-disable-line

   


    const profileFormSchema = Yup.object().shape({
        organizationName: Yup.string().validateName().required(t("Organizationname is required")),
        active: Yup.string().required(t("Active is required")),
        sicCd: Yup.string().length(4, t("SIC number must be exactly 4 digits")).matches(/[0-9]+/gi,t("SIC number must be exactly 4 digits")),
        phone: Yup.string().validatePhoneLength().required(t("MobileNumber is required")),
        licenses: Yup.string().onlyNumbers(),
        orgCode: Yup.string().min(1,t("OrgCode should be min 1 character.")).max(24,t("OrgCode should be max 24 character")).validateDescription().onlyAlphaNumeric().required(t("orgCode is a required field")),
        licenseExpirationDate: Yup.string().required(t("licenseexpiration is required")),
        organizationAddress: Yup.object({
            addressLine1: Yup.string().validateDescription().nullable(),
            addressLine2: Yup.string().nullable(),
            city: Yup.string().trim(t('Remove Trailing WhiteSpace')).strict().min(2, "min 2 characters"),
            state: Yup.string().required(t("State is a required field")),
            country: Yup.string().onlyAlphabet().max(99, t("Country code must be exactly 2 characters")),
            postalCode: Yup.string().length(5, t('Postal code should be 5')).matches(/^[0-9]*$/,t("Postal code should be Numbers")).required(t("Postal Code is required")),
        }),
        areaCode: Yup.string().length(4, t("Area code should be 4 digits")).matches(/^[0-9]*$/,t("Area code should be 4 digits")),
        activeTimeoutInMs: Yup.string().onlyNumbers().matches(/^[0-9]*$/).required(t("ActiveTimeout is required")),
        inactiveTimeoutInMs: Yup.string().onlyNumbers().matches(/^[0-9]*$/).required(t("InActiveTimeout is required")),
        loginAttempts: Yup.string().onlyNumbers().matches(/^[0-9]*$/).required(t("LoginAttempts is required")),
        lockoutTimeInMs: Yup.string().onlyNumbers().matches(/^[0-9]*$/).required(t("LockoutTimeInms is required")),
        passwordExpirationAge: Yup.string().onlyNumbers(),
        followupDays: Yup.string().onlyNumbers(),
        reminder1 : followup ? Yup.number().max(followDays,t("Value should be lesser than followupDays")) : Yup.number().max(90,'Value should be Max 90 days'),
        reminder2 : followup ? Yup.number().min(remind1,t("Value should be greater than reminder1")).max(followDays, t("Value should be lesser than followupDays and should be greater than reminder 1")) : Yup.number().min(remind1,t("Value should be greater than reminder1")),
        reminder3 : followup ? Yup.number().min(remind2,t("Value should be greater than reminder2")).max(followDays, t("Value should be lesser than followupDays and should be greater than reminder 2")) : Yup.number().min(remind2,t("Value should be greater than reminder2")),
    })

    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            setFormLoading(true)
            updateForm()
            setFormLoading(false)
        }
    })

    useLayoutEffect(() => {
        if(profileForm.values.followupDays && profileForm.values.followupDays !== undefined && profileForm.values.followupDays !== 0 && profileForm.values.followupDays !== ''){
            setFollowDays(profileForm.values.followupDays)
            setFollowup(true)    
        }else{
            setFollowDays(profileForm.values.followupDays)
            setFollowup(false)
        }
    },[profileForm.values.followupDays]) // eslint-disable-line


    const getAddress = (value,field) => {
        if(value.length ===5){
            API.get(`/intake/zones?zipCode=${value}`).then((res)=>{
                if(res.status === 200){
                    if(field === 'cg'){
                        profileForm.setValues({...profileForm.values,...profileForm.values,organizationAddress:{...profileForm.values.organizationAddress,state:res.data.body.state,city:res.data.body.city,country:res.data.body.country}})
                    }
                }
            })
        }else if(value.length === 0){
            profileForm.setValues({...profileForm.values,...profileForm.values,organizationAddress:{...profileForm.values.organizationAddress,state:"",city:"",country:""}})
        }
    }

    const toggleFormEdit = () => {
        if (editForm) profileForm.submitForm()
        if (!editForm) setEditForm(!editForm)
    }

    const togglecancel =()=>{
        profileForm.resetForm()
        setEditForm(false)
    }

    const Autoremind = (value) =>{
        if(value === true){
        setAutoReminder(true)
        }else{
        setAutoReminder(false)
        }
    }

    const setReminder =(value,type) =>{
        if(type === 'remind1'){
            setRemind1(parseInt(value))
            profileForm.values.reminder2 = ''
            profileForm.values.reminder3 = ''
        }else if(type === 'remind2'){
            setRemind2(parseInt(value))
            profileForm.values.reminder3  = ''
        }
    }

    const ProtocolCard =(value)=>{
        if(value === true){
            setProtocolEnable(true)
        }else{
            setProtocolEnable(false)
        }
    }

    const updateForm = () => {
        let details = {
            organizationAddress: {
                country: profileForm.values.organizationAddress.country,
                addressLine1: profileForm.values.organizationAddress.addressLine1,
                addressLine2: profileForm.values.organizationAddress.addressLine2,
                state: profileForm.values.organizationAddress.state,
                postalCode: profileForm.values.organizationAddress.postalCode,
                city: profileForm.values.organizationAddress.city
            },
            active: profileForm.values.active,
            organizationName: profileForm.values.organizationName.trim(),
            sicCd: profileForm.values.sicCd,
            activeTimeoutInMs: parseInt(profileForm.values.activeTimeoutInMs),
            inactiveTimeoutInMs: parseInt(profileForm.values.inactiveTimeoutInMs),
            licenseExpirationDate: new Date(profileForm.values.licenseExpirationDate).toISOString(),
            apiServiceActivated: profileForm.values.apiServiceActivated,
            areaCode: profileForm.values.areaCode,
            chatbotActivated: profileForm.values.chatbotActivated,
            cgIntake: profileForm.values.cgIntake,
            iddServiceActivated: profileForm.values.iddServiceActivated,
            lockoutTimeInMs: parseInt(profileForm.values.lockoutTimeInMs),
            loginAttempts: parseInt(profileForm.values.loginAttempts),
            militaryServiceActivated: profileForm.values.militaryServiceActivated,
            phone: profileForm.values.phone.trim(),
            resourceDatabaseApi: profileForm.values.resourceDatabaseApi,
            rescreenActivated:profileForm.values.rescreenActivated,
            userCreateMailAlert: profileForm.values.userCreateMailAlert,
            referralDyadRequired: profileForm.values.referralDyadRequired,
            subscriberIdRequired: profileForm.values.subscriberIdRequired,
            zipCodeEnableCR: profileForm.values.zipCodeEnableCR,
            dobFullRequired: profileForm.values.dobFullRequired,
            reportActivated: profileForm.values.reportActivated,
            schedulingActivated: profileForm.values.schedulingActivated,
            telephonyActivated: profileForm.values.telephonyActivated,
            organizationUpload: profileForm.values.organizationUpload,
            followupDays: parseInt(profileForm.values.followupDays),
            passwordExpirationAge: parseInt(profileForm.values.passwordExpirationAge),
            specializedQuestions: profileForm.values.specializedQuestions,
            caremanagerMail: profileForm.values.caremanagerMail,
            caregiverMail: profileForm.values.caregiverMail,
            filterRdb: profileForm.values.filterRdb,
            licenses: profileForm.values.licenses,
            orgCode: profileForm.values.orgCode,
            contactCG:profileForm.values.contactCG,
            automaticReminder: profileForm.values.automaticReminder,
            automatic: profileForm.values.automatic,
            reminder1: parseInt(profileForm.values.reminder1) ? parseInt(profileForm.values.reminder1) : 0,
            reminder2: parseInt(profileForm.values.reminder2) ? parseInt(profileForm.values.reminder2) : 0,
            reminder3: parseInt(profileForm.values.reminder3) ? parseInt(profileForm.values.reminder3) : 0,
            vipEnabled: profileForm.values.vipEnabled,
            tcareProtocolEnabled: profileForm.values.tcareProtocolEnabled,
            orgEthnicity: profileForm.values.orgEthnicity,
            bulkServiceUsage: profileForm.values.bulkServiceUsage,
            jobFamilyGroup: profileForm.values.jobFamilyGroup,
            employeeType: profileForm.values.employeeType,
            militaryService: profileForm.values.militaryService,
            enhancedCloseCaseReason:profileForm.values.enhancedCloseCaseReason,
            serviceAuthorization:profileForm.values.serviceAuthorization,
            orgPendingCareplanAction:profileForm.values.orgPendingCareplanAction,
            protocol:profileForm.values.protocol,
            defaultProtocol:profileForm.values.defaultProtocol
        }
        if(userDetails.role.includes('superadmin') && profileForm.values.tcareProtocolEnabled){
            const protocolValid = details.protocol.filter(x => x.active)
            if(protocolValid.length >= 1){
                API.patch(`organizations/${initialData.organizationId}`, { ...details }).then(res => {
                if(res.status === 200 && res.data.status === false){
                    dispatch(setAlert({title:t('Warning'),subtitle:res.data.errorMessage,type:'warning',active:true}))
                    setEditForm(true)
                }else if (res.status === 200) {
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Organization updated successfully'), type: 'success', active: true }))
                    localStorage.setItem('refdyad',res.data.referralDyadRequired) 
                    localStorage.setItem('subId',res.data.subscriberIdRequired)
                    localStorage.setItem('CrZip',res.data.zipCodeEnableCR)
                    localStorage.setItem('birthyear',res.data.dobFullRequired) 
                    localStorage.setItem('contactCG',res.data.contactCG)
                    localStorage.setItem('uploads',res.data.organizationUpload)
                    localStorage.setItem('vipEnable',res.data.vipEnabled)
                    localStorage.setItem('tcareProtocolEnabled',res.data.tcareProtocolEnabled)
                    localStorage.setItem('orgEthnicity',res.data.orgEthnicity)
                    localStorage.setItem('bulkService',res.data.bulkServiceUsage)
                    localStorage.setItem('jobFamily',res.data.jobFamilyGroup)
                    localStorage.setItem('empType',res.data.employeeType)
                    localStorage.setItem('military',res.data.militaryService)
                    setEditForm(false)
                    if(userDetails.role[0] === 'superadmin'){
                        history.goBack()
                    }
                }
            }).catch(e => {
                console.log(e)
            }).finally()
            }else{
                dispatch(setAlert({title:t('Warning'),subtitle:'Please Select Atleast One Protocol',type:'warning',active:true}))
            }   
        }else{
            API.patch(`organizations/${initialData.organizationId}`, { ...details }).then(res => {
                if(res.status === 200 && res.data.status === false){
                    dispatch(setAlert({title:t('Warning'),subtitle:res.data.errorMessage,type:'warning',active:true}))
                    setEditForm(true)
                }else if (res.status === 200) {
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Organization updated successfully'), type: 'success', active: true }))
                    localStorage.setItem('refdyad',res.data.referralDyadRequired) 
                    localStorage.setItem('subId',res.data.subscriberIdRequired)
                    localStorage.setItem('CrZip',res.data.zipCodeEnableCR)
                    localStorage.setItem('birthyear',res.data.dobFullRequired) 
                    localStorage.setItem('contactCG',res.data.contactCG)
                    localStorage.setItem('uploads',res.data.organizationUpload)
                    localStorage.setItem('vipEnable',res.data.vipEnabled)
                    localStorage.setItem('tcareProtocolEnabled',res.data.tcareProtocolEnabled)
                    localStorage.setItem('orgEthnicity',res.data.orgEthnicity)
                    localStorage.setItem('bulkService',res.data.bulkServiceUsage)
                    localStorage.setItem('jobFamily',res.data.jobFamilyGroup)
                    localStorage.setItem('empType',res.data.employeeType)
                    localStorage.setItem('military',res.data.militaryService)
                    setEditForm(false)
                    if(userDetails.role[0] === 'superadmin'){
                        history.goBack()
                    }
                }
            }).catch(e => {
                console.log(e)
            }).finally()
        }
      
    }

    return (
        <div class="pb-8">
            {/* <button className="flex mt-3 bg-tcolor px-6 py-2 rounded-xl items-center shadow-lg text-white ring-offset-2 hover:ring-2 hover:ring-tcolor dark:ring-offset-black whitespace-nowrap" onClick={() => history.goBack()}>
                <FaArrowLeft className="mr-3" /> Back
            </button> */}
            <div className="text-center">
                <TabButtonGroup>
                    <TabButton index={0} value={tab} setValue={setTab}>
                        {t("Profile")}
                    </TabButton>
                    {
                        (userDetails.role[0] === 'superadmin') ? '' : <TabButton index={1} value={tab} setValue={setTab}>
                            {t("Notification")}
                        </TabButton>
                    }
                      {
                        (userDetails.role[0] === 'superadmin' || campaign === 'false' ) ? '' : <TabButton index={2} value={tab} setValue={setTab}>
                            {t("Campaign")}
                        </TabButton>
                    }
                </TabButtonGroup>
            </div>
            <TabGroup value={tab}>
                <Tab index={0} value={tab}>
                    <FormCard title={t("Basic Information")}>
                        {((organization && userDetails && userDetails.orgId === parseInt(organization))|| (userDetails.role.includes('dynamic_admin'))) ? <FavFormButton injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleFormEdit} handleCancel={togglecancel}/> : ''}
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldInput ariaLabel={'Organization Name'} loading={formLoading} label={t("Organization Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationName')} fieldProps={profileForm.getFieldProps('organizationName')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Active/Inactive Dropdown'} loading={formLoading} label={t("Active/Inactive")} fieldMeta={profileForm.getFieldMeta('active')} fieldHelper={profileForm.getFieldHelpers('active')} fieldProps={profileForm.getFieldProps('active')} edit={editForm} options={select} position="top" dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'SIC Code'} loading={formLoading} label={t("SIC Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('sicCd')} fieldProps={profileForm.getFieldProps('sicCd')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'4'} />
                            <FieldInput ariaLabel={'License Expiry Date'} loading={formLoading} type="date" minDate={new Date()} label={t("Licences Expire Date")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('licenseExpirationDate')} fieldHelper={profileForm.getFieldHelpers('licenseExpirationDate')} fieldProps={profileForm.getFieldProps('licenseExpirationDate')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Phone Number'} loading={formLoading} type="phone" label={t("Phone Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('phone')} fieldProps={profileForm.getFieldProps('phone')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Number Of License'} loading={formLoading} label={t("Number of Licences Hold")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('licenses')} fieldProps={profileForm.getFieldProps('licenses')} edit={editForm} dataLoaded={dataLoaded} />
                            {orgCode ? <FieldInput ariaLabel={'Org Code'} loading={formLoading} label={t("Org Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('orgCode')} fieldProps={profileForm.getFieldProps('orgCode')} edit={false} dataLoaded={dataLoaded} />:
                            <FieldInput ariaLabel={'Org Code'} loading={formLoading} label={t("Org Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('orgCode')} fieldProps={profileForm.getFieldProps('orgCode')} edit={editForm} dataLoaded={dataLoaded} />}
                            {(userDetails.role.includes('superadmin')) ? <FieldSelect ariaLabel={'Default Protocol'} loading={formLoading} label={t("Default Protocol")} fieldMeta={profileForm.getFieldMeta('defaultProtocol')} fieldHelper={profileForm.getFieldHelpers('defaultProtocol')} fieldProps={profileForm.getFieldProps('defaultProtocol')} edit={editForm} options={protocolDefault} position="top" dataLoaded={dataLoaded} />:
                            <FieldSelect ariaLabel={'Default Protocol'} loading={formLoading} label={t("Default Protocol")} fieldMeta={profileForm.getFieldMeta('defaultProtocol')} fieldHelper={profileForm.getFieldHelpers('defaultProtocol')} fieldProps={profileForm.getFieldProps('defaultProtocol')} edit={false} options={protocolDefault} position="top" dataLoaded={dataLoaded} />}
                        </div>
                    </FormCard>
                    <FormCard title={t("Address Information")}>
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                        <FieldInput ariaLabel={'Postal Code'} loading={formLoading} label={t("Postal Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationAddress.postalCode')} fieldProps={profileForm.getFieldProps('organizationAddress.postalCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'cg')} />
                            <FieldInput ariaLabel={'Address Line 1'} loading={formLoading} label={t("Address Line 1")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationAddress.addressLine1')} fieldProps={profileForm.getFieldProps('organizationAddress.addressLine1')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Address Line 2'} loading={formLoading} label={t("Address Line 2")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationAddress.addressLine2')} fieldProps={profileForm.getFieldProps('organizationAddress.addressLine2')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'City'} loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationAddress.city')} fieldProps={profileForm.getFieldProps('organizationAddress.city')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Country'} loading={formLoading} label={t("Country")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationAddress.country')} fieldProps={profileForm.getFieldProps('organizationAddress.country')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'2'} />
                            <FieldSelect ariaLabel={'State Dropdown'} loading={formLoading} label={t("State")} fieldMeta={profileForm.getFieldMeta('organizationAddress.state')} fieldHelper={profileForm.getFieldHelpers('organizationAddress.state')} fieldProps={profileForm.getFieldProps('organizationAddress.state')} edit={editForm} options={states} position="top" dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Area Code'} loading={formLoading} label={t("Area Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('areaCode')} fieldProps={profileForm.getFieldProps('areaCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'4'} />
                        </div>
                    </FormCard>
                    <FormCard title={t("Additional Information")}>

                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldInput ariaLabel={'Active Timeout in Minutes'} loading={formLoading} label={t("Active Timeout(minutes)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('activeTimeoutInMs')} fieldProps={profileForm.getFieldProps('activeTimeoutInMs')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'InActive Timeout in Minutes'} loading={formLoading} label={t("InActive Timeout(minutes)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('inactiveTimeoutInMs')} fieldProps={profileForm.getFieldProps('inactiveTimeoutInMs')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Login Attempt'} loading={formLoading} label={t("Login Attempt")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('loginAttempts')} fieldProps={profileForm.getFieldProps('loginAttempts')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Lockout Time in Minutes'} loading={formLoading} label={t("Lockout Time(minutes)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('lockoutTimeInMs')} fieldProps={profileForm.getFieldProps('lockoutTimeInMs')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Password Expiration Age'} loading={formLoading} label={t("Password Expiration Age")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('passwordExpirationAge')} fieldProps={profileForm.getFieldProps('passwordExpirationAge')} edit={editForm} dataLoaded={dataLoaded} />
                        </div>
                    </FormCard>

                    <FormCard title={t("Followup Information")}>

                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldInput ariaLabel={'Followup Days'} loading={formLoading} label={t("Followup Days")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('followupDays')} fieldProps={profileForm.getFieldProps('followupDays')} edit={editForm} dataLoaded={dataLoaded} />
                        </div>
                    </FormCard>

                    <FormCard title={t("Service Information")}>

                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldCheckbox loading={formLoading} label={t('Military Service')} fieldMeta={profileForm.getFieldMeta('militaryServiceActivated')} fieldHelper={profileForm.getFieldHelpers('militaryServiceActivated')} fieldProps={profileForm.getFieldProps('militaryServiceActivated')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("IDD Service")} fieldMeta={profileForm.getFieldMeta('iddServiceActivated')} fieldProps={profileForm.getFieldProps('iddServiceActivated')} fieldHelper={profileForm.getFieldHelpers('iddServiceActivated')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("API Service")} fieldMeta={profileForm.getFieldMeta('apiServiceActivated')} fieldProps={profileForm.getFieldProps('apiServiceActivated')} fieldHelper={profileForm.getFieldHelpers('apiServiceActivated')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Report Service")} fieldMeta={profileForm.getFieldMeta('reportActivated')} fieldProps={profileForm.getFieldProps('reportActivated')} fieldHelper={profileForm.getFieldHelpers('reportActivated')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Chatbot Service")} fieldMeta={profileForm.getFieldMeta('chatbotActivated')} fieldProps={profileForm.getFieldProps('chatbotActivated')} fieldHelper={profileForm.getFieldHelpers('chatbotActivated')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Scheduling Service")} fieldMeta={profileForm.getFieldMeta('schedulingActivated')} fieldProps={profileForm.getFieldProps('schedulingActivated')} fieldHelper={profileForm.getFieldHelpers('schedulingActivated')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Telephony Service")} fieldMeta={profileForm.getFieldMeta('telephonyActivated')} fieldProps={profileForm.getFieldProps('telephonyActivated')} fieldHelper={profileForm.getFieldHelpers('telephonyActivated')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Org Uploads")} fieldMeta={profileForm.getFieldMeta('organizationUpload')} fieldProps={profileForm.getFieldProps('organizationUpload')} fieldHelper={profileForm.getFieldHelpers('organizationUpload')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Specialized Question")} fieldMeta={profileForm.getFieldMeta('specializedQuestions')} fieldProps={profileForm.getFieldProps('specializedQuestions')} fieldHelper={profileForm.getFieldHelpers('specializedQuestions')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("FilteredRdb")} fieldMeta={profileForm.getFieldMeta('filterRdb')} fieldProps={profileForm.getFieldProps('filterRdb')} fieldHelper={profileForm.getFieldHelpers('filterRdb')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Screener completion email to CG")} fieldMeta={profileForm.getFieldMeta('cgIntake')} fieldProps={profileForm.getFieldProps('cgIntake')} fieldHelper={profileForm.getFieldHelpers('cgIntake')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Resource Database API")} fieldMeta={profileForm.getFieldMeta('resourceDatabaseApi')} fieldProps={profileForm.getFieldProps('resourceDatabaseApi')} fieldHelper={profileForm.getFieldHelpers('resourceDatabaseApi')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Allow Unassigned Cases for Re-Screen")} fieldMeta={profileForm.getFieldMeta('rescreenActivated')} fieldProps={profileForm.getFieldProps('rescreenActivated')} fieldHelper={profileForm.getFieldHelpers('rescreenActivated')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Allow Welcome User Email")} fieldMeta={profileForm.getFieldMeta('userCreateMailAlert')} fieldProps={profileForm.getFieldProps('userCreateMailAlert')} fieldHelper={profileForm.getFieldHelpers('userCreateMailAlert')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Referral Dyad Required")} fieldMeta={profileForm.getFieldMeta('referralDyadRequired')} fieldProps={profileForm.getFieldProps('referralDyadRequired')} fieldHelper={profileForm.getFieldHelpers('referralDyadRequired')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Subscriber ID Required")} fieldMeta={profileForm.getFieldMeta('subscriberIdRequired')} fieldProps={profileForm.getFieldProps('subscriberIdRequired')} fieldHelper={profileForm.getFieldHelpers('subscriberIdRequired')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Full Birthdate Required")} fieldMeta={profileForm.getFieldMeta('dobFullRequired')} fieldProps={profileForm.getFieldProps('dobFullRequired')} fieldHelper={profileForm.getFieldHelpers('dobFullRequired')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Contact Caregivers By Default")} fieldMeta={profileForm.getFieldMeta('contactCG')} fieldProps={profileForm.getFieldProps('contactCG')} fieldHelper={profileForm.getFieldHelpers('contactCG')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Automated Reminders")} fieldMeta={profileForm.getFieldMeta('automaticReminder')} fieldProps={profileForm.getFieldProps('automaticReminder')} fieldHelper={profileForm.getFieldHelpers('automaticReminder')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => Autoremind(ev)} />
                            <FieldCheckbox loading={formLoading} label={t("Zip code Enable CR")} fieldMeta={profileForm.getFieldMeta('zipCodeEnableCR')} fieldProps={profileForm.getFieldProps('zipCodeEnableCR')} fieldHelper={profileForm.getFieldHelpers('zipCodeEnableCR')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Allow VIP status")} fieldMeta={profileForm.getFieldMeta('vipEnabled')} fieldProps={profileForm.getFieldProps('vipEnabled')} fieldHelper={profileForm.getFieldHelpers('vipEnabled')} edit={editForm} dataLoaded={dataLoaded} />
                            {(userDetails.role[0] === 'superadmin') ? <FieldCheckbox loading={formLoading} label={t("TCARE Protocol")} fieldMeta={profileForm.getFieldMeta('tcareProtocolEnabled')} fieldProps={profileForm.getFieldProps('tcareProtocolEnabled')} fieldHelper={profileForm.getFieldHelpers('tcareProtocolEnabled')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => ProtocolCard(ev)} />: ''}
                           {(userDetails.role.includes('superadmin')) ? <FieldCheckbox loading={formLoading} label={t("Ethnicity Enable")} fieldMeta={profileForm.getFieldMeta('orgEthnicity')} fieldProps={profileForm.getFieldProps('orgEthnicity')} fieldHelper={profileForm.getFieldHelpers('orgEthnicity')} edit={editForm} dataLoaded={dataLoaded} />:''}
                           {(userDetails.role.includes('superadmin')) ? <FieldCheckbox loading={formLoading} label={t("Bulk Entry for Service Usage")} fieldMeta={profileForm.getFieldMeta('bulkServiceUsage')} fieldProps={profileForm.getFieldProps('bulkServiceUsage')} fieldHelper={profileForm.getFieldHelpers('bulkServiceUsage')} edit={editForm} dataLoaded={dataLoaded} />:''}
                           {(userDetails.role.includes('superadmin')&&(userDetails.env === 'LSS' || userDetails.env === 'LSS-SB')) ? <FieldCheckbox loading={formLoading} label={t("LSS Job Family Group")} fieldMeta={profileForm.getFieldMeta('jobFamilyGroup')} fieldProps={profileForm.getFieldProps('jobFamilyGroup')} fieldHelper={profileForm.getFieldHelpers('jobFamilyGroup')} edit={editForm} dataLoaded={dataLoaded} />:''}
                           {(userDetails.role.includes('superadmin')&&(userDetails.env === 'LSS' || userDetails.env === 'LSS-SB')) ? <FieldCheckbox loading={formLoading} label={t("LSS Employment Type")} fieldMeta={profileForm.getFieldMeta('employeeType')} fieldProps={profileForm.getFieldProps('employeeType')} fieldHelper={profileForm.getFieldHelpers('employeeType')} edit={editForm} dataLoaded={dataLoaded} />:''}
                           {(userDetails.role.includes('superadmin')&&(userDetails.env === 'DAVORG'|| userDetails.env === 'DAVORG-SB')) ? <FieldCheckbox loading={formLoading} label={t("DAV Military Service")} fieldMeta={profileForm.getFieldMeta('militaryService')} fieldProps={profileForm.getFieldProps('militaryService')} fieldHelper={profileForm.getFieldHelpers('militaryService')} edit={editForm} dataLoaded={dataLoaded} />:''}
                           {(userDetails.role.includes('superadmin')) ? <FieldCheckbox loading={formLoading} label={t("Enhanced Close Case Reasons")} fieldMeta={profileForm.getFieldMeta('enhancedCloseCaseReason')} fieldProps={profileForm.getFieldProps('enhancedCloseCaseReason')} fieldHelper={profileForm.getFieldHelpers('enhancedCloseCaseReason')} edit={editForm} dataLoaded={dataLoaded} />:''}
                           {(userDetails.role.includes('superadmin')) ? <FieldCheckbox loading={formLoading} label={t("Authorization of Service")} fieldMeta={profileForm.getFieldMeta('serviceAuthorization')} fieldProps={profileForm.getFieldProps('serviceAuthorization')} fieldHelper={profileForm.getFieldHelpers('serviceAuthorization')} edit={editForm} dataLoaded={dataLoaded} />:''}
                           {(userDetails.role.includes('superadmin')) ? <FieldCheckbox loading={formLoading} label={t("Care Plan Actions")} fieldMeta={profileForm.getFieldMeta('orgPendingCareplanAction')} fieldProps={profileForm.getFieldProps('orgPendingCareplanAction')} fieldHelper={profileForm.getFieldHelpers('orgPendingCareplanAction')} edit={editForm} dataLoaded={dataLoaded} />:''}
                        </div>
                    </FormCard>

                    {protocolEnable && userDetails.role.includes('superadmin') ? <FormCard title={t("Protocol")}>
                        <div className="flex flex-row">
                            {protocolOption.map((c,i)=>{
                            return(<div key={i} className="w-96 mx-2">
                            <FieldCheckbox loading={formLoading} label={`${c.protocolName}`} fieldMeta={profileForm.getFieldMeta(`protocol.${i}.active`)} fieldProps={profileForm.getFieldProps(`protocol.${i}.active`)} fieldHelper={profileForm.getFieldHelpers(`protocol.${i}.active`)} edit={editForm} dataLoaded={dataLoaded} />
                            </div>)})}</div>
                        </FormCard> : ''}

                    { autoReminder ?  <FormCard title={t("Reminders Date")}>

                         <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                          <FieldInput ariaLabel={'Remainder 1'} loading={formLoading} label={t("Remainder 1")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('reminder1')} fieldProps={profileForm.getFieldProps('reminder1')} edit={editForm} dataLoaded={dataLoaded} onChange={ev => setReminder(ev ,'remind1')} />
                          <FieldInput ariaLabel={'Remainder 2'} loading={formLoading} label={t("Remainder 2")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('reminder2')} fieldProps={profileForm.getFieldProps('reminder2')} edit={editForm} dataLoaded={dataLoaded} onChange={ev => setReminder(ev ,'remind2')} />
                          <FieldInput ariaLabel={'Remainder 3'} loading={formLoading} label={t("Remainder 3")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('reminder3')} fieldProps={profileForm.getFieldProps('reminder3')} edit={editForm} dataLoaded={dataLoaded} onChange={ev => setReminder(ev ,'remind3')}  />
                       </div>
                    </FormCard> :''}


                    <FormCard title={t("Communicate")}>

                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldCheckbox loading={formLoading} label={t("Email to Caregiver")} fieldMeta={profileForm.getFieldMeta('caregiverEamil')} fieldProps={profileForm.getFieldProps('caregiverMail')} fieldHelper={profileForm.getFieldHelpers('caregiverMail')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Email to Caremanager")} fieldMeta={profileForm.getFieldMeta('caremanagerEmail')} fieldProps={profileForm.getFieldProps('caremanagerMail')} fieldHelper={profileForm.getFieldHelpers('caremanagerMail')} edit={editForm} dataLoaded={dataLoaded} />
                        </div>
                    </FormCard>
                    { editForm && 
                <div className="flex justify-center mt-7 mx-5">
                    <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700  hover:bg-opacity-80 text-white" onClick={() => setEditForm(false)}>{t("Cancel")}</button>
                    <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Save")}</button>
                </div>
            }
                </Tab>
                <Tab index={1} value={tab}>
                    <Notifications props={orgId} org={true} />
                </Tab>
                <Tab index={2} value={tab}>
                    <Campaign props={orgId} org={true}/>
                </Tab>
            </TabGroup>
        </div>
    )
}

export default EditOrganization