import { HiDotsVertical } from 'react-icons/hi'
import { AnimatePresence, motion } from 'framer-motion'
import { useLayoutEffect, useMemo } from 'react'
import { quickActionAnim } from '../../commons/anims'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

const QuickAction = ({ active, setActive, id, title, table, options }) => {
    const {t} = useTranslation()
    // const [activeTrigger,setActiveTrigger] = useState()

    const toggleActiveQuickAction = (ev) => {
        // ev.stopPropagation()
        let position = ev.pageY >= window.innerHeight / 2 ? 'top' : 'bottom'
        let left = `${ev.pageX}px`
        let top = `${ev.pageY}px`
        setActive({ position, top, left, active: active.active === id ? null : id })
    }
    const hideActiveQuickAction = () => setActive({ position: 'bottom', active: null })
    useLayoutEffect(() => {
        if (active.active === id) {
            setTimeout(() => {
                document.body.addEventListener('click', hideActiveQuickAction)
            }, 200)
        } else document.body.removeEventListener('click', hideActiveQuickAction)

        return () => document.body.removeEventListener('click', hideActiveQuickAction)
    }, [active]) // eslint-disable-line
    const openAnim = useMemo(() => {
        let anim = quickActionAnim
        anim.visible.originY = active.position === 'bottom' ? 'top' : 'bottom'
        if(active.position === 'top') anim.visible.y = '-100%'
        else anim.visible.y = '0'
        return anim
    }, [active.position])
    const triggerClick = (c) => {
        if (typeof c === 'function') c()
    }
    const OptionComp = useMemo(() => (
        <>
            {
                options.map(c => {
                    let Tag = c.link === undefined || c.link === '' ? 'button' : Link
                    return <Tag key={c.title} to={c.link} onClick={() => triggerClick(c.onClick)} className="whitespace-nowrap w-full flex items-center pr-4 pl-2 py-2 cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark text-xs">
                        <c.icon size="18px" className="mr-2" /> {c.title}
                    </Tag>
                })
            }
        </>
    ), [options]) // eslint-disable-line

    return (
        <div className="relative">
            <HiDotsVertical size="35px" className="cursor-pointer block hover:bg-bluegray-400 hover:text-white p-2 rounded-full" onClick={(ev) => toggleActiveQuickAction(ev)} />
            <AnimatePresence>
                {
                    active.active === id &&
                    <div className="fixed left-0 right-0 bottom-0 top-0 z-[2]">
                    <motion.div variants={openAnim} initial="hidden" animate="visible" exit="hidden" style={{top: active.top, left: active.left}} className={`fixed text-gray-700 dark:text-gray-200 bg-bluegray-300 dark:bg-ldark py-2 z-[2] rounded w-80 shadow-xl}`}>
                        <span className="text-[10px] ml-2 mb-2 text-gray-600 dark:text-gray-300 font-bold">{t('Quick Actions')} ({title || id})</span>
                        { OptionComp }
                    </motion.div>
                    </div>
                }
            </AnimatePresence>
        </div>
    )
}

export default QuickAction