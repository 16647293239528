
import { useFormik } from "formik"
import { useState, useEffect } from "react"
import * as Yup from 'yup'
import { useMemo } from "react"
import FavFormButton from "../../components/snippets/FavFormButton"
import FieldInput from "../../components/snippets/FieldInput"
import FieldSelect from "../../components/snippets/FieldSelect"

import FormCard from "../../components/snippets/FormCard"
import FieldCheckbox from "../../components/snippets/FieldCheckbox"

import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import { FaArrowLeft } from 'react-icons/fa'
import API from '../../axios/API'
import axios from 'axios'
import { setAlert } from "../../store/theme/actions"
import { motion } from "framer-motion"
import AssessmentOptionField from '../../components/snippets/Assessment/AssessmentOptionField'
import { useTranslation } from 'react-i18next'
import UserMultiSelect from "../../components/snippets/UserMultiSelect"


const initialFormValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    preferredName: '',
    ssn: '',
    userName: '',
    password: '',
    role: [],
    rolesId:[],
    phone: '',
    homePhone: '',
    email: '',
    gender: '',
    race: [],
    maritalStatus: '',
    education: '',
    employment: '',
    income: '',
    address: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        state: '',
        postalCode: ''
    },
    day: '',
    month: '',
    year: '',
    date: '',
    organizationId: '',
    white : false,
    black : false,
    asian : false,
    hispanic : false,
    middleEastern : false,
    nativeAmerican : false,
    refusedRace : false,
    pacificIslander : false,
    otherRace : false,
}



const Adduser = () => {

    let history = useHistory()

    const userDetails = useSelector(state => state.auth.userDetails)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [editForm, setEditForm] = useState(true)
    const [formLoading, setFormLoading] = useState(false)
    const [states, setStates] = useState([])
    const [gender, setGender] = useState([])
    const [education, setEducation] = useState([])
    const [employment, setEmployment] = useState([])
    const [maritalStatus, setMarital] = useState([])
    const [organization, setOrganization] = useState([])
    const [raceEthnicity, setRaceEthnicity] = useState([])
    const [Income, setIncome] = useState([])
    const [Role, setRole] = useState([])
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [cgRefused,setCgRefused] = useState(false)
    const [years,setYears] = useState([])
    // const [zipEnable,setZipEnable] = useState(false)
    let orgId = localStorage.getItem('orgid')
    let birthyear = localStorage.getItem('birthyear')
    const [birthyear1,setBirthyear1] = useState(false)
    const formData = useMemo(() => {
        setDataLoaded(true)
        return { ...initialFormValues }
    }, [])  // eslint-disable-line
    let lang = localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))

    
Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
    return this.test(t('validPhone'), t("Not a Valid Phone"), (value) => {
        if (value === undefined || value === '') return true
        return (value.replace(/ /g, '').length === 13 || value.replace(/ /g, '').length === 12)
    })
})
Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
    return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
        if (value === undefined || value === '') return true
        let r = /^[a-zA-Z]*$/g
        return r.test(value)
    })
})

Yup.addMethod(Yup.string, 'validateEmail', function () {
    return this.test('validEmail', t("Not valid"), (value) => {
        if (value === undefined || value === '') return true
        let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
        return r.test(value)
    })
})


Yup.addMethod(Yup.string, 'validatePassword', function () {
    return this.test('Password must be atleast 8 characters with 1 uppercase, 1 numeric character & 1 special character', "Password must be atleast 8 characters with 1 uppercase, 1 numeric character & 1 special character", (value) => {
        if (value === undefined || value === '') return true
        let r = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g
        return r.test(value)
    })
})


Yup.addMethod(Yup.string, 'validateName', function () {
    return this.test(t('only Alphabet and numbers are allowed'), t('Only Alphabets and numbers allowed'), (value) => {
        if (value === undefined || value === '') return true
        let r = /^[^-\s]/g
        return r.test(value)
    })
})

Yup.addMethod(Yup.string, 'EmptySpace', function () {
    return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
        if (value === undefined || value === '') return true
        let r = /^[^-\s]/g
        return r.test(value)
    })
})

    useEffect(()=>{
        for(let i = 1900; i <= new Date().getFullYear(); i++){
            years.push(i)
        }
    },[years])
    
    useEffect(() => {
        if(birthyear === 'true'){
            setBirthyear1(true)
        }
        if(menuItems !== undefined && menuItems !== null && menuItems !== ''){
            if(menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined){
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            }else{
                API.get(`menus/states?lang=${lang}`).then((statesResp)=>{
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if(menuItems.gender && menuItems.gender.length > 0 && menuItems.gender !== undefined){
                setGender(menuItems.gender.map((c)=>({text:c.name,value:c.key})))
            }else{
                API.get(`menus/genders?lang=${lang}`).then((gendersResp)=>{
                    setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if(menuItems.employment && menuItems.employment.length > 0 && menuItems.employment !== undefined){
                setEmployment(menuItems.employment.map((c)=>({text: c.name, value: c.value})))
            }else{
                API.get(`menus/employments?lang=${lang}`).then((employmentResp)=>{
                    setEmployment(employmentResp.data.body.map(c => ({ text: c.name, value: c.value })))
                })
            }
            if(menuItems.education && menuItems.education.length > 0 && menuItems.education !== undefined){
                setEducation(menuItems.education.map((c)=>({text: c.name, value: c.value})))
            }else{
                API.get(`menus/educations?lang=${lang}`).then((educationResp)=>{
                    setEducation(educationResp.data.body.map((c)=>({text: c.name, value: c.value})))
                })
            }
            if(menuItems.income && menuItems.income.length > 0 && menuItems.income !== undefined){
                setIncome(menuItems.income.map((c)=>({text:c.name,value:c.value})))
            }else{
                API.get(`menus/incomes?lang=${lang}`).then((incomeResp)=>{
                    setIncome(incomeResp.data.body.map((c)=>({text: c.name, value: c.value})))
                })
            }
            if(menuItems.maritalStat && menuItems.maritalStat.length > 0 && menuItems.maritalStat !== undefined){
                setMarital(menuItems.maritalStat.map((c)=>({text:c.name,value:c.key})))
            }else{
                API.get(`menus/marital-status?lang=${lang}`).then((maritalResp)=>{
                    setMarital(maritalResp.data.body.map((c)=>({text:c.name,value:c.key})))
                })
            }
            if(menuItems.roles && menuItems.roles.length > 0 && menuItems.roles !== undefined){
                setMarital(menuItems.roles.map((c)=>({text:c.name,value:c.key})))
            }else{
                API.get(`menus/roles/org/${orgId}?lang=${lang}`).then((rolesResp)=>{
                    if (userDetails.role[0] === 'superadmin') {
                        setRole([{value:1,name:'admin'}].map(c => ({ answerLabel:c.name,answerId:c.value,selected:false,key:"2" })))
                        API.get('organizations').then(organizationResp => {
                            if (organizationResp.status === 200) {
                                setOrganization(organizationResp.data.map(c => ({ text: c.organizationName, value: c.organizationId })))
                            }
                        })
                    }else{
                        setRole(rolesResp.data.body.map(c => ({ answerLabel:c.name,answerId:c.value,selected:false ,key:c.key})))
                    }
                   
                })
            }
            if(menuItems.race && menuItems.race.length > 0 && menuItems.race !== undefined){
                setRaceEthnicity(menuItems.race.map((c)=>({ text: c.name, value: c.value})))
            }else{
                API.get(`menus/races?lang=${lang}`).then((raceResp)=>{
                    setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
                })
            }
        }else{
        axios.all([API.get(`menus/states?lang=${lang}`), API.get(`menus/genders?lang=${lang}`), API.get(`menus/educations?lang=${lang}`), API.get(`menus/marital-status?lang=${lang}`), API.get(`menus/employments?lang=${lang}`),
        API.get(`menus/races?lang=${lang}`), API.get(`menus/incomes?lang=${lang}`), API.get(`menus/roles/org/${orgId}?lang=${lang}`)])
            .then(axios.spread((statesResp, gendersResp, educationsResp, maritalStatusResp, employmentsResp, raceResp, incomeResp, rolesResp) => {
                setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setEducation(educationsResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setMarital(maritalStatusResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setEmployment(employmentsResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setIncome(incomeResp.data.body.map(c => ({ text: c.name, value: c.value })))
                if (userDetails.role[0] === 'superadmin') {
                    setRole([{value:1,name:'admin'}].map(c => ({ answerLabel:c.name,answerId:c.value,selected:false,key:"2" })))
                    API.get('organizations').then(organizationResp => {
                        if (organizationResp.status === 200) {
                            setOrganization(organizationResp.data.map(c => ({ text: c.organizationName, value: c.organizationId })))
                        }
                    })
                }else{
                    setRole(rolesResp.data.body.map(c => ({ answerLabel:c.name,answerId:c.value,selected:false ,key:c.key})))
                }
               
            }))
            setYears(years.map(c=>({text:c,value:c})))

        }
    }, []) // eslint-disable-line


    const profileFormSchema = (userDetails.role[0] === 'superadmin') ? Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
        middleName: Yup.string().validateName(),
        lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
        preferredName: Yup.string().validateName(),
        ssn: Yup.string().length(4, t("SSN code should be 4 digits")).matches(/^[0-9]*$/, t("SSN code should be numerics")),
        userName: Yup.string().validateName(),
        password: Yup.string().validatePassword().min(3, t("Too short!")).max(25, t("Too long!")),
        role: Yup.array().of(Yup.string().required(t("Role is required"))),
        date: Yup.date().nullable(),
        phone: Yup.string().nullable(),
        homePhone: Yup.string().nullable(),
        email: Yup.string().validateEmail().required(t("Email is required")),
        gender: Yup.string(),
        maritalStatus: Yup.string(),
        education: Yup.string(),
        employment: Yup.string(),
        income: Yup.string(),
        race : Yup.array(),
        address: Yup.object({
            state: Yup.string().required(t("State is a required field")),
            addressLine1: Yup.string().EmptySpace().nullable(),
            addressLine2: Yup.string().nullable(),
            city: Yup.string().min(2,t("min 2 characters")).nullable(),
            postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numerics")).nullable(),
            addressLine3: Yup.string()
        }),
        day: Yup.number(),
        month: Yup.number(),
        year: Yup.number(),
        organizationId: Yup.string().required(t('Organization is a required field'))
    }) : cgRefused ? Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
        middleName: Yup.string().validateName(),
        lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
        preferredName: Yup.string().validateName(),
        race : Yup.array(),
        ssn: Yup.string().length(4, t("SSN code should be 4 digits")).matches(/^[0-9]*$/, t("SSN code should be numerics")),
        userName: Yup.string().validateName(),
        password: Yup.string().validatePassword().min(3, t("Too short!")).max(20),
        role: Yup.array().of(Yup.string().required(t("Role is required"))),
        date: Yup.date(),
        phone: Yup.string().validatePhoneLength(),
        homePhone: Yup.string().validatePhoneLength(),
        email: Yup.string().validateEmail().required(t("Email is required")),
        gender: Yup.string(),
        maritalStatus: Yup.string(),
        education: Yup.string(),
        employment: Yup.string(),
        income: Yup.string(),
        address: Yup.object({
            state: Yup.string().required(t("State is a required field")),
            addressLine1: Yup.string().EmptySpace(),
            addressLine2: Yup.string(),
            city: Yup.string().min(2, t("min 2 characters")),
            postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/,t("ZIP code should be 5 digits")),
            addressLine3: Yup.string()
        }),
        day: Yup.number(),
        month: Yup.number(),
        year: Yup.number(),
        organizationId: Yup.string()
    }): Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
        middleName: Yup.string().validateName(),
        lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
        preferredName: Yup.string().validateName(),
        race : Yup.array(),
        ssn: Yup.string().length(4, t("SSN code should be 4 digits")).matches(/^[0-9]*$/, t("SSN code should be numerics")),
        userName: Yup.string().validateName(),
        password: Yup.string().validatePassword().min(3, t("Too short!")).max(20),
        role: Yup.array().of(Yup.string().required(("Role is required"))),
        date: Yup.date(),
        phone: Yup.string().validatePhoneLength(),
        homePhone: Yup.string().validatePhoneLength(),
        email: Yup.string().validateEmail().required(t("Email is required")),
        gender: Yup.string(),
        maritalStatus: Yup.string(),
        education: Yup.string(),
        employment: Yup.string(),
        income: Yup.string(),
        address: Yup.object({
            state: Yup.string().required(t("State is a required field")),
            addressLine1: Yup.string().EmptySpace(),
            addressLine2: Yup.string(),
            city: Yup.string().min(2, t("min 2 characters")),
            postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numerics")),
            addressLine3: Yup.string()
        }),
        day: Yup.number(),
        month: Yup.number(),
        year: Yup.number(),
        organizationId: Yup.string()
    })

    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            setFormLoading(true)
            submitForm()
            setFormLoading(false)
        }
    })

    const getAddress = (value,field) => {
        if(value.length ===5){
            API.get(`/intake/zones?zipCode=${value}`).then((res)=>{
                if(res.status === 200){
                    // setZipEnable(true)
                    if(field === 'cg'){
                        profileForm.setValues({...profileForm.values,...profileForm.values,address:{...profileForm.values.address,state:res.data.body.state,city:res.data.body.city,country:res.data.body.country}})
                    }
                }
            })
        }else if(value.length === 0){
            // setZipEnable(false)
            profileForm.setValues({...profileForm.values,...profileForm.values,address:{...profileForm.values.address,state:"",city:"",country:""}})
        }
    }

    const setRefused = (side,value) =>{
        if(side === 'cg'){
            setCgRefused(value)
        }
    }
    const setFieldValue = (val) =>{
        setRole(val)
     }

    const changeRacevalues = ((val) => {
        if (val === 1) {
            profileForm.values.white = !profileForm.values.white;
        } else if (val === 2) {
            profileForm.values.black = !profileForm.values.black;
        } else if (val === 3) {
            profileForm.values.hispanic = !profileForm.values.hispanic;
        } else if (val === 4) {
            profileForm.values.asian = !profileForm.values.asian;
        } else if (val === 5) {
            profileForm.values.nativeAmerican = !profileForm.values.nativeAmerican;
        } else if (val === 7) {
            profileForm.values.middleEastern = !profileForm.values.middleEastern;
        } else if (val === 6) {
            profileForm.values.pacificIslander = !profileForm.values.pacificIslander;
        } else if (val === 9) {
            profileForm.values.refusedRace = !profileForm.values.refusedRace;
        } else if (val === 8) {
            profileForm.values.otherRace = !profileForm.values.otherRace;
        } else { }
    })

    const toggleFormEdit = () => {
        if (editForm) profileForm.submitForm()
        if (profileForm.isValid || !editForm) setEditForm(!editForm)
    }

    const submitForm = () => {
        profileForm.values.role = []
        Role.map(c=>{
            if(c.selected){
                profileForm.values.role.push(c.answerLabel)
            } return null;
        })
        let RolesId=[]
        Role.map(c=>{
            console.log(c)
            if(c.selected){
                RolesId.push(c.key)
            } return null;
        })
        if (profileForm.values.date && birthyear1) {
            let date = profileForm.values.date;
            date = profileForm.values.date?.split('/')
            profileForm.values.day = date[2]
            profileForm.values.month = date[1]
            profileForm.values.year = date[0]
        }
        let orgId = localStorage.getItem('orgid')
        let details = {
            "month": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.month) : parseInt(profileForm.values.month ? profileForm.values.month:1),
            "day": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.day) : parseInt(profileForm.values.day ? profileForm.values.day :1),
            "year":cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.year) : parseInt(profileForm.values.date ? profileForm.values.date :0),
            "userName": profileForm.values.userName.trim(),
            "address": {
                "state": profileForm.values.address.state,
                "addressLine1": profileForm.values.address.addressLine1,
                "addressLine2": profileForm.values.address.addressLine2,
                "city": profileForm.values.address.city,
                "postalCode": profileForm.values.address.postalCode,
                "addressLine3": profileForm.values.address.addressLine3
            },
            "password": profileForm.values.password,
            "roles": profileForm.values.role,
            "rolesId":RolesId,
            "email": profileForm.values.email,
            "organizationId": profileForm.values.organizationId ? profileForm.values.organizationId : orgId,
            "firstName": profileForm.values.firstName.trim(),
            "lastName": profileForm.values.lastName.trim(),
            "organizationName": userDetails.orgName,
            "phone": profileForm.values.phone,
            "middleName": profileForm.values.middleName,
            "prefferedName": profileForm.values.preferredName,
            "ssn": profileForm.values.ssn,
            "homePhone": profileForm.values.homePhone,
            "gender": profileForm.values.gender,
            "employmentId": profileForm.values.employment ? profileForm.values.employment : 0,
            "employment": profileForm.values.employment,
            "educationId": profileForm.values.education ? profileForm.values.education : 0,
            "education": profileForm.values.education,
            "incomeId": profileForm.values.income ? profileForm.values.income : 0,
            "income": profileForm.values.income,
            "maritalStatus": profileForm.values.maritalStatus,
            "consentToContact": true,
            "white": profileForm.values.race.includes(1),
            "black":  profileForm.values.race.includes(2),
            "hispanicOrLatino":  profileForm.values.race.includes(3),
            "asian":  profileForm.values.race.includes(4),
            "pacificIslander":  profileForm.values.race.includes(6),
            "nativeHawaiian":  profileForm.values.race.includes(5),
            "middleEastern":  profileForm.values.race.includes(7),
            "otherRace":  profileForm.values.race.includes(8),
            "refused":  profileForm.values.race.includes(9)
        }
        if(profileForm.values.role?.length === 0){
            dispatch(setAlert({title:t("Warning"), subtitle: t("Please select a role"),type:'warning',active: true}))
            setEditForm(true)
        }else{
            axios.all([API.post('users/-/email:check-availability', { email: profileForm.values.email }),
            ]).then(axios.spread((mailResp) => {
                if (mailResp.data.params === true) {
                    API.post('/users', details).then(res => {
                        if(res.status === 200 && res.data.status === false){
                            dispatch(setAlert({title:t('Warning'),subtitle:res.data.errorMessage,type:'warning',active:true}))
                            setEditForm(true)
                        }else if (res.status === 200) {
                            // alert('User Created Successfully')
                            dispatch(setAlert({ title: t('Success'), subtitle: t('User Created Successfully'), type: 'success', active: true }))
                            history.goBack()
                        }
                        setEditForm(!editForm)
                    }).catch(e => {
                        console.log(e)
                        dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error',active: true }))
                    }).finally()
                }
                else if (mailResp.data.params === false) {
                    dispatch(setAlert({ title: t('Error'), subtitle: t('Email already exists'), type: 'error', active: true }))
                    setEditForm(true)
                }
                // else if (checkResp.data.params === false) {
                //     dispatch(setAlert({ title: ('Error'), subtitle: 'Username already exists', type: 'error', active: true }))
                // }
                else {
                    dispatch(setAlert({ title: t('Error'), subtitle: t('Both Username and Email already exists'), type: 'error', active: true }));
                    setEditForm(true)
                }
            }))
        }
    }

    return (
        <div class="pb-8">
            <button className="flex mt-3 bg-tcolor px-6 py-2 rounded-xl items-center shadow-lg text-black ring-offset-2 hover:ring-2 hover:ring-tcolor dark:ring-offset-black whitespace-nowrap" onClick={() => history.goBack()}>
                <FaArrowLeft className="mr-3" /> {t("Back")}
            </button>
            <FormCard title={t("Basic Info")} injectClass="mt-12">
                <FavFormButton injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleFormEdit} handleCancel={() => setEditForm(false)} />
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldInput ariaLabel={'First Name'} hideData={true} loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('firstName')} fieldProps={profileForm.getFieldProps('firstName')} edit={editForm} dataLoaded={dataLoaded} />
                    {/* <FieldInput loading={formLoading} label={t("Middle Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('middleName')} fieldProps={profileForm.getFieldProps('middleName')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'1'} /> */}
                    <FieldInput ariaLabel={'Last Name'} hideData={true} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('lastName')} fieldProps={profileForm.getFieldProps('lastName')} edit={editForm} dataLoaded={dataLoaded} />
                   { birthyear1 ? <FieldInput ariaLabel={'Date Of Birth'} disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('date')} fieldProps={profileForm.getFieldProps('date')} fieldHelper={profileForm.getFieldHelpers('date')} edit={editForm} dataLoaded={dataLoaded} />:
                    <FieldSelect ariaLabel={'Birth Year Dropdown'} disabled={cgRefused ? true:false} loading={formLoading} label={t('Birth Year')} fieldMeta={profileForm.getFieldMeta(`date`)} fieldProps={profileForm.getFieldProps(`date`)} fieldHelper={profileForm.getFieldHelpers(`date`)} options={years} edit={editForm} dataLoaded={dataLoaded} position="bottom" />
                    }
                    <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative w-96">
                    {/* <FieldInput  disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={profileForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} /> */}
                    {cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cg',false)}>{t('Refused')}</button>}
                     {!cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tgray" onClick={()=>setRefused('cg',true)}>{t('Refused')}</button>}
                    </div>
                    <FieldInput ariaLabel={'Preferred Name'} loading={formLoading} label={t("Preferred Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('preferredName')} fieldProps={profileForm.getFieldProps('preferredName')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={'SSN'} loading={formLoading} label={t("SSN (Last 4 Digit)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('ssn')} fieldProps={profileForm.getFieldProps('ssn')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'4'} />
                    {/* <FieldInput loading={formLoading} label={t("Username")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('userName')} fieldProps={profileForm.getFieldProps('userName')} edit={editForm} dataLoaded={dataLoaded} /> */}
                    {/* <FieldInput loading={formLoading} type="password" label={t("Password")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('password')} fieldProps={profileForm.getFieldProps('password')} edit={editForm} dataLoaded={dataLoaded} /> */}
                    {userDetails.role.includes('superadmin') ? <FieldSelect ariaLabel={'Organization Dropdown'} loading={formLoading} label={t("Organization")} fieldMeta={profileForm.getFieldMeta('organizationId')} fieldHelper={profileForm.getFieldHelpers('organizationId')} fieldProps={profileForm.getFieldProps('organizationId')} edit={editForm} options={organization} position="top" dataLoaded={dataLoaded} /> : ''}
                </div>
            </FormCard>
            <motion.div initial='hidden' animate='visible' exit='hidden' className={`mt-3 form-wrap py-4`}>
            <div className="w-11/12 text-sm">
                        {t('Roles (You can select multiple roles)')}
                    </div>
                    <div className="mt-4 pb-3">
                        <AssessmentOptionField edit={editForm} type={'multi_option'} value={Role} options={Role} setValue={val => setFieldValue(val)} />
                    </div>
            </motion.div>
            <FormCard title={t("Contact Information")}>

                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldInput ariaLabel={'Mobile Number'} hideData={true} loading={formLoading} type="phone" label={t("Mobile Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('phone')} fieldProps={profileForm.getFieldProps('phone')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={'Home Phone Number'} hideData={true} loading={formLoading} type="phone" label={t("Home Phone Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('homePhone')} fieldProps={profileForm.getFieldProps('homePhone')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={'Email'} hideData={true} loading={formLoading} label={t("Email")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('email')} fieldProps={profileForm.getFieldProps('email')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox  loading={formLoading} label={t("Consented to be contacted")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('consentToContact')} fieldProps={profileForm.getFieldProps('consentToContact')} fieldHelper={profileForm.getFieldHelpers('consentToContact')} edit={editForm} dataLoaded={dataLoaded} />
                    {/* <FieldInput loading={formLoading} type="checkbox" label={"Consented to be contacted"} autoComplete='off' fieldMeta={profileForm.getFieldMeta('phoneNumber')} fieldProps={profileForm.getFieldProps('phoneNumber')} edit={editForm} /> */}
                </div>
            </FormCard>
            <FormCard title={t("Additional Information")}>

                <div>
                    <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                        <FieldSelect ariaLabel={'Gender Dropdown'} loading={formLoading} label={t("Gender")} fieldMeta={profileForm.getFieldMeta('gender')} fieldHelper={profileForm.getFieldHelpers('gender')} fieldProps={profileForm.getFieldProps('gender')} edit={editForm} options={gender} position="bottom" dataLoaded={dataLoaded} />
                        <FieldSelect ariaLabel={'Education Dropdown'} loading={formLoading} label={t("Education")} fieldMeta={profileForm.getFieldMeta('education')} fieldHelper={profileForm.getFieldHelpers('education')} fieldProps={profileForm.getFieldProps('education')} edit={editForm} options={education} position="bottom" dataLoaded={dataLoaded} />
                        <FieldSelect ariaLabel={'Marital Status Dropdown'} loading={formLoading} label={t("Marital Status")} fieldMeta={profileForm.getFieldMeta('maritalStatus')} fieldHelper={profileForm.getFieldHelpers('maritalStatus')} fieldProps={profileForm.getFieldProps('maritalStatus')} edit={editForm} options={maritalStatus} position="top" dataLoaded={dataLoaded} />
                        <FieldSelect ariaLabel={'Total Household Income Dropdown'} loading={formLoading} label={t("Total Household Income")} fieldMeta={profileForm.getFieldMeta('income')} fieldHelper={profileForm.getFieldHelpers('income')} fieldProps={profileForm.getFieldProps('income')} edit={editForm} options={Income} position="top" dataLoaded={dataLoaded} />
                        <FieldSelect ariaLabel={'Employment Status Dropdown'} loading={formLoading} label={t("Employment Status")} fieldMeta={profileForm.getFieldMeta('employment')} fieldHelper={profileForm.getFieldHelpers('employment')} fieldProps={profileForm.getFieldProps('employment')} edit={editForm} options={employment} position="top" dataLoaded={dataLoaded} />
                        <UserMultiSelect ariaLabel={'Race/Ethnicity Dropdown'} loading={formLoading} label={t("Race/Ethnicity")} fieldMeta={profileForm.getFieldMeta('race')} fieldHelper={profileForm.getFieldHelpers('race')} fieldProps={profileForm.getFieldProps('race')} edit={editForm} options={raceEthnicity} position="bottom" dataLoaded={dataLoaded} onChange={val=>changeRacevalues(val)} />

                    </div>
                </div>

            </FormCard>
            <FormCard title={t("Primary Address")}>

                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                <FieldInput ariaLabel={'Zipcode'} loading={formLoading} label={t("Zip Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.postalCode')} fieldProps={profileForm.getFieldProps('address.postalCode')} fieldHelper={profileForm.getFieldHelpers('address.postalCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'cg')} />
                 <FieldInput ariaLabel={'Address Line 1'} loading={formLoading} label={t("Address Line 1")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine1')} fieldProps={profileForm.getFieldProps('address.addressLine1')} fieldHelper={profileForm.getFieldHelpers('address.addressLine1')} edit={editForm} dataLoaded={dataLoaded} />  
                 <FieldInput ariaLabel={'Address Line 2'} loading={formLoading} label={t("Address Line 2")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine2')} fieldProps={profileForm.getFieldProps('address.addressLine2')} fieldHelper={profileForm.getFieldHelpers('address.addressLine2')} edit={editForm} dataLoaded={dataLoaded} /> 
                    {/* <FieldInput loading={formLoading} label={"Address Line 3"} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine3')} fieldProps={profileForm.getFieldProps('address.addressLine3')} fieldHelper={profileForm.getFieldHelpers('address.addressLine3')} edit={editForm} dataLoaded={dataLoaded} /> */}
                 <FieldInput ariaLabel={'City'} loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.city')} fieldProps={profileForm.getFieldProps('address.city')} fieldHelper={profileForm.getFieldHelpers('address.city')} edit={editForm} dataLoaded={dataLoaded} /> 
                 <FieldSelect ariaLabel={'State Dropdown'} loading={formLoading} label={t("State")} fieldMeta={profileForm.getFieldMeta('address.state')} fieldHelper={profileForm.getFieldHelpers('address.state')} fieldProps={profileForm.getFieldProps('address.state')} edit={editForm} options={states} position="top" dataLoaded={dataLoaded} ml={true} length={'5'} />  
                    
                </div>
            </FormCard>
            {editForm &&
                <div className="flex justify-center mt-7 mx-5">
                    <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700 hover:bg-opacity-80 text-white"  onClick={() => setEditForm(false)}>{t("Cancel")}</button>
                    <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white"  onClick={toggleFormEdit}>{t("Save")}</button>
                </div>
            }
        </div>
    )
}

export default Adduser