import { useFormik } from "formik"
import { useState, useEffect } from "react"
import API from '../../axios/API'
import * as Yup from 'yup'
import FavFormButton from "../../components/snippets/FavFormButton"
import FieldInput from "../../components/snippets/FieldInput"
import FieldSelect from "../../components/snippets/FieldSelect"
import FieldCheckbox from '../../components/snippets/FieldCheckbox'
// import FieldMultiSelect from '../../components/snippets/FieldMultiSelect'
import FormCard from "../../components/snippets/FormCard"
import axios from "axios"
import { setAlert } from '../../store/theme/actions'
import { useDispatch,useSelector } from 'react-redux'
import { setProfileCount } from "../../store/auth/actions"
import { useTranslation } from 'react-i18next'





const Info = () => {
    const [editForm, setEditForm] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [formLoading, setFormLoading] = useState(false)
    const [profile, setProfile] = useState()
    const [states, setStates] = useState([])
    // const [user , setUser] = useState([])
    const dispatch = useDispatch()
    const profilecount = useSelector(state =>state.auth.profileCount)
    const { userDetails } = useSelector(state => state.auth)
    const [tempUserName,setTempUserName] = useState('')
    // const logout = () => dispatch(setLoggedIn(false))
    const { t } = useTranslation()
    const [cgRefused,setCgRefused] = useState(false)
    const [years,setYears] = useState([])
    // const [zipEnable,setZipEnable] = useState(false)
    // const [casedetail,setCaseDetail] = useState('')
    const [gender, setGender] = useState([])
    const [employment, setEmployment] = useState([])
    // const [pronouns, setPronouns] = useState([])
    // const [raceEthnicity, setRaceEthnicity] = useState([])
    // const [language, setLanguage] = useState([])
    const [maritalStatus, setMarital] = useState([])
    const [education, setEducation] = useState([])
    const [Income, setIncome] = useState([])
    const [datayear, setDatayear] = useState('')
    const [rolesid,setRolesId] = useState([])
    let birthyear = localStorage.getItem('birthyear')
    const [birthyear1, setBirthYear1] = useState(false)
    let lang = localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))

    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test('validPhone', t("Not a Valid Phone"), (value) => {
            if (value === undefined || value === '' || value === null) return true
            return (value.replace(/ /g, '').length === 13 || value.replace(/ /g, '').length === 12)
        })
    })
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test('onlyAlphabet', t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[a-zA-Z ]*$/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'validateEmail', function () {
        return this.test('validEmail', t("Not valid"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            return r.test(value)
        })
    })
    
    
    Yup.addMethod(Yup.string, 'validateName', function () {
        return this.test('onlyAlphabet and numbers are allowed', t('Only Alphabets and numbers allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validName', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
   
    useEffect(() => {
        for (let i = 1900; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
    }, []) //eslint-disable-line

    useEffect(() => {
         if(birthyear === 'true'){
                setBirthYear1(true)
               }
        setDataLoaded(false)
        axios.all([API.get(`users`)]).then(axios.spread((userResp) => {
            if (userResp.data) {  
                if(userResp.data.body.year === 0){
                    setCgRefused(true)
                }
                setRolesId(userResp.data.body.rolesId)
                let tempProfile = userResp.data.body
                setTempUserName(tempProfile.userName)
            
                // setUser(tempProfile.userName)
                setDatayear(tempProfile.year)
                console.log(tempProfile.year,birthyear1,birthyear)
                if(birthyear === 'true' && tempProfile.year !== 0){
                    tempProfile.date = userResp.data.body.year + "/" + userResp.data.body.month + "/" + userResp.data.body.day              
                    }else if(userResp.data.body.year !== 0){
                        tempProfile.date = userResp.data.body.year
                    }else{
                        tempProfile.date = ''
                    }
                    setProfile(tempProfile)
                setFormLoading(false)
                setDataLoaded(true)
            }
          
        }))
    }, [profilecount,birthyear1])// eslint-disable-line

    useEffect(() => {
        if(menuItems !== undefined && menuItems !== null && menuItems !== ''){
            if(menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined){
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            }else{
                API.get(`menus/states?lang=${lang}`).then((statesResp)=>{
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if(menuItems.gender && menuItems.gender.length > 0 && menuItems.gender !== undefined){
                setGender(menuItems.gender.map((c)=>({text:c.name,value:c.key})))
            }else{
                API.get(`menus/genders?lang=${lang}`).then((gendersResp)=>{
                    setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if(menuItems.employment && menuItems.employment.length > 0 && menuItems.employment !== undefined){
                setEmployment(menuItems.employment.map((c)=>({text: c.name, value: c.value})))
            }else{
                API.get(`menus/employments?lang=${lang}`).then((employmentResp)=>{
                    setEmployment(employmentResp.data.body.map(c => ({ text: c.name, value: c.value })))
                })
            }
            if(menuItems.education && menuItems.education.length > 0 && menuItems.education !== undefined){
                setEducation(menuItems.education.map((c)=>({text: c.name, value: c.value})))
            }else{
                API.get(`menus/educations?lang=${lang}`).then((educationResp)=>{
                    setEducation(educationResp.data.body.map((c)=>({text:c.name,value:c.key})))
                })
            }
            if(menuItems.income && menuItems.income.length > 0 && menuItems.income !== undefined){
                setIncome(menuItems.income.map((c)=>({text:c.name,value:c.value})))
            }else{
                API.get(`menus/incomes?lang=${lang}`).then((incomeResp)=>{
                    setIncome(incomeResp.data.body.map((c)=>({text: c.name, value: c.value})))
                })
            }
            if(menuItems.maritalStat && menuItems.maritalStat.length > 0 && menuItems.maritalStat !== undefined){
                setMarital(menuItems.maritalStat.map((c)=>({text:c.name,value:c.key})))
            }else{
                API.get(`menus/marital-status?lang=${lang}`).then((maritalResp)=>{
                    setMarital(maritalResp.data.body.map((c)=>({text:c.name,value:c.key})))
                })
            }
        }else{
        axios.all([API.get(`menus/states?lang=${lang}`), API.get(`menus/genders?lang=${lang}`), API.get(`menus/employments?lang=${lang}`),
        API.get(`menus/pronouns?lang=${lang}`), API.get(`menus/races?lang=${lang}`), API.get(`menus/marital-status?lang=${lang}`), API.get(`menus/educations?lang=${lang}`), API.get(`menus/languages?lang=${lang}`),
        API.get(`menus/incomes?lang=${lang}`)]).then(axios.spread((statesResp, gendersResp, employmentResp, pronounsResp,
            raceResp, maritalResp, educationResp, languageResp, incomeResp) => {
            setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setEmployment(employmentResp.data.body.map(c => ({ text: c.name, value: c.value })))
            // setPronouns(pronounsResp.data.body.map(c => ({ text: c.name, value: c.name })))
            // setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
            setMarital(maritalResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setEducation(educationResp.data.body.map(c => ({ text: c.name, value: c.value })))
            // setLanguage(languageResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setIncome(incomeResp.data.body.map(c => ({ text: c.name, value: c.value })))
        }))
    }
    setYears(years.map(c=>({text:c,value:c})))
    }, [])// eslint-disable-line

    // const changeRacevalues = ((val) => {
    //     if (val === 1) {
    //         profileForm.values.white = !profileForm.values.white;
    //     } else if (val === 2) {
    //         profileForm.values.black = !profileForm.values.black;
    //     } else if (val === 3) {
    //         profileForm.values.hispanic = !profileForm.values.hispanic;
    //     } else if (val === 4) {
    //         profileForm.values.asian = !profileForm.values.asian;
    //     } else if (val === 5) {
    //         profileForm.values.nativeAmerican = !profileForm.values.nativeAmerican;
    //     } else if (val === 7) {
    //         profileForm.values.middleEastern = !profileForm.values.middleEastern;
    //     } else if (val === 6) {
    //         profileForm.values.pacificIslander = !profileForm.values.pacificIslander;
    //     } else if (val === 9) {
    //         profileForm.values.refusedRace = !profileForm.values.refusedRace;
    //     } else if (val === 8) {
    //         profileForm.values.otherRace = !profileForm.values.otherRace;
    //     } else { }
    // })
 
    const profileFormSchema = !cgRefused ? Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validName().required(t("Firstname is required field")),
        middleName: Yup.string().nullable(),
        lastName: Yup.string().nullable().max(50).validName().required(t("Lastname is a required field")),
        date: Yup.string().nullable(),
        // userName: Yup.string().min(3, "Too short!").max(25, t("Too long!")).required(t("Username is required")),
        phone: Yup.string().nullable().validatePhoneLength(),
        homePhone: Yup.string().nullable().validatePhoneLength(),
        email: Yup.string().validateEmail().required(t("Email is required")),
        address: Yup.object({
            state: Yup.string().required(t("State is a required field")),
            addressLine1: Yup.string().validName().min(3,t("Too short!")).max(50, "Too long!").nullable(),
            city: Yup.string().nullable(),
            postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numerics")).nullable()
        })
    }):Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validName().required(t("Firstname is required field")),
        middleName: Yup.string().nullable(),
        lastName: Yup.string().nullable().max(50).validName().required(t("Lastname is a required field")),
        date: Yup.string().nullable(),
        // userName: Yup.string().min(3, t("Too short!")).max(25, "Too long!"),
        phone: Yup.string().nullable().validatePhoneLength(),
        homePhone: Yup.string().nullable().validatePhoneLength(),
        email: Yup.string().validateEmail().required(t("Email is required")),
        address: Yup.object({
            state: Yup.string().required("State is a required field"),
            addressLine1: Yup.string().validName().min(3, t("Too short!")).max(50, t("Too long!")).nullable(),
            city: Yup.string().nullable(),
            postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numerics")).nullable()
        })
    })

    const profileForm = useFormik({
        initialValues: profile,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            setFormLoading(true)
            updateForm()
            setFormLoading(false)
        }
    })

    const getAddress = (value,field) => {
        if(value.length ===5){
            API.get(`/intake/zones?zipCode=${value}`).then((res)=>{
                if(res.status === 200){
                    // setZipEnable(true)
                    if(field === 'cg'){
                        profileForm.setValues({...profileForm.values,...profileForm.values,address:{...profileForm.values.address,state:res.data.body.state,city:res.data.body.city,country:res.data.body.country}})
                    }
                }
            })
        }else if(value.length === 0){
            // setZipEnable(false)
            profileForm.setValues({...profileForm.values,...profileForm.values,address:{...profileForm.values.address,state:"",city:"",country:""}})
        }
    }

  

    const toggleFormEdit = () => {
        if (editForm) profileForm.submitForm()
        if (!editForm) setEditForm(!editForm)
    }
    
    const togglecancel =()=>{
        profileForm.resetForm()
        setCgRefused(false)
        setEditForm(false)
        if(datayear === 0){
            setCgRefused(true)
        }
    }

    const setRefused = (side,value) =>{
        if(side === 'cg'){
            setCgRefused(value)
        }
    }
    const updateForm = () => {
        if (profileForm.values.date && birthyear1) {
            let date = profileForm.values.date;
            date = profileForm.values.date && profileForm.values.date?.split('/')
            profileForm.values.day = date[2]
            profileForm.values.month = date[1]
            profileForm.values.year = date[0]
        }
        if(tempUserName === profileForm.values.userName) {
            let details = {
            year:cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.year) : parseInt(profileForm.values.date ? profileForm.values.date:0),
            month:cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.month): 1,
            day:cgRefused ? 0 :birthyear1 ? parseInt(profileForm.values.day): 1,
            userName: profileForm.values.userName,
            address: {
                state: profileForm.values.address.state,
                country: profile.address.country,
                addressLine1: profileForm.values.address.addressLine1,
                addressLine2: profile.address.addressLine2,
                city: profileForm.values.address.city,
                postalCode: profileForm.values.address.postalCode,
                addressLine3: profile.address.addressLine3
            },
            roles: profile.roles,
            rolesId:rolesid,
            userId: profile.userId,
            organizationName: profile.organizationName,
            phone: profileForm.values.phone,
            organizationId: profile.organizationId,
            education: profileForm.values.education,
            employment: profileForm.values.employment,
            gender: profileForm.values.gender,
            maritalStatus: profileForm.values.maritalStatus,
            income: profileForm.values.income,
            email: profileForm.values.email,
            firstName: profileForm.values.firstName.trim(),
            lastName: profileForm.values.lastName.trim(),
            pplId: profile.pplId,
            white: profile.white,
            black: profile.black,
            hispanicOrLatino: profile.hispanicOrLatino,
            asian: profile.asian,
            americanIndian: profile.americanIndian,
            nativeHawaiian: profile.nativeHawaiian,
            middleEastern: profile.middleEastern,
            refused: profile.refused,
            middleName: profileForm.values.middleName,
            prefferedName: profile.prefferedName,
            ssn: profile.ssn,
            homePhone: profileForm.values.homePhone,
            employmentId: profileForm.values.employmentId,
            educationId: profileForm.values.educationId,
            incomeId: profileForm.values.incomeId,
            consentToContact: profileForm.values.consentToContact
        }
        API.patch(`organizations/${userDetails.orgId}/users`, details).then(res => {
            if (res.data.params === true) {
                // window.location.reload(false)
                // window.parent.location = window.parent.location.href; 
                dispatch(setAlert({ title: t('Success'), subtitle: t('User Updated Successfully'), type: 'success', active: true }))
                setEditForm(false)
                dispatch(setProfileCount(profilecount+1))
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: res.data.errorMessage, type: 'error', active: true }))
            }
            if(res.data.body.year !== 0){
                setCgRefused(false)
            }
        }).catch(e => {
            console.log(e)
        })
        }else{
            // API.post('users/-/username:check-availability', { userName: profileForm.values.userName }).then(res=>{
            //     if(res.data.params === false) {
            //         dispatch(setAlert({ title: 'Error', subtitle: 'Username already exists', type: 'error', active: true }))
            //     }else{
            //         let details = {
            //             year: cgRefused ? 0 : parseInt(profileForm.values.date ? profileForm.values.date:0),
            //             month:cgRefused ? 0 : 1,
            //             day:cgRefused ? 0 : 1,
            //             userName: profileForm.values.userName,
            //             address: {
            //                 state: profileForm.values.address.state,
            //                 country: profile.address.country,
            //                 addressLine1: profileForm.values.address.addressLine1,
            //                 addressLine2: profile.address.addressLine2,
            //                 city: profileForm.values.address.city,
            //                 postalCode: profileForm.values.address.postalCode,
            //                 addressLine3: profile.address.addressLine3
            //             },
            //             roles: profile.roles,
            //             userId: profile.userId,
            //             organizationName: profile.organizationName,
            //             phone: profileForm.values.phone,
            //             organizationId: profile.organizationId,
            //             education: profile.education,
            //             employment: profile.employment,
            //             gender: profile.gender,
            //             maritalStatus: profileForm.values.maritalStatus,
            //             income: profile.income,
            //             email: profileForm.values.email,
            //             firstName: profileForm.values.firstName,
            //             lastName: profileForm.values.lastName,
            //             pplId: profile.pplId,
            //             white: profileForm.values.white,
            //             black: profileForm.values.black,
            //             hispanicOrLatino: profile.hispanicOrLatino,
            //             asian: profileForm.values.asian,
            //             americanIndian: profileForm.values.americanIndian,
            //             nativeHawaiian: profileForm.values.nativeHawaiian,
            //             middleEastern: profileForm.values.middleEastern,
            //             refused: profileForm.values.refused,
            //             middleName: profileForm.values.middleName,
            //             prefferedName: profile.prefferedName,
            //             ssn: profile.ssn,
            //             homePhone: profileForm.values.homePhone,
            //             employmentId: profileForm.values.employmentId,
            //             educationId: profileForm.values.educationId,
            //             incomeId: profileForm.values.incomeId,
            //             consentToContact: profile.consentToContact
            //         }
            //         API.patch(`organizations/${userDetails.orgId}/users`, details).then(res => {
            //             if (res.data.params === true) {
            //                 dispatch(setAlert({ title: 'Success', subtitle: 'User Updated Successfully', type: 'success', active: true }))
            //                 logout();
            //             } else {
            //                 dispatch(setAlert({ title: 'Error', subtitle: res.data.errorMessage, type: 'error', active: true }))
            //             }
            //         }).catch(e => {
            //             console.log(e)
            //         })
            //     }
            // })
        }
    }

    return (
        <div>
            <FormCard title={t("Personal Information")} injectClass="mt-8">
                <FavFormButton injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleFormEdit} handleCancel={togglecancel} />
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldInput ariaLabel={'First Name'} hideData={true} loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('firstName')} fieldProps={profileForm.getFieldProps('firstName')} edit={editForm} dataLoaded={dataLoaded} />
                    {/* <FieldInput loading={formLoading} label={t("Middle Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('middleName')} fieldProps={profileForm.getFieldProps('middleName')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'1'} /> */}
                    <FieldInput ariaLabel={'Last Name'}  hideData={true} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('lastName')} fieldProps={profileForm.getFieldProps('lastName')} edit={editForm} dataLoaded={dataLoaded} />
                   {birthyear1 ? <FieldInput ariaLabel={'Date of Birth'}  disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('date')} fieldProps={profileForm.getFieldProps('date')} fieldHelper={profileForm.getFieldHelpers('date')} edit={editForm} dataLoaded={dataLoaded} />:
                    <FieldSelect ariaLabel={'Birth Year Dropdown'}  disabled={cgRefused ? true:false} loading={formLoading} label={t('Birth Year')} fieldMeta={profileForm.getFieldMeta('date')} fieldProps={profileForm.getFieldProps('date')} fieldHelper={profileForm.getFieldHelpers('date')} options={years} edit={editForm} dataLoaded={dataLoaded} position="bottom" />

                   } <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative">
                   {/* <FieldInput  disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={profileForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} /> */}
                   {editForm && cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cg',false)}>{t('Refused')}</button>}
                    {editForm && !cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tgray" onClick={()=>setRefused('cg',true)}>{t('Refused')}</button>}
                   </div>
                    {/* <span>
                        <FieldInput loading={formLoading} label={t("UserName")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('userName')} fieldProps={profileForm.getFieldProps('userName')} edit={editForm} dataLoaded={dataLoaded} />
                        {editForm ? <span className="text-xs text-red-600 px-0">
                            Please note: Updating username will navigate to login screen.
                        </span>:''}
                    </span> */}
                    <FieldInput ariaLabel={'Roles'} loading={formLoading} label={t("Roles")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('roles')} fieldProps={profileForm.getFieldProps('roles')} dataLoaded={dataLoaded} />
                </div>
            </FormCard>
            <FormCard title={t("Contact Information")}>
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldInput ariaLabel={'Phone Number'} hideData={true} loading={formLoading} type="phone" label={t("Phone Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('phone')} fieldProps={profileForm.getFieldProps('phone')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={'Home Phone Number'} hideData={true} loading={formLoading} type="phone" label={t("Home Phone Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('homePhone')} fieldProps={profileForm.getFieldProps('homePhone')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={'Email Address'} loading={formLoading} label={t("Email")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('email')} fieldProps={profileForm.getFieldProps('email')}  dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Consented to be contacted")} autoComplete='off' fieldHelper={profileForm.getFieldHelpers('consentToContact')} fieldMeta={profileForm.getFieldMeta('consentToContact')} fieldProps={profileForm.getFieldProps('consentToContact')} edit={editForm} dataLoaded={dataLoaded} />
                </div>
            </FormCard>
            
          <FormCard title={t("Additional Information")}>
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldSelect ariaLabel={'Gender Dropdown'}  loading={formLoading} label={t("Gender")} fieldMeta={profileForm.getFieldMeta('gender')} fieldHelper={profileForm.getFieldHelpers('gender')} fieldProps={profileForm.getFieldProps('gender')} edit={editForm} options={gender} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Education Level Dropdown'} loading={formLoading} label={t("Education Level")} fieldMeta={profileForm.getFieldMeta('educationId')} fieldHelper={profileForm.getFieldHelpers('educationId')} fieldProps={profileForm.getFieldProps('educationId')} edit={editForm} options={education} position="bottom" dataLoaded={dataLoaded} />
                            {/* <FieldSelect loading={formLoading} label={t("Preferred Language")} fieldMeta={profileForm.getFieldMeta('language')} fieldHelper={profileForm.getFieldHelpers('language')} fieldProps={profileForm.getFieldProps('language')} edit={editForm} options={language} position="top" dataLoaded={dataLoaded} /> */}
                            {/* <FieldMultiSelect loading={formLoading} label={t("Race/Ethnicity")} fieldMeta={profileForm.getFieldMeta('race')} fieldHelper={profileForm.getFieldHelpers('race')} fieldProps={profileForm.getFieldProps('race')} edit={editForm} options={raceEthnicity} position="bottom" dataLoaded={dataLoaded} onChange={changeRacevalues(profileForm.getFieldProps('race'))} /> */}
                            <FieldSelect ariaLabel={'Total Household Income Dropdown'} loading={formLoading} label={t("Total Household Income")} fieldMeta={profileForm.getFieldMeta('incomeId')} fieldHelper={profileForm.getFieldHelpers('incomeId')} fieldProps={profileForm.getFieldProps('incomeId')} edit={editForm} options={Income} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Marital Status Dropdown'} loading={formLoading} label={t("Marital Status")} fieldMeta={profileForm.getFieldMeta('maritalStatus')} fieldHelper={profileForm.getFieldHelpers('maritalStatus')} fieldProps={profileForm.getFieldProps('maritalStatus')} edit={editForm} options={maritalStatus} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Employment Status'} loading={formLoading} label={t("Employment Status")} fieldMeta={profileForm.getFieldMeta('employmentId')} fieldHelper={profileForm.getFieldHelpers('employmentId')} fieldProps={profileForm.getFieldProps('employmentId')} edit={editForm} options={employment} position="bottom" dataLoaded={dataLoaded} />
                            {/* <FieldSelect loading={formLoading} label={t("Pronouns")} fieldMeta={profileForm.getFieldMeta('pronouns')} fieldHelper={profileForm.getFieldHelpers('pronouns')} fieldProps={profileForm.getFieldProps('pronouns')} edit={editForm} options={pronouns} position="bottom" dataLoaded={dataLoaded} /> */}

                        </div>
                    </FormCard>
            <FormCard title={t("Address Information")}>
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                <FieldInput ariaLabel={'Zip Code'}  loading={formLoading} label={t("Zip Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.postalCode')} fieldProps={profileForm.getFieldProps('address.postalCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'cg')} />
                    <FieldInput ariaLabel={'Address'} loading={formLoading} label={t("Address")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine1')} fieldProps={profileForm.getFieldProps('address.addressLine1')} edit={editForm} dataLoaded={dataLoaded} />  
                    <FieldInput ariaLabel={'City'} loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.city')} fieldProps={profileForm.getFieldProps('address.city')} edit={editForm} dataLoaded={dataLoaded} />
                   <FieldSelect ariaLabel={'State'} loading={formLoading} label={t("State")} fieldMeta={profileForm.getFieldMeta('state')} fieldHelper={profileForm.getFieldHelpers('address.state')} fieldProps={profileForm.getFieldProps('address.state')} edit={editForm} dataLoaded={dataLoaded} options={states} position="top" />              
                </div>
            </FormCard>
            { editForm && 
                <div className="flex justify-center mt-7 mx-5">
                    <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700 hover:bg-opacity-80 text-white" onClick={togglecancel}>{t('Cancel')}</button>
                    <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t('Save')}</button>
                </div>
            }
        </div>
    )
}

export default Info