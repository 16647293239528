
import React from 'react'

const AssessmentOptionField = ({ edit, loading, options = [], type, value, setValue }) => {
    const chgValue = (e) => {
        if (typeof setValue === 'function' && edit) {
            if (type === 'option') {
                setValue(value === e ? '' : e)
            } else if (type === 'multi_option') {
                let option = (value.find(c => c.answerLabel.toString().toLowerCase() === 'none') || value.find(c => c.answerLabel.toString().toLowerCase() === 'refused') || (value.find(c => c.answerLabel.toString().toLowerCase() === 'ninguna')))
                if (option) {
                    if (option.answerId === e) {
                        setValue(value.map(c => {
                            if (option.answerId === c.answerId) c.selected = !c.selected
                            else c.selected = false
                            return c
                        }))
                    } else {
                        setValue(value.map(c => {
                            if (option.answerId === c.answerId) c.selected = false
                            if (c.answerId === e) c.selected = !c.selected
                            return c
                        }))
                    }
                } else {
                    setValue(value.map(c => {
                        if (c.answerId === e) c.selected = !c.selected
                        return c
                    }))
                }
            }
        }
    }
    // const isSelected = useCallback((val) => {
    //     if (type === 'option') return val
    //     else if (type === 'multi_option') return value.find(e => e.answerId === val.answerId && e.selected)
    //     return false
    // }, [value, type])

    return (
        <div className={`relative px-2 py-1 rounded flex flex-wrap gap-4 items-center ${loading ? 'animate-pulse' : ''}`}>
            {
                [...options].map(c => (
                    edit?
                    <button key={c.answerId} className={`flex items-center text-sm py-1 px-3 rounded-lg ${c.answerLabel ? 'ring-1' :''} ring-gray-400 dark:ring-gray-600 focus:ring-gray-600 ${loading ? 'hover:cursor-wait' : 'hover:cursor-pointer'} ${c.selected ? 'bg-tcolor text-black' : ''}`} onClick={() => chgValue(c.answerId)}>
                        {/* <AnimatePresence>
                            {c.selected &&
                                <motion.span className="overflow-hidden" variants={assessmentOptionAnim} initial="hidden" animate="visible" exit="hidden">
                                    <BsCheck size="20px" className="pr-1 -ml-1 -mb-1" />
                                </motion.span>
                            }
                        </AnimatePresence> */}
                        {c.answerLabel}
                    </button>:
                    <div key={c.answerId} className={`flex items-center text-sm py-1 px-3 rounded-lg ring-1 ring-gray-400 dark:ring-gray-600 focus:ring-gray-600 ${loading ? 'hover:cursor-wait' : 'hover:cursor-pointer'} ${c.selected ? 'bg-tcolor text-black' : ''}`} onClick={() => chgValue(c.answerId)}>
                    {/* <AnimatePresence>
                        {c.selected &&
                            <motion.span className="overflow-hidden" variants={assessmentOptionAnim} initial="hidden" animate="visible" exit="hidden">
                                <BsCheck size="20px" className="pr-1 -ml-1 -mb-1" />
                            </motion.span>
                        }
                    </AnimatePresence> */}
                    {c.answerLabel}
                </div>
                ))
            }
        </div>
    )
}

export default AssessmentOptionField
