import { useTranslation } from "react-i18next"
import { FaExclamationTriangle } from "react-icons/fa"

const ConfirmFinalize = ({ onClose, onFinalize ,disabled=false }) => {

    const { t } = useTranslation()

    return (
        <div className="w-full">
            <div className="flex items-center ml-3 gap-4">
                <div>
                    <FaExclamationTriangle size="28px" className="text-red-500 dark:text-red-400"/>
                </div>
                <div className="font-medium">
                    <div>
                        {t('Are you sure you want to finalize this Care Plan?')}
                    </div>
                    <div>
                        {t('It will be locked and no longer editable.')}
                    </div>
                </div>
            </div>
            <div className="flex justify-between mt-8 mx-2">
                <button disabled={false} className="flex items-center relative py-1 px-4 ml-2 rounded text-red-700 dark:text-red-300 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={onClose}>{t('cancel')}</button>
                <button disabled={disabled} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={onFinalize}>{t('Finalize')}</button>
            </div>
        </div>
    )
}

export default ConfirmFinalize