import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useMemo } from "react"
import * as Yup from 'yup'
import FieldInput from "../../components/snippets/FieldInput"
import API from "../../axios/API"
import { setAlert } from "../../store/theme/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { setRelationshipCount } from "../../store/auth/actions"
import { useTranslation } from "react-i18next"
import axios from "axios"
import ServiceProvider from "../../components/snippets/ServiceProvider"
import CloseSelect from "../../components/snippets/CloseSelect"
import FieldDate from "../../components/snippets/FieldDate"

const EditServiceUsage = ({ details, crId,onClose,caseID }) => {
    // const [formLoading] = useState(false)
    const [editForm] = useState(true)
    const [dataLoaded] = useState(true)
    // const [services,setServices] = useState([])
    const dispatch = useDispatch()
    const [vendorDetails, setVendorDetails] = useState([])
    const [fundingDetails, setFundingDetails] = useState([])
    const [serviceUsageDetails, setServiceUsageDetails] = useState([])
    const [serviceUnnitDetails, setServiceUnitDetails] = useState([])
    const relationshipCount = useSelector(state =>state.auth.relationShipCount)
    const [serviceCategories, setServiceCategories] = useState([])
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')
    let lang = localStorage.getItem('language')
    let serviceUsage = localStorage.getItem('serviceUsage')

    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })

    useEffect(()=>{
        if(serviceUsage === 'true'){
            axios.all([API.get(`organizations/${orgId}/menus/service-usage?lang=${lang}&orgService=0`)]).then(axios.spread((usageResp)=>{
                setServiceUsageDetails(usageResp.data.body.map(c=>({text:c.serviceName,value:c.serviceId})))
                setServiceCategories(usageResp.data.body)
                // setVendorDetails(vendorResp.data.body.map(c=> ({ text: c.name, value: c.value })))
            }))  
        }else{
          axios.all([API.get(`organizations/${orgId}/menus/service-usage?lang=${lang}&orgService=0`),API.get(`organizations/${orgId}/menus/funding?lang=${lang}`),API.get(`/options/organizations/${orgId}/service-units?lang=${lang}`)]).then(axios.spread((usageResp,fundingResp,unitResp)=>{
            setServiceUsageDetails(usageResp.data.body.map(c=>({text:c.serviceName,value:c.serviceId})))
            setServiceCategories(usageResp.data.body)
            // setVendorDetails(vendorResp.data.body.map(c=> ({ text: c.name, value: c.value })))
            setFundingDetails(fundingResp.data.body.map(c => ({ text: c.name, value: c.value })))
            setServiceUnitDetails(unitResp.data.body.map(c => ({ text: c.name, value: c.value })))
          }))
        }
        // API.get(`caregivers/${crId}/services`).then(res=>{
        //     if(res.status === 200){
        //         setServices(res.data.body.map(c => ({ text: c.display, value: c.optionId })))
        //     }
        // })
    },[])

    const formData = useMemo(()=>{
        details.date = details.year + "/" + details.month + "/" + details.day
        details.vendorName = details.vendorId
        return details
    },[details])
    
    useEffect(() => {
        let prod = []
        let fund = []
        let unit = []
        if(serviceUsage === 'true'){
            serviceCategories.forEach((c,i)=> {
                if(details.serviceUsageId === c.serviceId){
                    console.log(c)
                    c.options.forEach(e => (prod.push({text:e.vendorName,value:e.vendorId})))
                    c.fundingOption.forEach(e=>(fund.push({text:e.fundingName,value:e.fundingId})))
                    c.unitOptions.forEach(e=>(unit.push({text:e.unitName,value:e.unitId})))

                }
            })
            setVendorDetails(prod)
            setFundingDetails(fund)
            setServiceUnitDetails(unit)

        }else{
            serviceCategories.forEach((c,i)=> {
                if(details.serviceUsageId === c.serviceId){
                    c.options.forEach(e => (prod.push({text:e.vendorName,value:e.vendorId})))
                }
            })
            setVendorDetails(prod)
        }
    },[serviceCategories, details])
    const profileFormSchema = Yup.object().shape({
        date: Yup.date().required(t("Date is a required field")),
        serviceUsageId: Yup.string().required(t("Service Category is required field")),
        units: Yup.string().validateDescription().min(1, t("Atlest one digit is required")).matches(/^[0-9.]*$/, t("Only Numbers Allowed")).required(t("Units Consumed is required")),
        cost: Yup.string().max(8,t("Upto 8 allowed")).required(t('Unit Cost is required')).nullable(),
        vendorName: Yup.string().validateDescription().required(t("Provider is required")).nullable(),
        fundingId: Yup.string().required(t("Funding Source is required")),
        unitId: Yup.string().required(t("Service Units is required"))
    })

    const profileForm =  useFormik({
        initialValues: formData,
        validateOnMount : true,
        enableReinitialize : true,
        validationSchema : profileFormSchema,
        onSubmit : values =>{
            updateForm()
        }
    })

    const servicecate =(val) =>{
        profileForm.setValues({...profileForm.values,vendorName:null,fundingId:null,unitId:null,serviceUsageId:val})
        if(serviceUsage === 'true'){
            serviceCategories.map((c,i)=> {
                if(c.serviceId === val){
                    setVendorDetails(c.options.map(e => ({text:e.vendorName,value:e.vendorId})))
                    setFundingDetails(c.fundingOption.map(e => ({ text:e.fundingName,value:e.fundingId })))
                    setServiceUnitDetails(c.unitOptions.map(e => ({ text:e.unitName,value:e.unitId })))
                } return null;
            })
        }else{
          serviceCategories.map((c,i)=> {
            if(c.serviceId === val){
                setVendorDetails(c.options.map(e => ({text:e.vendorName,value:e.vendorId})))
                
            } return null;
          })
        }
       }

    const provide =(val)=>{
     if(typeof val === 'string'){
      profileForm.setValues({...profileForm.values,vendorName:val,vendorId:null})
     }else if(typeof val === 'number'){
        profileForm.setValues({...profileForm.values,vendorId:val,vendorName:val})
     }
    }
    const updateForm = ()=>{
        if (profileForm.values.date) {
            let date =  profileForm.values.date;
            date = date?.split('/')
            profileForm.values.day = date[2]
            profileForm.values.month = date[1]
            profileForm.values.year = date[0]
        }
        let details = {
            // pplId: profileForm.values.pplId,
            // serviceUsageId: profileForm.values.serviceUsageId,
            // units: profileForm.values.units,
            // cost: profileForm.values.cost,
            // day: parseInt(profileForm.values.day),
            // month: parseInt(profileForm.values.month),
            // year: parseInt(profileForm.values.year),
            pplServiceUsageId : profileForm.values.pplServiceUsageId,
            pplId: profileForm.values.pplId,
            serviceUsageId: profileForm.values.serviceUsageId,
            units: profileForm.values.units,
            cost: profileForm.values.cost,
            day: profileForm.values.day,
            month: profileForm.values.month,
            year: profileForm.values.year,
            vendorId : profileForm.values.vendorId,
            vendorName : profileForm.values.vendorName,
            fundingId : profileForm.values.fundingId,
            unitId : profileForm.values.unitId,
            details : profileForm.values.details,
            caseId : caseID
        }
        API.patch(`caregivers/${crId}/service-usage?orgId=${orgId}`,details).then(res=>{
            if(res.status === 200){
                dispatch(setAlert({title:t('Success'),subtitle:t('Service usage details edited successfully'),type:'success',active:true}))
                dispatch(setRelationshipCount(relationshipCount+1))
                onClose()
            }
        }).catch(err=>{
            dispatch(setAlert({title:t('Error'),subtitle:t('Something went wrong'),type:'error',active:true}))
            onClose()
            console.log(err)
        })
    }
    return (
        <div className="p-2 w-full text-left">
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-2">
                {/* <FieldInput loading={formLoading} maxDate={new Date()} type="date" label={t("Date")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('date')} fieldProps={profileForm.getFieldProps('date')} fieldHelper={profileForm.getFieldHelpers('date')} edit={editForm} dataLoaded={dataLoaded} />
                <FieldSelect loading={formLoading} label={t("Service")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('serviceUsageId')} fieldProps={profileForm.getFieldProps('serviceUsageId')} fieldHelper={profileForm.getFieldHelpers('serviceUsageId')} edit={editForm} options={serviceUsageDetails} dataLoaded={dataLoaded} />
                <FieldSelect label={t("Vendor")} fieldMeta={profileForm.getFieldMeta('vendorId')} fieldHelper={profileForm.getFieldHelpers('vendorId')} fieldProps={profileForm.getFieldProps('vendorId')} edit={editForm} options={vendorDetails} position="top" dataLoaded={dataLoaded} />
                <FieldInput loading={formLoading}  label={t("Units")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('units')} fieldProps={profileForm.getFieldProps('units')} edit={editForm} dataLoaded={dataLoaded} />
                <FieldInput loading={formLoading}  type ={t("price")} label="Cost" autoComplete='off' fieldMeta={profileForm.getFieldMeta('cost')} fieldProps={profileForm.getFieldProps('cost')} edit={editForm} dataLoaded={dataLoaded} /> */}
                <FieldDate type="date" maxDate={new Date()} label={t("Service Date")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('date')} fieldProps={profileForm.getFieldProps('date')} fieldHelper={profileForm.getFieldHelpers('date')} edit={editForm} dataLoaded={dataLoaded} />
                            <CloseSelect label={t("Service Category")} fieldMeta={profileForm.getFieldMeta('serviceUsageId')} fieldHelper={profileForm.getFieldHelpers('serviceUsageId')} fieldProps={profileForm.getFieldProps('serviceUsageId')} edit={editForm} options={serviceUsageDetails} position="top" dataLoaded={dataLoaded} onChange={(val)=>servicecate(val)} />
                            {/* <FieldSelect label={t("Provider")} fieldMeta={profileForm.getFieldMeta('vendorId')} fieldHelper={profileForm.getFieldHelpers('vendorId')} fieldProps={profileForm.getFieldProps('vendorId')} edit={editForm} options={vendorDetails} position="top" dataLoaded={dataLoaded} /> */}
                            <ServiceProvider label={t("Provider")} fieldMeta={profileForm.getFieldMeta('vendorName')} fieldHelper={profileForm.getFieldHelpers('vendorName')} fieldProps={profileForm.getFieldProps('vendorName')} edit={editForm} options={vendorDetails} position="top" dataLoaded={dataLoaded} onChange={(val)=>provide(val)} />
                            <CloseSelect label={t("Service Units")} fieldMeta={profileForm.getFieldMeta('unitId')} fieldHelper={profileForm.getFieldHelpers('unitId')} fieldProps={profileForm.getFieldProps('unitId')} edit={editForm} options={serviceUnnitDetails} position="top" dataLoaded={dataLoaded} />
                            <FieldInput label={t("Units Consumed")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('units')} fieldProps={profileForm.getFieldProps('units')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput type="price" label={t("Unit Cost $")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('cost')} fieldProps={profileForm.getFieldProps('cost')} edit={editForm} dataLoaded={dataLoaded} />
                            <CloseSelect label={t("Funding Source")} fieldMeta={profileForm.getFieldMeta('fundingId')} fieldHelper={profileForm.getFieldHelpers('fundingId')} fieldProps={profileForm.getFieldProps('fundingId')} edit={editForm} options={fundingDetails} position="top" dataLoaded={dataLoaded} />
                            <FieldInput type="text" label={t("Details")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('details')} fieldProps={profileForm.getFieldProps('details')} edit={editForm} dataLoaded={dataLoaded} />
            </div>
            <div className="flex justify-between mt-8 mx-2">
                <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={onClose}>{t("Cancel")}</button>
                <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Update")}</button>
            </div>
        </div>
    )
}

export default EditServiceUsage