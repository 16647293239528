import { useTranslation } from "react-i18next"
import FieldInput from "../../components/snippets/FieldInput"
import CloseSelect from "../../components/snippets/CloseSelect"
import * as Yup from 'yup'
import { useFormik } from "formik"
import AssessmentOptionField from "../../components/snippets/Assessment/AssessmentOptionField"
import API from '../../axios/API'
import { useState,useEffect } from "react"
import { setAlert } from "../../store/theme/actions"
import { useDispatch } from "react-redux"
import axios from 'axios'
import ServiceInput from "../../components/snippets/ServiceInput"
import AddServiceInput from "../../components/snippets/AddServiceInput"
import AddServiceSelect from "../../components/snippets/AddServiceSelect"

const initialValues = {
    serviceName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    webLink: '',
}




const AddService = ({ loading=false, serviceType, caseId, onAdd, serviceId, categoryId, onAddCategoryId,careplanId,additional=false }) => {
    const { t } = useTranslation() 
    // const [zipCode,setZipcode] = useState()
    const [keywords,setKeywords] = useState([])
    // const [zipEnable, setZipEnable] = useState(false)
    const dispatch = useDispatch()
    const [states, setStates] = useState([])
    let lang= localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))

    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test(t('validPhone'), t("Not a Valid Phone"), (value) => {
            if(value === undefined || value === '') return true
            return (value.replace(/ /g, '').length === 13 || value.replace(/ /g,'').length === 12)
        })
    })
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if(value === undefined || value === '') return true
            let r = /^[a-zA-Z ]*$/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'validateEmail', function () {
        return this.test('validEmail',t("Not valid"), (value) => {
            if(value === undefined || value === '') return true
            let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string,'validateName',function(){
        return this.test(t('onlyAlphabet and numbers are allowed'),t('Only Alphabets and numbers allowed'),(value)=>{
            if(value === undefined || value === '')return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'whiteSpace', function () {
        return this.test(t('whiteSpace'), t("Space not allowed at beginning"), (value) => {
            return value === undefined || value.toString().length === 0 || value.toString()[0] !== ' '
        })
    })
    
    Yup.addMethod(Yup.string,'validateLink',function(){
        return this.test(t('Enter valid link like http://www.google.com'),t('Enter valid link like http://www.google.com'),(value)=>{
            if(value === undefined || value === '')return true
            let r = /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-@:%_~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/g;
            return r.test(value)
        })
    })

    useEffect(()=>{
        API.get(`care-plans/${careplanId}/services/${additional ? 0 : serviceType}/keywords`).then(res=>{
            if(res.status === 200){
                // setZipcode(res.data.body.zipcode)
                setKeywords(res.data.body.keywords.map(c=>({ answerLabel: c, value: c ,answerId:c,selected:false})))
            }
        }).catch(e=>{
            console.log(e)
        })
    },[careplanId, serviceType])

    useEffect(() => {
        if (menuItems !== undefined && menuItems !== null && menuItems !== '') {
            if (menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined) {
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/states?lang=${lang}`).then((statesResp) => {
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
        }else{
            axios.all([API.get(`menus/states?lang=${lang}`)]).then(axios.spread((statesResp) => {
                setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
            }))
        }
    }, [])

    const formSchema = Yup.object().shape({
        serviceName: Yup.string().whiteSpace().required(t("Service Name is a required field")),
        address: Yup.string().whiteSpace().required(t("Address is a required field")),
        city: Yup.string().whiteSpace().required(t("City is a required field")),
        state: Yup.string().validateName().required(t("State is a required field")).nullable(),
        zipCode: Yup.string().length(5,t('ZIP code must be a 5-digit number')).matches(/^[0-9]*$/,t("ZIP Code Field Only Numbers ...")).required(t("ZIP Code is a required field")),
        phone: Yup.string().validatePhoneLength().required(t("Phone is a required field")),
        webLink: Yup.string().validateLink(),
        keyword: Yup.string(),
    })
    const getAddress = (value, field, i) => {
        if (value.length === 5) {
            API.get(`/intake/zones?zipCode=${value}`).then((res) => {
                if (res.status === 200) {
                    // setZipEnable(true)
                    if (field === 'cg') {
                        form.setValues({ ...form.values,state: res.data.body.state, city: res.data.body.city, country: res.data.body.country })
                    }
                }
            })
        }
    }

    const form = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            values.keyword = keywords.filter(c=>c.selected).map(c=>c.answerLabel).join(', ')
            if(values.keyword.length === 0){
                dispatch(setAlert({title:t('Warning'),subtitle:t('Select atleast one keyword to add the Care Plan'),type:'warning',active:true}))
            }else{
                API.post('resources:add-custom',values).then(res=>{
                    if(res.status===200){
                        let address = `${form.values.serviceName},${form.values.address},${form.values.city},${form.values.state},${form.values.city},${form.values.zipCode},${form.values.phone}\n`
                        onAdd([{adhocContent:null,adhocTemplate:null,comment:null,deleted:false,info:address,serviceId:serviceId,selectedServiceId:null,webLink:`${form.values.webLink}`,keyword:form.values.keyword,serviceName:form.values.serviceName,rdbId:res.data.params}])
                        !additional && onAddCategoryId(categoryId)
                    }
                }).catch(err=>{
                    console.log(err)
                })
            }
        }
    })

    return (
        <div className="flex flex-col gap-2 py-2 my-2 px-2 overflow-y-auto">
            <div>
                <AddServiceInput ariaLabel={t('Service Name')} loading={loading} label={t('Service Name')} autoComplete='off' fieldMeta={form.getFieldMeta('serviceName')} fieldProps={form.getFieldProps('serviceName')} edit={true} dataLoaded={true} />
            </div>
            <div>
                <ServiceInput ariaLabel={t('Address')} loading={loading} label={t('Address')} type="textarea" autoComplete='off' fieldMeta={form.getFieldMeta('address')} fieldProps={form.getFieldProps('address')} edit={true} dataLoaded={true} />
            </div>
            <div className="flex gap-2">
            <div className="flex-1">
                    <AddServiceInput ariaLabel={t('ZIP Code')} loading={loading} label={t('ZIP Code')} autoComplete='off' fieldMeta={form.getFieldMeta('zipCode')} fieldProps={form.getFieldProps('zipCode')} edit={true} dataLoaded={true} ml={true} length={'5'} onChange={(ev) => getAddress(ev, 'cg')}/>
                </div>
                <div className="flex-1">
                    <AddServiceInput ariaLabel={t('City')} loading={loading} label={t('City')} autoComplete='off' fieldMeta={form.getFieldMeta('city')} fieldProps={form.getFieldProps('city')} edit={true} dataLoaded={true} />
                </div>
                <div className="flex-1">
                <AddServiceSelect ariaLabel={t('State')} loading={loading} label={t("State")} fieldMeta={form.getFieldMeta('state')} fieldHelper={form.getFieldHelpers('state')} fieldProps={form.getFieldProps('state')} edit={true} options={states} position="top" dataLoaded={true} /> 
                  </div>
              
            </div>
            <div className="flex gap-2">
                <div className="flex-1">
                    <AddServiceInput ariaLabel={t('Phone')} loading={loading} label={t('Phone')} type="phone" autoComplete='off' fieldMeta={form.getFieldMeta('phone')} fieldProps={form.getFieldProps('phone')} edit={true} dataLoaded={true} />
                </div>
                <div className="flex-1">
                    <AddServiceInput ariaLabel={t('Website')} loading={loading} label={t('Website')} autoComplete='off' fieldMeta={form.getFieldMeta('webLink')} fieldProps={form.getFieldProps('webLink')} edit={true} dataLoaded={true} />
                </div>
            </div>
            <div  className="py-2 max-h-[100px] overflow-y-auto">
            <AssessmentOptionField type="multi_option" options={keywords} value={keywords}  edit={true} loading={false} setValue={val => setKeywords(val)}/>
            </div>
            <div className="flex justify-end mr-4 mt-1">
                <button disabled={loading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => form.submitForm()}>{t('Add to Care Plan')}</button>
            </div>
        </div>
    )
}

export default AddService