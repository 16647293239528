import { useFormik } from "formik"
import { useEffect,  useState } from "react"
import { useHistory } from 'react-router-dom';
import  {FaInfoCircle} from 'react-icons/fa'
// import {AiFillPrinter} from 'react-icons/ai'
import FormCard from "../../components/snippets/FormCard"
import { motion } from 'framer-motion'
import { createTheme } from 'react-data-table-component'
import FieldSelect from "../../components/snippets/FieldSelect"
import { datatableTheme } from '../../data/dataTableTheme'
// import { useDispatch, useSelector } from 'react-redux'
import API from '../../axios/API'
import { setAlert } from "../../store/theme/actions"
import { useDispatch } from "react-redux"
import * as Yup from 'yup'
import PageLoader from "../CaseDetails/PageLoader";
import Dialog from "../../components/snippets/Dialog"
import FlowText from "../../components/snippets/FlowText"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { summaryData } from "../../data/dummy/summary";





createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const initialFormValues = {
    // otherUsefullInfo:{
    //     availabilityFamily: '',
    //     formalServices: '',
    //     specialCircumstances: '',
    // }
    summFour: '',
    summOne: '',
    summThree: '',
    summTwo: ''
}

const Summarydetails = ({ assessmentId ,caseId }) => {

    // const [editForm] = useState(false)
    let history = useHistory();
    const [loading, setLoading] = useState(true)
    const [formLoading, setFormLoading] = useState(false)
    const dispatch = useDispatch()
    const [dataLoaded, setDataLoaded] = useState(true)
    const [summary, setSummary] = useState();
    const [questions,setQuestions] = useState([])
    const [answers, setAnswers] = useState([])
    const [quest,setQuest] = useState([])
    const [downloadPopup, setDownloadPopup] = useState(false)
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')
    const [disable,setDisable] = useState(false)
    const [questInfo,setQuestInfo] = useState([])
    const [viewQuestion,setViewQuestion] = useState(false)
    let protocolType = localStorage.getItem('tcareProtocolEnabled')

   
    useEffect(() => {
        setDataLoaded(false)
        profileForm.values.summOne = ''
        profileForm.values.summTwo = ''
        profileForm.values.summThree = ''
        profileForm.values.summFour = ''
        setSummary(summaryData)
        API.get(`organizations/${orgId}/assessments/${assessmentId}/summary`,{
            headers: {
                'Authorization': localStorage.getItem('token'), 
                'Accept': 'application/json'
              }
        }).then(res => {
            if (res.status === 200) {
                setSummary(res.data.body)
                // setLoading(false)
            }
        }).catch(e => {
            console.log(e)
        })
        API.get(`organizations/${orgId}/assessments/${assessmentId}/summaryQuestion`).then(res => {
            if (res.status === 200) {
                setAnswers([])
               res.data.body.question.map((c,i)=>{
                let viewQuestions = c.questInfo !== null && c.questInfo.split('?')
                    questInfo.push({id:i,selected:false,questions:viewQuestions})
                    setQuestInfo(questInfo)
                    c.answers.map((e)=>{
                        if(e.selected){
                            if(i === 0 ){
                                answers.push({questionId : c.questionId,answers:[{answerId : e.answerId}]})
                                profileForm.values.summOne = e.answerId
                            }else if(i===1){
                                answers.push({questionId : c.questionId,answers:[{answerId : e.answerId}]})
                                profileForm.values.summTwo = e.answerId
                            }else if(i===2){
                                answers.push({questionId : c.questionId,answers:[{answerId : e.answerId}]})
                                profileForm.values.summThree = e.answerId
                            }else{
                                answers.push({questionId : c.questionId,answers:[{answerId : e.answerId}]})
                                profileForm.values.summFour = e.answerId
                            }
                        }
                        setAnswers(answers)
                        return e
                    })
                    c.answers = c.answers.map((e)=>({text:e.answerLabel,value:e.answerId}))
                    return c
                })
                setQuest(quest)
                setQuestions(res.data.body)
                setLoading(false)
            }
        }).catch(e => {
            console.log(e)
        }).finally(() => {
            setDataLoaded(true)
            // setLoading(false)
        })
    }, [])// eslint-disable-line

    // useEffect(() => {
    //     // setDataLoaded(false)
     
    // }, [])// eslint-disable-line
    
    const profileFormSchema = Yup.object().shape({
        // otherUsefullInfo: Yup.object({
        //     // availabilityFamily: Yup.string().required(),
        //     // formalServices: Yup.string().required(),
        //     // specialCircumstances: Yup.string().required(),
        // })
        summOne: Yup.string().required(t("required field")).nullable(),
        summThree: Yup.string().required(t("required field")).nullable(),
        summTwo: Yup.string().required(t("required field")).nullable(),
        summFour: Yup.string().required(t("required field")).nullable(),
    })
    const profileForm = useFormik({
        initialValues: initialFormValues,
        validationSchema:profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            setDisable(true)
            setFormLoading(true)
            saveSummary()
            setFormLoading(false)
        }
    })

   
    
    
    const downloadFiles = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/assessments/${assessmentId}/summary`,{
            headers: {
                'Authorization': localStorage.getItem('token'), 
                'Accept': ''
              }
        }).then(res => {
            if (res.status === 200) {
                const linkSource = `data:application/pdf;base64,${res.data.body}`;
                const downloadLink = document.createElement("a");
                const fileName = "TCE-Summary.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                setDownloadPopup(false)
            }else{
                dispatch(setAlert({title:t('Error'),subtitle:t('Something went wrong'),active:true,type:'error'}))
                setDownloadPopup(false)
            }
        }).catch(err=>{
            dispatch(setAlert({title:t('Error'),subtitle:t('Something went wrong'),active:true,type:'error'}))
            setDownloadPopup(false)
        })
    }


    const setFieldValue = (id, value,index) =>{
        quest.map((c,j)=>{
            if(j === index){
                c.answered = true
            }
            return c
        })
        setQuest(quest)
    if(answers.filter((x)=>(x.questionId === id)).length > 0){
        answers.map((c)=>{
            if(c.questionId === id){
                c.answers = [{answerId : value}]
            }
            return c
        })
    }else{
        answers.push({questionId : id,answers:[{answerId : value}]})
    }
    setAnswers(answers)
    }
    const saveSummary = () =>{
        let details = {
            "assessmentId": assessmentId,
            "caseId": caseId,
             questions: answers,
        }
        API.patch(`organizations/${orgId}/saveSummary`,details).then((res)=>{
            if(res.status === 200){
                setDisable(false)
                dispatch(setAlert({title:t('Success'),subtitle:t('Summary Saved successfully'),active:true,type:'success'}))
                history.push(`/casedata/${caseId}`);
            }
        }).catch((err)=>{
            if (err.response.status === 406) {
                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                history.push(`/casedata/${caseId}`)
               
            }
        })
    }

    const informationGenerator = (i)=>{
        setQuestInfo(questInfo.map((c)=>{
            if(c.id === i){
                c.selected = !c.selected
            }
            return c
        }))
        if(questInfo.filter((x)=>x.selected).length > 0){
            setViewQuestion(true)
        }else{
            setViewQuestion(false)
        }
    }

    return (
        <div className={'flex-1'}>
              {
                loading ? <PageLoader/>:
                <div className="pl-3">
                     {/* <div className={'flex flex-1 justify-end mr-8 h-6'}>
                            <div className="flex gap-1 justify-end items-center bg-gradient-to-tr from-tcolor to-blue-400 px-2 py-0.5 rounded text-[15px] cursor-pointer hover:ring-2 hover:ring-offset-2 hover:ring-tcolor hover:ring-offset-gray-200 dark:hover:ring-offset-ldark text-white" onClick={() => setDownloadPopup(true)} >
                                <AiFillPrinter size="12px" /><span>{t("Print")}</span>
                            </div>
                        </div> */}
                      <div className="mt-1 mb-5 pr-8 overflow-y-auto height_less_topbar">
                       {
                           summary.cgStatus !== null ?
                           <FormCard title={t("1. Caregiver Status")}>
                           <div className="grid gap-10 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-2">
                           <div class="flex mb-4 space-x-4">
                                    <div title={summary.cgMsg.caregiverPhaseMsg !== undefined ? summary.cgMsg.caregiverPhaseMsg :''} class="w-1/2 px-7 underline" >{t("Caregiver Phase")}:</div><br></br>
                                     <div class="w-1/2" className="answer-1">
                                     {summary.cgStatus.caregiverPhase !== undefined ? summary.cgStatus.caregiverPhase : ''}
                                     </div>
                                 </div>
                                 <div class="flex mb-4 space-x-4">
                                    <div title={summary.cgMsg.intentionPlaceMsg !== undefined ? summary.cgMsg.intentionPlaceMsg :''} class="w-1/2 px-6 underline" >{t("Intention to Place")}:</div><br></br>
                                     {/* <div class="w-1/2" className="answer-1">
                                    { summary.cgStatus.intentionToPlace}
                                     </div> */}
                                     <div  class="w-1/2">
                                       {
                                            summary.cgStatus.intentionToPlace === "0" ?
                                            <div className="answer-4">{t("No")}</div>:
                                            summary.cgStatus.intentionToPlace === "1" ?
                                            <div className="answer-3">{t("Yes")}</div>:''
                                        }
                                       </div>
                                 </div>
                                 {/* <div class="flex mb-4 space-x-10">
                                    <div title={summary.cgMsg.caregiverMsgType} class="w-1/2 px-7 underline">{t("Caregiver Type")}:</div><br></br>
                                     <div class="w-1/2" className="answer-8">
                                     {summary.cgMsg.caregiverType}
                                     </div>
                                 </div> */}
{/*                                 
                               <FieldInput loading={formLoading} label="Caregiver Phase:" autoComplete='off' fieldMeta={profileForm.getFieldMeta('cgStatus.caregiverPhase')} fieldProps={profileForm.getFieldProps('cgStatus.caregiverPhase')} edit={editForm} dataLoaded={dataLoaded} />
                               <FieldInput loading={formLoading} label="Intention to Place:" autoComplete='off' fieldMeta={profileForm.getFieldMeta('cgStatus.intentionToPlace')} fieldProps={profileForm.getFieldProps('cgStatus.intentionToPlace')} edit={editForm} dataLoaded={dataLoaded} />
                               <FieldInput loading={formLoading} label="Caregiver Type:" autoComplete='off' fieldMeta={profileForm.getFieldMeta('cgStatus.phaseId')} fieldProps={profileForm.getFieldProps('cgStatus.phaseId')} edit={editForm} dataLoaded={dataLoaded} /> */}
                           </div>
                       </FormCard> :''
                       }
                    <div>
                        <FormCard>
                            <div class="flex mb-4 flex-row ... ">
                                <div class="w-1/3 px-2">
                                <FormCard title={t("2. Caregiver Needs")}>&nbsp;
                            <motion.div>
                                {/* <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-1"> */}
                                    <div class="flex mb-4">
                                        <div title={summary.cgEmotionalNeedsMsg.cgRelationshipBurdenMsg !== undefined ? summary.cgEmotionalNeedsMsg.cgRelationshipBurdenMsg :''} class="w-1/2 underline">{t("Relationship Burden")}:</div>
                                        <div class="w-1/2 px-8">
                                            {
                                                summary.cgEmotionalNeeds.cgRelationBurden === "1" ? <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgRelationBurden === "2" ? <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgRelationBurden === "3"? <div className=" answer-3">{t("High")}</div> : ''
                                            }
                                        </div>
                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgObjectiveBurdenMsg !== undefined ? summary.cgEmotionalNeedsMsg.cgObjectiveBurdenMsg :''} class="w-1/2 underline">{t("Objective Burden")}:</div>
                                        <div class="w-1/2 px-8">
                                            {
                                                summary.cgEmotionalNeeds.cgObjectiveBurden === "1" ? <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgObjectiveBurden === "2" ? <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgObjectiveBurden === "3" ? <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>

                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgStressBurdenMsg !== undefined ? summary.cgEmotionalNeedsMsg.cgStressBurdenMsg :''} class="w-1/2 underline">{t("Stress Burden")}:</div>
                                        <div class="w-1/2 px-8">
                                            {
                                                summary.cgEmotionalNeeds.cgStressBurden === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgStressBurden === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgStressBurden === "3" ?
                                                            <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>

                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgUpliftMsg !== undefined ? summary.cgEmotionalNeedsMsg.cgUpliftMsg :''} class="w-1/2 underline">{t("Uplifts")}:</div>
                                        <div class="w-1/2 px-8">
                                            {
                                                summary.cgEmotionalNeeds.cgUplifts === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgUplifts === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgUplifts === "3" ?
                                                            <div  className="answer-3" >{t("High")}</div> : ''
                                            }
                                        </div>
                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgDepressionMsg !== undefined ? summary.cgEmotionalNeedsMsg.cgDepressionMsg :''} class="w-1/2 underline">{t("Depression")}:</div>
                                        <div class="w-1/2 px-8">
                                            {
                                                summary.cgEmotionalNeeds.cgDepression === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgDepression === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgDepression === "3" ?
                                                            <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>
                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgIdentityDiscrepMsg !== undefined  ? summary.cgEmotionalNeedsMsg.cgIdentityDiscrepMsg :''} class="w-1/2 underline">{t("Identity Discrepancy")}:</div>
                                        <div class="w-1/2 px-8">
                                            {
                                                summary.cgEmotionalNeeds.cgIdentityDiscrep === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgIdentityDiscrep === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgIdentityDiscrep === "3" ?
                                                            <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>
                                        
                                    </div>
                                    <div class="flex mb-4">
                                    <div class="w-1/2"></div>
                                        <div class="w-1/2 px-8">
                                            {
                                              
                                            }
                                        </div>
                                        
                                    </div>
                                {/* </div> */}
                            </motion.div>
                        </FormCard>
                     </div>
                                <div class="w-1/3 px-2">
                                <FormCard title={t("3. Care Receiver Needs")}>&nbsp;
                            {/* <div className="grid gap-1 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-1"> */}
                                {protocolType === 'true'&& summary.crNeeds.crAdl === 0 ? '':<div class="flex mb-4">
                                <div title={summary.crNeedsMsg.crAdlMsg !== undefined ? summary.crNeedsMsg.crAdlMsg :''} class="w-1/2 underline">{("ADL")}:</div>
                                    <div class="w-1/2 px-8">
                                        {
                                            summary.crNeeds.crAdl === 1 ?
                                                <div className="answer-4">{t("Low")}</div> :
                                                summary.crNeeds.crAdl === 2 ?
                                                    <div className="answer-2">{t("Medium")}</div> :
                                                    summary.crNeeds.crAdl === 3 ?
                                                        <div className="answer-3">{t("High")}</div> : ''
                                        }
                                    </div>
                                </div>}
                               {protocolType === 'true'&& summary.crNeeds.crIadl === 0 ? '': <div class="flex mb-4">
                                <div title={summary.crNeedsMsg.crIadlMsg !== undefined ? summary.crNeedsMsg.crIadlMsg :''} class="w-1/2 underline">{("IADL")}:</div>
                                    <div class="w-1/2 px-8">
                                        {
                                            summary.crNeeds.crIadl === 1 ?
                                                <div className="answer-4">{t("Low")}</div> :
                                                summary.crNeeds.crIadl === 2 ?
                                                    <div className="answer-2">{t("Medium")}</div> :
                                                    summary.crNeeds.crIadl === 3 ?
                                                        <div className="answer-3">{t("High")}</div> : ''
                                        }
                                    </div>
                                </div>}
                                <div class="flex mb-4">
                                <div title={summary.crNeedsMsg.crProblemBehaviourMsg !== undefined ? summary.crNeedsMsg.crProblemBehaviourMsg :''} class="w-1/2 underline">{t("Problem Behaviors")}:</div>
                                    <div class="w-1/2 px-8">
                                        {
                                            summary.crNeeds.crProblemBehavior === 1 ?
                                                <div className="answer-4">{t("Low")}</div> :
                                                summary.crNeeds.crProblemBehavior === 2 ?
                                                    <div className="answer-2">{t("Medium")}</div> :
                                                    summary.crNeeds.crProblemBehavior === 3 ?
                                                        <div className="answer-3">{t("High")}</div> : ''
                                        }
                                    </div>
                                </div>
                                {protocolType === 'true'&& !summary.crNeeds.memoryIssue ? '':<div class="flex mb-4">
                                <div class="w-1/2 underline">{t("Memory")}:</div><br></br>
                                    <div class="w-1/2 px-8" ><div className="answer-6">
                                    {
                                            summary.crNeeds.memoryIssue
                                        }
                                    </div>
                                       
                                    </div>
                                </div>}
                                <div class="flex mb-4">
                                <div class="w-1/2"></div><br></br>
                                    <div class="w-1/2 px-8">
                                        {
                                           
                                        }
                                    </div>
                                </div>
                                <div class="flex mb-4">
                                <div class="w-1/2"></div><br></br>
                                    <div class="w-1/2 px-8">
                                        {
                                            
                                        }
                                    </div>
                                </div>
                                <div class="flex mb-4">
                                <div class="w-1/2"></div><br></br>
                                    <div class="w-1/2 px-8">
                                        {
                                            
                                        }
                                    </div>
                                </div>
                            {/* </div> */}
                        </FormCard>

                                </div>
                            <div class="w-1/3 px-2">
                                <FormCard title={t("4. Caregiver Obligations")}>&nbsp;
                                  {/* <div className="grid gap-1 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-1"> */}
                                  <div class="flex mb-4 justify-between">
                                     <div class="underline">{t("Employment")}:</div>
                                      <div><div className="answer-8" >{summary.employment}</div>
                                      </div>
                                  </div>
                                  <div class="flex mb-4 justify-between">
                                     <div class="underline">{t("Personal Care Hours")}:</div>
                                      <div><div  className="answer-1"> {summary.cgObligations.personalCareHrs}</div>
                                       
                                      </div>
                                  </div>
                                  <div class="flex mb-4 justify-between">
                                     <div class="underline">{t("Household Care Hours")}:</div>
                                      <div><div className="answer-1">{summary.cgObligations.householdCareHrs}</div>
                                      </div>
                                  </div>
                                  <div class="flex mb-4 justify-between">
                                     <div class="underline">{t("Other Assistance Hours")}:</div>
                                      <div><div className="answer-1"> {summary.cgObligations.otherAssistanceHrs}</div>
                                       
                                      </div>
                                  </div>
                                  <div class="flex mb-4 justify-between">
                                     <div class="underline">{t("Total Dependents")}:</div>
                                      <div><div className="answer-1"> {summary.cgObligations.totalDependents}</div>
                                       
                                      </div>
                                  </div>
                                  <div class="flex mb-4 justify-between">
                                     <div class="w-1/2"></div><br></br>
                                      <div>
                                        {
                                           
                                        }
                                      </div>
                                  </div>
                             {/* </div> */}
                                </FormCard>
                                </div>
                            </div>                      
                        </FormCard>

                    </div>
                        {/* <FormCard title="Other Useful Information" >
                            <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-1">
                                <div>
                                    <h1>Availability of family, friends, neighbors:</h1>
                                    <FieldInput type="textarea" fieldMeta={profileForm.getFieldMeta('otherUsefullInfo.availabilityFamily')} fieldProps={profileForm.getFieldProps('otherUsefullInfo.availabilityFamily')} edit={true} dataLoaded={dataLoaded} />
                                </div>
                                <div>
                                    <h1>Formal Services currently in place:</h1>
                                    <FieldInput type="textarea" fieldMeta={profileForm.getFieldMeta('otherUsefullInfo.formalServices')} fieldProps={profileForm.getFieldProps('otherUsefullInfo.formalServices')} edit={true} dataLoaded={dataLoaded} />
                                </div>
                                <div>
                                    <h1>Special circumstances:</h1>
                                    <FieldInput type="textarea" fieldMeta={profileForm.getFieldMeta('otherUsefullInfo.specialCircumstances')} fieldProps={profileForm.getFieldProps('otherUsefullInfo.specialCircumstances')} edit={true} dataLoaded={dataLoaded} />
                                </div>
                            </div>
                        </FormCard> */}
                        <FormCard title={t("Questionnaire")}>
                        {questions.question.map((c, i)=>{
                            return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 form-wrap py-4'}`}>
                            <div className={`flex flex-1 justify-start items-center`}>
                                <div className="text-sm mr-2 ml-2">
                                    {c.questionLabel}
                                </div><span className="mr-2 ml-2" style={{marginRight:'100px'}} onClick={()=>informationGenerator(i)}><FaInfoCircle size="20px"/></span>
                            </div>
                            {/* {
                                viewQuestion && questInfo.selectedId === i && <div className="mt-4 pb-3">
                                <ul style={{listStyle:'none !important'}}>
                                   {
                                       questInfo.questions.map((c)=>{
                                           return <li>
                                               {c !== null && c !== "" && `${c} ?`}
                                           </li>
                                       })
                                   }        
                               </ul>
                           </div>
                            } */}
                            <div className="mt-4 pb-3">
                                <ul style={{listStyle:'none !important'}}>

                            {
                                viewQuestion && questInfo.map((c,j)=>{
                                    return c.selected && j=== i && c.questions.map((e)=>{
                                        return <li>
                                        {e !== null && e !== "" && `${e} ?`}
                                    </li>
                                    })
                                })
                            }
                                </ul>
                            </div>
                            <div className="mt-4 pb-3">
                            {/* <FieldSelect label="" fieldMeta={profileForm.getFieldMeta(ans[i].value)} fieldProps={profileForm.getFieldProps(ans[i].value)} fieldHelper={profileForm.getFieldHelpers(ans[i].value)} options={c.answers} onChange={(ev) => setFieldValue(c.questionId,ev,i)} edit={true} position="bottom" dataLoaded={dataLoaded} /> */}
                            { i === 0 && 
                            <FieldSelect ariaLabel={c.questionLabel+" Select From the dropdown list "} label="" fieldMeta={profileForm.getFieldMeta('summOne')} fieldProps={profileForm.getFieldProps('summOne')} fieldHelper={profileForm.getFieldHelpers('summOne')} options={c.answers} onChange={(ev) => setFieldValue(c.questionId,ev,i)} edit={true} position="bottom" dataLoaded={dataLoaded} />}
                             { i === 1 && 
                            <FieldSelect ariaLabel={c.questionLabel+" Select From the dropdown list "} label="" fieldMeta={profileForm.getFieldMeta('summTwo')} fieldProps={profileForm.getFieldProps('summTwo')} fieldHelper={profileForm.getFieldHelpers('summTwo')} options={c.answers} onChange={(ev) => setFieldValue(c.questionId,ev,i)} edit={true} position="bottom" dataLoaded={dataLoaded} />}
                             { i === 2 && 
                            <FieldSelect ariaLabel={c.questionLabel+" Select From the dropdown list "} label="" fieldMeta={profileForm.getFieldMeta('summThree')} fieldProps={profileForm.getFieldProps('summThree')} fieldHelper={profileForm.getFieldHelpers('summThree')} options={c.answers} onChange={(ev) => setFieldValue(c.questionId,ev,i)} edit={true} position="bottom" dataLoaded={dataLoaded} />}
                             { i === 3 && 
                            <FieldSelect ariaLabel={c.questionLabel+" Select From the dropdown list "} label="" fieldMeta={profileForm.getFieldMeta('summFour')} fieldProps={profileForm.getFieldProps('summFour')} fieldHelper={profileForm.getFieldHelpers('summFour')} options={c.answers} onChange={(ev) => setFieldValue(c.questionId,ev,i)} edit={true} position="bottom" dataLoaded={dataLoaded} />}
                            </div>
                        </motion.div>
                        })}
                        </FormCard>
                      
                       
                    </div>
                    <div className="flex justify-center mt-7 mx-5">
                            <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700 hover:bg-opacity-80 text-white" onClick={()=>history.push(`/casedata/${caseId}`)}>{t("Cancel")}</button>
                            <button disabled={disable} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Save Summary")}</button>
                        </div>
                </div>
                  
            }
            {
                createPortal(<Dialog title={t("Download File")} showDialog={downloadPopup} onClose={() => setDownloadPopup(false)}>
                    <div className="py-2 w-full">
                        <div className="px-4 mt-2">
                            <FlowText text={t("Are you sure you want to download this summary form?")} />
                        </div>
                        <div className="flex justify-between mt-8 mx-2">
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setDownloadPopup(false)}>{t("No")}</button>
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => downloadFiles()}>{t("Yes")}</button>
                        </div>
                    </div>
                </Dialog>, document.body)
            }
        </div>
           
    )
}

export default Summarydetails