import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import * as Yup from 'yup'
import { setAlert } from "../../store/theme/actions"
import API from '../../axios/API'
import FormCard from "../../components/snippets/FormCard"
import FieldInput from '../../components/snippets/FieldInput'
import { datatableTheme } from '../../data/dataTableTheme'
import DataTable, { createTheme } from 'react-data-table-component'
import { FaEdit, FaTrash } from "react-icons/fa"
import Dialog from "../../components/snippets/Dialog"
import { useSelector } from "react-redux"
import { setNotesAction } from "../../store/auth/actions"
import DatatableLoader from "../../components/snippets/DatatableLoader"
import { createPortal } from "react-dom"
import FlowText  from "../../components/snippets/FlowText"
import { useTranslation } from "react-i18next"
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import RemindersDialog from "../Dialogs/RemindersDialog"
import Switch from "../../components/snippets/Switch"
import CampaignInput from "../../components/snippets/CampaignInput"
import AddFormButton from "../../components/snippets/AddFormButton"

const initialValues = {
    date:'',
    description: ''
}

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)



const Reminders = ({ props,CmId }) => {

    const [tableLoading, setTableLoading] = useState(false)
    const [editForm, setEditForm] = useState(false)
    const [dataLoaded] = useState(true)
    const [notes, setNotes] = useState([])
    const [editData, setEditData] = useState()
    const dispatch = useDispatch()
    const [notesModal, setNotesModal] = useState(false)
    const { themeMode } = useSelector(state => state.theme)
    const [noteAction,setNoteAction] = useState(0)
    const notesAction = useSelector(state =>state.auth.notesAction)
    const userDetails = useSelector(state=>state.auth.userDetails)
    const [deletePopup,setDeletePopup] = useState(false)
    const [notesId,setNotesId] = useState()
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')
    const [page, setPage] = useState(1)
    const organization = useSelector(state => state.auth.organization)
    const [action,setaction] = useState(false)

    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    
    useEffect(() => {
        setTableLoading(true)
        if((userDetails.orgId === parseInt(organization) && (userDetails.parentRole.length === 1 && userDetails.parentRole.includes('screener'))) || (userDetails.orgId !== parseInt(organization) && (userDetails.childRole.length === 1 && userDetails.childRole.includes('dynamic_scr')))){
            setaction(true)
        }
        API.get(`${props}/caseReminders`).then(res => {
            if (res.status === 200) {
                setNotes(res.data)
                setTableLoading(false)
            }
        }).catch(e => {
            console.log(e)
        }).finally()
    }, [notesAction, noteAction, props])// eslint-disable-line

    const notesFormSchema = Yup.object().shape({
        date: Yup.string().required(t("Date is a required field")),
        description: Yup.string().validateDescription().required(t("Reminder is a required field")),
    })

    const notesForm = useFormik({
        initialValues: initialValues,
        validationSchema: notesFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            addNotes()
        }
    })

    const addNotes = () => {
        let details =
        {
        "organizationId":orgId,
        "caseId":props,
        "reminderDate":notesForm.values.date,
        "activeIndicator":true,
        "notes":notesForm.values.description
        }
        API.post(`createCaseReminder`, details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t("Reminder Created successfully"), type: "success", active: true }))
                setEditForm(false)
                notesForm.resetForm()
                dispatch(setNotesAction(notesAction + 1))
                setNoteAction(noteAction+1)
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            } else {

            }
        }).catch(e => {
            console.log(e)
        }).finally(() => {
            notesForm.resetForm()
        })
    }

    const setActive = (value,id) => {
        let details = {
            "organizationId":orgId,
            "caseId":props,
            "activeIndicator":value,
            "reminderId":id
          }
          API.patch(`/caseReminderActivate`, details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: res.data.params, type: "success", active: true }))
                dispatch(setNotesAction(notesAction+1))
            }
        }).catch(e => {
            console.log(e)
        })
    }

    const toggleFormEdit = () => {
        if (editForm) notesForm.submitForm()
        if (!editForm) setEditForm(!editForm)
    }
    const toggleFormCancel =()=>{
        setEditForm(false)
        notesForm.resetForm()
    }

  

    const columns = [
        {
            name: t('Modified Date'),
            selector: row => `${modifiedAt(row.modifiedAt)}`,
            width: '200px'
        },
        {
            name: t('Created By'),
            selector: 'createdBy',
            width: '200px'
        },
        {
            name: t('Reminder Date'),
            selector: row => <Moment format="MMM D,YYYY">{row.reminderDate}</Moment>,
            width: '200px'
        },
        {
            name: t('Status'),
            selector: row =>{return <Switch value={row.activeIndicator}  setValue={value =>(row.createdById === userDetails.caremanagerId) ? setActive(value,row.reminderId):((row.activeIndicator === true) && (CmId === userDetails.caremanagerId)) ? setActive(value,row.reminderId):((organization && userDetails && userDetails.orgId === parseInt(organization)&& userDetails.role.includes('admin')) || (organization && userDetails && userDetails.orgId !== parseInt(organization)&& userDetails.role.includes('dynamic_admin'))) ? setActive(value,row.reminderId): (userDetails.orgId === parseInt(organization) && (userDetails.role.includes('caremanager') || (userDetails.role.includes('referrer')))) ? setActive(value,row.reminderId) : (userDetails.orgId !== parseInt(organization) && (userDetails.role.includes('dynamic_cm') || (userDetails.role.includes('dynamic_ref')))) ? setActive(value,row.reminderId) : '' } />} ,
            width: '130px'
        },
        {
            width: '100px',
             cell: columns => <>
             <div style={{ margin: "-85px" }}>{columns.activeIndicator ? t('Active') : t('InActive')}</div>
            </>
        },
        
        {
            name: t('Reminder'),
            selector:  row => {return(<div style={{wordWrap:'break-word',padding:'10px',whiteSpace:'normal',width:'auto'}}>{row.notes}</div>)},
            width: '250px'
        },
        {
            name: t('Action'), button: true, width:'100px',omit: action, cell: row =>  <>
               { (row.createdById === userDetails.caremanagerId)  ? <button onClick={() => openNotesDialog(row)}>{(userDetails.role[0] === 'screener' && organization && userDetails && userDetails.orgId === parseInt(organization))?'':<FaEdit title={t("Edit")} style={{marginLeft:'10',marginRight:'10'}} size="20px" className="text-gray-600 dark:text-gray-300" />}</button>:''}&nbsp;
                {((organization && userDetails && userDetails.orgId === parseInt(organization)&& userDetails.role.includes('admin')) || (row.createdById === userDetails.caremanagerId) || (organization && userDetails && userDetails.orgId !== parseInt(organization)&& userDetails.role.includes('dynamic_admin')))? <button onClick={() =>  openDeletePopup(row)}><FaTrash  title={t("Delete")} style={{marginLeft:'10',marginRight:'10'}} size="20px" className="text-gray-600 dark:text-gray-300" /></button>:''}
            </>,
        }
    ]

    const modifiedAt =(value) => {
        if(value !== null && value){
            let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss')+ ' ' +tzabbr
        //     let followupdate = new Date(value)
        // return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
        }else{
            return ''
        }
     }
    //  const ReminderAt =(value) => {
    //     if(value !== null && value){
    //         let timestamp = new Date(value).getTime();
    //     let tz = moment.tz.guess()
    //     let tzabbr = moment.tz.zone(tz).abbr(timestamp)
    //         let followupdate = new Date(value)
    //     return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)}`
    //     }else{
    //         return ''
    //     }
    //  }

    const openNotesDialog = (c) => {
        if (c !== undefined)
            setEditData({caseId:props,notes:c})
        setNotesModal(true)
    }

    const deleteNotes = () => {
        let details ={
        "organizationId":orgId,
        "caseId":props,
        "activeIndicator":false,
        "reminderId":notesId.reminderId
    }
        API.patch(`/deleteCaseReminder`,details).then(res => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('Reminder deleted successfully'), type: 'success', active: true }))
                setNoteAction(noteAction+1)
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            } else {

            }
        }).catch(e => {
            console.log(e)
        }).finally(()=>setDeletePopup(false))
    }
    const openDeletePopup = (values)=>{
        setNotesId(values)
        setDeletePopup(true)
    }
    // useEffect(() => {
    //     const element = document.querySelectorAll('[role="table"]');
    //     console.log(element)
    //     if (element) {
    //         element.forEach((e) => {
    //             e.setAttribute('role','none')
    //         })
    //     }
    // })
    return (
        <div>
            {(userDetails.parentRole.length === 1 && userDetails.parentRole.includes('screener') && userDetails.orgId === parseInt(organization) || (userDetails.childRole.length === 1 && userDetails.childRole.includes('dynamic_scr')) && userDetails.orgId !== parseInt(organization))?'':<FormCard title={t("Reminders")}>
                <AddFormButton injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleFormEdit} handleCancel={toggleFormCancel}/>
                    <div style={{width:'200px'}}>
                <FieldInput ariaLabel={'Reminder Date'} type="date" minDate={new Date()} label={t("Reminder Date")} autoComplete='off' fieldMeta={notesForm.getFieldMeta('date')} fieldProps={notesForm.getFieldProps('date')} fieldHelper={notesForm.getFieldHelpers('date')} edit={editForm} dataLoaded={dataLoaded} />
                    </div><br></br>
                <CampaignInput ariaLabel={'Type a reminder'} type="textarea" label={t("Type a reminder")} fieldMeta={notesForm.getFieldMeta('description')} fieldProps={notesForm.getFieldProps('description')} edit={editForm} dataLoaded={dataLoaded} />     
            </FormCard>}
            {

                    <FormCard title={t("Reminders List")}>
                        {
                            tableLoading ?
                                <DatatableLoader />
                                :
                                notes.length>0?
                                <DataTable injectClass="mt-12"
                                    columns={columns}
                                    data={notes} noHeader
                                    theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"}
                                    noDataComponent={t('There are no records to display')}
                                    pagination
                                    onChangePage={val => setPage(val)} paginationDefaultPage={page}
                                    paginationComponentOptions={{ rowsPerPageText: 'Items per page', rangeSeparatorText: 'of',
                                     noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}/>:
                                     <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>
                                    }
                    </FormCard> 
            }
            {
                createPortal(<Dialog title={t("Update Reminder")} showDialog={notesModal} onClose={() => setNotesModal(false)}>
                    <RemindersDialog onClose={() => setNotesModal(false)} props={editData} type={'update'} />
                </Dialog>, document.body)
            }
            {
                createPortal(<Dialog title={t("Delete Reminder")} showDialog={deletePopup} onClose={() => setDeletePopup(false)}>
                     <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Are you sure you want to delete this reminder")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setDeletePopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => deleteNotes()}>{t("Yes")}</button>
                    </div>
                </div>
                </Dialog>, document.body)
            }
        </div>
    )
}

export default Reminders