import { useMemo} from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

const FieldSupportDatePicker = ({value, setValue, showTime=false, maxDate=null, minDate=null,showYear=false,disabled=false ,ariaLabel=''}) => {

    const startDate = useMemo(() => {
        let t = new Date(value)
        if(isNaN(t.getDate())) return new Date()
        return new Date(value)
    }, [value])
    const years = useMemo(() => {
        let y = []
        let d = new Date()
        let year = d.getFullYear() + 50
        for(let j = 1900; j <= year; j++) {
            y.push(j)
        }
        return y
    }, [])
   
    return (
        <div className='daref'>
    
        {
            showYear ? <DatePicker inline
            selected={startDate}
            onChange={(date) => setValue(date)}
            showYearPicker
            dateFormat="yyyy"
            readOnly={false}
          /> : <DatePicker inline showTimeSelect={showTime} maxDate={maxDate} minDate={minDate} 
          renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
          }) => (
              <div className="flex justify-between mx-4">
                  <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      {"<"}
                  </button>
                  <select aria-label='Year'
                      value={date.getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                  >
                      {years.map((option) => (
                          <option key={option} value={option}>
                              {option}
                          </option>
                      ))}
                  </select>

                  <select aria-label='Month'
                      value={months[date.getMonth()]}
                      onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                      }
                  >
                      {months.map((option) => (
                          <option key={option} value={option}>
                              {option}
                          </option>
                      ))}
                  </select>

                  <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {">"}
                  </button>
              </div>
          )}
          selected={startDate}
          showYearPicker ={showYear}
          onChange={date => setValue(date)}
          disabledKeyboardNavigation
      />
        }
        </div>
    )
}

export default FieldSupportDatePicker