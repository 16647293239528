import { useFormik } from "formik"
import { useState, useEffect,useLayoutEffect } from "react"
import * as Yup from 'yup'
import { useMemo } from "react"
import FavFormButton from "../../components/snippets/FavFormButton"
import FieldInput from "../../components/snippets/FieldInput"
import FieldSelect from "../../components/snippets/FieldSelect"
import FormCard from "../../components/snippets/FormCard"

import API from '../../axios/API'
import { useHistory } from "react-router-dom"
import { FaArrowLeft } from 'react-icons/fa'
import FieldCheckbox from '../../components/snippets/FieldCheckbox'
import { useDispatch ,useSelector} from "react-redux"
import { setAlert } from '../../store/theme/actions'
import { useTranslation } from 'react-i18next'

const select = ['Active', 'InActive'].map(c => ({ text: c, value: c }))

const initialFormValues = {
    organizationName: '',
    active: '',
    sicCd: '',
    phone: '',
    licenses: '',
    orgCode: '',
    licenseExpirationDate: '',
    organizationAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
    },
    areaCode: '',
    activeTimeoutInMs: '',
    inactiveTimeoutInMs: '',
    loginAttempts: '',
    lockoutTimeInMs: '',
    passwordExpirationAge: '',
    followupDays: '',
    militaryServiceActivated: false,
    iddServiceActivated: false,
    apiServiceActivated: false,
    reportServiceActivated: false,
    chatbotServiceActivated: false,
    schedulingServiceActivated: false,
    telephonyServiceActivated: false,
    orgUploadsActivated: false,
    specializedQuestion: false,
    resourceDatabaseApi: false,
    filteredRdb: false,
    cgIntake: false,
    caregiverEmail: false,
    caremanagerEmail: false,
    rescreenActivated: false,
    zipCodeEnableCR: false
}



const Addorganization = () => {

    let history = useHistory()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [editForm, setEditForm] = useState(true)
    const [formLoading, setFormLoading] = useState(false)
    const [states, setStates] = useState([])
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [autoReminder,setAutoReminder] = useState(false)
    const [followup,setFollowup]  =  useState(false)
    const [followDays,setFollowDays] = useState()
    const [remind1,setRemind1] = useState()
    const [remind2,setRemind2] = useState()
    const [protocolEnable,setProtocolEnable] = useState(false)
    let lang = localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))
    const userDetails = useSelector(state => state.auth.userDetails)
    const [protocolValue,setProtocolValue] = useState([])
    const [protocolOption,setProtocolOption] = useState([])

    const formData = useMemo(() => {
        setDataLoaded(true)
        initialFormValues.protocol = protocolValue
        return { ...initialFormValues, }
    }, [protocolValue])

    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test('validPhone',t("Not a Valid Phone"), (value) => {
            if (value === undefined || value === '') return true
            return (value.replace(/ /g, '').length === 12)
        })
    })
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test('onlyAlphabet', t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[a-zA-Z ]*$/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'onlyAlphaNumeric', function () {
        return this.test('only AlphaNumeric', t("only AlphaNumeric Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[A-Za-z0-9_]*$/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'validateEmail', function () {
        return this.test('validEmail', t("Not valid"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'onlyNumbers', function () {
        return this.test('onlynubmers', t("Only Numbers Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[0-9]*$/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string,'validateName',function(){
        return this.test(t('onlyAlphabet and numbers are allowed'),t('Only Alphabets and numbers allowed'),(value)=>{
            if(value === undefined || value === '')return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    useEffect(() => {
        if(menuItems !== undefined && menuItems !== null && menuItems !== ''){
            if(menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined){
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            }else{
                API.get(`menus/states?lang=${lang}`).then((statesResp)=>{
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            API.get(`/menus/protocol`).then((protocolResp)=>{
                    initialFormValues.protocol = protocolResp.data.body.map(c => ({ protocolname: c.name, protocolId: c.value, active: false }))
                    setProtocolValue(protocolResp.data.body.map(c => ({ protocolName: c.name, protocolId: c.value, active: false })))
                    setProtocolOption(protocolResp.data.body.map(c => ({ text: c.name, value: c.value })))
                })

        }else{
        API.get(`/menus/states?lang=${lang}`).then(res => {
            setStates(res.data.body.map(c => ({ text: c.name, value: c.key })))
        }).catch(e => {
            // console.log(e);
        }).finally()
    }
    }, [])// eslint-disable-line


    const profileFormSchema = Yup.object().shape({
        organizationName: Yup.string().validateName().required(t("Organizationname is required")),
        active: Yup.string().required(t("Active is required")),
        sicCd: Yup.string().length(4, t("SicCode number must be exactly 4 digits")).matches( /^[0-9]*$/,t("SicCode number must be exactly 4 digits")),
        phone: Yup.string().validatePhoneLength().required(t("MobileNumber is required")),
        licenses: Yup.string().onlyNumbers(),
        orgCode: Yup.string().min(1,t("orgCode should be min 1 character.")).max(24,t("orgCode should be max 24 character")).validateDescription().onlyAlphaNumeric().required(t("orgCode is a required field")),
        licenseExpirationDate: Yup.string().required(t("licenseexpiration is required")),
        organizationAddress: Yup.object({
            addressLine1: Yup.string().validateDescription(),
            addressLine2: Yup.string(),
            city: Yup.string().trim(t('Remove Trailing WhiteSpace')).strict().onlyAlphabet().min(2,t("min 2 characters")),
            state: Yup.string().required(t("State is a required field")),
            country: Yup.string().onlyAlphabet().max(99, t("Country code must be exactly 2 characters")),
            postalCode: Yup.string().matches(/^[0-9]*$/,t("Postal code should be Numbers")).required(t("Postal Code is a required field")),
        }),
        areaCode: Yup.string().length(4, t("Area code  atmost 4 digits")).matches(/^[0-9]*$/,t("Area code only number")),
        activeTimeoutInMs: Yup.string().onlyNumbers().matches(/^[0-9]*$/).required(t("ActiveTimeout is required")),
        inactiveTimeoutInMs: Yup.string().onlyNumbers().matches(/^[0-9]*$/).required(t("InActiveTimeout is required")),
        loginAttempts: Yup.string().onlyNumbers().matches(/^[0-9]*$/).required(t("LoginAttempts is required")),
        lockoutTimeInMs: Yup.string().onlyNumbers().matches(/^[0-9]*$/).required(t("LockoutTimeInms is required")),
        passwordExpirationAge: Yup.number().required(t("passwordExpiration is required")),
        followupDays: Yup.string().onlyNumbers(),
        reminder1 : followup ? Yup.number().max(followDays, t("Value should be lesser than followupDays")) : Yup.number().max(90,'Value should be Max 90 days'),
        reminder2 : followup ? Yup.number().min(remind1,t("Value should be greater than reminder1")).max(followDays, t("Value should be lesser than followupDays and should be greater than reminder 1")) : Yup.number().min(remind1,t("Value should be greater than reminder1")),
        reminder3 : followup ? Yup.number().min(remind2,t("Value should be greater than reminder2")).max(followDays, t("Value should be lesser than followupDays and should be greater than reminder 2")) : Yup.number().min(remind2,t("Value should be greater than reminder2")),
    })

    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            setFormLoading(true)
            submitForm()
            setFormLoading(false)
        }
    })

    useLayoutEffect(() => {
        if(profileForm.values.followupDays && profileForm.values.followupDays !== undefined && profileForm.values.followupDays !== 0 && profileForm.values.followupDays !== ''){
            setFollowDays(parseInt(profileForm.values.followupDays))
            profileForm.values.reminder1 = ''
            profileForm.values.reminder2 = ''
            profileForm.values.reminder3 = ''
            setFollowup(true)    
        }else{
            setFollowDays(parseInt(profileForm.values.followupDays))
            profileForm.values.reminder1 = ''
            profileForm.values.reminder2 = ''
            profileForm.values.reminder3 = ''
            setFollowup(false)
        }
    },[profileForm.values.followupDays]) // eslint-disable-line

    const getAddress = (value,field) => {
        if(value.length ===5){
            API.get(`/intake/zones?zipCode=${value}`).then((res)=>{
                if(res.status === 200){
                    if(field === 'cg'){
                        profileForm.setValues({...profileForm.values,...profileForm.values,organizationAddress:{...profileForm.values.organizationAddress,state:res.data.body.state,city:res.data.body.city,country:res.data.body.country}})
                    }
                }
            })
        }else if(value.length === 0){
            profileForm.setValues({...profileForm.values,...profileForm.values,organizationAddress:{...profileForm.values.organizationAddress,state:"",city:"",country:""}})
        }
    }
    const toggleFormEdit = () => {
        if (editForm) profileForm.submitForm()
        if (profileForm.isValid || !editForm) setEditForm(!editForm)
    }

    const Autoremind = (value) =>{
        if(value === true){
        setAutoReminder(true)
        }else{
        setAutoReminder(false)
        }
    }

    const setReminder =(value,type) =>{
        if(type === 'remind1'){
            setRemind1(parseInt(value))
            profileForm.values.reminder2 = ''
            profileForm.values.reminder3 = ''
        }else if(type === 'remind2'){
            setRemind2(parseInt(value))
            profileForm.values.reminder3  = ''
        }
    }
    
    const ProtocolCard =(value)=>{
        if(value === true){
            setProtocolEnable(true)
        }else{
            setProtocolEnable(false)
        }
    }

    const submitForm = () => {
        let licenseExpirationDate = profileForm.values.licenseExpirationDate.split('/')
        let details = {
            organizationAddress: {
                country: profileForm.values.organizationAddress.country,
                addressLine1: profileForm.values.organizationAddress.addressLine1,
                addressLine2: profileForm.values.organizationAddress.addressLine2,
                state: profileForm.values.organizationAddress.state,
                postalCode: profileForm.values.organizationAddress.postalCode,
                city: profileForm.values.organizationAddress.city
            },
            organizationName: profileForm.values.organizationName.trim(),
            sicCd: profileForm.values.sicCd,
            activeTimeoutInMs: parseInt(profileForm.values.activeTimeoutInMs),
            inactiveTimeoutInMs: parseInt(profileForm.values.inactiveTimeoutInMs),
            licenseExpirationDate: new Date(licenseExpirationDate[0] + '-' + licenseExpirationDate[1] + '-' + licenseExpirationDate[2]).toISOString(),
            apiServiceActivated: profileForm.values.apiServiceActivated,
            areaCode: profileForm.values.areaCode,
            chatbotActivated: profileForm.values.chatbotActivated,
            cgIntake: profileForm.values.cgIntake,
            iddServiceActivated: profileForm.values.iddServiceActivated,
            lockoutTimeInMs: parseInt(profileForm.values.lockoutTimeInMs),
            loginAttempts: parseInt(profileForm.values.loginAttempts),
            militaryServiceActivated: profileForm.values.militaryServiceActivated,
            phone: profileForm.values.phone.trim(),
            resourceDatabaseApi: profileForm.values.resourceDatabaseApi,
            rescreenActivated:profileForm.values.rescreenActivated,
            userCreateMailAlert: profileForm.values.userCreateMailAlert,
            referralDyadRequired: profileForm.values.referralDyadRequired,
            subscriberIdRequired: profileForm.values.subscriberIdRequired,
            zipCodeEnableCR: profileForm.values.zipCodeEnableCR,
            dobFullRequired: profileForm.values.dobFullRequired,
            reportActivated: profileForm.values.reportActivated,
            schedulingActivated: profileForm.values.schedulingActivated,
            telephonyActivated: profileForm.values.telephonyActivated,
            organizationUpload: profileForm.values.organizationUpload,
            followupDays: parseInt(profileForm.values.followupDays),
            passwordExpirationAge: parseInt(profileForm.values.passwordExpirationAge),
            specializedQuestions: profileForm.values.specializedQuestions,
            caremanagerMail: profileForm.values.caremanagerEmail,
            caregiverMail: profileForm.values.caregiverEmail,
            filterRdb: profileForm.values.filterRdb,
            licenses: parseInt(profileForm.values.licenses),
            orgCode: profileForm.values.orgCode,
            contactCG:profileForm.values.contactCG,
            automaticReminder: profileForm.values.automaticReminder,
            automatic: profileForm.values.automatic,
            reminder1: profileForm.values.reminder1 ? profileForm.values.reminder1 : 0,
            reminder2: profileForm.values.reminder2 ? profileForm.values.reminder2 : 0,
            reminder3: profileForm.values.reminder3 ? profileForm.values.reminder3 : 0,
            vipEnabled: profileForm.values.vipEnabled,
            tcareProtocolEnabled: profileForm.values.tcareProtocolEnabled,
            orgEthnicity: profileForm.values.orgEthnicity,
            bulkServiceUsage: profileForm.values.bulkServiceUsage,
            jobFamilyGroup: profileForm.values.jobFamilyGroup,
            employeeType: profileForm.values.employeeType,
            militaryService: profileForm.values.militaryService,
            enhancedCloseCaseReason: profileForm.values.enhancedCloseCaseReason,
            serviceAuthorization:profileForm.values.serviceAuthorization,
            orgPendingCareplanAction:profileForm.values.orgPendingCareplanAction,
            protocol:profileForm.values.protocol,
            defaultProtocol:profileForm.values.defaultProtocol
        }
        if(userDetails.role.includes('superadmin') && profileForm.values.tcareProtocolEnabled){
            const protocolValid = details.protocol.filter(x => x.active)
            if(protocolValid.length >= 2){
                API.post(`organizations`, details).then(res => {
                    if(res.status === 200 && res.data.status === false){
                        dispatch(setAlert({title:t('Warning'),subtitle:res.data.errorMessage,type:'warning',active:true}))
                        setEditForm(true)
                    }else if (res.status === 200) {
                        dispatch(setAlert({ title: t('Success'), subtitle: t('Organization created successfully'), type: 'success', active: true }))
                        history.goBack()
                    } else if (res.status === 400) {
                        dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                        history.goBack()
                    } else {
                        dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                        history.goBack()
                    }
                }).catch(e => {
                    console.log(e)
                }).finally();
            }else{
                dispatch(setAlert({title:t('Warning'),subtitle:'Please Select Minimum Two Protocol',type:'warning',active:true}))
            }
        }else{
                API.post(`organizations`, details).then(res => {
                    if(res.status === 200 && res.data.status === false){
                        dispatch(setAlert({title:t('Warning'),subtitle:res.data.errorMessage,type:'warning',active:true}))
                        setEditForm(true)
                    }else if (res.status === 200) {
                        dispatch(setAlert({ title: t('Success'), subtitle: t('Organization created successfully'), type: 'success', active: true }))
                        history.goBack()
                    } else if (res.status === 400) {
                        dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                        history.goBack()
                    } else {
                        dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                        history.goBack()
                    }
                }).catch(e => {
                    console.log(e)
                }).finally();
            }
       
    }

    return (
        <div class="pb-8">
            <button className="flex mt-3 bg-tcolor px-6 py-2 rounded-xl items-center shadow-lg text-white ring-offset-2 hover:ring-2 hover:ring-tcolor dark:ring-offset-black whitespace-nowrap" onClick={() => history.goBack()}>
                <FaArrowLeft className="mr-3" />{t("Back")}
            </button>
            <FormCard title={t("Basic Information")}>
                <FavFormButton injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleFormEdit} handleCancel={() => setEditForm(false)} />
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldInput loading={formLoading} label={t("Organization Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationName')} fieldProps={profileForm.getFieldProps('organizationName')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldSelect loading={formLoading} label={t("Active/Inactive")} fieldMeta={profileForm.getFieldMeta('active')} fieldHelper={profileForm.getFieldHelpers('active')} fieldProps={profileForm.getFieldProps('active')} edit={editForm} options={select} position="top" dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("SIC Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('sicCd')} fieldProps={profileForm.getFieldProps('sicCd')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'4'} />
                    <FieldInput loading={formLoading} type="date" minDate={new Date()} label={t("Licences Expire Date")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('licenseExpirationDate')} fieldProps={profileForm.getFieldProps('licenseExpirationDate')} fieldHelper={profileForm.getFieldHelpers('licenseExpirationDate')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} type="phone" label={t("Phone Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('phone')} fieldProps={profileForm.getFieldProps('phone')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading}  label={t("Number of Licences Hold")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('licenses')} fieldProps={profileForm.getFieldProps('licenses')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading}  label={t("Org Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('orgCode')} fieldProps={profileForm.getFieldProps('orgCode')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldSelect loading={formLoading} label={t("Default Protocol")} fieldMeta={profileForm.getFieldMeta('defaultProtocol')} fieldHelper={profileForm.getFieldHelpers('defaultProtocol')} fieldProps={profileForm.getFieldProps('defaultProtocol')} edit={editForm} options={protocolOption} position="top" dataLoaded={dataLoaded} />
                </div>
            </FormCard>
            <FormCard title={t("Address Information")}>
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                <FieldInput loading={formLoading} label={t("Postal Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationAddress.postalCode')} fieldProps={profileForm.getFieldProps('organizationAddress.postalCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'cg')}/>
                    <FieldInput loading={formLoading} label={t("Address Line 1")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationAddress.addressLine1')} fieldProps={profileForm.getFieldProps('organizationAddress.addressLine1')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("Address Line 2")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationAddress.addressLine2')} fieldProps={profileForm.getFieldProps('organizationAddress.addressLine2')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationAddress.city')} fieldProps={profileForm.getFieldProps('organizationAddress.city')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("Country")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('organizationAddress.country')} fieldProps={profileForm.getFieldProps('organizationAddress.country')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'2'} />
                    <FieldSelect loading={formLoading} label={t("State")} fieldMeta={profileForm.getFieldMeta('organizationAddress.state')} fieldHelper={profileForm.getFieldHelpers('organizationAddress.state')} fieldProps={profileForm.getFieldProps('organizationAddress.state')} edit={editForm} options={states} position="top" dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("Area Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('areaCode')} fieldProps={profileForm.getFieldProps('areaCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={"4"} />
                </div>
            </FormCard>
            <FormCard title={t("Additional Information")}>

                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldInput loading={formLoading} label={t("Active Timeout(minutes)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('activeTimeoutInMs')} fieldProps={profileForm.getFieldProps('activeTimeoutInMs')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("InActive Timeout(minutes)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('inactiveTimeoutInMs')} fieldProps={profileForm.getFieldProps('inactiveTimeoutInMs')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("Login Attempt")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('loginAttempts')} fieldProps={profileForm.getFieldProps('loginAttempts')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("Lockout Time(minutes)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('lockoutTimeInMs')} fieldProps={profileForm.getFieldProps('lockoutTimeInMs')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("Password Expiration Age")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('passwordExpirationAge')} fieldProps={profileForm.getFieldProps('passwordExpirationAge')} edit={editForm} dataLoaded={dataLoaded} />
                </div>
            </FormCard>

            <FormCard title={t("Followup Information")}>

                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldInput loading={formLoading} label={t("Followup Days")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('followupDays')} fieldProps={profileForm.getFieldProps('followupDays')} edit={editForm} dataLoaded={dataLoaded} />
                </div>
            </FormCard>

            <FormCard title={t("Service Information")}>

                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldCheckbox loading={formLoading} label={t('Military Service')} fieldMeta={profileForm.getFieldMeta('militaryServiceActivated')} fieldHelper={profileForm.getFieldHelpers('militaryServiceActivated')} fieldProps={profileForm.getFieldProps('militaryServiceActivated')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("IDD Service")} fieldMeta={profileForm.getFieldMeta('iddServiceActivated')} fieldProps={profileForm.getFieldProps('iddServiceActivated')} fieldHelper={profileForm.getFieldHelpers('iddServiceActivated')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("API Service")} fieldMeta={profileForm.getFieldMeta('apiServiceActivated')} fieldProps={profileForm.getFieldProps('apiServiceActivated')} fieldHelper={profileForm.getFieldHelpers('apiServiceActivated')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Report Service")} fieldMeta={profileForm.getFieldMeta('reportActivated')} fieldProps={profileForm.getFieldProps('reportActivated')} fieldHelper={profileForm.getFieldHelpers('reportActivated')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Chatbot Service")} fieldMeta={profileForm.getFieldMeta('chatbotActivated')} fieldProps={profileForm.getFieldProps('chatbotActivated')} fieldHelper={profileForm.getFieldHelpers('chatbotActivated')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Scheduling Service")} fieldMeta={profileForm.getFieldMeta('schedulingActivated')} fieldProps={profileForm.getFieldProps('schedulingActivated')} fieldHelper={profileForm.getFieldHelpers('schedulingActivated')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Telephony Service")} fieldMeta={profileForm.getFieldMeta('telephonyActivated')} fieldProps={profileForm.getFieldProps('telephonyActivated')} fieldHelper={profileForm.getFieldHelpers('telephonyActivated')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Org Uploads")} fieldMeta={profileForm.getFieldMeta('organizationUpload')} fieldProps={profileForm.getFieldProps('organizationUpload')} fieldHelper={profileForm.getFieldHelpers('organizationUpload')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Specialized Question")} fieldMeta={profileForm.getFieldMeta('specializedQuestions')} fieldProps={profileForm.getFieldProps('specializedQuestions')} fieldHelper={profileForm.getFieldHelpers('specializedQuestions')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("FilteredRdb")} fieldMeta={profileForm.getFieldMeta('filterRdb')} fieldProps={profileForm.getFieldProps('filterRdb')} fieldHelper={profileForm.getFieldHelpers('filterRdb')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Screener completion email to CG")} fieldMeta={profileForm.getFieldMeta('cgIntake')} fieldProps={profileForm.getFieldProps('cgIntake')} fieldHelper={profileForm.getFieldHelpers('cgIntake')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Resource Database API")} fieldMeta={profileForm.getFieldMeta('resourceDatabaseApi')} fieldProps={profileForm.getFieldProps('resourceDatabaseApi')} fieldHelper={profileForm.getFieldHelpers('resourceDatabaseApi')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Allow Unassigned Cases for Re-Screen")} fieldMeta={profileForm.getFieldMeta('rescreenActivated')} fieldProps={profileForm.getFieldProps('rescreenActivated')} fieldHelper={profileForm.getFieldHelpers('rescreenActivated')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Allow Welcome User Email")} fieldMeta={profileForm.getFieldMeta('userCreateMailAlert')} fieldProps={profileForm.getFieldProps('userCreateMailAlert')} fieldHelper={profileForm.getFieldHelpers('userCreateMailAlert')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Referral Dyad Required")} fieldMeta={profileForm.getFieldMeta('referralDyadRequired')} fieldProps={profileForm.getFieldProps('referralDyadRequired')} fieldHelper={profileForm.getFieldHelpers('referralDyadRequired')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Subscriber ID Required")} fieldMeta={profileForm.getFieldMeta('subscriberIdRequired')} fieldProps={profileForm.getFieldProps('subscriberIdRequired')} fieldHelper={profileForm.getFieldHelpers('subscriberIdRequired')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Full Birthdate Required")} fieldMeta={profileForm.getFieldMeta('dobFullRequired')} fieldProps={profileForm.getFieldProps('dobFullRequired')} fieldHelper={profileForm.getFieldHelpers('dobFullRequired')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Contact Caregivers By Default")} fieldMeta={profileForm.getFieldMeta('contactCG')} fieldProps={profileForm.getFieldProps('contactCG')} fieldHelper={profileForm.getFieldHelpers('contactCG')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Automated Reminders")} fieldMeta={profileForm.getFieldMeta('automaticReminder')} fieldProps={profileForm.getFieldProps('automaticReminder')} fieldHelper={profileForm.getFieldHelpers('automaticReminder')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => Autoremind(ev)} />
                    <FieldCheckbox loading={formLoading} label={t("Zip code Enable CR")} fieldMeta={profileForm.getFieldMeta('zipCodeEnableCR')} fieldProps={profileForm.getFieldProps('zipCodeEnableCR')} fieldHelper={profileForm.getFieldHelpers('zipCodeEnableCR')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Allow VIP status")} fieldMeta={profileForm.getFieldMeta('vipEnabled')} fieldProps={profileForm.getFieldProps('vipEnabled')} fieldHelper={profileForm.getFieldHelpers('vipEnabled')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("TCARE Protocol")} fieldMeta={profileForm.getFieldMeta('tcareProtocolEnabled')} fieldProps={profileForm.getFieldProps('tcareProtocolEnabled')} fieldHelper={profileForm.getFieldHelpers('tcareProtocolEnabled')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => ProtocolCard(ev)} />
                    <FieldCheckbox loading={formLoading} label={t("Ethnicity Enable")} fieldMeta={profileForm.getFieldMeta('orgEthnicity')} fieldProps={profileForm.getFieldProps('orgEthnicity')} fieldHelper={profileForm.getFieldHelpers('orgEthnicity')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Bulk Entry for Service Usage")} fieldMeta={profileForm.getFieldMeta('bulkServiceUsage')} fieldProps={profileForm.getFieldProps('bulkServiceUsage')} fieldHelper={profileForm.getFieldHelpers('bulkServiceUsage')} edit={editForm} dataLoaded={dataLoaded} />
                    {(userDetails.env === 'LSS'|| userDetails.env === 'LSS-SB') ?<FieldCheckbox loading={formLoading} label={t("LSS Job Family Group")} fieldMeta={profileForm.getFieldMeta('jobFamilyGroup')} fieldProps={profileForm.getFieldProps('jobFamilyGroup')} fieldHelper={profileForm.getFieldHelpers('jobFamilyGroup')} edit={editForm} dataLoaded={dataLoaded} />:''}
                    {(userDetails.env === 'LSS'|| userDetails.env === 'LSS-SB') ?<FieldCheckbox loading={formLoading} label={t("LSS Employment Type")} fieldMeta={profileForm.getFieldMeta('employeeType')} fieldProps={profileForm.getFieldProps('employeeType')} fieldHelper={profileForm.getFieldHelpers('employeeType')} edit={editForm} dataLoaded={dataLoaded} />:''}
                    {(userDetails.env === 'DAVORG'|| userDetails.env === 'DAVORG-SB') ?<FieldCheckbox loading={formLoading} label={t("DAV Military Service")} fieldMeta={profileForm.getFieldMeta('militaryService')} fieldProps={profileForm.getFieldProps('militaryService')} fieldHelper={profileForm.getFieldHelpers('militaryService')} edit={editForm} dataLoaded={dataLoaded} />:''}
                    <FieldCheckbox loading={formLoading} label={t("Enhanced Close Case Reasons")} fieldMeta={profileForm.getFieldMeta('enhancedCloseCaseReason')} fieldProps={profileForm.getFieldProps('enhancedCloseCaseReason')} fieldHelper={profileForm.getFieldHelpers('enhancedCloseCaseReason')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Authorization of Service")} fieldMeta={profileForm.getFieldMeta('serviceAuthorization')} fieldProps={profileForm.getFieldProps('serviceAuthorization')} fieldHelper={profileForm.getFieldHelpers('serviceAuthorization')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Care Plan Actions")} fieldMeta={profileForm.getFieldMeta('orgPendingCareplanAction')} fieldProps={profileForm.getFieldProps('orgPendingCareplanAction')} fieldHelper={profileForm.getFieldHelpers('orgPendingCareplanAction')} edit={editForm} dataLoaded={dataLoaded} />
                </div>
            </FormCard>

            {protocolEnable ? <FormCard title={t("Protocol")}>
                <div className="flex flex-row">
           { protocolValue.map((c,i)=>{
            return(
                <div className="w-96 mx-4">
               <FieldCheckbox loading={formLoading} label={`${c.protocolName}`} fieldMeta={profileForm.getFieldMeta(`protocol.${i}.active`)} fieldProps={profileForm.getFieldProps(`protocol.${i}.active`)} fieldHelper={profileForm.getFieldHelpers(`protocol.${i}.active`)} edit={editForm} dataLoaded={dataLoaded} />
            </div>
            )
           }) }</div>
            </FormCard>:''}

          { autoReminder ?  <FormCard title={t("Reminders Date")}>

                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                    <FieldInput loading={formLoading} label={t("Reminder 1")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('reminder1')} fieldProps={profileForm.getFieldProps('reminder1')} edit={editForm} dataLoaded={dataLoaded} onChange={ev => setReminder(ev ,'remind1')} />
                    <FieldInput loading={formLoading} label={t("Reminder 2")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('reminder2')} fieldProps={profileForm.getFieldProps('reminder2')} edit={editForm} dataLoaded={dataLoaded} onChange={ev => setReminder(ev,'remind2')}/>
                    <FieldInput loading={formLoading} label={t("Reminder 3")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('reminder3')} fieldProps={profileForm.getFieldProps('reminder3')} edit={editForm} dataLoaded={dataLoaded} onChange={ev => setReminder(ev,'remind3')} />
                </div>
            </FormCard> :''}

            <FormCard title={t("Communicate")}>

                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldCheckbox loading={formLoading} label={t("Email to Caregiver")} fieldMeta={profileForm.getFieldMeta('caregiverEamil')} fieldProps={profileForm.getFieldProps('caregiverEmail')} fieldHelper={profileForm.getFieldHelpers('caregiverEmail')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Email to Caremanager")} fieldMeta={profileForm.getFieldMeta('caremanagerEmail')} fieldProps={profileForm.getFieldProps('caremanagerEmail')} fieldHelper={profileForm.getFieldHelpers('caremanagerEmail')} edit={editForm} dataLoaded={dataLoaded} />
                </div>
            </FormCard>
            { editForm && 
                <div className="flex justify-center mt-7 mx-5">
                    <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-500 hover:bg-opacity-80 text-white" onClick={() => setEditForm(false)}>{t("Cancel")}</button>
                    <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Save")}</button>
                </div>
            }

        </div>
    )
}

export default Addorganization