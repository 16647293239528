import { GiCheckMark } from 'react-icons/gi'
import { FaExclamationTriangle } from 'react-icons/fa'
import { HiSelector } from 'react-icons/hi'
import { AnimatePresence, motion } from 'framer-motion'
import { TextAnimateX } from '../../commons/anims'
import { useEffect, useMemo, useRef, useState } from 'react'
import FlowText from './FlowText'
import { createPortal } from "react-dom"
// import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa'

const SelectOptionAnim = {
    hidden: {
        height: 0,
        opacity: 0
    },
    visible: {
        height: 'auto',
        opacity: 1,
        zIndex: 10000,
    }
}

const ReferrerSelect = ({ fieldProps, fieldMeta, label, edit, options, fieldHelper, position = 'bottom', loading = false, dataLoaded = false, onChange, disabled = false, ariaLabel }) => {

    const input = useRef(null)
    const wrapRef = useRef(null)
    const [showError, setShowError] = useState(false)
    const [filter, setFilter] = useState('')
    const [focussed, setFocussed] = useState(false)
    const [pos, setPos] = useState({ width: 0, top: 0, left: 0 })
    const [index,setIndex] = useState(-1);
    const resultContainer = useRef(null)

    // const { t } = useTranslation()

    const makeFocus = () => {
        if (input.current !== null)
            input.current.focus()
    }
    const toggleFocus = () => {
        if (focussed) {
            setFilter('')
            if (input.current !== null) input.current.blur()
        }
        setFocussed(!focussed)
        document.body.removeEventListener('click', toggleFocus)
    }
    const handleBlur = () => {
        setTimeout(() => {
            if (focussed) toggleFocus()
        }, 200)
    }

    useEffect(() => {
        if (focussed) {
            document.body.addEventListener('click', toggleFocus)
            let e = wrapRef.current.getBoundingClientRect()
            let o = { width: `${e.width}px`, left: `${e.x}px` }
            if ((e.top + e.height + 270) > window.innerHeight) {
                o.bottom = `${window.innerHeight - e.top}px`
            } else {
                o.top = `${(e.top + e.height)}px`
            }
            setPos(o)
        }
            
        return () => document.body.removeEventListener('click', toggleFocus)
    }, [focussed]) // eslint-disable-line

    useEffect(() => {
        if (!resultContainer.current) return;
        resultContainer.current.scrollIntoView({
            block: "center"
        })
    }, [focussed, index])

    const toggleError = (val) => setShowError(val)
    const setValue = (value) => {
        fieldHelper.setValue(value)
        if (typeof onChange === 'function') onChange(value)
    }
    const filteredOptions = useMemo(() => {
        return options.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim())))
    }, [filter, options]) // eslint-disable-line
    const displayText = useMemo(() => {
        let a = options.filter(c => c.value === fieldProps.value)
        return a.length > 0 ? a[0].text : false
    }, [fieldProps.value, options])// eslint-disable-line

    const setSelectedValue = (e) => {
        setValue(e);
        setFocussed(!focussed)
        document.body.removeEventListener('click', toggleFocus)
    }
    const handleKeyDown=(e)=>{
        let ind = 0
        if(e==="ArrowDown"){
            ind = (index+1)%filteredOptions.length;

        }else if(e==="Enter"){
            setSelectedValue(filteredOptions[index].value);
            ind=-1;
            handleSelection(ind)
        }else if(e==="ArrowUp"){
            // console.log(filteredOptions[index].value)
            ind = (index-1)%filteredOptions.length;
        }
        setIndex(ind)
    }
    const handleSelection=(index)=>{
        const selectedItem = filteredOptions[index];
        setIndex(-1)
    }
    return (
        <div className="relative">
            <div ref={wrapRef} className={`group relative flex flex-col ${disabled ? 'bg-gray-300 dark:bg-gray-600' : edit ? 'ring-1 ring-gray-300 dark:ring-gray-600' : ''} px-2 pt-1 rounded-lg ${focussed ? ' focus-within:ring-tcolor dark:focus-within:ring-tcolor' : ''} transition-all duration-100 cursor-pointer ${loading ? 'animate-pulse' : ''}`} onClick={makeFocus}>
                <span className={`${edit ? 'ml-1' : ''} text-xs text-gray-500 dark:text-gray-400`}><FlowText text={label} direction="x" /></span>
                <div className="relative">
                    {/* <input ref={input} type="text" className="h-0 w-0 absolute" {...fieldProps} onFocus={toggleFocus} /> */}
                    {!edit && dataLoaded &&
                        <div className={`pb-2 h-8 ${fieldProps.value ? '' : 'text-gray-500 line-through'}`}>{displayText || ' '}</div>
                    }
                    {
                        !dataLoaded &&
                        <div className="w-3/4 h-2 my-2 rounded-lg animate-pulse bg-gray-300"></div>
                    }
                    {
                        edit && dataLoaded &&
                        <>
                            <div className={`pb-1.5 pl-3 h-8 ${fieldProps.value ? '' : 'text-zinc-700'} ${focussed ? 'hidden' : ''}`}>{displayText || `${label}`}</div>
                            <input onKeyDown={ev=>handleKeyDown(ev.key)} ref={input} disabled={disabled} className={`pb-1.5 pl-3 bg-transparent pr-10 w-full outline-none ${!focussed ? 'h-0 w-0 absolute' : ''}`} type="text" value={filter} autoComplete="off" onFocus={toggleFocus} onBlur={handleBlur} onChange={ev => setFilter(ev.target.value)} aria-label={ariaLabel} />
                        </>
                    }
                    {edit &&
                        <span className="absolute right-2 -top-1 text-xl flex">
                            {fieldMeta.error && fieldMeta.touched &&
                                <FaExclamationTriangle className="text-red-500 cursor-pointer" onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} />}
                            <HiSelector className="text-gray-400 ml-2" />
                        </span>
                    }
                    <AnimatePresence>
                        {fieldMeta.error && fieldMeta.touched && showError &&
                            <motion.span variants={TextAnimateX} initial="hidden" animate="visible" exit="hidden" className="absolute -top-1 right-16 inline-block bg-gray-300 dark:bg-ldark py-0.5 px-2 rounded text-sm text-red-500">
                                {fieldMeta.error}
                            </motion.span>
                        }
                    </AnimatePresence>
                </div>
            </div>
            {
                createPortal(
                    <AnimatePresence>
                        {
                            focussed &&
                            // <div className="fixed left-0 right-0 bottom-0 top-0">
                            <motion.section tabIndex={0} variants={SelectOptionAnim} initial="hidden" animate="visible" exit="hidden" className={`fixed overflow-y-auto max-h-[270px] bg-bluegray-200 dark:bg-mdark ring-1 ring-bluegray-300 dark:ring-ldark p-2 rounded`} style={pos}>
                                <span className="inline-block mb-2 text-xs text-tcolor dark:text-gray-400">{label}</span>
                                {
                                    filteredOptions.map((c, i) => (
                                        <div key={i} onMouseDown={()=>handleSelection(i)} 
                                        ref={i==index?resultContainer:null}
                                        style={{backgroundColor:i==index?"rgba(3, 153, 216)":""}}

                                        onClick={() => setValue(c.value)} className="py-2 px-3 focus:bg-tcolor hover:bg-tcolor hover:text-white rounded-lg text-sm flex justify-between items-center text-bluegray-600 dark:text-gray-300 dark:hover:text-white">
                                            {c.text}
                                            {c.value === fieldProps.value && <GiCheckMark className="text-green-500" />}
                                        </div>
                                    ))
                                }
                                {filteredOptions.length === 0 && <span className="block px-5 pb-2 text-gray-500"> </span>}
                            </motion.section>
                            // </div>
                            //     <motion.div variants={SelectOptionAnim} initial="hidden" animate="visible" exit="hidden" className="absolute text-gray-700 dark:text-gray-200 bg-bluegray-300 dark:bg-ldark py-2 z-[2] rounded-md w-72 top-full right-0 max-h-[300px] overflow-y-auto shadow-xl">
                            //     <span className="text-[10px] ml-2 mb-2 text-gray-600 dark:text-gray-300 font-bold">{label}</span>
                            //     {
                            //         filteredOptions.map((c,i) => (
                            //             <div key={i} className="group whitespace-nowrap flex items-center pr-4 pl-4 py-2 cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark text-xs" >
                            //                 <span className={`ring-1 ring-bluegray-500 dark:ring-white group-hover:ring-white w-3 h-3 inline-block align-middle relative mr-3`}>
                            //                     {c.active ? <FaCheck className="absolute -top-1 text-green-500 text-lg" /> : null}
                            //                 </span>
                            //                 {c.text}
                            //             </div>
                            //         ))
                            //     }
                            // </motion.div>



                        }
                    </AnimatePresence>, document.body)
            }
        </div>
    )
}

export default ReferrerSelect