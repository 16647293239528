import { useMemo } from 'react'
import {FaUserPlus} from 'react-icons/fa'
import { Link,useHistory } from 'react-router-dom'
const AddCg = ({to, onClick, title}) => {
    let history = useHistory();
    const trigger = () => {
        history.push({state:''})
        if(typeof onClick === 'function') onClick()
    }
    const Tag = useMemo(() => {
        return to ? Link : 'span'
    }, [to])

    return (
        <Tag to={to || ''} className="group relative cursor-pointer" onClick={trigger}>
            <FaUserPlus size="30px" className="flex ml-3 bg-tcolor p-2 rounded-full items-center shadow-lg text-white" />
            {title ? <span className={`absolute hidden group-hover:inline-block z-20 whitespace-nowrap right-0 -bottom-7 text-white bg-bluegray-500 dark:bg-ldark text-[10px] py-1 px-2 rounded-lg ring-1 ring-gray-600`}>{ title }</span> : ''}
        </Tag>
    )
}

export default AddCg