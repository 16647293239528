import { useFormik } from "formik"
import { useState, useEffect, useLayoutEffect } from "react"
import * as Yup from 'yup'
import { useMemo } from "react"
import FavFormButton from "../../components/snippets/FavFormButton"
import FieldInput from "../../components/snippets/FieldInput"
import FieldSelect from "../../components/snippets/FieldSelect"
import FormCard from "../../components/snippets/FormCard"
import { useTranslation } from 'react-i18next'

// import { useHistory } from "react-router-dom"
import { FaArrowLeft } from 'react-icons/fa'
import { useDispatch, useSelector } from "react-redux"
import FieldCheckbox from '../../components/snippets/FieldCheckbox'
import API from "../../axios/API"
import axios from 'axios'
import { setAlert } from '../../store/theme/actions'
import Tab from "../../components/snippets/Tabs/Tab"
import TabGroup from "../../components/snippets/Tabs/TabGroup"
import TabButtonGroup from "../../components/snippets/Tabs/TabButtonGroup"
import TabButton from "../../components/snippets/Tabs/TabButton"
import ServiceUsage from "../Carereceiver/ServiceUsage"
import FieldMultiSelect from '../../components/snippets/FieldMultiSelect'
import UserMultiSelect from "../../components/snippets/UserMultiSelect"


const ClientEdit = ({ match, history }) => {

    // let history = useHistory()
    const { t } = useTranslation()
    const [editForm, setEditForm] = useState(false)
    const [formLoading, setFormLoading] = useState(false)
    const dispatch = useDispatch()
    const [req, setReq] = useState(false)
    const [dataLoaded, setDataLoad] = useState(false)
    const [initialData, setInitialData] = useState([])
    const [cgRefused, setCgRefused] = useState(false)
    let orgId = localStorage.getItem('orgid')
    let subId = localStorage.getItem('subId')
    const [subreq, setSubReq] = useState(false)
    let birthyear = localStorage.getItem('birthyear')
    const [birthyear1, setBirthYear1] = useState(false)
    let prgName = localStorage.getItem('prgname')
    let assessType1 = localStorage.getItem('assessType')
    // let pcsp = localStorage.getItem('pcsp')
    const [programName1, setProgramName1] = useState(false)
    // const [datePscp, setDatePscp] = useState(false)
    const [assessmentTypeId1, setAssessmentTypeId1] = useState(false)
    let contact = localStorage.getItem('contactCG')
    let ssn = localStorage.getItem('ssn')
    let vipEnable = localStorage.getItem('vipEnable')
    const [cgDetail, setCGDetail] = useState(false)
    const [subAsian,setSubAsians] = useState([])
    const [subNative,setSubNative] = useState([])
    const [otherAsian,setOtherAsian] = useState({otherSubRaceId : '',otherSubRaceValue :'',raceId:''})
    const [otherAsianString,setOtherAsianString] = useState(false)
    const [careGiverSubraceId,setCaregiverSubraceId] = useState()
    const [otherNative,setOtherNative] = useState({otherSubRaceId : '',otherSubRaceValue :'',raceId:''})
    const [otherNativeString,setOtherNativeString] = useState(false)
    const [careGiverNativeId,setCaregiverNativeId] = useState()
    const [asianRaceId,setAsianRaceId] = useState(0)
    const [nativeRaceId,setNativeRaceId] = useState(0)
    const [errOtherAsian,setErrOtherAsian] = useState(false)
    const [errOtherNative,setErrOtherNative] = useState(false)
    const OTHER_ASIAN = 29;
    const OTHER_NATIVE = 35;
    const [otherAsianValue,setOtherAsianValue] = useState([])
    const [otherNativeValue,setOtherNativeValue] = useState([])
    let lang = localStorage.getItem('language')
    let orgEthnicityEnable = localStorage.getItem('orgEthnicity')
    const [orgRaceEnable,setOrgRaceEnabl] = useState(false)
    let jobFamily = localStorage.getItem('jobFamily')
    const [jobFamilyGroup,setJobFamilyGroup] =useState([])
    const [jobFamilyEnable,setJobFamilyEnable] = useState(false)
    let employeeFamily = localStorage.getItem('empType')
    const [employeeGroup,setEmployeeGroup] =useState([])
    const [employeeEnable,setEmployeeEnable] = useState(false)
    let davService = localStorage.getItem('military')
    const [militaryService,setMilitaryService] = useState([])
    const [militaryRank,setMilitaryRank] = useState([])
    const [serviceEnable,setServiceEnable] = useState(false)

    

    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test(t('validPhone'), t("Not a Valid Phone"), (value) => {
            if (value === undefined || value === '') return true
            return (value.replace(/ /g, '').length === 13 || value.replace(/ /g, '').length === 12)
        })
    })
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^\d]+$/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'validateEmail', function () {
        return this.test(t('validEmail'), t("Not valid"), (value) => {
            if (value === undefined || value === '' || value === null) return true
            let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'validateName', function () {
        return this.test(t('onlyAlphabet and numbers are allowed'), t('Only Alphabets and numbers allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'LengthSubId', function(){
        return this.test(t('SubscriberId must be the length of either 9 or 14'),t('SubscriberId must be the length of either 9 or 14'),(value)=>{
            if(value === undefined || value == '' || value.length === 0 || value === null || value.length === 9 || value.length === 14) return true
            if(value.length >= 10 && value.length < 14) return false  
        })
    })
    useEffect(() => {
        setFormLoading(true)
        API.get(`clients/${match?.params?.carereceiver_id}`).then(res => {
            
            if (subId === 'true' && res.data.crDetails === true) {
                setSubReq(true)
            }
            if (prgName === 'true' && res.data.cgDetails === true) {
                setProgramName1(true)
            }
            // if (pcsp === 'true' && res.data.cgDetails === true) {
            //     setDatePscp(true)
            // }
            if (assessType1 === 'true' && res.data.crDetails === true) {
                setAssessmentTypeId1(true)
            }
            if(res.data.phone.split('-').length === 1){
                res.data.phone = res.data.phone.slice(0,3) + '-' + res.data.phone.slice(3,6) + '-' + res.data.phone.slice(6,10)
            }
            if(orgEthnicityEnable === 'true'){
                setOrgRaceEnabl(true)
            }
            setCGDetail(res.data.cgDetails)
            setInitialData(res.data);
            if(res.data.otherAsianRace.otherSubRaceId === OTHER_ASIAN){
                setOtherAsianString(true)
            }
            if(res.data.otherNativeRace.otherSubRaceId === OTHER_NATIVE){
                setOtherNativeString(true)
            }
            setFormLoading(false)
            // setDataLoad(true)
            setReq(res.data.vipStatus)
            let start = (initialData.fromDate !== null && initialData.fromDate !== undefined && initialData.fromDate.split('-')[0] + "/" + initialData.fromDate.split('-')[1] + "/" + initialData.fromDate.split('-')[2]) || ''
            initialData.fromDate = start || ''
            let End = (initialData.toDate !== null && initialData.toDate !== undefined && initialData.toDate.split('-')[0] + "/" + initialData.toDate.split('-')[1] + "/" + initialData.toDate.split('-')[2]) || ''
            initialData.toDate = End || ''
            if(jobFamily === 'true' && res.data.cgDetails === true){
                API.get(`menus/jobFamilyGroup?lang=${lang}`).then((subfamily) => {
                    setJobFamilyGroup(subfamily.data.body.map(c => ({ text: c.name, value: c.value })))
                })
                setJobFamilyEnable(true)
            }
            if(employeeFamily === 'true' && res.data.cgDetails === true){
                API.get(`menus/employeeType?lang=${lang}`).then((subemployee) => {
                    setEmployeeGroup(subemployee.data.body.map(c => ({ text: c.name, value: c.value })))
                })
                setEmployeeEnable(true)
            }
        }).catch(e => {
            console.log(e);
        }).finally()
    }, [])// eslint-disable-line

    const formData = useMemo(() => {
        setServiceEnable(history.location.state.service)
        if (history.location.state !== undefined && history.location.state.editMode) {
            setEditForm(true)
        }
        // let start = (initialData.fromDate !== null && initialData.fromDate.split('-')[0] + "/" + initialData.fromDate.split('-')[1] + "/" + initialData.fromDate.split('-')[2]) || ''
        // initialData.fromDate = start
        // let End = (initialData.toDate !== null && initialData.toDate.split('-')[0] + "/" + initialData.toDate.split('-')[1] + "/" + initialData.toDate.split('-')[2]) || ''
        // initialData.toDate = End
        if (initialData.dobYear === 0) {
            initialData.dateOfBirth = ''
        } else if (initialData.dobYear !== 0 && birthyear1) {
            initialData.dateOfBirth = initialData.dobYear + "/" + initialData.dobMonth + "/" + initialData.dobDay
        } else if (initialData.dobYear !== 0) {
            initialData.dateOfBirth = initialData.dobYear
        }
        if (initialData.fromDate === null) {
            initialData.fromDate = ''
        }
        if (initialData.toDate === null) {
            initialData.toDate = ''
        }
        // console.log(initialData.fromDate)


        if (initialData.homePhone === null || initialData.homePhone === "--") {
            initialData.homePhone = ''
        }
        if (initialData.phone === null || initialData.phone === "--") {
            initialData.phone = ''
        }
        initialData.race = []
        if (initialData.white === true) {
            initialData.race.push(1)
        } if (initialData.black === true) {
            initialData.race.push(2)
        } if (initialData.hispanic === true) {
            initialData.race.push(3)
        }if (initialData.asian === true && !orgRaceEnable) {
            initialData.race.push(4)
        } if (initialData.nativeAmerican === true) {
            initialData.race.push(5)
        } if (initialData.pacificIslander === true && !orgRaceEnable) {
            initialData.race.push(6)
        } if (initialData.middleEastern === true) {
            initialData.race.push(7)
        } if (initialData.otherRace === true) {
            initialData.race.push(8)
        } if (initialData.refused === true) {
            initialData.race.push(9)
        }
        if(initialData.ethnicity !== undefined && orgRaceEnable){
            initialData.ethnicity.map((c,i)=>{
                initialData.race.push(c)
                if(c >= 10 && c<= 29){
                    setOtherAsianValue((prevValue)=>[...prevValue,c])
                }
                if(c >= 30 && c<= 35){
                    setOtherNativeValue((prevValue)=>[...prevValue,c])
                }
            })
        }
       
        if (initialData.dobYear === 0) {
            setCgRefused(true)
        }
        // if (initialData.pcspDate === null) {
        //     initialData.pcspDate = ''
        // }
        if (initialData.pgNameId === 0) {
            initialData.pgNameId = ''
        }
        if (initialData.assessTypeId === 0) {
            initialData.assessTypeId = ''
        }
        
        initialData.emailAddress = initialData.emailAddress ? initialData.emailAddress : ''
        if(initialData.enlistmentDate === null){
            initialData.enlistmentDate = ''
        }
        if(initialData.dischargeDate === null){
            initialData.dischargeDate = ''
        }
        return { ...initialData }
    }, [initialData, birthyear1, history.location.state])
    const userDetails = useSelector(state => state.auth.userDetails)
    const [states, setStates] = useState([])
    const [gender, setGender] = useState([])
    const [employment, setEmployment] = useState([])
    const [pronoun, setPronouns] = useState([])
    const [raceEthnicity, setRaceEthnicity] = useState([])
    const [language, setLanguage] = useState([])
    const [maritalStatus, setMarital] = useState([])
    const [education, setEducation] = useState([])
    const [Income, setIncome] = useState([])
    const [tab, setTab] = useState(0)
    const [years, setYears] = useState([])
    const [zipEnable, setZipEnable] = useState(false)
    const [disability, setDisability] = useState([])
    const [programName, setProgramName] = useState([])
    const [assessmentType, setAssessmentType] = useState([])
    const [errWarning, setErrWarning] = useState(false)
    const [errWarning1, setErrWarning1] = useState(false)
    const [customsubWarn, setCustomSubWarn] = useState()
    const [customsubWarn1, setCustomSubWarn1] = useState()
    const [nextButtonDisable, setNextButtonDisable] = useState(false)
    const [nextButtonDisable1, setNextButtonDisable1] = useState(false)
    const [minDateError, setMinDateError] = useState(false)
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))
    const organization = useSelector(state => state.auth.organization)
    



    const [tempCrSub,setTempCrSub] = useState()

    useEffect(() => {
        for (let i = 1900; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
    }, []) //eslint-disable-line

    useEffect(() => {
        if (birthyear === 'true') {
            setBirthYear1(true)
        }
        if(menuItems && menuItems !== '' && menuItems !== null && menuItems !== undefined){
            if (menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined) {
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/states?lang=${lang}`).then((statesResp) => {
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.gender && menuItems.gender.length > 0 && menuItems.gender !== undefined) {
                setGender(menuItems.gender.map((c) => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/genders?lang=${lang}`).then((gendersResp) => {
                    setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.employment && menuItems.employment.length > 0 && menuItems.employment !== undefined) {
                setEmployment(menuItems.employment.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/employments?lang=${lang}`).then((employmentResp) => {
                    setEmployment(employmentResp.data.body.map(c => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.education && menuItems.education.length > 0 && menuItems.education !== undefined) {
                setEducation(menuItems.education.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/educations?lang=${lang}`).then((educationResp) => {
                    setEducation(educationResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.income && menuItems.income.length > 0 && menuItems.income !== undefined) {
                setIncome(menuItems.income.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/incomes?lang=${lang}`).then((incomeResp) => {
                    setIncome(incomeResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.maritalStat && menuItems.maritalStat.length > 0 && menuItems.maritalStat !== undefined) {
                setMarital(menuItems.maritalStat.map((c) => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/marital-status?lang=${lang}`).then((maritalResp) => {
                    setMarital(maritalResp.data.body.map((c) => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.pronoun && menuItems.pronoun.length > 0 && menuItems.pronoun !== undefined) {
                setPronouns(menuItems.pronoun.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/pronouns?lang=${lang}`).then((pronounsResp) => {
                    setPronouns(pronounsResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.race && menuItems.race.length > 0 && menuItems.race !== undefined) {
                setRaceEthnicity(menuItems.race.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/races?lang=${lang}`).then((raceResp) => {
                    setRaceEthnicity(raceResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.language && menuItems.language.length > 0 && menuItems.language !== undefined) {
                setLanguage(menuItems.language.map((c) => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/languages?lang=${lang}`).then((languageResp) => {
                    setLanguage(languageResp.data.body.map((c) => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.disability && menuItems.disability.length > 0 && menuItems.disability !== undefined) {
                setDisability(menuItems.disability.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/disability?lang=${lang}`).then((disability) => {
                    setDisability(disability.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.programName && menuItems.programName.length > 0 && menuItems.programName !== undefined) {
                setProgramName(menuItems.programName.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/case-programName?lang=${lang}`).then((programNameId) => {
                    setProgramName(programNameId.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.assessmentType && menuItems.assessmentType.length > 0 && menuItems.assessmentType !== undefined) {
                setAssessmentType(menuItems.assessmentType.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/case-assessmentType?lang=${lang}`).then((assessmentTypeId) => {
                    setAssessmentType(assessmentTypeId.data.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.subRaces && menuItems.subRaces !== null && menuItems.subRaces !== '' && menuItems.subRaces !== undefined) {
                setSubAsians(menuItems.subRaces.subAsianRace.map((c) => ({ text: c.name, value: c.value })))
                setSubNative(menuItems.subRaces.subHawaiianRace.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/subraces?lang=${lang}`).then((subRaces) => {
                    setSubAsians(subRaces.data.body.subAsianRace.map(c => ({ text: c.name, value: c.value })))
                    setSubNative(subRaces.data.body.subHawaiianRace.map(c => ({ text: c.name, value: c.value })))
                })
            }
            API.get(`menus/militaryService`).then((militaryresp) => {
                setMilitaryService(militaryresp.data.body.militaryService.map(c => ({ text: c.name, value: c.value })))
                setMilitaryRank(militaryresp.data.body.militaryRank.map(c => ({ text: c.name, value: c.value })))
            })
            setDataLoad(true)
        }else{
            axios.all([API.get(`menus/states?lang=${lang}`), API.get(`menus/genders?lang=${lang}`), API.get(`menus/employments?lang=${lang}`),
            API.get(`menus/pronouns?lang=${lang}`), API.get(`menus/races?lang=${lang}`), API.get(`menus/marital-status?lang=${lang}`), API.get(`menus/educations?lang=${lang}`), API.get(`menus/languages?lang=${lang}`),
            API.get(`menus/incomes?lang=${lang}`), API.get(`menus/disability?lang=${lang}`), API.get(`menus/case-programName?lang=${lang}`), API.get(`menus/case-assessmentType?lang=${lang}`),API.get(`menus/subraces?lang=${lang}`)]).then(axios.spread((statesResp, gendersResp, employmentResp, pronounsResp,
                raceResp, maritalResp, educationResp, languageResp, incomeResp, disability, programNameId, assessmentTypeId,subRaces) => {
                setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setEmployment(employmentResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setPronouns(pronounsResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setMarital(maritalResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setEducation(educationResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setLanguage(languageResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setIncome(incomeResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setDisability(disability.data.body.map(c => ({ text: c.name, value: c.value })))
                setProgramName(programNameId.data.body.map(c => ({ text: c.name, value: c.value })))
                setAssessmentType(assessmentTypeId.data.body.map(c => ({ text: c.name, value: c.value })))
                setSubAsians(subRaces.data.body.subAsianRace.map(c=>({ text:c.name, value: c.value})))
                setSubNative(subRaces.data.body.subHawaiianRace.map(c=>({ text:c.name, value: c.value})))
                setDataLoad(true)
            }))
        }
        setYears(years.map(c => ({ text: c, value: c })))
    }, [])// eslint-disable-line  

    Yup.addMethod(Yup.string, 'EmptySpace', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    
    const profileFormSchema = !cgRefused ? Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required(t("FirstName is a required field")),
        middleName: Yup.string().validateName().nullable(),
        lastName: Yup.string().nullable().max(50).validateName().required(t("Last name is a required field")),
        preferredName: Yup.string().validateName().nullable(),
        address: Yup.object({
            addressLine1: cgDetail ? Yup.string().EmptySpace().nullable().required(t("Address is required")) : Yup.string().EmptySpace().nullable(),
            addressLine2: Yup.string().nullable(),
            city: Yup.string().nullable().validateName(),
            county: Yup.string().nullable().validateName(),
            state: Yup.string().required(t("State is a required field")).nullable(),
            postalCode: Yup.string().length(5, t('ZIP code must be a 5-digit number')).matches(/^[0-9]*$/, t("ZIP Code Field Only Numerics...")).required(t("ZIP Code is a required field")).nullable(),
        }),
        dateOfBirth: Yup.date().required(t("Please provide the required details")),
        fromDate: req && userDetails.role.includes('admin') && initialData.crDetails ? Yup.date().required(t("Please provide the required details")).nullable() : Yup.date().nullable(),
        toDate: req & userDetails.role.includes('admin') && initialData.crDetails ? Yup.date().nullable() : Yup.date().nullable(),
        homePhone: Yup.string().validatePhoneLength(),
        phone: initialData.crDetails ? Yup.string().nullable().validatePhoneLength() : Yup.string().nullable().validatePhoneLength().required(t("Phone is a required field")),
        emailAddress: Yup.string().validateEmail().nullable(),
        ssn: Yup.string().length(4, t("SSN number must be exactly 4 digits")).matches(/^[0-9]*$/, t("Only Numerics ...")).nullable(),
        language: Yup.string().nullable(),
        gender: Yup.string().nullable(),
        maritalStat: Yup.string().nullable(),
        relationShip: Yup.string().nullable(),
        employmentId: Yup.string(),
        educationId: Yup.string(),
        incomeId: Yup.string(),
        dobDay: Yup.number(),
        dobMonth: Yup.number(),
        dobYear: Yup.number(),
        phoneAuthorized: Yup.string().required(t("Required Field")),
        subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should numeric value")).required(t("Subscriber Id is a required field")): !subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should numeric value")): subreq && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
        assessTypeId: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")) : Yup.string(),
        pgNameId: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string(),
        otherAsianRace:Yup.object({
            otherSubRaceId: Yup.string(),
            otherSubRaceValue:otherAsianString ? Yup.string().EmptySpace().onlyAlphabet().nullable().required('Required') : Yup.string().nullable()
        }),
        otherNativeRace:Yup.object({
            otherSubRaceId: Yup.string(),
            otherSubRaceValue:otherNativeString ?  Yup.string().EmptySpace().onlyAlphabet().nullable().required('Required') : Yup.string().nullable()
        })
        // pcspDate: datePscp ? Yup.date().required(t("Date Of Associated PCSP is a required field")).nullable() : Yup.date().nullable()
    }) : Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required(t("FirstName is a required field")),
        middleName: Yup.string().validateName().nullable(),
        lastName: Yup.string().nullable().max(50).validateName().required(t("Last name is a required field")),
        preferredName: Yup.string().validateName().nullable(),
        address: Yup.object({
            addressLine1: cgDetail ? Yup.string().EmptySpace().nullable().required(t("Address is required")) : Yup.string().EmptySpace().nullable(),
            addressLine2: Yup.string().nullable(),
            city: Yup.string().nullable().validateName(),
            county: Yup.string().nullable().validateName(),
            state: Yup.string().required(t("State is a required field")).nullable(),
            postalCode: Yup.string().length(5, t('ZIP code must be a 5-digit number')).matches(/^[0-9]*$/, t("ZIP Code Field Only Numerics...")).required(t("ZIP Code is a required field")).nullable(),
        }),
        dateOfBirth: Yup.date(),
        fromDate: req && userDetails.role.includes('admin') && initialData.crDetails ? Yup.date().required(t("Please provide the required details")).nullable() : Yup.date().nullable(),
        toDate: req && userDetails.role.includes('admin') && initialData.crDetails ? Yup.date().nullable() : Yup.date().nullable(),
        phoneAuthorized: Yup.string().required(t("Required Field")),
        homePhone: Yup.string().validatePhoneLength(),
        phone: initialData.crDetails ? Yup.string().nullable().validatePhoneLength() : Yup.string().nullable().validatePhoneLength().required(t("Phone is a required field")),
        emailAddress: Yup.string().validateEmail().nullable(),
        ssn: Yup.string().length(4, t("SSN number must be exactly 4 digits")).matches(/^[0-9]*$/, "Only Numerics ...").nullable(),
        language: Yup.string().nullable(),
        gender: Yup.string().nullable(),
        maritalStat: Yup.string().nullable(),
        relationShip: Yup.string().nullable(),
        employmentId: Yup.string(),
        educationId: Yup.string(),
        incomeId: Yup.string(),
        dobDay: Yup.number(),
        dobMonth: Yup.number(),
        dobYear: Yup.number(),
        subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should numeric value")).required(t("Subscriber Id is a required field")): !subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should numeric value")): subreq && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
        disability: Yup.string(),
        assessTypeId: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")) : Yup.string(),
        pgNameId: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string(),
        // pcspDate: datePscp ? Yup.date().required(t("Date Of Associated PCSP is a required field")).nullable() : Yup.date().nullable()
        otherAsianRace:Yup.object({
            otherSubRaceId: Yup.string(),
            otherSubRaceValue:otherAsianString ? Yup.string().EmptySpace().onlyAlphabet().nullable().required(t('Required')).nullable() : Yup.string().nullable()
        }),
        otherNativeRace:Yup.object({
            otherSubRaceId: Yup.string(),
            otherSubRaceValue:otherNativeString ?  Yup.string().EmptySpace().onlyAlphabet().nullable().required(t('Required')).nullable() : Yup.string().nullable()
        })
    })

    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            if (!errWarning && !errWarning1 && !errOtherAsian && !errOtherNative) {
                setFormLoading(true)
                submitForm()
                setFormLoading(false)
            }

        }
    })
    const required = (val) => {
        if (val === true) {
            setReq(true)
        }
        else if (val === false) {
            setReq(false)
        }else{
            setReq(false)
        }
    }

    const getEmailAddress = (value) => {
        var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
        if (regex.test(value)) {
            API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${match?.params?.carereceiver_id}/emailValidate?email=${value}`).then((res) => {
                if (res.status === 200) {
                    setErrWarning1(false)
                    setNextButtonDisable(false)
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    setErrWarning1(true)
                    setCustomSubWarn1(err.response.data.errorMessage)
                    setNextButtonDisable(true)
                    // profileForm.setValues({ ...profileForm.values,...profileForm.values,emailAddress:''  })   
                }
            })
        } else {
            setErrWarning1(false)
            setNextButtonDisable(false)
        }
    }

    const getSubscriberId = (value) => {
        var regex = /^[a-zA-Z0-9_]*$/;
        if (value && regex.test(value) && tempCrSub !== value) {
            API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${match?.params?.carereceiver_id}/SubIdValidate?subId=${value}`).then((res) => {
                if (res.status === 200) {
                    setErrWarning(false)
                    setNextButtonDisable1(false)
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    setErrWarning(true)
                    setCustomSubWarn(err.response.data.errorMessage)
                    setNextButtonDisable1(true)
                    // profileForm.setValues({...profileForm.values,...profileForm.values,subscriberId:""})  
                }
            })

        } else {
            setErrWarning(false)
            setNextButtonDisable1(false)
        }
        setTempCrSub(value)
    }
    const getAddress = (value, field) => {
        if (value.length === 5) {
            API.get(`/intake/zones?zipCode=${value}`).then((res) => {
                setZipEnable(true)
                if (res.status === 200) {
                    if (field === 'cg') {
                        profileForm.setValues({ ...profileForm.values, ...profileForm.values, address: { ...profileForm.values.address, state: res.data.body.state, city: res.data.body.city, country: res.data.body.country, county: res.data.body.county } })
                    }
                }
            })
        } else if (value.length === 0) {
            setZipEnable(false)
            profileForm.setValues({ ...profileForm.values, ...profileForm.values, address: { ...profileForm.values.address, state: "".state, city: "", country: "", county: "" } })
        }
    }
    const setRefused = (side, value) => {
        if (side === 'cg') {
            setCgRefused(value)
        }
    }
    const toggleFormEdit = () => {
        if (editForm) profileForm.submitForm()
        if (!editForm) setEditForm(!editForm)
    }

    const togglecancel = () => {
        profileForm.resetForm()
        setCgRefused(false)
        setEditForm(false)
        setErrWarning(false)
        setCustomSubWarn()
        setNextButtonDisable1(false)
        setErrWarning1(false)
        setCustomSubWarn1()
        setNextButtonDisable(false)
        
        if (initialData.dobYear === 0) {
            setCgRefused(true)
        }
        if(initialData.vipStatus === true){
            setReq(true)
        }else{
            setReq(false)
        }
    }

    const Saveclient = () => {
        // setNextButtonDisable(true)
        // setNextButtonDisable1(true)
        profileForm.submitForm()
    }

    const changeRacevalues = ((val) => {
        if (val === 1) {
            profileForm.values.white = !profileForm.values.white;
        } else if (val === 2) {
            profileForm.values.black = !profileForm.values.black;
        } else if (val === 3) {
            profileForm.values.hispanic = !profileForm.values.hispanic;
        } else if (val === 4) {
            profileForm.values.asian = !profileForm.values.asian;
        } else if (val === 5) {
            profileForm.values.nativeAmerican = !profileForm.values.nativeAmerican;
        } else if (val === 7) {
            profileForm.values.middleEastern = !profileForm.values.middleEastern;
        } else if (val === 6) {
            profileForm.values.pacificIslander = !profileForm.values.pacificIslander;
        } else if (val === 9) {
            profileForm.values.refused = !profileForm.values.refused;
        } else if (val === 8) {
            profileForm.values.otherRace = !profileForm.values.otherRace;
        } else { }
    })

    const checkCgInsurances = (value, val) => {
        let checkedValue = val.name
        if (checkedValue === 'uninsured' && value) {
            profileForm.setValues({ ...profileForm.values, ...profileForm.values, medicaid: false, medicare: false, tricare: false, otherInsurer: false, uninsured: true })
        } else if (checkedValue === 'medicaid' && value) {
            profileForm.setValues({ ...profileForm.values, ...profileForm.values, medicaid: true, uninsured: false })
        } else if (checkedValue === 'medicare' && value) {
            profileForm.setValues({ ...profileForm.values, ...profileForm.values, medicare: true, uninsured: false })
        } else if (checkedValue === 'tricare' && value) {
            profileForm.setValues({ ...profileForm.values, ...profileForm.values, tricare: true, uninsured: false })
        } else if (checkedValue === 'otherInsurer' && value) {
            profileForm.setValues({ ...profileForm.values, ...profileForm.values, otherInsurer: true, uninsured: false })
        }
    }
    const submitForm = () => {
        if (profileForm.values.dateOfBirth && birthyear1) {
            profileForm.values.dateOfBirth = profileForm.values.dateOfBirth?.split('/')
            profileForm.values.dobDay = profileForm.values.dateOfBirth[2]
            profileForm.values.dobMonth = profileForm.values.dateOfBirth[1]
            profileForm.values.dobYear = profileForm.values.dateOfBirth[0]
        }
        let details = {
            address: {
                state: profileForm.values.address.state,
                country: profileForm.values.address.country,
                addressLine1: profileForm.values.address.addressLine1,
                addressLine2: profileForm.values.address.addressLine2,
                city: profileForm.values.address.city,
                county: profileForm.values.address.county,
                postalCode: profileForm.values.address.postalCode,
            },
            language: profileForm.values.language ? profileForm.values.language : null,
            areaCode: profileForm.values.areaCode,
            phone: profileForm.values.phone,
            careManagerId: userDetails.caremanagerId,
            personId: initialData.personId,
            emailAddress: profileForm.values.emailAddress ? profileForm.values.emailAddress : "",
            firstName: profileForm.values.firstName.trim(),
            lastName: profileForm.values.lastName.trim(),
            subscriberId: profileForm.values.subscriberId,
            dobDay: cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.dobDay) : 1,
            hispanic: profileForm.values.race.includes(3),
            dobMonth: cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.dobMonth) : 1,
            dobYear: cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.dobYear) : parseInt(profileForm.values.dateOfBirth ? profileForm.values.dateOfBirth : 0),
            fromDate: profileForm.values.fromDate ? profileForm.values.fromDate.toString().replace('/', '-').replace('/', '-').slice(0, 10) : null,
            toDate: profileForm.values.toDate ?profileForm.values.toDate.toString().replace('/', '-').replace('/', '-').slice(0, 10) : null,
            uninsured: profileForm.values.uninsured,
            maritalStat: profileForm.values.maritalStat ? profileForm.values.maritalStat : null,
            relationshipId: profileForm.values.relationShip ? profileForm.values.relationShip : null,
            caseId: history.location.state.caseId,
            pronouns: profileForm.values.pronouns ? profileForm.values.pronouns : null,
            nativeAmerican: profileForm.values.race.includes(5),
            pacificIslander: otherNativeValue.length > 0 ? true : profileForm.values.race.includes(6),
            multiRacial: profileForm.values.multiRacial,
            preferredName: profileForm.values.preferredName,
            ssn: profileForm.values.ssn,
            homePhone: profileForm.values.homePhone,
            gender: profileForm.values.gender ? profileForm.values.gender : null,
            employmentId: profileForm.values.employmentId ? profileForm.values.employmentId : 0,
            educationId: profileForm.values.educationId ? profileForm.values.educationId : 0,
            incomeId: profileForm.values.incomeId ? profileForm.values.incomeId : 0,
            white: profileForm.values.race.includes(1),
            black: profileForm.values.race.includes(2),
            asian: otherAsianValue.length > 0 ? true : profileForm.values.race.includes(4),
            middleEastern: profileForm.values.race.includes(7),
            middleNam: profileForm.values.middleName,
            concentToContat: contact === 'true' ? true : profileForm.values.phoneAuthorized,
            medicaid: profileForm.values.medicaid,
            medicare: profileForm.values.medicare,
            tricare: profileForm.values.tricare,
            otherInsurer: profileForm.values.otherInsurer,
            pgName: profileForm.values.pgNameId ? profileForm.values.pgNameId : 0,
            // pcspDate: profileForm.values.pcspDate ? profileForm.values.pcspDate : null,
            assessType: profileForm.values.assessTypeId ? profileForm.values.assessTypeId : 0,
            otherRace: profileForm.values.race.includes(8),
            refused: profileForm.values.race.includes(9),
            disability: profileForm.values.disability ? profileForm.values.disability : null,
            vipStatus: profileForm.values.vipStatus,
            ethnicity: profileForm.values.race.includes(9) ? [] : [...otherAsianValue,...otherNativeValue],
            otherAsianRace:{
                otherSubRaceId:profileForm.values.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.otherAsianRace.otherSubRaceValue !== null ? 29 :'',
                otherSubRaceValue:profileForm.values.otherAsianRace.otherSubRaceValue,
                raceId: profileForm.values.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.otherAsianRace.otherSubRaceValue !== null ? 4 : ''
            },
            otherNativeRace:{
                otherSubRaceId:profileForm.values.otherNativeRace.otherSubRaceValue !== ''&& profileForm.values.otherNativeRace.otherSubRaceValue !== null ? 35 : '',
                otherSubRaceValue:profileForm.values.otherNativeRace.otherSubRaceValue,
                raceId: profileForm.values.otherNativeRace.otherSubRaceValue !== ''&& profileForm.values.otherNativeRace.otherSubRaceValue !== null ? 6 : ''  
            },
            jobFamilyId: profileForm.values.jobFamilyId ? profileForm.values.jobFamilyId : 0,
            employeeTypeId: profileForm.values.employeeTypeId ? profileForm.values.employeeTypeId : 0,
            militaryServiceId: profileForm.values.militaryServiceId ?  profileForm.values.militaryServiceId :0,
            militaryRankId : profileForm.values.militaryRankId ? profileForm.values.militaryRankId : 0,
            enlistmentDate : profileForm.values.enlistmentDate ? profileForm.values.enlistmentDate.toString().replace('/', '-').replace('/', '-').slice(0, 10) : null,
            dischargeDate  : profileForm.values.dischargeDate ? profileForm.values.dischargeDate.toString().replace('/', '-').replace('/', '-').slice(0, 10) : null
        }
        if (!minDateError) {
            API.patch(`/people/${match?.params?.carereceiver_id}?orgId=${orgId}`, details).then(res => {
                dispatch(setAlert({ title: t('Success'), subtitle: t('User Updated Successfully'), type: 'success', active: true }))
                history.goBack()
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    if (profileForm.values.dateOfBirth && birthyear1) {
                        profileForm.values.dobDay = profileForm.values.dateOfBirth[2]
                        profileForm.values.dobMonth = profileForm.values.dateOfBirth[1]
                        profileForm.values.dobYear = profileForm.values.dateOfBirth[0]
                        profileForm.values.dateOfBirth = profileForm.values.dobYear + "/" + profileForm.values.dobMonth + "/" + profileForm.values.dobDay

                    }
                } else {
                    dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                }
            }).finally()
        }
    }

    useLayoutEffect(() => {
        var date = new Date(profileForm.values.fromDate)
        var date1 = new Date(profileForm.values.toDate)
        if (date > date1) {
            setMinDateError(true)
        }else if(date < date1) {
            setMinDateError(false)
        }
    }, [profileForm.values.fromDate,profileForm.values.toDate])// eslint-disable-line  

    const setOtherAsianSelect = (value) =>{
        if(otherAsianValue.includes(value)){
            let values = otherAsianValue.filter((c)=>c!==value)
            setOtherAsianValue(values)
        }else{
            setOtherAsianValue((prevValue)=>[...prevValue,value])
        }
    }

    // console.log(profileForm.values.race)
    const setNativeSelect = (value) =>{
        if(otherNativeValue.includes(value)){
            let values = otherNativeValue.filter((c)=>c!==value)
            setOtherNativeValue(values)
        }else{
            setOtherNativeValue((prevValue)=>[...prevValue,value])
        }
    }
   
    useLayoutEffect(()=>{
        if(profileForm.values.race.includes(29)){
            setOtherAsianString(true)
          }else{
            setOtherAsianString(false)
            profileForm.setValues({ ...profileForm.values, ...profileForm.values, otherAsianRace: { ...profileForm.values.otherAsianRace, otherSubRaceValue: "" } })
          }
    },[profileForm.values.race.includes(29)])
    useLayoutEffect(()=>{
        if(profileForm.values.race.includes(35)){
            setOtherNativeString(true)
          }else{
            setOtherNativeString(false)
            profileForm.setValues({ ...profileForm.values, ...profileForm.values, otherNativeRace: { ...profileForm.values.otherNativeRace, otherSubRaceValue: "" } })
          }
    },[profileForm.values.race.includes(35)])
   
    return (
        <div class="pb-8">
            <button className="flex mt-3 bg-tcolor px-5 py-1 rounded-lg items-center shadow-lg text-black ring-offset-2 hover:ring-2 hover:ring-tcolor dark:ring-offset-black whitespace-nowrap" onClick={() => history.goBack()}>
                <FaArrowLeft className="mr-3" /> {t("Back")}
            </button>
            <div className="text-center">
                <TabButtonGroup>
                    <TabButton index={0} value={tab} setValue={setTab}>
                        {t("Profile")}
                    </TabButton>
                    {((userDetails.orgId === parseInt(organization) && serviceEnable && (userDetails.role.includes('caremanager') || userDetails.role.includes('admin'))) ||(userDetails.orgId !== parseInt(organization) && serviceEnable && (userDetails.role.includes('dynamic_cm') || userDetails.role.includes('dynamic_admin')))) ? <TabButton index={1} value={tab} setValue={setTab}>
                        {t("Service Usage")}
                    </TabButton> : ''}
                    {/* <TabButton index={2} value={tab} setValue={setTab}>
                        {t("Associated Cases")}
                    </TabButton> */}
                </TabButtonGroup>
            </div>
            <TabGroup value={tab}>
                <Tab index={0} value={tab}>
                    <FormCard title={t("Basic Information")}>
                        <FavFormButton injectClass="absolute -top-5 right-5" edit={editForm} disabled={nextButtonDisable || nextButtonDisable1} handleClick={toggleFormEdit} handleCancel={togglecancel} />
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldInput ariaLabel={'First Name'} hideData={true} loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('firstName')} fieldProps={profileForm.getFieldProps('firstName')} edit={editForm} dataLoaded={dataLoaded} />
                            {/* <FieldInput loading={formLoading} label={t("Middle Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('middleName')} fieldProps={profileForm.getFieldProps('middleName')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'1'} /> */}
                            <FieldInput ariaLabel={'Last Name'} hideData={true} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('lastName')} fieldProps={profileForm.getFieldProps('lastName')} edit={editForm} dataLoaded={dataLoaded} />
                            {birthyear1 ? <FieldInput ariaLabel={'Date Of Birth'} disabled={cgRefused ? true : false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('dateOfBirth')} fieldProps={profileForm.getFieldProps('dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} /> :
                                <FieldSelect ariaLabel={'Birth Year Dropdown'} disabled={cgRefused ? true : false} loading={formLoading} label={t('Birth Year')} autoComplete="off" fieldMeta={profileForm.getFieldMeta('dateOfBirth')} fieldProps={profileForm.getFieldProps('dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('dateOfBirth')} options={years} edit={editForm} dataLoaded={dataLoaded} position="bottom" />
                            }
                            <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative">
                                {/* <FieldInput  disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={profileForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} /> */}
                                {editForm && cgRefused && <button style={{ height: 'fit-content', width: 'fit-content' }} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={() => setRefused('cg', false)}>{t('Refused')}</button>}
                                {editForm && !cgRefused && <button style={{ height: 'fit-content', width: 'fit-content' }} className="ring-2 rounded-lg p-1 bg-tgray" onClick={() => setRefused('cg', true)}>{t('Refused')}</button>}
                            </div>
                            <FieldInput ariaLabel={'Preferred Name'} loading={formLoading} label={t("Preferred Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('preferredName')} fieldProps={profileForm.getFieldProps('preferredName')} edit={editForm} dataLoaded={dataLoaded} />
                            {ssn === 'false' ? '' : <FieldInput ariaLabel={'SSN'} loading={formLoading} label={t("SSN(Last 4 digit)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('ssn')} fieldProps={profileForm.getFieldProps('ssn')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'4'} />}
                            {initialData.crDetails ? <FieldInput ariaLabel={'Subscriber ID'} loading={formLoading} label={t("Subscriber Id")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('subscriberId')} fieldProps={profileForm.getFieldProps('subscriberId')} edit={editForm} dataLoaded={dataLoaded} ml={true}  onBlur={(ev) => getSubscriberId(ev.target.value)} onPaste={(ev) => getSubscriberId(ev)} err={errWarning} customErrmsg={customsubWarn} /> : ''}
                            {programName1 ? <FieldSelect ariaLabel={'Program Name'} loading={formLoading} label={t('Program Name')} fieldMeta={profileForm.getFieldMeta('pgNameId')} fieldHelper={profileForm.getFieldHelpers('pgNameId')} fieldProps={profileForm.getFieldProps('pgNameId')} edit={editForm} options={programName} position="bottom" dataLoaded={dataLoaded} /> : ''}
                            {/* {datePscp ? <FieldInput loading={formLoading} type="date" label={t("Date Of Associated PCSP")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('pcspDate')} fieldProps={profileForm.getFieldProps('pcspDate')} fieldHelper={profileForm.getFieldHelpers('pcspDate')} edit={editForm} dataLoaded={dataLoaded} /> : ''} */}
                            {assessmentTypeId1 ? <FieldSelect ariaLabel={'Assessment Type Dropdown'} loading={formLoading} label={t("Assessment Type")} fieldMeta={profileForm.getFieldMeta('assessTypeId')} fieldHelper={profileForm.getFieldHelpers('assessTypeId')} fieldProps={profileForm.getFieldProps('assessTypeId')} edit={editForm} options={assessmentType} position="top" dataLoaded={dataLoaded} /> : ''}
                        </div>
                    </FormCard>
                    {vipEnable === 'true' && userDetails.role.includes('admin') && initialData.crDetails ? <FormCard title={t("VIP Information")} injectClass="mt-12">
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                            <FieldCheckbox loading={formLoading} label={t("VIP Member")} autoComplete='off' fieldHelper={profileForm.getFieldHelpers('vipStatus')} fieldMeta={profileForm.getFieldMeta('vipStatus')} fieldProps={profileForm.getFieldProps('vipStatus')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => required(ev)} />
                           {req ? <FieldInput ariaLabel={'Start Date'} loading={formLoading} type="date" minDate={new Date()} label={t("Start Date")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('fromDate')} fieldProps={profileForm.getFieldProps('fromDate')} fieldHelper={profileForm.getFieldHelpers('fromDate')} edit={editForm} dataLoaded={dataLoaded} />:''}
                            {req ? <FieldInput ariaLabel={'End Date'} loading={formLoading} disabled={!profileForm.values.fromDate} type="date" minDate={new Date(profileForm.values.fromDate)} label={t("End Date")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('toDate')} fieldProps={profileForm.getFieldProps('toDate')} fieldHelper={profileForm.getFieldHelpers('toDate')} edit={editForm} dataLoaded={dataLoaded} />:''}
                            <p></p>
                            <p></p>
                            {minDateError && req ? <p className="mt-0 text-red-500">Start Date should be Before the End Date</p> : ''}

                            {/* <div className="flex flex-1 flex-col">
                            </div> */}
                        </div>
                    </FormCard> : ''}
                    <FormCard title={t("Contact Information")} injectClass="mt-12">

                        <div>{t("Select client’s most commonly used method of communication and enter below.")}</div>
                        &nbsp;
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldInput ariaLabel={'Mobile Number'} hideData={true} loading={formLoading} type="phone" label={t("Mobile Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('phone')} fieldProps={profileForm.getFieldProps('phone')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Home Phone Number'} hideData={true} loading={formLoading} type="phone" label={t("Home Phone Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('homePhone')} fieldProps={profileForm.getFieldProps('homePhone')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Email Address'} hideData={true} loading={formLoading} label={t("Email")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('emailAddress')} fieldProps={profileForm.getFieldProps('emailAddress')} edit={editForm} dataLoaded={dataLoaded} onChange={(val) => getEmailAddress(val)} err={errWarning1} customErrmsg={customsubWarn1} />
                            {contact === 'true' ? '' : <FieldCheckbox loading={formLoading} label={t("Consented to be contacted")} autoComplete='off' fieldHelper={profileForm.getFieldHelpers('phoneAuthorized')} fieldMeta={profileForm.getFieldMeta('phoneAuthorized')} fieldProps={profileForm.getFieldProps('phoneAuthorized')} edit={editForm} dataLoaded={dataLoaded} />}
                        </div>
                    </FormCard>
                   {davService === 'true' ? <FormCard title={t("DAV Military Service")} injectClass="mt-12">
                    <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldSelect ariaLabel={'Service Branch'} loading={formLoading} label={t("Service Branch")} fieldMeta={profileForm.getFieldMeta('militaryServiceId')} fieldHelper={profileForm.getFieldHelpers('militaryServiceId')} fieldProps={profileForm.getFieldProps('militaryServiceId')} edit={editForm} options={militaryService} position="bottom" dataLoaded={dataLoaded} />
                    <FieldSelect ariaLabel={'Rank'} loading={formLoading} label={t("Rank")} fieldMeta={profileForm.getFieldMeta('militaryRankId')} fieldHelper={profileForm.getFieldHelpers('militaryRankId')} fieldProps={profileForm.getFieldProps('militaryRankId')} edit={editForm} options={militaryRank} position="bottom" dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={'Date of Enlistment'}  loading={formLoading} type="date" label={t("Date of Enlistment")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('enlistmentDate')} fieldProps={profileForm.getFieldProps('enlistmentDate')} fieldHelper={profileForm.getFieldHelpers('enlistmentDate')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={'Date of Discharge'}  loading={formLoading} type="date"minDate={profileForm.values !== undefined  && profileForm.values.enlistmentDate !== undefined && new Date(profileForm.values.enlistmentDate)} label={t("Date of Discharge")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('dischargeDate')} fieldProps={profileForm.getFieldProps('dischargeDate')} fieldHelper={profileForm.getFieldHelpers('dischargeDate')} edit={editForm} dataLoaded={dataLoaded} />
                    </div>
                    </FormCard> :''}
                    <FormCard title={t("Additional Information")} injectClass="mt-12">

                        <div><h1>{t("This helps identify the best resources for the client — not for eligibility requirements")}</h1></div>
                        &nbsp;
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldSelect ariaLabel={'Gender Dropdown'} loading={formLoading} label={t("Gender")} fieldMeta={profileForm.getFieldMeta('gender')} fieldHelper={profileForm.getFieldHelpers('gender')} fieldProps={profileForm.getFieldProps('gender')} edit={editForm} options={gender} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Education Level Dropdown'} loading={formLoading} label={t("Education Level")} fieldMeta={profileForm.getFieldMeta('educationId')} fieldHelper={profileForm.getFieldHelpers('educationId')} fieldProps={profileForm.getFieldProps('educationId')} edit={editForm} options={education} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Preferred Language Dropdown'} loading={formLoading} label={t("Preferred Language")} fieldMeta={profileForm.getFieldMeta('language')} fieldHelper={profileForm.getFieldHelpers('language')} fieldProps={profileForm.getFieldProps('language')} edit={editForm} options={language} position="top" dataLoaded={dataLoaded} />
                            {/* {otherAsian && <FieldSelect ariaLabel={'Sub Asian'} loading={formLoading} label={t("Sub Asian")} fieldMeta={profileForm.getFieldMeta('otherAsianRace.otherSubRaceId')} fieldHelper={profileForm.getFieldHelpers('otherAsianRace.otherSubRaceId')} fieldProps={profileForm.getFieldProps('otherAsianRace.otherSubRaceId')} edit={editForm} options={subAsian} position="bottom" dataLoaded={dataLoaded} onChange={(ev) => changeOtherAsian(ev)}  customErrmsg={"Required"} err={errOtherAsian} />} */}
                            {/* {otherNative && <FieldSelect ariaLabel={'Sub Native'} loading={formLoading} label={t("Sub Native")} fieldMeta={profileForm.getFieldMeta('otherNativeRace.otherSubRaceId')} fieldHelper={profileForm.getFieldHelpers('otherNativeRace.otherSubRaceId')} fieldProps={profileForm.getFieldProps('otherNativeRace.otherSubRaceId')} edit={editForm} options={subNative} position="bottom" dataLoaded={dataLoaded} onChange={(ev) => changeOtherNative(ev)} customErrmsg={"Required"} err={errOtherNative} />} */}
                            <FieldSelect ariaLabel={'Total Household Income Dropdown'} loading={formLoading} label={t("Total Household Income")} fieldMeta={profileForm.getFieldMeta('incomeId')} fieldHelper={profileForm.getFieldHelpers('incomeId')} fieldProps={profileForm.getFieldProps('incomeId')} edit={editForm} options={Income} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Marital Status Dropdown'} loading={formLoading} label={t("Marital Status")} fieldMeta={profileForm.getFieldMeta('maritalStat')} fieldHelper={profileForm.getFieldHelpers('maritalStat')} fieldProps={profileForm.getFieldProps('maritalStat')} edit={editForm} options={maritalStatus} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Employment Status Dropdown'} loading={formLoading} label={t("Employment Status")} fieldMeta={profileForm.getFieldMeta('employmentId')} fieldHelper={profileForm.getFieldHelpers('employmentId')} fieldProps={profileForm.getFieldProps('employmentId')} edit={editForm} options={employment} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Pronouns Dropdown'} loading={formLoading} label={t("Pronouns")} fieldMeta={profileForm.getFieldMeta('pronouns')} fieldHelper={profileForm.getFieldHelpers('pronouns')} fieldProps={profileForm.getFieldProps('pronouns')} edit={editForm} options={pronoun} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Disability Dropdown'} loading={formLoading} label={t("Disability")} fieldMeta={profileForm.getFieldMeta('disability')} fieldHelper={profileForm.getFieldHelpers('disability')} fieldProps={profileForm.getFieldProps('disability')} edit={editForm} options={disability} position="bottom" dataLoaded={dataLoaded} />
                           {orgRaceEnable ? <FieldMultiSelect ariaLabel={'Race/Ethnicity Multiselect Dropdown'} loading={formLoading} label={t("Race/Ethnicity")} fieldMeta={profileForm.getFieldMeta('race')} fieldHelper={profileForm.getFieldHelpers('race')} fieldProps={profileForm.getFieldProps('race')} edit={editForm} options={raceEthnicity} asians={subAsian} native={subNative} position="bottom" dataLoaded={dataLoaded} setAsian={(ev)=>setOtherAsianSelect(ev)} setNative={(ev)=>setNativeSelect(ev)} onChange={changeRacevalues(profileForm.getFieldProps('race'))} />:
                            <UserMultiSelect ariaLabel={'Race/Ethnicity Multiselect Dropdown'} loading={formLoading} label={t("Race/Ethnicity")} fieldMeta={profileForm.getFieldMeta('race')} fieldHelper={profileForm.getFieldHelpers('race')} fieldProps={profileForm.getFieldProps('race')} edit={editForm} options={raceEthnicity} position="bottom" dataLoaded={dataLoaded} onChange={changeRacevalues(profileForm.getFieldProps('race'))} />}
                            {otherAsianString && <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4" style={{width:'43rem'}}> <FieldInput ariaLabel={'Other Asian'} hideData={true} loading={formLoading} label={t("Other Asian")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('otherAsianRace.otherSubRaceValue')} fieldProps={profileForm.getFieldProps('otherAsianRace.otherSubRaceValue')} edit={editForm} dataLoaded={dataLoaded} /></div>}
                            {otherNativeString && <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4" style={{width:'43rem'}}> <FieldInput ariaLabel={'Other Native Hawaiian'} hideData={true} loading={formLoading} label={t("Other Native Hawaiian")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('otherNativeRace.otherSubRaceValue')} fieldProps={profileForm.getFieldProps('otherNativeRace.otherSubRaceValue')} edit={editForm} dataLoaded={dataLoaded} /></div>}
                            {employeeEnable ?<FieldSelect ariaLabel={'Employee Type Dropdown'} loading={formLoading} label={t("Employee Type")} fieldMeta={profileForm.getFieldMeta('employeeTypeId')} fieldHelper={profileForm.getFieldHelpers('employeeTypeId')} fieldProps={profileForm.getFieldProps('employeeTypeId')} edit={editForm} options={employeeGroup} position="bottom" dataLoaded={dataLoaded} />:''}
                            {jobFamilyEnable ?<FieldSelect ariaLabel={'Job Family Group Dropdown'} loading={formLoading} label={t("Job Family Group")} fieldMeta={profileForm.getFieldMeta('jobFamilyId')} fieldHelper={profileForm.getFieldHelpers('jobFamilyId')} fieldProps={profileForm.getFieldProps('jobFamilyId')} edit={editForm} options={jobFamilyGroup} position="bottom" dataLoaded={dataLoaded} />:''}
                        </div>
                    </FormCard>

                    <FormCard title={t("Primary Address")} injectClass="mt-12">
                        <div>{t("The primary address will be used as the default location for client resources")}</div>
                        &nbsp;
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldInput ariaLabel={'Zip Code'} loading={formLoading} label={t("ZIP Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.postalCode')} fieldProps={profileForm.getFieldProps('address.postalCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev) => getAddress(ev, 'cg')} />
                            <FieldInput ariaLabel={'Address Line 1'} loading={formLoading} label={t("Address Line 1")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine1')} fieldProps={profileForm.getFieldProps('address.addressLine1')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Address Line 2'} loading={formLoading} label={t("Address Line 2")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine2')} fieldProps={profileForm.getFieldProps('address.addressLine2')} edit={editForm} dataLoaded={dataLoaded} />
                            {/* <FieldInput loading={formLoading} label={t("Address Line 3")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine3')} fieldProps={profileForm.getFieldProps('address.addressLine3')} edit={editForm} dataLoaded={dataLoaded} /> */}
                            <FieldInput ariaLabel={'City'} loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.city')} fieldProps={profileForm.getFieldProps('address.city')} edit={editForm} dataLoaded={dataLoaded} />
                            {/* <FieldInput loading={formLoading} label={t("Country")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.country')} fieldProps={profileForm.getFieldProps('address.country')} edit={editForm} dataLoaded={dataLoaded} /> */}
                            <FieldSelect ariaLabel={'State Dropdown'} loading={formLoading} label={t("State")} fieldMeta={profileForm.getFieldMeta('address.state')} fieldHelper={profileForm.getFieldHelpers('address.state')} fieldProps={profileForm.getFieldProps('address.state')} edit={editForm} options={states} position="top" dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'County'} loading={formLoading} label={t("County")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.county')} fieldProps={profileForm.getFieldProps('address.county')} edit={editForm} dataLoaded={dataLoaded} />

                        </div>
                    </FormCard>

                    <FormCard title={t("Insurance")} injectClass="mt-12">

                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldCheckbox loading={formLoading} label={t("Medicaid")} fieldMeta={profileForm.getFieldMeta('medicaid')} fieldProps={profileForm.getFieldProps('medicaid')} fieldHelper={profileForm.getFieldHelpers('medicaid')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCgInsurances(ev, profileForm.getFieldProps('medicaid'))} />
                            <FieldCheckbox loading={formLoading} label={t("Medicare")} fieldMeta={profileForm.getFieldMeta('medicare')} fieldProps={profileForm.getFieldProps('medicare')} fieldHelper={profileForm.getFieldHelpers('medicare')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCgInsurances(ev, profileForm.getFieldProps('medicare'))} />
                            <FieldCheckbox loading={formLoading} label={t("Tricare")} fieldMeta={profileForm.getFieldMeta('tricare')} fieldProps={profileForm.getFieldProps('tricare')} fieldHelper={profileForm.getFieldHelpers('tricare')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCgInsurances(ev, profileForm.getFieldProps('tricare'))} />
                            <FieldCheckbox loading={formLoading} label={t("Other Insurer")} fieldMeta={profileForm.getFieldMeta('otherInsurer')} fieldProps={profileForm.getFieldProps('otherInsurer')} fieldHelper={profileForm.getFieldHelpers('otherInsurer')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCgInsurances(ev, profileForm.getFieldProps('otherInsurer'))} />
                            <FieldCheckbox loading={formLoading} label={t("Uninsured")} fieldMeta={profileForm.getFieldMeta('uninsured')} fieldProps={profileForm.getFieldProps('uninsured')} fieldHelper={profileForm.getFieldHelpers('uninsured')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCgInsurances(ev, profileForm.getFieldProps('uninsured'))} />
                        </div>
                    </FormCard>
                    {editForm &&
                        <div className="flex justify-center mt-7 mx-5">
                            <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700 hover:bg-opacity-80 text-white" onClick={() => setEditForm(false)}>{t("Cancel")}</button>
                            <button disabled={formLoading || nextButtonDisable || nextButtonDisable1} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => Saveclient()}>{t("Save")}</button>
                        </div>
                    }
                </Tab>
                <Tab index={1} value={tab}>
                    <ServiceUsage carereceiverId={match?.params?.carereceiver_id} caseId={history.location.state.caseId} />
                </Tab>
                {/* <Tab index={2} value={tab}>
                    <AssociateCase carereceiverId={match?.params?.carereceiver_id} />
                </Tab> */}
            </TabGroup>

        </div>
    )
}

export default ClientEdit